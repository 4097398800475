/* eslint-disable react/display-name */
import React from 'react'
import prop from 'lodash/fp/prop'
import { v4 as uuid } from 'uuid'

import { UserContext } from '../..'
import { CompareCredit } from '../../../../types/compare-credit'
import {
  LoadingSkeleton,
  SlimLoadingSkeleton,
} from '../../loading-skeleton-single-card-tile'
import ProductTipCard from './product-tip-card'

export const TipSingleCardTile =
  (data: {
    cardOrderIds: null | Record<string, string>
    referencedCards: null | Record<string, CompareCredit.Entities>
  }) =>
  (props: {
    value: {
      layoutSlim: boolean
      floatRight: boolean
      displayElement: { display?: string; breakpoint?: string }
      displayName: boolean
      isSticky: boolean
      hideMoreDetails: boolean
      featuredRibbon: boolean
      featuredRibbonText: string
      recommendedCredit: boolean
      asideCardTileMargin: string
      hideRatingCount: boolean
      hideTrustLabel: boolean
      hideLeadingBadge: boolean
      singleCardTip: {
        card: {
          _id: string
          slug: string
        }
        content: {
          children: any
        }[]
      }
      tag: CompareCredit.CategoryTag
      categoryTag: CompareCredit.CategoryTag
    }
  }) => {
    const {
      tag,
      categoryTag,
      singleCardTip,
      layoutSlim,
      floatRight,
      displayElement,
      displayName,
      isSticky,
      hideMoreDetails,
      featuredRibbon,
      featuredRibbonText,
      recommendedCredit,
      asideCardTileMargin,
      hideRatingCount,
      hideTrustLabel,
      hideLeadingBadge,
    } = props.value

    const { card } = singleCardTip
    const { breakpoint, display } = displayElement

    const { clientLocation } = React.useContext(UserContext)

    const [browserSize, setBrowserSize] = React.useState({
      height: 0,
      width: 0,
    })
    const [navHeight, setNavHeight] = React.useState(0)
    const [mainHeight, setMainHeight] = React.useState(0)
    const [innerAsideHeight, setInnerAsideHeight] = React.useState(0)
    const [asideTileDistanceFromNav, setAsideTileDistanceFromNav] =
      React.useState(0)
    const [cardTileIsSticky, setCardTileIsSticky] = React.useState(false)
    const [unstickCardTile, setUnstickCardTile] = React.useState(false)
    const [asideOverflow, setAsideOverflow] = React.useState(false)

    const [modalOpen, setModalOpen] = React.useState(false)
    const [query, setQuery] = React.useState('')
    const { cardOrderIds, referencedCards } = data

    const productTip = prop(card.slug, referencedCards)

    const externalId = tag ? tag.id : '80'
    const categoryId = categoryTag ? categoryTag.id : '80'

    const cardTileAsideRef = React.useRef<HTMLDivElement>(null)

    const [orderId, setOrderId] = React.useState<string | null>(null)
    React.useEffect(() => {
      const id = prop(card.slug, cardOrderIds) || uuid()
      setOrderId(id)
    }, [productTip])

    React.useEffect(() => {
      setQuery(window.location.search)
    }, [card.slug])

    React.useEffect(() => {
      setBrowserSize({
        height: window.innerHeight,
        width: window.innerWidth,
      })
      const handleResize = () =>
        setBrowserSize({
          height: window.innerHeight,
          width: window.innerWidth,
        })
      window.addEventListener('resize', handleResize)
      return () => {
        window.removeEventListener('resize', handleResize)
      }
    }, [])

    React.useEffect(() => {
      const nav = document.getElementById('navbar-container')
      if (nav) setNavHeight(nav.scrollHeight)

      const main = document.getElementById('main')
      if (main) setMainHeight(main.scrollHeight)

      const innerAside = document.getElementById('inner-aside')
      if (innerAside) {
        setInnerAsideHeight(innerAside.scrollHeight)
        setAsideOverflow(browserSize.height < innerAside.scrollHeight)
      }

      const asideTile = document.getElementById('aside')

      if (nav && asideTile)
        setAsideTileDistanceFromNav(asideTile.scrollHeight - navHeight)
    })

    // 1.0 = Sticky card tile - aside
    React.useEffect(() => {
      const cardTileAsideTpos =
        cardTileAsideRef.current === null
          ? 0
          : window.scrollY +
            cardTileAsideRef.current.getBoundingClientRect().top

      const handleStick = () =>
        setCardTileIsSticky(window.pageYOffset > cardTileAsideTpos - navHeight)

      window.addEventListener('scroll', handleStick)
      return () => {
        window.removeEventListener('scroll', handleStick)
      }
    }, [asideTileDistanceFromNav])

    // 2.0 = Card tile stick at bottom of aside
    React.useEffect(() => {
      const handleUnstick = () =>
        setUnstickCardTile(window.pageYOffset > mainHeight - innerAsideHeight)

      window.addEventListener('scroll', handleUnstick)
      return () => window.removeEventListener('scroll', handleUnstick)
    }, [mainHeight, innerAsideHeight])

    return productTip &&
      (productTip._type === 'card' || productTip._type === 'nonPaidCard') ? (
      <ProductTipCard
        cardTileAsideRef={cardTileAsideRef}
        cardTileIsSticky={cardTileIsSticky}
        asideCardTileMargin={asideCardTileMargin}
        isSticky={isSticky}
        asideOverflow={asideOverflow}
        unstickCardTile={unstickCardTile}
        featuredRibbon={featuredRibbon}
        featuredRibbonText={featuredRibbonText}
        floatRight={floatRight}
        layoutSlim={layoutSlim}
        productTip={productTip}
        categoryId={categoryId}
        externalId={externalId}
        orderId={orderId}
        query={query}
        displayName={displayName}
        recommendedCredit={recommendedCredit}
        clientLocation={clientLocation}
        referencedCards={referencedCards}
        cardOrderIds={cardOrderIds}
        singleCardTip={singleCardTip}
        setModalOpen={setModalOpen}
        modalOpen={modalOpen}
        hideMoreDetails={hideMoreDetails}
        hideRatingCount={hideRatingCount}
        hideTrustLabel={hideTrustLabel}
        hideLeadingBadge={hideLeadingBadge}
      />
    ) : (
      <div
        className={`${breakpoint}:${
          display === 'hidden' ? 'hidden' : 'block'
        } ${display === 'hidden' ? 'block' : 'hidden'} `}
      >
        {layoutSlim ? (
          <div
            className={`container-1 relative z-30 sm:float-right shrink-0 / w-full / mb-4 sm:ml-8 sm:mt-8 lg:mt-0 / sm:w-auto / sm:max-w-xs`}
          >
            <SlimLoadingSkeleton />
          </div>
        ) : (
          <aside
            className={`relative / lg:flex lg:flex-col / w-full h-full / mb-12 md:mb-0 ${
              cardTileIsSticky === true ? '' : asideCardTileMargin
            } `}
          >
            <LoadingSkeleton n={1} />
          </aside>
        )}
        <style jsx>{`
          @media (640px) {
            .container-1 {
              width: 320px;
              min-width: 320px;
            }
          }
        `}</style>
      </div>
    )
  }
