import React from 'react'
import Image from 'next/image'
import { nextImageHelper } from 'utils/'

export function TrustSecure(props: {
  hideTrustLabel?: boolean
  hideLeadingBadge?: boolean
}) {
  return (
    <div className="text-center">
      {props.hideTrustLabel ? (
        ''
      ) : (
        <h5 className="mb-3 / uppercase font-bold text-fs13 tracking-wider text-gray-500">
          Trust &amp; Secure
        </h5>
      )}

      <ul className="flex flex-col justify-center items-center / mb-0 pl-0">
        {props.hideLeadingBadge ? (
          ''
        ) : (
          <li className={`w-full mb-2 w-auto`}>
            <a
              href="https://www.inc.com/profile/compare-credit"
              target="_blank"
              rel="noreferrer"
              aria-label="visit inc 5000"
              className={`inline-block w-24 xl:w-28 / md:w-16 lg:w-24`}
            >
              <Image
                src="/static/as-seen-on/badge-inc-5000.png"
                height={72}
                width={72}
                alt="INC 5000"
                aria-hidden={true}
                style={nextImageHelper.responsive}
                sizes="100vw"
              />
            </a>
          </li>
        )}

        <li className="relative mb-4 w-32 h-16 mx-auto">
          <Image
            src="/static/logos/norton-base.svg"
            alt="Norton"
            aria-hidden="true"
            fill
            sizes="100vw"
          />
        </li>
      </ul>
    </div>
  )
}
