import React from 'react'
import Image from 'next/image'
import { nextImageHelper } from 'utils/'
import cn from 'utils/classnames'

export function AsSeenInRow(props: { align?: string }) {
  return (
    <div className="bg-gray-100 py-3 pb-2 -mx-4 xs:-mx-8 / lg:mx-0">
      <div
        className={cn('container-fluid w-full text-slate-400 xs:px-4', {
          'flex flex-row items-center justify-center':
            props.align === 'horizontal',
        })}
      >
        <p
          className={cn('font-semibold uppercase text-center leading-tight', {
            'text-fs11 shrink-0': props.align === 'horizontal',
            'text-fs13': props.align !== 'horizontal',
          })}
        >
          As Seen In
        </p>
        <ul
          className={cn('flex items-center justify-between text-center', {
            'w-auto': props.align === 'horizontal',
            'mx-auto max-w-xl': props.align !== 'horizontal',
          })}
        >
          <li className="flex justify-center items-center">
            <a
              href="https://www.inc.com/profile/compare-credit"
              target="_blank"
              rel="noreferrer"
              aria-label="visit inc 5000"
              className="w-10 sm:w-12 opacity-40 hover:opacity-100"
            >
              <Image
                src="/static/as-seen-on/badge-inc-5000-bw.png"
                height={72}
                width={72}
                alt="INC 5000"
                aria-hidden={true}
                style={nextImageHelper.responsive}
                sizes="100vw"
              />
            </a>
          </li>
          <li className="flex justify-center items-center / w-16 sm:w-18 mr-1 opacity-25">
            <Image
              src="/static/as-seen-on/pbs.svg"
              height={48}
              width={106}
              alt="PBS logo small"
              loading="lazy"
              style={nextImageHelper.intrinsic}
            />
          </li>
          <li className="flex justify-center items-center / w-18 sm:w-20 mx-1 opacity-25">
            <Image
              alt="Forbes logo small"
              height={23}
              width={90}
              src="/static/as-seen-on/forbes-black.svg"
              loading="lazy"
              style={nextImageHelper.intrinsic}
            />
          </li>
          <li className="flex justify-center items-center / w-12 mx-1 opacity-25">
            <Image
              alt="WSJ logo small"
              height={34}
              width={64}
              src="/static/as-seen-on/wsj-mark.svg"
              loading="lazy"
              style={nextImageHelper.intrinsic}
            />
          </li>
          <li className="flex justify-center items-center / w-12 ml-1 xs:mx-2 opacity-25">
            <Image
              alt="Tech Crunch logo small"
              height={24}
              width={48}
              src="/static/as-seen-on/tech-crunch-black.svg"
              loading="lazy"
              style={nextImageHelper.intrinsic}
            />
          </li>
          <li className="justify-center items-center / w-20 sm:w-[7.5rem] hidden sm:flex opacity-25">
            <Image
              alt="USA Today logo small"
              height={26}
              width={88}
              src="/static/as-seen-on/usa-today-black.svg"
              loading="lazy"
              sizes="100vw"
              style={nextImageHelper.responsive}
            />
          </li>
        </ul>
      </div>
    </div>
  )
}
