import React from 'react'
import Image from 'next/image'
import { isMobileOnly } from 'react-device-detect'
import { CompareCredit } from '../../../types/compare-credit'
import { UserContext } from '../UserContext'
import { nextImageHelper } from '../../utils'
import cn from 'utils/classnames'

export function AddToCompareBtn(props: {
  themeLight?: boolean
  card?: CompareCredit.FormattedCard
  detailsTop?: boolean
  inCart: boolean
  compact?: boolean
  width?: boolean
  align?: string
  updateCart: (
    arg0: 'add' | 'remove',
    card?: CompareCredit.FormattedCard,
  ) => void
}) {
  const [checkmarkIcon, setCheckmarkIcon] = React.useState(
    'icon-checkmark-black',
  )
  const [opacity, setOpacity] = React.useState('opacity-25')
  const [showAlert, setShowAlert] = React.useState(false)
  const { comparisonCart } = React.useContext(UserContext)
  const disabled = isMobileOnly
    ? comparisonCart.length >= 2
    : comparisonCart.length >= 3

  const handleUpdateCart = (action: 'add' | 'remove') =>
    props.card ? props.updateCart(action, props.card) : props.updateCart(action)

  return (
    <>
      <button
        className={cn(
          'c-compare-btn / flex items-center / mx-auto / transition-all ',
          {
            'c-compare-btn--light text-white': props.themeLight,
            'c-compare-btn--w-auto': props.width,
            'justify-start': props.align === 'left',
            'justify-center': props.align !== 'left',
            'text-gray-600': !props.themeLight,
            'c-compare-btn--sm w-full max-w-xs px-0 md:px-0 text-xs xs:text-sm':
              props.compact,
            'text-sm': !props.compact,
            'c-compare-btn--in-cart border-gray-400 text-primary-bright / c-compare-btn--added':
              props.inCart,
            'md:ml-0 py-2 text-lg md:text-base lg:text-lg w-full':
              props.detailsTop,
            'opacity-50 cursor-default': disabled && !props.inCart,
          },
        )}
        onClick={() => {
          if (props.inCart) {
            handleUpdateCart('remove')
          } else {
            if (!disabled) {
              handleUpdateCart('add')
              setCheckmarkIcon('icon-checkmark-primary-bright')
              setOpacity('opacity-100')
            } else {
              setShowAlert(true)
            }
          }
        }}
        onMouseEnter={() => {
          setCheckmarkIcon('icon-checkmark-primary-bright')
          setOpacity('opacity-100')
        }}
        onMouseLeave={() => {
          setCheckmarkIcon('icon-checkmark-black')
          setOpacity('opacity-25')
        }}
      >
        {/* <span className={`mr-2 ${props.comparisonDisabled && 'hidden'}`}> */}
        <span className={`mr-2`}>
          <span className="c-compare-toggle__icon / flex">
            <svg
              className="c-compare-toggle__tick"
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="21"
              viewBox="0 0 21 21"
            >
              <rect
                x="0"
                y="0"
                className="c-compare-toggle__rect-obj"
                width="21"
                height="21"
              ></rect>
              <path
                x="0"
                y="0"
                className="c-compare-toggle__box-line first"
                d="M21,0  L0,0  L0,21"
                fill="transparent"
              ></path>
              <path
                x="0"
                y="0"
                className="c-compare-toggle__box-line last"
                d="M0,21  L21,21  L21,0"
                fill="transparent"
              ></path>
              <polyline
                x="0"
                y="0"
                className="c-compare-toggle__tick-path hover-tick"
                strokeLinecap="round"
                points="6,11 10,15 15,6"
              ></polyline>
              <polyline
                x="0"
                y="0"
                className="c-compare-toggle__tick-path active-tick"
                strokeLinecap="round"
                points="6,11 10,15 15,6"
              ></polyline>
            </svg>
          </span>

          <img
            className={`hidden w-3 ${props.inCart && `w-4 ${opacity}`}`}
            alt=""
            src={`/static/icons/${
              props.inCart ? checkmarkIcon : 'icon-plus-primary-bright'
            }.svg`}
          />
        </span>
        {props.inCart ? 'Added to Compare' : 'Add to Compare'}
      </button>
      {showAlert && (
        <div
          className={
            'c-cart__alert / absolute z-10 left-0 / w-full / mx-auto mt-2 / px-4 md:px-0 /transition-25s'
          }
        >
          <div
            className={
              'relative / flex items-center justify-center / max-w-sm md:max-w-xs / mx-auto md:-mx-2 lg:-mx-4 / py-2 pl-6 pr-8 xxs:px-10 md:pl-4 md:pr-8 / text-fs13 text-left leading-tight text-primary / rounded-sm border-gray-300 shadow-lg bg-primary-light-bg'
            }
          >
            <p>
              You can only compare up to {isMobileOnly ? `2` : `3`} cards at
              once. Remove one or more cards before adding another to compare.
            </p>
            <button
              aria-label="close alert"
              className="flex items-center justify-center shrink-0 w-5 h-5 / absolute mt-1 mr-1 xxs:mt-2 xxs:mr-2 / top-0 right-0 / cursor-pointer / opacity-50 / border border-black rounded-full / hover:opacity-75 / transition-all--25"
              onClick={() => setShowAlert(false)}
            >
              <div className="jsx-1835740362 w-3">
                <Image
                  alt="close icon"
                  role="presentation"
                  src="/static/icons/icon-close-black.svg"
                  height={12}
                  width={12}
                  style={nextImageHelper.responsive}
                  sizes="100vw"
                />
              </div>
            </button>
          </div>
        </div>
      )}
    </>
  )
}
