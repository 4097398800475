import Cookies from 'js-cookie'
import { ALLOC } from '../sort/constants'
import { transformExperiment } from '../transform-experiment'

export function getExperiment() {
  const experimentCookie = Cookies.get(ALLOC)
  if (experimentCookie) {
    const experimentParsed = JSON.parse(experimentCookie)
    const inExp = experimentParsed?.experiment
    return inExp ? transformExperiment(experimentParsed?.experiment) : null
  } else {
    return null
  }
}
