import assoc from 'lodash/fp/assoc'
import compose from 'lodash/fp/compose'
import path from 'lodash/fp/path'
import * as Sentry from '@sentry/nextjs'

import { CompareCredit } from '../../types/compare-credit'

const getItem = (
  item: string,
  pathToVersion?: string[],
  compareVersion?: string,
): any => {
  const alloc = item === 'alloc'
  try {
    const found = localStorage.getItem(item)

    if (!found || found === 'null' || found === 'undefined') {
      return alloc ? { value: null, success: true } : null
    }
    return isJSON(found)
      ? compose(
          handleVersionCheck(item, pathToVersion, compareVersion),
          (x: any) => (alloc ? assoc('success', true, x) : x),
          JSON.parse,
        )(found)
      : alloc
      ? { value: found, success: true }
      : found
  } catch (e) {
    Sentry.withScope(function (scope) {
      scope.setFingerprint(['LocalStorage Error'])
      Sentry.captureException(e)
    })
    return alloc ? { value: null, success: false } : null
  }
}

/**
 * Get a value from local-storage. These "cached" values are stored as:
 *
 * `{ value: string; expires: number }`
 *
 * where expires is a timestamp stored as the unix value.
 * and returned as:
 * `{ value: string | null; success: boolean }`
 * where success is that of local-storage read/write capability.
 *
 * If the expiration is in the future, the value will be returned, but if the
 * expiration is in the past `null` will be returned and the value will be
 * removed from localStorage.
 */
const getCachedItem = (
  key: string,
): { value: string | null; success: boolean } => {
  const retrievedItem = getItem(key)
  const { value, success } = retrievedItem
  if (value && success) {
    if (new Date().valueOf() > (retrievedItem.expires || 0)) {
      removeItem(key)
      return { value: null, success }
    }
    return { value, success }
  }
  return { value, success }
}

const removeItem = (item: string): void => {
  try {
    localStorage.removeItem(item)
  } catch (err) {
    Sentry.withScope((scope) => {
      scope.setFingerprint(['LocalStorage Error'])
      Sentry.captureException(err)
    })
  }
}

const setItem = (
  item: string,
  payload: CompareCredit.LocalStoragePayload | string,
): void => {
  try {
    localStorage.setItem(item, JSON.stringify(payload))
  } catch (e) {
    Sentry.withScope(function (scope) {
      scope.setFingerprint(['LocalStorage Error'])
      Sentry.captureException(e)
    })
  }
}

/**
 * Set an item to local storage that can be retrieved until the TTL is expired,
 * at which point, the next access attempt will trigger that the item be removed
 * from local storage and null will be returned.
 *
 * To be used in conjunction with `getCachedItem`
 */
const setCachedItem = (
  /**
   * Key for local storage item.
   */
  key: string,
  /**
   * String value to be stored in item.
   */
  value: string,
  /**
   * Time in milleseconds from _now_ for this item to be cached. It will expire
   * after this time and the next access after expiration will return `null` and
   * trigger the removal of the item from localStorage.
   */
  ttl: number,
  /**
   * Time in milleseconds to be used for _now_. Mostly for the benefit of
   * providing a fixed time value for tests. Will default to using current
   * timestamp via `new Date()`.
   * @default `(new Date()).valueOf()`
   */
  _now?: number,
): void => {
  const currentTS = _now || new Date().valueOf()
  const data = {
    expires: currentTS + ttl,
    value,
  }

  setItem(key, data)
}

function handleVersionCheck(
  key: string,
  pathToVersion?: string[],
  compareVersion?: string,
): (data: any) => any {
  if (pathToVersion == null || compareVersion == null) {
    return (data: any) => data
  }

  const getStoredVersion = path(pathToVersion)
  return (data: any) => {
    if (getStoredVersion(data) === compareVersion) {
      return data
    } else {
      localStorage.removeItem(key)
      return null
    }
  }
}

function isJSON(str: string): boolean {
  const firstChar = str[0]
  return firstChar === '{' || firstChar === '['
}

export const ls = {
  getItem,
  getCachedItem,
  removeItem,
  setItem,
  setCachedItem,
}
