import React from 'react'

/**
 * The following is taken _almost_ verbatim from Dan Abramov's post regarding
 * the issues with using `setInterval` within React Hooks. He said we (the
 * public) could use it :-p
 *
 * https://overreacted.io/making-setinterval-declarative-with-react-hooks/
 */

type Callback = () => void
type Delay = number | null

export function useInterval(callback: Callback, delay: Delay) {
  const savedCallback = React.useRef<Callback>()

  React.useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  React.useEffect(() => {
    function tick() {
      savedCallback.current && savedCallback.current()
    }
    if (delay !== null) {
      const id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}
