import * as Sentry from '@sentry/nextjs'
import { encode } from '.'
import { RprServiceParams } from './rpr/rpr-service-helpers-types'

export async function getRevenuePerRedirect(
  params: RprServiceParams,
  host?: string,
): Promise<void> {
  const encoded = encode(params)
  try {
    const url = host
      ? `http://${host}/api/rpr/${encoded}/`
      : `/api/rpr/${encoded}/`

    await fetch(url)
  } catch (err) {
    Sentry.captureException(err, {
      contexts: {
        data: {
          params,
        },
      },
    })
    console.log('Get RPR Error', err)
  }
}
