import { startCheckout } from '../clients/segment'
import { Product } from '../fetch-data/products'

export const handleProductClickOut = (orderId: string, props: Product) => {
  const { productName, brand, category, revenue, network } = props
  startCheckout(
    {
      affiliation: brand,
      category,
      order_id: orderId,
      products: [
        {
          brand: brand,
          id: productName,
          price: revenue,
          product_id: productName,
          quantity: 1,
          sku: productName,
        },
      ],
      network,
      revenue,
    },
    false,
  ).then((r) => r)
}
