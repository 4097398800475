import { CompareCredit } from '../../../../types/compare-credit'
/* eslint-disable react/display-name */
import React from 'react'
import isEmpty from 'lodash/fp/isEmpty'
import propOr from 'lodash/fp/propOr'

import { CardTile } from './card-tile'
import { CardTileList } from './card-tile-list'
import { useExperiment } from '../../../utils/use-experiment-wrapper'

export const CardRelatedList =
  (data: { referencedCards: null | Record<string, CompareCredit.Entities> }) =>
  (props: {
    value: {
      cardTips: CompareCredit.Tip[]
      tag: CompareCredit.CategoryTag
      categoryTag: CompareCredit.CategoryTag
      title: string
      subtitle: string
      theme?: boolean
      pinnedCardTips?: CompareCredit.PinnedProduct[]
      cardOrderIds: null | Record<string, string>
    }
  }) => {
    const { categoryTag, tag, title, subtitle, theme } = props.value
    const { referencedCards } = data
    const categoryId = categoryTag ? categoryTag.id : '00'
    const externalId = tag ? tag.id : '00'

    // 1. CARDS
    const cardSlugs = props.value.cardTips.map((cardTip) => cardTip.card.slug)
    const cards = isEmpty(referencedCards)
      ? []
      : (cardSlugs
          .map((slug) => propOr(null, slug, referencedCards))
          .filter((x) => x) as CompareCredit.FormattedCard[])

    // 2024.1: EXP-204 Other Cards
    const { experiment: config204, isLoading: isLoading204 } = useExperiment(
      'exp_204_single_tips_other_cards',
    )
    const variation204 = config204?.value?.name || 'control'

    return (
      <>
        {!isLoading204 && (
          <section
            className={`c-related-cards / mt-6 mb-8 / leading-snug /  ${
              theme
                ? 'c-related-cards--list'
                : 'c-related-cards--tile px-4 pt-4 pb-8 bg-primary-light-bg rounded text-center'
            } hidden
            ${
              variation204 == 'v1-other-cards-tile'
                ? 'c-related-cards--v1'
                : variation204 == 'v2-other-cards-list'
                ? 'c-related-cards--v2'
                : ''
            }
      `}
          >
            <p
              className={`c-related-cards__title / text-primary font-bold / mb-0.5 ${
                theme ? 'text-lg' : 'font-serif text-2xl'
              }`}
            >
              {title}
            </p>
            <p
              className={`c-related-cards__subtitle / text-sm text-slate-700 ${
                theme ? '' : 'md:text-base'
              }`}
            >
              {subtitle}
            </p>
            <ul
              className={`c-related-cards__content / flex /
        ${
          theme
            ? 'flex-col mt-2 / border-t-2 border-slate-600'
            : 'flex-wrap justify-center items-stretch / md:flex-row mx-auto / px-4 lg:px-0 pt-8'
        }
      `}
            >
              {cards.map((card) => {
                return card._type === 'card' ? (
                  theme ? (
                    <li
                      key={card.slug}
                      className="c-related-cards__list / py-4 / border-b border-slate-200"
                    >
                      <CardTileList
                        card={card}
                        categoryId={categoryId}
                        externalId={externalId}
                      />
                    </li>
                  ) : (
                    <li
                      key={card.slug}
                      className={`c-related-cards__card / flex items-stretch / w-full max-w-xs /  ${
                        cards.length == 4
                          ? 'c-related-cards__card--4 md:w-1/2 px-4 lg:w-1/4 lg:px-2'
                          : cards.length == 3
                          ? 'c-related-cards__card--3 md:w-1/3 md:px-2 lg:px-4'
                          : cards.length == 2
                          ? 'c-related-cards__card--2 md:w-1/2 md:px-4'
                          : 'md:w-full'
                      }`}
                    >
                      <CardTile
                        card={card}
                        categoryId={categoryId}
                        externalId={externalId}
                      />
                    </li>
                  )
                ) : null
              })}
            </ul>
          </section>
        )}

        <style jsx>{`
          .c-related-cards__card {
            margin-bottom: 2.5rem;
          }
          .c-related-cards__card:last-child,
          .c-related-cards__card--4:nth-child(3) {
            margin-bottom: 0;
          }
          @media (min-width: 768px) {
            .c-related-cards__card--2 {
              margin-bottom: 0;
            }
          }
          @media (min-width: 1024px) {
            .c-related-cards__card {
              margin-bottom: 0;
            }
          }
        `}</style>
      </>
    )
  }
