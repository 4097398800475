import React from 'react'
import Image from 'next/image'
import { getViewsBySlug } from '../../fetch-data/utils/pageViews'

export function CardPopularPill() {
  const views = getViewsBySlug()
  if (!views) return null // if there are no views and no number, don't render
  const nf = new Intl.NumberFormat('en-US')
  const viewCount = nf.format(views)

  return (
    <div className="c-card-label / relative z-1 / text-center md:text-left -mb-4 -mt-2">
      <div className="c-card-label__inner / inline-block / w-auto">
        <div className="c-card-label__pill / relative overflow-hidden / flex flex-row items-center / w-auto / text-left text-fs13 leading-[1.1] / shadow-lg rounded-full bg-primary-bright">
          <div className="c-card-label__label / relative z-1 / flex flex-row items-center / pl-1 pr-3 py-1 / text-primary font-semibold / rounded-full bg-tetriary">
            <span className="c-card-label__icon / shrink-0 / inline-block w-7 h-7 / p-0.5 / mr-1.5 /  / bg-white rounded-full">
              <Image
                src="/static/icons/flame.svg"
                alt="flame"
                width={48}
                height={48}
              />
            </span>
            <span>Popular&nbsp;Card</span>
          </div>
          <div className="c-card-label__description / relative z-1 / px-3 sm:pr-5 py-0.5 / font-semibold text-white">
            {viewCount} People have viewed this offer in the last&nbsp;week
          </div>
          <div
            className="c-card-label__shine / absolute z-2 / w-16 / rotate-12"
            role="presentation"
          ></div>
        </div>
      </div>
      <style jsx>{`
        .c-card-label {
          opacity: 0;
          animation-name: fadeInOpacity, animateTopNorthRelative;
          animation-duration: 1s;
          animation-fill-mode: forwards;
          animation-delay: 0.75s;
        }
        .c-card-label__icon {
          background-color: #ffedd3;
        }
        .c-card-label__pill {
          background: linear-gradient(
            to right,
            rgba(16, 110, 251, 1) 0%,
            rgba(17, 158, 254, 1) 100%
          );
        }
        .c-card-label__shine {
          background: linear-gradient(
            to right,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.3) 100%
          );
          display: block;
          height: 100%;
          left: -75%;
          position: absolute;
          top: 0;
          transform: skewX(-25deg);
          width: 50%;
          animation: shine 5s infinite;
          animation-delay: 1.5s;
        }
        @keyframes shine {
          0% {
            left: -75%;
          }
          30% {
            left: 125%;
          }
          100% {
            left: 125%;
          }
        }
      `}</style>
    </div>
  )
}
