import React from 'react'

export const CalloutRenderer = (props: any) => {
  const { content, placement } = props.value
  return (
    <div
      className={`sm:inline-block sm:w-auto sm:max-w-xs / my-5 pl-6 py-2 md:py-1 / border-l-4 border-tetriary ${
        placement
          ? placement === 'left'
            ? 'sm:float-left sm:mr-8'
            : 'sm:float-right sm:ml-8'
          : 'sm:float-right sm:ml-8'
      }`}
    >
      <p className="text-primary-mid font-serif text-2xl leading-tight">
        {content}
      </p>
    </div>
  )
}
