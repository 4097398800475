import * as React from 'react'

type Props = {
  onLoad?: (src: string) => void
  onError?: (error: unknown) => void
  debug?: boolean
  src: string
}

const DEFAULT_ON_LOAD = () => undefined

const DEFAULT_ON_ERROR = (error: unknown) => {
  console.error(error)
}

export function ImgPixel({
  debug = false,
  onError = DEFAULT_ON_ERROR,
  onLoad = DEFAULT_ON_LOAD,
  src,
}: Props) {
  if (debug) {
    console.log(`[info]: prod would load pixel from ${src}`)
    return null
  }
  return (
    <img
      alt="ImgPìxel"
      src={src}
      width={0}
      style={{ height: 0, width: 0, opacity: 0 }}
      height={0}
      onError={(error) => onError(error)}
      onLoad={() => onLoad(src)}
    />
  )
}
