import React from 'react'
import Image from 'next/image'
import { nextImageHelper } from '../../utils'

export const BTBanner = () => {
  return (
    <div className="px-4 py-6 md:pt-8 md:pb-10 lg:pt-10 lg:pb-12 / rounded-lg bg-primary-light-bg">
      <h4 className="mb-4 md:mb-6 / font-bold text-primary text-center uppercase">
        How to choose the right balance transfer card
      </h4>

      <div className="flex flex-col md:flex-row / text-center">
        <div className="mb-4 md:mb-0 md:px-2">
          <Image
            alt="savings icon"
            src="/static/icons/icon-savings.svg"
            width={56}
            height={56}
            role="presentation"
            style={nextImageHelper.intrinsic}
          />
          <b className="inline-block w-full mb-1 text-primary">Know the fees</b>
          <p className="text-gray-700 text-sm">
            Make sure you factor in the balance transfer fee (typically 3% to
            5%) when choosing a card.
          </p>
        </div>

        <div className="mb-4 md:mb-0 md:px-2">
          <Image
            alt="zero percent"
            src="/static/icons/icon-zero-percent.svg"
            width={56}
            height={56}
            role="presentation"
            style={nextImageHelper.intrinsic}
          />
          <b className="inline-block w-full mb-1 text-primary">
            Avoid Paying Interest
          </b>
          <p className="text-gray-700 text-sm">
            Make sure you factor in the balance transfer fee (typically 3% to
            5%) when choosing a card.
          </p>
        </div>

        <div className="mb-4 md:mb-0 md:px-2">
          <Image
            alt="balance transfer"
            src="/static/icons/categories/balance-transfer.svg"
            width={56}
            height={56}
            role="presentation"
            style={nextImageHelper.intrinsic}
          />
          <b className="inline-block w-full mb-1 text-primary">
            Use a Different Provider
          </b>
          <p className="text-gray-700 text-sm">
            Make sure you factor in the balance transfer fee (typically 3% to
            5%) when choosing a card.
          </p>
        </div>
      </div>
    </div>
  )
}
