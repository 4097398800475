import * as React from 'react'

export function FadeIn(props: { children: React.ReactNode }) {
  const [transitionFinished, setTransitionFinished] = React.useState(false)

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setTransitionFinished(true)
    }, 200)

    return () => {
      clearTimeout(timeout)
    }
  }, [])

  return (
    <>
      <div
        className={`fade-wrapper min-h-screen ${
          transitionFinished ? `fade-in` : `fade-out`
        }`}
      >
        {props.children}
      </div>
      <style jsx>{`
        .fade-wrapper {
          position: relative;
          transition: all 0.4s ease;
        }

        .fade-wrapper.fade-out {
          top: 1.25rem;
          opacity: 0;
        }

        .fade-wrapper.fade-in {
          top: 0;
          opacity: 1;
        }
      `}</style>
    </>
  )
}
