import React from 'react'

interface Props {
  color?: string
  className?: string
}

export function ArrowUp(props: Props) {
  return (
    <svg
      width="11px"
      height="7px"
      viewBox="0 0 11 7"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <g
        id="Symbols"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          id="dropdown/nav/credit-cards"
          transform="translate(-702.000000, -19.000000)"
          stroke={props.color || 'black'}
          strokeWidth="1.5"
        >
          <g id="01-text-nav">
            <g transform="translate(567.000000, 0.000000)">
              <g id="Group-Copy-2" transform="translate(37.000000, 14.000000)">
                <polyline
                  id="Shape"
                  transform="translate(103.437730, 10.837730) rotate(-135.000000) translate(-103.437730, -10.837730) "
                  points="106.37546 7.89999964 106.37546 13.7754598 100.5 13.7754598"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
