/* eslint-disable react/display-name */
import React from 'react'
import prop from 'lodash/fp/prop'

import { CompareCredit } from '../../../types'
import { useFetchCardsSlug } from '../../utils'
import { buildSecureHref } from '../../utils/buildSecureHref'
import { getOrderId } from '../../utils/get-order-id'

// import { Statsig, StatsigContext } from 'statsig-react'

export const ApplyNowLink =
  (
    externalId?: string,
    orderId?: string | null,
    orderIdsBySlug?: { [key: string]: string },
  ) =>
  ({ children, value }: { children: any; value: { slug: string } }) => {
    const cardsSlug = value.slug ? [value.slug] : []
    const {
      cards: [productTip],
    } = useFetchCardsSlug(cardsSlug)

    // const { initialized } = React.useContext(StatsigContext)
    const externalTagId = externalId ? externalId : '00'

    const slug = productTip ? prop('slug', productTip) : ''

    let applyNowLinkParameters: CompareCredit.Card['applyNowLinkParameters'] =
      []
    if (
      productTip &&
      productTip._type !== 'product' &&
      productTip._type !== 'nonPaidCard'
    ) {
      applyNowLinkParameters = productTip?.applyNowLinkParameters
    }

    // Secure page exposure experiment
    // const [secureExp, setSecureExp] = React.useState<any>(null)
    // React.useEffect(() => {
    //   if (!initialized) return
    //   const exp = Statsig.getExperiment('secure_direct')
    //   const group = exp.get('group', 'control')
    //   setSecureExp(group)
    // }, [initialized])

    const href = buildSecureHref({
      orderId: getOrderId({ orderId, slug, orderIdsBySlug }),
      slug,
      externalId: externalTagId,
      pathName: typeof window !== 'undefined' ? window?.location?.pathname : '',
      linkParams: applyNowLinkParameters,
      expGroup: null,
    })

    return (
      <>
        {/* eslint-disable react/jsx-no-target-blank */}
        {slug ? (
          <a
            className="mt-8 mb-2 / text-primary-bright / hover:text-primary-mid"
            href={href}
            target="_blank"
          >
            {children}
          </a>
        ) : (
          <span>{children}</span>
        )}
      </>
    )
  }
