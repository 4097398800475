import compose from 'lodash/fp/compose'
import every from 'lodash/fp/every'
import filter from 'lodash/fp/filter'
import last from 'lodash/fp/last'
import map from 'lodash/fp/map'
import prop from 'lodash/fp/prop'
import slice from 'lodash/fp/slice'

import { CompareCredit } from '../../types/compare-credit'
import settings from '../ssg/settings/settings.json'

export function getPopularArticles(
  articles: CompareCredit.PopularArticle[],
): CompareCredit.PopularArticle[] {
  if (articles.length > 3) return articles
  return useDefaultArticles(articles)
}

function useDefaultArticles(
  articles: CompareCredit.PopularArticle[],
): CompareCredit.PopularArticle[] {
  const numNeeded = 4 - articles.length
  const defaultArticles: CompareCredit.PopularArticle[] = compose(
    // get only the number of default articles needed
    slice(0, numNeeded),
    // filter out any default articles that already exist on props.articles
    filter((defaultArticle: CompareCredit.PopularArticle) =>
      every(
        (i: boolean) => i,
        map(
          (article: CompareCredit.PopularArticle) =>
            article.slug !== defaultArticle.slug,
          articles,
        ),
      ),
    ),
    // use defaultPopularArticles property
    prop('defaultPopularArticles'),
    // get draft if on staging
    last,
  )(settings)
  return articles.concat(defaultArticles)
}
