import omitAll from 'lodash/fp/omitAll'
import path from 'lodash/fp/path'
import * as Sentry from '@sentry/nextjs'

import { CompareCredit } from '../../types/compare-credit'
import { replaceDomain } from './replace-domain'
import { appendToQueryString } from './urls'

export function getRedirectUrl({
  categoryId = '00',
  urlParams,
  product,
  tracking,
  isProd,
  hostURL,
}: {
  categoryId?: string
  urlParams?:
    | {
        [key: string]: string | undefined
      }
    | undefined
  product: CompareCredit.FormattedCard | CompareCredit.ProductGeneric
  tracking: { key: string; orderId: string }
  isProd: boolean
  hostURL?: URL
}): string {
  let href: string
  if (isCard(product)) {
    const profileId = urlParams?.profileId

    const profileApplyNow = path(
      ['applyNowLinkProfiles', profileId ?? '', 'applyNowLink'],
      product,
    )
    const applyNowLink = profileApplyNow || product.applyNowLink

    const applyNowLinkParams = omitAll(['profileId', 'utm_medium'], urlParams)
    //using this for capital one to send sharedid for utm_medium of native and social only
    const sharedid =
      urlParams?.utm_medium === 'native'
        ? '1'
        : urlParams?.utm_medium === 'social'
        ? '2'
        : urlParams?.utm_medium === 'pmax'
        ? '3'
        : undefined

    href = appendToQueryString(applyNowLink, {
      ...(applyNowLinkParams && applyNowLinkParams),
      [tracking.key]: tracking.orderId,
      ...(product.issuer.slug.current === 'capital-one' && {
        adplacement: `${categoryId}-0-1`,
      }),
      ...(product.issuer.slug.current === 'capital-one' &&
        sharedid && { sharedid }),
    })
  } else if (isProduct(product)) {
    const { link } = product
    const applyLinkParams = omitAll(['profileId'], urlParams)

    href = appendToQueryString(link, {
      ...(applyLinkParams && applyLinkParams),
      [tracking.key]: tracking.orderId,
    })
  } else {
    Sentry.captureException(
      new Error(
        'Should never get here but this is an error on the getRedirectUrl',
      ),
    )
    href = ''
  }

  return replaceDomain(href, isProd, hostURL)
}

function isCard(product: any): product is CompareCredit.FormattedCard {
  return (product as CompareCredit.FormattedCard)._type === 'card'
}

function isProduct(product: any): product is CompareCredit.ProductGeneric {
  return (product as CompareCredit.ProductGeneric)._type === 'product'
}
