import find from 'lodash/find'
import findKey from 'lodash/findKey'
import includes from 'lodash/includes'

import { CompareCredit } from '../../types/compare-credit'

type Slug = { slug: string; _id: string }

export function fetchRelatedCards(
  card: CompareCredit.FormattedCard,
  num: number,
  allCardSlugs: string[],
  cardsByCategory: {
    [key: string]: Slug[]
  },
) {
  const selectedCard = {
    card,
    category: getCategoryOfCard(card.slug, cardsByCategory) || 'best',
  }
  /**
   * This return statement accounts for 4 different scenarios:
   * 1. The selected card has enough cards in the `relatedCards` field - use those cards
   * 2. The selected card has some `relatedCards`, but not enough - use the `relatedCards`, and supplement
   *    with other cards from the same category or from the 'best' category
   * 3. The selected card doesn't have any `relatedCards` - use cards from the same category or from the
   *    'best' category
   * 4. If all else fails, use cards from the 'best' category
   */
  return (
    useRelatedCardsOnly(card, num, allCardSlugs) ||
    useRelatedAndCategoryCards(
      selectedCard,
      num,
      allCardSlugs,
      cardsByCategory,
    ) ||
    useCategoryCardsOnly(selectedCard, num, cardsByCategory) ||
    getCategoryCards(
      num,
      { card: selectedCard.card, category: 'best' },
      cardsByCategory,
    )
  )
}

function useRelatedCardsOnly(
  card: CompareCredit.FormattedCard,
  num: number,
  allCardSlugs: string[],
) {
  if (!card.relatedCards) return null
  if (card.relatedCards.length < num) return null

  const relatedSlugs = getCardSlugs(card)
  return getRelatedCards(relatedSlugs, num, allCardSlugs)
}

function useRelatedAndCategoryCards(
  selectedCard: {
    card: CompareCredit.FormattedCard
    category: string
  },
  num: number,
  allCardSlugs: string[],
  cardsByCategory: {
    [key: string]: Slug[]
  },
) {
  if (!selectedCard.card.relatedCards) return null
  if (selectedCard.card.relatedCards.length === 0) return null

  const relatedSlugs = getCardSlugs(selectedCard.card)
  const relatedCards = getRelatedCards(
    relatedSlugs,
    selectedCard.card.relatedCards.length,
    allCardSlugs,
  )

  const categoryCards = getCategoryCards(
    num - relatedCards.length,
    selectedCard,
    cardsByCategory,
    relatedCards,
  )
  if (relatedCards.length + categoryCards.length < num) {
    const bestCards = getCategoryCards(
      num - relatedCards.length - categoryCards.length,
      { card: selectedCard.card, category: 'best' },
      cardsByCategory,
      relatedCards.concat(categoryCards),
    )
    return relatedCards.concat(categoryCards).concat(bestCards)
  }
  if (relatedCards.length + categoryCards.length == num)
    return relatedCards.concat(categoryCards)
}

function useCategoryCardsOnly(
  selectedCard: {
    card: CompareCredit.FormattedCard
    category: string
  },
  num: number,
  cardsByCategory: {
    [key: string]: Slug[]
  },
) {
  const categoryCards = getCategoryCards(num, selectedCard, cardsByCategory)
  if (categoryCards.length == num) return categoryCards
  if (categoryCards.length < num) {
    const bestCards = getCategoryCards(
      num - categoryCards.length,
      { card: selectedCard.card, category: 'best' },
      cardsByCategory,
      categoryCards,
    )
    return categoryCards.concat(bestCards)
  }
}

function getRelatedCards(slugs: string[], num: number, allCardSlugs: string[]) {
  return allCardSlugs
    .filter((slug: string) => includes(slugs, slug))
    .reduce((acc: string[], val: string) => {
      const x = find(acc, (slug: string) => slug === val)
      return !x ? acc.concat([val]) : acc
    }, [])
    .slice(0, num)
}

function getCategoryCards(
  numNeeded: number,
  selectedCard: {
    card: CompareCredit.FormattedCard
    category: string
  },
  cardsByCategory: {
    [key: string]: Slug[]
  },
  relatedCards?: string[],
) {
  return cardsByCategory[selectedCard.category]
    .filter((card: { slug: string }) => card.slug !== selectedCard.card.slug)
    .filter((card: { slug: string }) =>
      relatedCards ? !includes(relatedCards, card.slug) : true,
    )
    .map((card: { slug: string }) => card.slug)
    .slice(0, numNeeded)
}

function getCategoryOfCard(
  slug: string,
  cardsByCategory: {
    [key: string]: Slug[]
  },
) {
  return findKey(cardsByCategory, (arr: any[]) =>
    find(arr, (card: any) => card?.slug === slug),
  )
}

function getCardSlugs(card: CompareCredit.FormattedCard) {
  return card.relatedCards.map((card) => card.slug)
}
