import __ from 'lodash/fp/__'
import assoc from 'lodash/fp/assoc'
import assocPath from 'lodash/fp/assocPath'
import compose from 'lodash/fp/compose'
import filter from 'lodash/fp/filter'
import flatten from 'lodash/fp/flatten'
import includes from 'lodash/fp/includes'
import map from 'lodash/fp/map'
import path from 'lodash/fp/path'
import pick from 'lodash/fp/pick'
import prop from 'lodash/fp/prop'
import reduce from 'lodash/fp/reduce'
import take from 'lodash/fp/take'
import values from 'lodash/fp/values'
import mapValues from 'lodash/mapValues'

import { CompareCredit } from '../../../types/compare-credit'
import { navOptionsBusiness } from '../business-nav'

const formatTextMap: { [key: string]: any } = {
  'credit-quality': (category: any) => {
    category.linkText = `Best Cards for ${category.text} Credit`
    return category
  },
  category: (category: any) => {
    if (category.slug === 'best') {
      category.linkText = `${category.text}`
      return category
    } else {
      category.linkText = `Best ${category.text} Credit Cards`
      return category
    }
  },
  main: (category: any) => {
    if (category.slug === 'best') {
      category.linkText = `${category.text}`
      return category
    } else {
      category.linkText = `Best ${category.text} Credit Cards`
      return category
    }
  },
  business: (category: any) => {
    if (category.slug === 'business_best') {
      category.linkText = `Top Business Credit Cards`
      return category
    } else {
      category.linkText = `Best ${category.text} Business Credit Cards`
      return category
    }
  },
}

/**
 * Based on the kind of category(ex. top cards, business, a regular category)
 * we want to format the hyperlink to show specific text
 */
export function addTextForCategories(navCategories: any) {
  return mapValues(navCategories, (value: any, key: string) => {
    const formatText = formatTextMap[key]
    return !formatText
      ? value
      : compose(
          assoc('items', __, value),
          map(formatText),
          prop('items'),
        )(value)
  })
}

/**
 * here we are:
 * removing categories that we want to hide -- dining and gas
 * sorting them to match the order of the mobile nav
 * limiting the number of categories to 6
 */
export function getCategoriesToRender(
  categories: CompareCredit.CardDetailProps['categoriesThatReferenceCard'],
) {
  // sorting them to match the order in the mobile nav
  const orderOfCategories = [
    'business',
    'best',
    'balance-transfer',
    'low-interest',
    'rewards',
    'cash-back',
    'student',
    'secured',
    'no-annual-fee',
    'no-foreign-transaction-fee',
    'travel',
    'business',
    '0-intro-apr',
    'excellent',
    'good',
    'fair',
    'poor',
    'limited',
  ]
  const sortCategories = (
    categories: CompareCredit.CategoryThatReferencesCard[],
  ) => {
    return categories.sort(
      (
        x: CompareCredit.CategoryThatReferencesCard,
        y: CompareCredit.CategoryThatReferencesCard,
      ) => {
        return (
          orderOfCategories.indexOf(x.slug.current) -
          orderOfCategories.indexOf(y.slug.current)
        )
      },
    )
  }

  const filterCategories = filter(
    (cat: CompareCredit.CategoryThatReferencesCard) => {
      return includes(cat.slug.current, orderOfCategories)
    },
  )
  return categories
    ? compose(take(6), sortCategories, filterCategories)(categories)
    : []
}

// building a mapping of data that is used to render the category to the UI
export function buildCategoryMap(navData: any) {
  return compose(
    reduce((acc: any, val: any) => {
      acc[val.slug] = val
      return acc
    }, {}),
    flatten,
    map(prop('items')),
    values,
    pick(['credit-quality', 'category', 'business', 'main']),
    addTextForCategories,
    assocPath(['business', 'items'], navOptionsBusiness),
    path(['credit-cards', 'sections']),
  )(navData)
}
