import isNil from 'lodash/fp/isNil'
import { v4 as uuid } from 'uuid'

import { getValueForParam } from './urls'

/**
 * Return user tracking info for apply now link
 */
export function userTrackingAttributes(affiliate: string): {
  key: string
  orderId: string
  isFallbackOrderId: boolean
} {
  const orderIdParam = getValueForParam(location.href, 'order_id')
  const orderId = orderIdParam || uuid()
  const isFallbackOrderId = isNil(orderIdParam)
  return {
    key: trackingKeyForAffiliate(affiliate),
    orderId,
    isFallbackOrderId,
  }
}

export function trackingKeyForAffiliate(affiliate: string): string {
  switch (affiliate) {
    case 'capital-one':
    case 'impact-radius':
      return 'subid1'
    case 'commission-junction':
      return 'sid'
    case 'rakuten':
      return 'u1'
    case 'commission-soup':
      return 's1'
    default:
      return ''
  }
}
