import React from 'react'

export const LinkV2 = ({
  children,
  value,
}: {
  children: React.ReactElement
  value: { href: string; url: string }
}) => {
  return (
    <a
      className="text-primary-bright / hover:text-primary-mid"
      href={value.href || value.url || ''}
      rel="noopener noreferrer"
      target="_blank"
    >
      {children}
    </a>
  )
}
