import React from 'react'
import Link from 'next/link'

export const BTGraphic = () => {
  return (
    <div className="lg:ml-8">
      <div className="flex / max-w-sm / mx-auto my-3 lg:my-2 / py-4 px-4 md:py-5 lg:px-6 lg:py-7 / shadow-lg bg-white rounded-lg">
        <table className="table-fixed border-collapse">
          <thead className="font-semibold text-xs lg:text-sm text-primary leading-tight">
            <tr>
              <th className="pr-2 xxs:pr-4">
                <span className="inline-block w-full / mb-2 pb-2 / text-right / border-b border-gray-400">
                  Balance owed on 18% APR Card
                </span>
              </th>
              <th>
                <span className="inline-block w-full / mb-2 pb-2 border-b border-gray-400 / text-left">
                  Total savings on 0% APR balance transfer card
                </span>
              </th>
            </tr>
          </thead>
          <tbody className="font-semibold text-gray-800 text-lg">
            <tr>
              <td className="lg:py-1 / pr-2 xxs:pr-4 / text-right">$10,000</td>
              <td>
                <span className="inline-block / w-5/12 h-2 lg:h-3 / lg:pb-2 / mr-2 / rounded-full bg-secondary"></span>{' '}
                <span className="text-secondary-dark">$1,431</span>
              </td>
            </tr>
            <tr>
              <td className="lg:py-1 / pr-2 xxs:pr-4 / text-right">$8,000</td>
              <td>
                <span className="inline-block / w-1/4 h-2 lg:h-3 / mr-2 / rounded-full bg-primary-bright"></span>{' '}
                <span className="text-primary-bright">$1,145</span>
              </td>
            </tr>
            <tr>
              <td className="lg:py-1 / pr-2 xxs:pr-4 / text-right">$5,000</td>
              <td>
                <span className="inline-block / w-1/4 h-2 lg:h-3 / mr-2 / rounded-full bg-primary-bright"></span>{' '}
                <span className="text-primary-bright">$715</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <p className="align-middle / md:mb-2 / text-center text-sm text-primary-bright / hover:text-primary-mid">
        <Link href="/credit-cards/best/balance-transfer">
          See the best balance transfer card offers
        </Link>
      </p>
    </div>
  )
}
