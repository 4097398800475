export const formatPinnedProducts = (
  pinnedProducts: {
    card: { _id: string; slug: string }
    frequencyPercent: number
  }[],
) => {
  if (pinnedProducts && pinnedProducts.length > 0) {
    return pinnedProducts.reduce(
      (acc: any, val: { card: { slug: string }; frequencyPercent: number }) => {
        if (val.card) {
          acc[val.card.slug] = val.frequencyPercent
        }
        return acc
      },
      {},
    )
  } else {
    return {}
  }
}
