import React from 'react'
import chunk from 'lodash/fp/chunk'
import pathOr from 'lodash/fp/pathOr'
import Image from 'next/image'
import Link from 'next/link'

import { CompareCredit } from '../../../types/compare-credit'
import { HubPagination } from '../credit-cards-hub/pagination'
import cn from 'utils/classnames'

const TipListItem = (props: { tip: CompareCredit.HubTipsList }) => {
  const { tip } = props

  const navigation = tip.route?.slug
    ? `${tip.route.slug}`
    : `/credit-cards/tips/${tip.slug.current}`

  return (
    <article
      className="c-article-trending / flex flex-row items-stretch justify-center / py-3 lg:py-4 xl:py-5 / border-b border-gray-300"
      key={tip._id}
    >
      <Link
        href={navigation}
        className="c-article-link / relative w-full / flex items-stretch / transition"
      >
        <span className="relative flex items-stretch / h-14 xs:h-16 sm:h-20 lg:h-32 w-1/4 xl:w-1/3 / rounded overflow-hidden">
          <Image
            alt=""
            className="c-article-link__img / transform transition"
            placeholder="blur"
            blurDataURL={tip.image.metadata.lqip}
            src={tip.image.url}
            fill
            sizes="100vw"
            style={{ objectFit: 'cover' }}
          />
        </span>
        <div className="flex flex-col items-start justify-center / w-3/4 / px-5 lg:pl-6 xl:pl-8 ">
          <h3
            id="teste-h3"
            className="c-article-link__title / mb-2 / font-bold text-primary text-sm xs:text-base xl:text-lg leading-tight / hover:text-primary-bright / transition"
          >
            {tip.headline}
          </h3>
          <p className="hidden lg:block / text-fs13 text-gray-700">
            {pathOr([], ['content', 0, 'children'], tip)
              .map((i: any) => i.text)
              .toString()
              .split(' ')
              .slice(0, 18)
              .join(' ')}
            ...
          </p>
        </div>
      </Link>
    </article>
  )
}

export const TipsList = (props: {
  tips: {
    articles: CompareCredit.HubTipsList[]
    reviews: CompareCredit.HubTipsList[]
  }
}) => {
  const { tips } = props
  const NUMER_TIPS_TO_SHOW = 10
  const initialList = tips.articles.length > 0 ? tips.articles : tips.reviews
  const tipsArticlesChunked = chunk(
    NUMER_TIPS_TO_SHOW,
    tips.articles
      .filter(
        (tip: CompareCredit.HubTipsList) =>
          tip.image !== undefined && !tip.excludeFromHub,
      )
      .slice()
      .sort((a, b) => b.pageViews - a.pageViews),
  )
  const tipsReviewsChunked = chunk(
    NUMER_TIPS_TO_SHOW,
    tips.reviews
      .filter(
        (tip: CompareCredit.HubTipsList) =>
          tip.image !== undefined && !tip.excludeFromHub,
      )
      .slice()
      .sort((a, b) => b.pageViews - a.pageViews),
  )

  const [tipsChunked, setTipsChunked] = React.useState<
    CompareCredit.HubTipsList[][]
  >(() => {
    return chunk(
      NUMER_TIPS_TO_SHOW,
      initialList
        .filter(
          (tip: CompareCredit.HubTipsList) =>
            tip.image !== undefined && !tip.excludeFromHub,
        )
        .slice()
        .sort((a, b) => b.pageViews - a.pageViews),
    )
  })

  const [pageCount, setpageCount] = React.useState(tipsChunked.length)
  const [page, setPage] = React.useState(0)
  const [pageArticlesList, setPageArticlesList] = React.useState(0)
  const [pageReviewsList, setPageReviewsList] = React.useState(0)
  const [tipsFilter, setTipsFilter] = React.useState<'articles' | 'reviews'>(
    'articles',
  )

  const scrollToTopTipsContainer = function () {
    const navBarContainer = document.getElementById('navbar-container')
    const tipsContainer = document.getElementById('tips-container')
    const navBarContainerPosition = navBarContainer
      ? navBarContainer.getBoundingClientRect().bottom
      : 0
    const tipsContainerPosition = tipsContainer ? tipsContainer.offsetTop : 0
    const offsetPosition = tipsContainerPosition - navBarContainerPosition
    if (offsetPosition > 0) {
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      })
    }
  }

  const handlePageClick = React.useCallback(
    (e: Event, selectedPage: number | '...') => {
      e.preventDefault()
      if (selectedPage !== '...') {
        if (selectedPage < 0) {
          setPage(0)
        } else if (selectedPage > pageCount - 1) {
          setPage(pageCount - 1)
        } else {
          setPage(selectedPage)
        }
        scrollToTopTipsContainer()
        handleFilterPageChange(selectedPage)
      }
    },
    [setPage, tipsFilter, pageCount],
  )

  const handleFilterPageChange = React.useCallback(
    (selectedPage: number) => {
      tipsFilter === 'articles'
        ? setPageArticlesList(selectedPage)
        : setPageReviewsList(selectedPage)
    },
    [tipsFilter, setPageArticlesList, setPageReviewsList],
  )

  const handleArticleTips = React.useCallback(() => {
    setTipsFilter('articles')
    setPage(pageArticlesList)
    setTipsChunked(tipsArticlesChunked)
    setpageCount(tipsArticlesChunked.length)
  }, [setTipsFilter, setPage, pageArticlesList, setTipsChunked, setpageCount])

  const handleRecentTips = React.useCallback(() => {
    setTipsFilter('reviews')
    setPage(pageReviewsList)
    setTipsChunked(tipsReviewsChunked)
    setpageCount(tipsReviewsChunked.length)
  }, [setTipsFilter, setPage, pageReviewsList, setTipsChunked, setpageCount])

  return (
    <div
      id="tips-container"
      className="lg:flex lg:flex-row / max-w-lg lg:max-w-none / mx-auto mb-12 lg:mb-24"
    >
      <div className="w-full / mb-20 lg:mb-0 / lg:pr-6">
        <div className="w-full / pb-3 / text-primary / border-b-3 border-gray-200">
          {tipsArticlesChunked.length > 0 && (
            <button
              className={cn(
                'mr-4 / py-2 px-8 / font-bold / rounded-full outline-none focus:outline-none',
                {
                  'text-white bg-primary-bright border-primary-bright':
                    tipsFilter === 'articles',
                  'border text-gray-600 border-gray-400':
                    tipsFilter !== 'articles',
                },
              )}
              onClick={handleArticleTips}
            >
              Articles
            </button>
          )}
          {tipsReviewsChunked.length > 0 && (
            <button
              className={cn(
                'mr-4 / py-2 px-8 / font-bold / rounded-full outline-none focus:outline-none',
                {
                  'text-white bg-primary-bright border-primary-bright':
                    tipsFilter === 'reviews',
                  'border text-gray-600 border-gray-400':
                    tipsFilter !== 'reviews',
                },
              )}
              onClick={handleRecentTips}
            >
              Reviews
            </button>
          )}
        </div>
        <div className="flex flex-col items-stretch">
          {tipsChunked &&
            tipsChunked[page].map((tip) => (
              <TipListItem key={tip._id} tip={tip} />
            ))}

          <HubPagination
            page={page}
            setPage={handlePageClick}
            pageCount={pageCount}
          />
          <div className=""></div>
        </div>
      </div>

      <style jsx>
        {`
          .c-header {
            background: linear-gradient(92.43deg, #f5fafc 0.53%, #f2fff3 99.9%);
          }
          @media (min-width: 1024px) {
            .c-header__title {
              font-size: 2.5rem;
            }
          }
          @media (min-width: 1152px) {
            .c-header__title {
              font-size: 2.75rem;
            }
          }
          .c-article-link:hover .c-article-link__img {
            transform: scale(1.05);
          }
          .c-article-link:hover .c-article-link__title {
            color: #21a3fc;
          }
          .c-article-trending {
            min-height: 4.5rem;
          }
        `}
      </style>
    </div>
  )
}
