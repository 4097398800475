import React from 'react'
import Image from 'next/image'
import { Tag } from './index'
import { ClickawayListener } from '../../'
import { CreditDisclosure } from '../../credit-disclosure'
import { nextImageHelper } from '../../../utils'

interface Props {
  handleFilter: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    idx: number,
  ) => void
  tags: Tag[]
  selectedTag: string
  isCreditScoreFilter?: boolean
}

export function MobileFilter(props: Props) {
  const [dropdownActive, setDropdownActive] = React.useState(false)
  const { handleFilter, tags, selectedTag, isCreditScoreFilter } = props

  const handleClickOnMenu = () => setDropdownActive(!dropdownActive)

  const handleClickOnFilter = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    idx: number,
  ) => {
    handleFilter(event, idx)
    setDropdownActive(false)
  }

  return (
    <ClickawayListener setState={setDropdownActive}>
      <div className="sm:flex sm:flex-row sm:items-center sm:justify-center / bg-primary-light-bg block font-body lg:hidden p-3 rounded-sm sm:rounded-full w-full / mx-auto">
        {!isCreditScoreFilter ? (
          <div className="relative z-30 / mb-1 sm:mr-3 lg:mr-0 xl:mr-4 / xxs:px-2 / text-sm lg:text-fs13 xl:text-sm text-center">
            <span className="font-semibold text-primary">
              Filter offers by card categories
            </span>{' '}
            <span className="inline-block xs:w-auto">
              <CreditDisclosure
                theme="t-align-rt"
                adjustPopup="lg-center"
                id="filter-credit-disclosure"
                content="We have selected the top credit card categories for you to explore."
              />
            </span>
          </div>
        ) : (
          <div className="relative z-30 / mb-1 sm:mr-3 lg:mr-0 xl:mr-4 / xxs:px-2 / text-sm lg:text-fs13 xl:text-sm text-center">
            <span className="font-semibold text-primary">
              Filter offers by your credit score
            </span>{' '}
            <span className="inline-block xs:w-auto">
              <CreditDisclosure
                theme="t-align-rt"
                adjustPopup="lg-center"
                id="filter-credit-disclosure"
              />
            </span>
          </div>
        )}
        <div className="relative flex items-center w-full sm:w-auto">
          <button
            className={`${
              dropdownActive
                ? 'bg-white shadow-lg border-primary-light'
                : 'bg-white shadow'
            } relative / w-full xxs:w-64 / mx-auto / focus:outline-none py-1 px-6 / rounded-full border border-transparent /
            text-center text text-primary font-semibold / hover:shadow-lg hover:border-primary-light / transition-all`}
            value={selectedTag || tags[0].label}
            onClick={handleClickOnMenu}
          >
            <div className="flex flex-row items-center justify-center / w-full">
              {selectedTag ? (
                tags
                  .filter((tag: Tag) => tag.id === selectedTag)
                  .map((tag: Tag) => (
                    <div
                      key={tag.id}
                      className="flex justify-center items-center"
                    >
                      {tag.icon && (
                        <img className="w-6 mr-2" alt="" src={tag.icon} />
                      )}
                      <span>{tag.label}</span>
                      <span className="inline-block ml-1.5 text-gray-600 text-fs12 font-normal">
                        {tag.range}
                      </span>
                    </div>
                  ))
              ) : (
                <div className="flex justify-center items-center">
                  {tags[0].icon && (
                    <img className="w-6 mr-2" alt="" src={tags[0].icon} />
                  )}
                  <span>{tags[0].label}</span>
                </div>
              )}
              <span
                className={`inline-block / w-4 / ml-3 / transition-all my-auto opacity-40 ${
                  dropdownActive ? 'rotate-180' : ''
                }`}
              >
                <Image
                  alt="chevron"
                  src="/static/icons/icon-chevron-d-black.svg"
                  role="presentation"
                  aria-hidden={true}
                  width={16}
                  height={16}
                  style={nextImageHelper.responsive}
                  sizes="100vw"
                />
              </span>
            </div>
          </button>

          {/* dropdown menu */}
          <div
            className={`c-dropdown__menu / absolute z-30 / xxs:w-64 mx-auto ${
              dropdownActive ? '' : 'is-closed'
            }`}
          >
            <ul className="c-dropdown__list  / flex flex-wrap shadow-md / rounded-lg bg-white px-2 py-2 / xs:px-1 xs:py-3">
              {tags.map((tag: Tag, idx: number) => (
                <li className="w-full / mb-1" key={tag.id}>
                  <button
                    className={`${
                      selectedTag === tag.id
                        ? 'bg-primary-light-bg'
                        : 'bg-white'
                    } focus:outline-none focus:text-primary-bright focus:bg-primary-light-bg / w-full / py-1 px-4 / xs:pr-2 / rounded-full text-primary
              text-left cursor-pointer / hover:bg-primary-light-bg / transition-all`}
                    value={tag.id}
                    onClick={(e) => handleClickOnFilter(e, idx)}
                  >
                    <div className="flex items-center">
                      {tag.icon && (
                        <img className="w-6 mr-2" alt="" src={tag.icon} />
                      )}
                      <span>{tag.label}</span>
                    </div>
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <style jsx>{`
        .c-dropdown__arrow {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 1.25rem;
        }
        .c-dropdown__menu {
          top: 100%;
          left: 0;
          right: 0;
          max-height: 60rem;
          opacity: 1;
          overflow: visible;
          transition: all 0.25s ease-in-out;
        }
        .c-dropdown__menu.is-closed {
          max-height: 0;
          opacity: 0;
          overflow: hidden;
        }
      `}</style>
    </ClickawayListener>
  )
}
