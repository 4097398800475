import { CompareCredit } from '../../types/compare-credit/index.d'
import {
  separateEntities,
  insertPinnedIntoOriginalIdx,
} from './sort/client/sortCardsByRpi'
import Tip = CompareCredit.Tip

export function pinEntities(
  entities: CompareCredit.Entities[] | Tip[],
  pinnedProducts: CompareCredit.PinnedProductFormatted,
  pinnedPositions: Record<string, number>,
) {
  const { nonPinned, pinned } = separateEntities(
    entities,
    pinnedProducts,
    pinnedPositions,
  )
  const allEntitiesSorted: any = insertPinnedIntoOriginalIdx(pinned, nonPinned)
  return allEntitiesSorted
}
