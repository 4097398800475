import * as Sentry from '@sentry/nextjs'

import React, { useContext, useEffect } from 'react'
import { SortSelectContext, UserContext } from '../components'
import { StatsigContext, useStatsigClient } from '@statsig/react-bindings'
import {
  handleFetchResponses,
  handleFetchs,
  useFetchCardsSlugComplexReturn,
} from './use-fetch-cards-helper'

import { CompareCredit } from '../../types'
import { SORT_DATA } from './sort/types/experimentation-types'
import { SORT_MAP } from './sort/sort-map'
import assocPath from 'lodash/fp/assocPath'
import { checkIfEmptyCardsList } from '.'
import { formatLocation } from './formatLocation'
import { getExpSlugsToHide } from './experimentation/get-exp-slugs-to-hide'
import getUserAgentInfo from './getUserAgentInfo'
import { handleSort } from './sort/server/sort-handler'
import includes from 'lodash/fp/includes'
import map from 'lodash/fp/map'
import { setPositionBias } from './sort/client/set-position-bias'
import { sortResponseInterface } from './sort/types'

export function useFetchCardsSlugComplex(
  slugs: string[],
  sortData?: { categoryId: string },
  cb?: (
    entities: CompareCredit.Entities[],
    sort?: string,
  ) => CompareCredit.Entities[],
): useFetchCardsSlugComplexReturn {
  const [entitiesToRender, setEntitiesToRender] = React.useState<
    CompareCredit.Entities[]
  >([])
  const [isFetching, setIsFetching] = React.useState<boolean>(true)
  const [error, setError] = React.useState<boolean>(false)

  const { client } = useContext(StatsigContext)
  const { getDynamicConfig, getExperiment } = useStatsigClient()

  const { entryQuery } = useContext(UserContext)

  const utmParams = {
    ad_group_id: entryQuery?.ad_group_id || '',
    campaign_medium: entryQuery?.utm_medium || '',
    campaign_source: entryQuery?.utm_source || '',
    campaign_name: entryQuery?.utm_campaign || '',
  }

  const setEntities = (
    entities: CompareCredit.Entities[],
    sortConfig: SORT_DATA | null,
  ) => {
    const [sort, version] = sortConfig?.sort || []
    const addSort = map(assocPath(['context', 'sort'], `${sort}:${version}`))
    const hasSort = !!sort && !!version
    const entitiesToRender = (hasSort ? addSort(entities) : entities) as any
    setEntitiesToRender(entitiesToRender)
  }

  const getSortConfig = (statsigSort: string) => {
    const sortConfig = SORT_MAP[statsigSort]
    if (!sortConfig) {
      Sentry.captureException(new Error(`Sort not found: ${statsigSort}`))
    }
    return sortConfig || SORT_MAP['default']
  }

  const { clientLocation } = React.useContext(UserContext)

  const {
    sort,
    weights,
    adjustment: proximity_to_target_adjustment,
  } = useContext(SortSelectContext)

  useEffect(() => {
    if (
      client.loadingStatus !== 'Ready' ||
      !slugs ||
      !Array.isArray(slugs) ||
      slugs.length === 0 ||
      !sortData ||
      !sort
    ) {
      return
    }

    const sortConfig = getSortConfig(sort)
    const decomposition_weights =
      Object.keys(weights || {}).length > 0 ? JSON.stringify(weights) : null

    const { browser, os } = getUserAgentInfo()

    const sortDataWithUtmParams = {
      ...sortData,
      ...utmParams,
      position: setPositionBias(sort),
      user_agent_os_name: os?.name,
      user_agent_browser_name: browser?.name,
      location_city: formatLocation(clientLocation.city),
      location_country: formatLocation(clientLocation.country),
      location_state: formatLocation(clientLocation.state),
      page_path: window.location.pathname,
      decomposition_weights,
      proximity_to_target_adjustment,
    }

    const config = getDynamicConfig('hide_cards_config')
    const slugsToHide = config.get('cards_to_hide', [])

    const geoConfig = getDynamicConfig('geo_location_config')
    const geoSlugsToHide = geoConfig.get('cards_to_hide', [])

    // 2023.9 Dynamic Config: hide_card_on_path_experiment_names
    //get Dynamic Config that holds array of experiment names
    const experimentNames = getDynamicConfig(
      'hide_card_on_path_experiment_names',
    ).get('experiment_names', []) as string[]

    const expSlugsToHide = getExpSlugsToHide(experimentNames, getExperiment)

    const slugsToRender = slugs.filter(
      (slug) =>
        !includes(slug, [...slugsToHide, ...geoSlugsToHide, ...expSlugsToHide]),
    )

    handleFetchs(slugsToRender, sortDataWithUtmParams, sortConfig)
      .then(
        ([entities, sortedEntities]: [
          entities: PromiseSettledResult<any>,
          sortedEntities: PromiseSettledResult<sortResponseInterface>,
        ]) => {
          // call to both sort and entities succeeded
          if (
            sortedEntities.status === 'fulfilled' &&
            entities.status === 'fulfilled'
          ) {
            const allEntitiesSorted = handleSort(
              entities.value,
              sortedEntities.value,
            ) as unknown as CompareCredit.Entities[]
            checkIfEmptyCardsList(entities.value.cards)
            setIsFetching(false)
            if (allEntitiesSorted && allEntitiesSorted.length > 0) {
              setEntities(allEntitiesSorted, sortConfig)
            } else {
              Sentry.captureException(new Error('entities sort failed'))
              setError(true)
            }
          } else {
            const { fetchError, fetchedEntities } = handleFetchResponses(
              entities,
              sortedEntities,
            )
            setError(fetchError)
            setEntitiesToRender(fetchedEntities)
            setIsFetching(false)
          }
        },
      )
      .catch((err) => {
        Sentry.captureException(err, {
          contexts: {
            data: {
              fileName: __filename,
            },
          },
        })
        setIsFetching(false)
        setError(true)
      })
    return () => {
      setEntities([], null)
      setIsFetching(true)
      setError(false)
    }
  }, [
    client.loadingStatus,
    JSON.stringify(slugs),
    sort,
    JSON.stringify(weights || {}),
  ])

  return {
    cards: cb ? cb(entitiesToRender, sort) : entitiesToRender,
    isFetching,
    error,
    sort,
  }
}
