import * as React from 'react'
import Link from 'next/link'
import { LinkToRedirect, StarRating } from '..'
import { CompareCredit } from '../../../types/compare-credit'
import { CardArt } from '../card-art'
import { v4 as uuid } from 'uuid'
import { supify } from '../../utils'
import { clickRatesAndFees } from '../../clients/segment'

export function CardTile(props: {
  card: CompareCredit.FormattedCard
  categoryId: string
  externalId: string
}) {
  const [orderId, setOrderId] = React.useState<string | null>(null)
  const { card, categoryId, externalId } = props

  React.useEffect(() => {
    const id = uuid()
    setOrderId(id)
  }, [])

  return (
    <div
      key={card.slug}
      className={`c-card-tile / relative / w-full max-w-xs sm:w-48pct md:w-5/12 lg:w-23pct / px-3 xs:px-4 sm:px-6 ${
        card.issuer.slug.current === 'american-express'
          ? 'pb-22'
          : 'pb-14 sm:pb-16'
      } / mb-13 / bg-white shadow rounded`}
    >
      <div className="c-card-art / text-center flex items-center justify-center / mb-2">
        <div className="relative / max-w-xs w-10/12 md:w-48 / -mt-4 / rounded-sm md:rounded shadow">
          <LinkToRedirect
            externalId={externalId}
            linkParams={card.applyNowLinkParameters}
            orderId={orderId}
            slug={card.slug}
          >
            <div className="relative flex rounded-sm overflow-hidden">
              <span className="c-card-art__content / absolute / flex flex-wrap content-center items-center / z-10 left-0 / w-full h-full text-white">
                <span className="c-card-art__icon / w-full">
                  <img
                    className="inline-block w-6"
                    alt=""
                    src="/static/icons/icon-lock-round-w.svg"
                  />
                </span>
                <span className="w-full font-bold text-sm">Apply Now</span>
              </span>
              <CardArt
                _rev={props.card._rev}
                cardArt={props.card.cardArt}
                imgClasses="relative z-0 w-full h-full object-cover top-0"
                customCodeSnippets={props.card.customCodeSnippets}
                issuer={props.card.issuer.slug.current}
                name={props.card.name}
                orderId={orderId}
                slug={props.card.slug}
                categoryId={categoryId}
                externalId={externalId}
              />
            </div>
          </LinkToRedirect>
        </div>
      </div>

      <div className="flex justify-center mb-2 lg:mb-0 w-full">
        <StarRating
          column
          marginBottom="mb-0"
          productSlug={card.slug}
          stars={card.expertReviewRating}
          userAvgRating={card.visitorReviewsAverageRating}
          visitorReviewsTotalCount={card.visitorReviewsTotalCount}
        />
      </div>

      <h4 className="font-sans font-bold text-center leading-tight text-sm sm:text-base lg:text-sm xl:text-base / pb-2 mb-2 -mt-3 lg:mt-0 / min-h-2-3/4r flex justify-center items-center">
        <Link
          href={`/credit-cards/${card.issuer.slug.current}/${card.slug}`}
          className="text-primary-mid hover:text-primary-bright"
          dangerouslySetInnerHTML={{
            __html: supify(card.name),
          }}
        />
      </h4>
      <div className="absolute bottom-0 left-0 right-0 / m-auto mb-5 sm:mb-8 / px-3 xs:px-4 sm:px-6 text-center">
        <div className="max-w-xs w-10/12 md:w-48 lg:w-full / mx-auto">
          <Link
            href={`/credit-cards/${card.issuer.slug.current}/${card.slug}`}
            className="c-btn c-btn--ghost / w-full / focus:outline-none"
          >
            Learn More <span className="sr-only">about the {card.name}</span>
          </Link>
          {card.ratesAndFees && card.ratesAndFees !== 'N/A' && (
            <span className="block mt-0.5 -mb-2 sm:-mb-6">
              <a
                className="inline-block / w-full / text-xs text-center text-primary-bright leading-snug / hover:underline hover:text-primary-mid"
                href={card.ratesAndFees}
                onClick={() => {
                  clickRatesAndFees({
                    applyNowLink: card.applyNowLink,
                    component: 'Related Cards Tile',
                    name: card.name,
                    url: window.location.href,
                  })
                }}
                rel="noopener noreferrer"
                target="_blank"
              >
                Rates &amp; Fees
              </a>
              <>
                {card.issuer.slug.current === 'american-express' && (
                  <p className="mb-1 text-fs10 text-gray-600 / text-center / w-full leading-4">
                    Terms Apply
                  </p>
                )}
              </>
            </span>
          )}
        </div>
      </div>
    </div>
  )
}
