import compose from 'lodash/fp/compose'
import filter from 'lodash/fp/filter'
import last from 'lodash/fp/last'
import orderBy from 'lodash/fp/orderBy'
import propOr from 'lodash/fp/propOr'

/**
 * Parse the current CPA for given card data.
 *
 * @param {string=} startDate start date in the form of `YYYY-MM-DD` to use for
 * evaluating current CPA's. Every CPA with a start date greater than the given
 * start date will be excluded.
 */
const cpa =
  (startDate?: string) =>
  (cardData: { cpaDocuments: { startDate: string; value?: number }[] }) => {
    const today = new Date().toISOString().split('T')[0]

    return compose(
      propOr(0, 'value'),
      last,
      orderBy(['startDate'], ['asc']),
      filter(
        (cpa: { startDate: string }) => cpa.startDate <= (startDate || today),
      ),
    )(cardData?.cpaDocuments || [])
  }

export default { cpa }
