/* eslint-disable react/display-name */
import React from 'react'
import prop from 'lodash/fp/prop'
import propOr from 'lodash/fp/propOr'

import { CompareCredit } from '../../../types/compare-credit'
import { supify } from '../../utils'

export const AttributeRendererWithSlugV2 = (data: {
  referencedCards: null | Record<string, CompareCredit.Entities>
}) => {
  return (props: {
    value: {
      attribute: string
      slug: string
    }
  }) => {
    const { referencedCards } = data
    const cardSlug = props.value.slug
    const card = prop(cardSlug, referencedCards)
    return React.createElement('span', {
      dangerouslySetInnerHTML: {
        __html: supify(propOr('', props.value.attribute, card)),
      },
    })
  }
}
