import React from 'react'
import Link from 'next/link'
import { CompareCredit } from '../../../../types'
import { CardArt, StarRating } from '../../'

export function CardTile(props: {
  orderId: string | null
  card: CompareCredit.FormattedCard
  authorName: string
  featuredCardQuote: string
}) {
  const { card, authorName, featuredCardQuote, orderId } = props
  const [firstName] = authorName.split(' ')

  return (
    <div>
      <div className="mb-4 md:mb-6 / text-center">
        <p className="c-ribbon c-ribbon--slimmer / relative inline-block / w-auto px-2 py-1 / font-bold text-primary text-xs tracking-wide uppercase / bg-tetriary">
          {firstName}&apos;s Favorite Card
        </p>
      </div>
      <div className="c-card-tile c-hero--hide-review-count / relative / p-4 md:pt-0 lg:px-6 xl:px-8 / rounded shadow-lg bg-gradient-blue-green-270">
        <div className="relative z-2 / flex flex-row flex-wrap items-start / md:flex-col">
          <div
            className={`c-card-art / text-center / flex items-center justify-center / w-1/4 md:w-3/4 lg:w-5/6 / mx-auto mb-3 md:mb-2 md:-mt-4`}
          >
            <div
              className={`relative / w-full / rounded-sm shadow / overflow-hidden`}
            >
              <Link
                href={`/credit-cards/${card.issuer.slug.current}/${card.slug}`}
                className="relative flex rounded-sm overflow-hidden"
              >
                <span className="c-card-art__content / absolute / flex flex-wrap content-center items-center / z-10 left-0 / w-full h-full text-white">
                  <span className="w-full font-semibold">Learn More</span>
                </span>

                <CardArt
                  _rev={null}
                  cardArt={card.cardArt}
                  imgClasses="relative / z-0 / w-full h-full object-cover top-0"
                  customCodeSnippets={card.customCodeSnippets}
                  issuer={card.issuer.slug.current}
                  name={card.name}
                  orderId={orderId}
                  slug={card.slug}
                  type={card._type}
                  categoryId="80"
                  externalId="80"
                  imagePriority={true}
                />
              </Link>
            </div>
          </div>
          <div className="flex flex-col / w-3/4 md:w-full / md:mb-3 / pl-4 md:pl-0">
            <p className="mb-0.5 / text-sm xs:text-base md:text-sm leading-tight font-bold md:text-center">
              <Link
                href={`/credit-cards/${card.issuer.slug.current}/${card.slug}`}
                className="text-primary-mid / hover:text-primary-bright"
              >
                {card.name}
              </Link>
            </p>
            <div className="md:text-center">
              <StarRating
                column
                marginBottom="mb-0"
                productSlug={card.slug}
                stars={card.expertReviewRating}
                userAvgRating={card.visitorReviewsAverageRating}
                visitorReviewsTotalCount={card.visitorReviewsTotalCount}
              />
            </div>
            <Link
              href={`/credit-cards/${card.issuer.slug.current}/${card.slug}`}
              className="c-btn c-btn--ghost / flex items-center / xxs:w-48 md:w-full / ml-0 / py-1 md:py-1.5 / text-sm md:text-base"
            >
              Learn More
            </Link>
          </div>
          {featuredCardQuote && (
            <>
              <hr className="w-full / my-3 / border-t border-gray-300 / md:hidden" />
              <p className="w-full / leading-tight italic text-center font-bold font-serif text-primary-mid text-lg md:text-base lg:text-lg">
                “{featuredCardQuote}”
              </p>
            </>
          )}
        </div>
        <div className="absolute left-0 top-0 / w-full h-40">
          <div className="w-full h-12 bg-white">&nbsp;</div>
          <img
            src="/static/images/bg-bot-mask-white-sm-2.png"
            className="c-bg-wave__bg-mask / w-full / rotate-180"
            alt="background mask"
            aria-hidden={true}
          />
        </div>
      </div>
    </div>
  )
}
