import React from 'react'

export const SmallTextBorder = (props: any) => {
  return (
    <div
      className={`c-small-text-border / border-gray-300 leading-snug ${
        props.value.editorialNote ? 'text-sm mt-1 mb-4 py-2.5' : 'my-10 py-3'
      } ${props.value.borderTop ? 'border-t' : ''} ${
        props.value.borderBottom ? 'border-b' : ''
      } ${props.value.fontSize ? props.value.fontSize : ''}`}
    >
      {props.value.editorialNote ? (
        ''
      ) : (
        <p
          className={`c-small-text-border__title / mb-1 / uppercase font-bold ${
            props.value.italicTitle && 'italic'
          }`}
        >
          {props.value.title}
        </p>
      )}

      <p
        className={`c-small-text-border__content / ${
          props.value.italicContent && 'italic'
        }`}
      >
        {props.value.editorialNote ? <b>{props.value.title}</b> : ''}{' '}
        {props.value.content}
      </p>
      <style jsx>{`
        .c-small-text-border__title {
          font-size: 68.75%;
        }
        .c-small-text-border__content {
          font-size: 81.25%;
        }
      `}</style>
    </div>
  )
}
