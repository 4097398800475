import React from 'react'
import last from 'lodash/fp/last'
import omitAll from 'lodash/fp/omitAll'
import prop from 'lodash/fp/prop'
import startCase from 'lodash/fp/startCase'
import toLower from 'lodash/fp/toLower'
import Image from 'next/image'
import { useRouter } from 'next/router'
import qs from 'query-string'
import { isIE } from 'react-device-detect'

import { CompareCredit } from '../../../types/compare-credit'
import {
  captureUserTraits,
  clickCreditScoreFilter,
} from '../../clients/segment'
import settings from '../../ssg/settings/credit-score-filter-settings.json'
import {
  appendToQueryString,
  ls,
  getParamsAsKeyValuePairs,
  nextImageHelper,
} from '../../utils'
import { CreditDisclosure } from '../credit-disclosure'
import cn from 'utils/classnames'

export function CreditModal() {
  const [creditFilter, setCreditFilter] = React.useState(false)
  const [showModal, setShowModal] = React.useState(false)
  const [hideModal, setHideModal] = React.useState(false)
  const [userCreditScore, setUserCreditScore] = React.useState<
    CompareCredit.CreditFilter | ''
  >('')
  const [destinationUrls, setDestinationUrls] =
    React.useState<CompareCredit.CreditDestinationUrls>({
      excellent: undefined,
      fair: undefined,
      good: undefined,
      poor: undefined,
      unknown: undefined,
    })

  const router = useRouter()

  const handleCreditSelection = (value: CompareCredit.CreditFilter): void => {
    setUserCreditScore(value)
    captureUserTraits(null, { creditScore: value })
    clickCreditScoreFilter(value)
    ls.setItem('creditScore', value)
    const destinationUrl = destinationUrls[value] || location.href
    const destinationPath = destinationUrl.split('?')[0]
    const currentQueryParams = getParamsAsKeyValuePairs(location.href)
    const destinationUrlQueryParams = getParamsAsKeyValuePairs(destinationUrl)
    const allQueryParams = {
      ...omitAll(['creditFilter'], currentQueryParams),
      ...destinationUrlQueryParams,
      filteredByCredit: true,
    }
    const urlWithParams = appendToQueryString(destinationPath, allQueryParams)

    const timeValue = value === 'excellent' || value === 'good' ? 500 : 2000

    // browser is not IE
    if (!isIE) {
      const url = new URL(destinationUrl)
      // redirect URL is other Compare Credit page
      if (url.host.indexOf('comparecredit') >= 0) {
        setTimeout(() => {
          router.push(urlWithParams)
          setHideModal(true)
        }, timeValue)

        // redirect URL is external site
      } else {
        setTimeout(() => {
          window.location.replace(destinationUrl)
        }, timeValue)
      }
      // browser is IE
    } else {
      setTimeout(() => {
        window.location.replace(urlWithParams)
        setHideModal(true)
      }, timeValue)
    }
  }

  const getDestinationUrls = () => {
    setDestinationUrls({
      excellent: prop('excellentUrl', last(settings)),
      fair: prop('fairUrl', last(settings)),
      good: prop('goodUrl', last(settings)),
      poor: prop('poorUrl', last(settings)),
      unknown: prop('unknownUrl', last(settings)),
    })
  }

  React.useEffect(() => {
    // only fetch settings if `creditFilter` is query param and creditScore not in localStorage
    const parsed = qs.parse(location.search)
    if ('creditFilter' in parsed) {
      const creditScore = ls.getItem('creditScore')
      if (!creditScore) {
        setCreditFilter(true)
        getDestinationUrls()
      } else {
        setCreditFilter(false)
      }
    } else {
      setCreditFilter(false)
    }
    const timeout = setTimeout(() => setShowModal(true), 200)
    return () => {
      clearTimeout(timeout)
    }
  }, [])

  return (
    <div
      className={creditFilter ? 'block' : 'hidden'}
      aria-hidden={creditFilter ? 'false' : 'true'}
    >
      <div
        className={cn(
          'c-credit-modal / fixed w-full top-0 left-0 / z-80 / overflow-scroll',
          {
            'modal-in': showModal,
            'modal-out': hideModal,
            'step-2': userCreditScore,
          },
        )}
      >
        <div className="c-credit-modal__container / relative top-0 / max-w-xl w-full / mx-auto my-6 / overflow-scroll z-80">
          <div
            className="c-credit-modal__content / relative / flex flex-col items-center justify-center / w-full / px-4 py-4 sm:py-8 / mx-auto bg-white rounded-lg"
            tabIndex={-1}
            role="dialog"
          >
            <button
              className="absolute top-0 right-0 / mt-2 mr-2 sm:mt-4 sm:mr-4 / cursor-pointer / opacity-25 / hover:opacity-75 focus:outline-none / transition-all--25"
              onClick={() => setHideModal(true)}
              aria-label="close modal"
              type="button"
            >
              <div className="w-8">
                <Image
                  src="/static/icons/icon-close-circle-black.svg"
                  alt="close icon"
                  role="presentation"
                  height={32}
                  width={32}
                  style={nextImageHelper.intrinsic}
                />
              </div>
            </button>

            <div className="c-credit-modal__step-1">
              <p className="px-6 font-bold text-primary font-xs uppercase text-center leading-tight tracking-wide">
                Let&apos;s Find the Right Product for&nbsp;You
              </p>
              <hr className="border-2 w-24 border-tetriary / my-2 mx-auto" />
              <form>
                <fieldset>
                  <legend className="relative z-1 / block / mb-4 / text-center text-xl xs:text-2xl font-serif font-bold text-primary leading-tight w-full">
                    What&apos;s your credit score?{' '}
                    <span className="inline-block xs:w-auto">
                      <CreditDisclosure theme="t-align-rt" />
                    </span>
                  </legend>

                  {options.map(
                    (btn: {
                      value: CompareCredit.CreditFilter
                      label: string
                      icon: string
                      range: string
                    }) => (
                      <button
                        className="flex items-center / min-h-14 w-full max-w-xs mx-auto mb-2 px-4 py-1 rounded / border border-gray-300 font-bold text-gray-700 text-lg justify-start / hover:bg-primary-light-bg hover:text-primary-mid hover:shadow / transition-all--25"
                        key={btn.value}
                        type="button"
                        value={btn.value}
                        name={btn.value}
                        onClick={() => handleCreditSelection(btn.value)}
                      >
                        <div className="w-12 h-12 mr-3">
                          <Image
                            alt="credit score icon"
                            role="presentation"
                            src={btn.icon}
                            height={48}
                            width={48}
                            style={nextImageHelper.intrinsic}
                          />
                        </div>
                        {btn.label}&nbsp;
                        <span className="text-sm font-normal">{btn.range}</span>
                      </button>
                    ),
                  )}
                </fieldset>
              </form>
            </div>
            <div className="c-credit-modal__step-2 / overflow-hidden / items-center justify-center">
              <p className="block / mb-8 px-6 / text-center text-xl xs:text-2xl font-serif font-bold text-primary leading-tight w-full">
                {userCreditScore === 'excellent' ||
                userCreditScore === 'good' ? (
                  <span>You&apos;re on the right page!</span>
                ) : (
                  <span>
                    Showing products for{' '}
                    <span className="c-credit-modal__credit-text">
                      {startCase(toLower(userCreditScore))}
                    </span>{' '}
                    Credit now
                  </span>
                )}
              </p>
              <div className="mb-6 w-20 mx-auto">
                <Image
                  alt="loader"
                  role="presentation"
                  src="/static/loader/cc-loader-base-repeat.gif"
                  height={103}
                  width={80}
                  style={nextImageHelper.intrinsic}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className={`c-credit-modal__bg / fixed top-0 left-0 / w-full h-full ${
            hideModal ? 'hidden' : ''
          }`}
          onClick={() => setHideModal(true)}
          onKeyDown={() => setHideModal(true)}
          role="button"
          tabIndex={0}
          aria-label="close modal"
        />
      </div>
      <style jsx>
        {`
          /*===== CREDIT MODAL =====*/
          .c-credit-modal {
            height: 100%;
          }
          .c-credit-modal__bg {
            background-color: rgba(2, 22, 45, 0);
          }
          .c-credit-modal__container {
            overflow: hidden;
          }
          .c-credit-modal__container,
          .c-credit-modal__bg {
            opacity: 0;
          }
          .c-credit-modal__content {
            min-height: 24rem;
          }
          .c-credit-modal__step-1 {
            max-height: 36rem;
            opacity: 1;
          }
          .c-credit-modal__step-2 {
            max-height: 0;
            opacity: 0;
          }

          // CENTER MODAL TO BROWSER
          @media (min-height: 667px) {
            .c-credit-modal {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
            }
          }

          /*===== THEMES =====*/
          // MODAL-IN
          .c-credit-modal.modal-in .c-credit-modal__container {
            animation-name: fadeInOpacity, animateTopNorth;
            animation-duration: 0.5s;
            animation-fill-mode: forwards;
          }
          .c-credit-modal.modal-in .c-credit-modal__bg {
            animation-name: fadeInOpacity;
            animation-duration: 0.5s;
            animation-fill-mode: forwards;
            background-color: rgba(2, 22, 45, 0.8);
          }

          // MODAL-OUT
          .c-credit-modal.modal-out {
            animation-name: animateFadeOutModal;
            animation-duration: 2s;
            animation-fill-mode: forwards;
            animation-delay: 0.5s;
          }
          .c-credit-modal.modal-out .c-credit-modal__container {
            animation-name: fadeOutOpacity, animateTopSouth;
            animation-duration: 0.5s;
            animation-fill-mode: forwards;
          }
          .c-credit-modal.modal-out .c-credit-modal__bg {
            animation-name: fadeOutOpacity;
            animation-duration: 0.5s;
            animation-fill-mode: forwards;
          }

          // STEPS
          .c-credit-modal.step-2 .c-credit-modal__step-1 {
            animation-name: fadeOutOpacity, animateHeightOut;
            animation-duration: 0.25s;
            animation-fill-mode: forwards;
          }
          .c-credit-modal.step-2 .c-credit-modal__step-2 {
            animation-name: fadeInOpacity, animateHeightIn;
            animation-duration: 0.5s;
            animation-fill-mode: forwards;
          }
          // ANIMATIONS
          @keyframes animateModalBg {
            from {
              background-color: rgba(2, 22, 45, 0);
            }
            to {
              background-color: rgba(2, 22, 45, 1);
            }
          }
          @keyframes animateTopNorth {
            from {
              top: 2rem;
            }
            to {
              top: 0;
            }
          }
          @keyframes animateTopSouth {
            from {
              top: 0;
            }
            to {
              top: 2rem;
            }
          }
          @keyframes fadeInOpacity {
            from {
              opacity: 0;
            }
            to {
              opacity: 1;
            }
          }
          @keyframes fadeOutOpacity {
            from {
              opacity: 1;
            }
            to {
              opacity: 0;
            }
          }
          @keyframes animateFadeOutModal {
            from {
              max-height: 100%;
            }
            to {
              max-height: 0;
              overflow: hidden;
            }
          }
          @keyframes animateHeightIn {
            from {
              max-height: 0;
            }
            to {
              max-height: 32rem;
            }
          }
          @keyframes animateHeightOut {
            from {
              max-height: 32rem;
            }
            to {
              max-height: 0;
            }
          }
        `}
      </style>
    </div>
  )
}

const options: {
  value: CompareCredit.CreditFilter
  label: string
  icon: string
  range: string
}[] = [
  {
    value: 'unknown',
    label: "Don't Know",
    icon: '/static/icons/credit-quality/empty.svg',
    range: '',
  },
  {
    value: 'poor',
    label: 'Poor',
    icon: '/static/icons/credit-quality/poor.svg',
    range: '(< 620)',
  },
  {
    value: 'fair',
    label: 'Fair',
    icon: '/static/icons/credit-quality/fair.svg',
    range: '(620-659)',
  },
  {
    value: 'good',
    label: 'Good',
    icon: '/static/icons/credit-quality/good.svg',
    range: '(660 - 719)',
  },
  {
    value: 'excellent',
    label: 'Excellent',
    icon: '/static/icons/credit-quality/excellent.svg',
    range: '(720+)',
  },
]
