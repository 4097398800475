import React from 'react'
import Image from 'next/image'
import { PortableText, PortableTextBlockComponent } from '@portabletext/react'
import { CompareCredit } from '../../../types/compare-credit'
import { nextImageHelper } from '../../utils'

import propOr from 'lodash/fp/propOr'
import { supify } from '../../utils'

export function FaqCard(props: { card: CompareCredit.FormattedCard }) {
  const { card } = props
  const toggleOpen = (id: string) => {
    const element = document.getElementById(id)
    const elementButton = document.getElementById(id) as HTMLButtonElement
    const elementContent = document.getElementById(
      'toggleContent-' + id,
    ) as HTMLButtonElement

    if (element && elementContent) {
      if (elementButton) {
        const elementButtonExpand = elementButton.getAttribute('aria-expanded')
        if (elementButtonExpand === 'true') {
          elementButton.setAttribute('aria-expanded', 'false')
        } else {
          elementButton.setAttribute('aria-expanded', 'true')
        }
      }
      if (element.classList) {
        elementContent.classList.toggle('c-slidedown--hidden')
      }
    }
  }
  const BlockRenderer = ({ children }: { children: React.ReactElement }) => {
    return <p>{children}</p>
  }
  function AttributeRenderer<K extends keyof CompareCredit.Entity>(attrProps: {
    value: { attribute: K }
  }) {
    return React.createElement('span', {
      dangerouslySetInnerHTML: {
        __html: supify(propOr('', attrProps.value.attribute, card)?.toString()),
      },
    })
  }

  return (
    <div id="faq" itemScope itemType="https://schema.org/FAQPage">
      <h3 className="mb-3 md:mb-4 / text-primary leading-tight text-3xl md:text-4xl font-serif font-bold text-center">
        Frequently Asked Questions
      </h3>
      <div>
        <ul>
          {card.faqCard.map((item: any, idx: number) => (
            <li
              className="sm:pl-4 / border-b border-slate-200"
              key={idx}
              itemScope
              itemProp="mainEntity"
              itemType="https://schema.org/Question"
            >
              <button
                id={`btn${idx}`}
                className="c-faq-btn / relative / flex items-center w-full / py-4 pr-12 / text-primary text-lg text-left leading-tight font-semibold / hover:text-primary-bright / transition-all"
                onClick={() => toggleOpen(`btn${idx}`)}
                onKeyDown={() => toggleOpen(`btn${idx}`)}
                aria-controls={`toggleContent-btn${idx}`}
                aria-expanded={false}
                itemProp="name"
              >
                {item.question}{' '}
                <span className="c-accordion__icon / absolute right-0 w-4 mr-3 sm:mr-4 / transition-all">
                  <Image
                    src="/static/icons/icon-chevron-d-primary-mid.svg"
                    height={32}
                    width={32}
                    alt="chevron"
                    role="presentation"
                    aria-hidden={true}
                    style={nextImageHelper.responsive}
                    sizes="100vw"
                  />
                </span>
              </button>

              <div
                id={`toggleContent-btn${idx}`}
                className={`c-slidedown c-slidedown--hidden / c-copy / text-gray-700`}
                itemScope
                itemProp="acceptedAnswer"
                itemType="https://schema.org/Answer"
              >
                <div itemProp="text">
                  <PortableText
                    value={item.answer}
                    components={{
                      block: BlockRenderer as PortableTextBlockComponent,
                      types: {
                        advertorialAttribute: AttributeRenderer,
                      },
                    }}
                  />
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <style jsx>{`
        .c-faq-btn[aria-expanded='true'] .c-accordion__icon {
          transform: rotate(180deg);
        }
      `}</style>
    </div>
  )
}
