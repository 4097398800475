import React from 'react'
// @ts-ignore
import exitIntent from 'exit-intent'
import { isMobileOnly } from 'react-device-detect'

export function ExitIntent(props: {
  show: boolean
  triggerMobileScrollPosition?: number
  children: React.ReactElement
}) {
  const [show, setShow] = React.useState(false)
  const [hasShown, setHasShown] = React.useState(false)
  const [scrollPosition, setScrollPosition] = React.useState(0)

  const showChildren = () => {
    if (!hasShown) {
      setShow(true)
    }
    setHasShown(true)
  }

  // toggle show on mobile at scroll position
  React.useEffect(() => {
    if (
      isMobileOnly &&
      props.triggerMobileScrollPosition &&
      scrollPosition > props.triggerMobileScrollPosition
    ) {
      showChildren()
    }
  }, [scrollPosition])

  // update current scroll position
  const handleScroll = () => {
    const position = window.pageYOffset
    setScrollPosition(position)
  }
  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true })
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  React.useEffect(() => {
    const removeExitIntent = exitIntent({
      maxDisplays: 1, // default 99999
      eventThrottle: 100, // default 200
      showAfterInactiveSecondsDesktop: 60, // default 60
      showAfterInactiveSecondsMobile: 10, // default 40
      onExitIntent: () => {
        showChildren()
      },
      debug: false,
    })
    return () => {
      removeExitIntent()
    }
  }, [])

  React.useEffect(() => {
    if (props.show && !hasShown) {
      setShow(true)
    }
  }, [props.show])
  return show ? <div>{props.children}</div> : null
}
