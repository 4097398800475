import React from 'react'
import Image from 'next/image'
import pageRoutes from '../../pageRoutes.json'
import { nextImageHelper } from '../../utils'

export function SecurityCTA() {
  return (
    <div className="flex items-center justify-center / mt-6 md:mt-10 mb-16 lg:mb-20 / pt-12 pb-6 px-6 sm:px-8 md:px-10  sm:pb-10  / gradient-bg rounded-lg">
      <div className="flex flex-col sm:flex-row items-center justify-center sm:justify-start">
        <div className="custom-static-width-icon-lock-b / mb-6 sm:mr-8 sm:mb-0">
          <Image
            src="/static/icons/icon-lock-b.svg"
            alt="lock"
            height={88}
            width={122}
            style={nextImageHelper.intrinsic}
          />
        </div>
        <div className="flex flex-col items-center sm:items-start">
          <h2 className="mb-2 / font-serif font-bold leading-tight text-center text-primary text-2xl whitespace-pre-wrap sm:whitespace-nowrap">{`Your security. \nOur priority.`}</h2>
          <div className="flex flex-col sm:flex-row sm:flex-wrap sm:justify-start">
            <p className="mb-4 sm:mb-3 / leading-relaxed sm:leading-normal max-w-4xl sm:max-w-full text-center sm:text-left text-card-gray">
              128-bit encryption applications. Only the highest industry
              standards will do.&nbsp;
            </p>
            <a
              className="leading-relaxed sm:leading-normal text-center sm:text-left text-primary-bright / hover:underline hover:text-primary-mid"
              href={pageRoutes['privacy']}
            >
              Learn More
            </a>
          </div>
        </div>
      </div>
      <style jsx>{`
        .custom-static-width-icon-lock-b {
          width: 122px;
        }
      `}</style>
    </div>
  )
}
