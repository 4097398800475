import * as React from 'react'
import range from 'lodash/range'

interface Props {
  /**
   * Number of loading skeleton's to render.
   */
  n: number
}

export function LoadingSkeleton({ n }: Props) {
  return (
    <>
      <div className=" h-60 justify-left mt-2 mb-10">
        {/* card */}
        <div className="flex flex-col lg:flex-col / leading-tight">
          {range(n).map((idx) => (
            <div key={idx} className="flex mx-2 py-3 rounded-sm">
              {/* card image */}
              <div className="h-17 md:w-1/3 bg-gray-300 rounded-sm animate-pulse"></div>
              <div className="h-17 md:w-2/3 md:ml-5">
                {/* content */}
                <div className="h-3 my-1 rounded-sm bg-gray-300 animate-pulse"></div>
                <div className="h-3 my-1 rounded-sm bg-gray-300 animate-pulse"></div>
                <div className="h-3 my-1 rounded-sm bg-gray-300 animate-pulse"></div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}
