import React from 'react'
import Image from 'next/image'
import { UserContext } from '../'
import { nextImageHelper, useUserContext } from '../../utils'
import pageRoutes from '../../pageRoutes.json'

export function CookieDisclaimer() {
  const { clientLocation } = React.useContext(UserContext)
  const [cookieContext, setCookieContext] = useUserContext()

  const [open, setOpen] = React.useState(false)
  const [gotLocation, setGotLocation] = React.useState(false)

  const handleClose = () => {
    setOpen(false)
    setCookieContext({ acceptCookie: true })
  }

  React.useEffect(
    () => setGotLocation(clientLocation.status === 'done'),
    [clientLocation.status],
  )

  React.useEffect(() => {
    if (gotLocation) {
      setOpen(
        (['CA', '', undefined].includes(clientLocation.state) ||
          clientLocation.country !== 'US') &&
          !cookieContext.acceptCookie,
      )
    }
  }, [gotLocation])

  return (
    <>
      {open ? (
        <div className="fixed z-60 bottom-0 left-0 w-full lg:max-w-xs / md:mb-2 lg:ml-2 / md:px-8 lg:px-0">
          <div
            className="bottom-0 left-0 relative / w-full lg:max-w-xs / pl-4 pr-10 py-4 xs:py-3 xs:px-12 md:pl-3 md:pr-8 lg:py-4 lg:pl-5 lg:pr-10 / bg-white shadow-2xl md:rounded"
            role="alert"
          >
            <button
              className="absolute right-0 top-0 / mt-2 mr-2 xs:mr-4 md:mr-2 / opacity-50 / hover:opacity-100 focus:outline-none / transition-all"
              type="button"
              onClick={handleClose}
            >
              <div className="inline-block w-6 h-6">
                <Image
                  src="/static/icons/icon-close-black.svg"
                  alt="close"
                  height={24}
                  width={24}
                  style={nextImageHelper.responsive}
                  sizes="100vw"
                />
              </div>
            </button>
            <div className="">
              <p className="mb-0 / text-gray-800 text-sm leading-tight xs:leading-normal text-left xs:text-center lg:text-left">
                By using our website you are consenting to our use of cookies in
                accordance with our{' '}
                <a
                  className="text-primary-bright / hover:underline hover:text-primary-mid"
                  href={pageRoutes['privacy']}
                >
                  Privacy&nbsp;Policy
                </a>
              </p>
            </div>
          </div>
        </div>
      ) : null}
    </>
  )
}
