import React from 'react'

export function LabelAd(props: { theme?: string }) {
  return (
    <div
      className={`c-label-ad / inline-block opacity-40 / px-1 pt-[1px] / rounded-sm border / leading-tight tracking-wider text-fs10 uppercase ${
        props.theme == 'light'
          ? 'border-white text-white'
          : props.theme == 'light-mix'
          ? 'border-white text-white lg:border-black lg:text-black'
          : 'border-black text-black'
      }`}
    >
      Ad
    </div>
  )
}
