import React from 'react'
import Link from 'next/link'

export const HubCategoriesAside = () => {
  return (
    <div className="mb-12">
      <p className="mb-3 / pb-1 / font-bold text-primary-mid tracking-wider uppercase / border-b-3 border-gray-200">
        Categories
      </p>
      {DEFAULT_ARRAY.map((category: any) => {
        return (
          <div className="mb-2" key={category.slug}>
            <Link
              href={category.slug}
              className="text-primary text-sm / hover:text-primary-bright"
            >
              {category.label}
            </Link>
          </div>
        )
      })}
    </div>
  )
}

const DEFAULT_ARRAY = [
  { label: 'Balance Transfer Advice', slug: '' },
  { label: 'Credit Scores & Reports', slug: '' },
  { label: 'Cash Card Reviews', slug: '' },
  { label: 'News', slug: '' },
  { label: 'Press', slug: '' },
  { label: 'Surveys', slug: '' },
]
