import React from 'react'
import { useExperiment } from 'utils/use-experiment-wrapper'
import { isStorageAccessible } from 'utils/'
import { useStatsigClient } from '@statsig/react-bindings'

export interface IModalLeaveBehindContext {
  modalLeaveBehindOrderId?: string | null
  setModalLeaveBehindOrderId: React.Dispatch<
    React.SetStateAction<string | undefined | null>
  >
  openModalLeaveBehind?: boolean
  setOpenModalLeaveBehind: React.Dispatch<
    React.SetStateAction<boolean | undefined>
  >
  openModalLeaveBehindExtension?: boolean
  setOpenModalLeaveBehindExtension: React.Dispatch<
    React.SetStateAction<boolean | undefined>
  >
  modalLeaveBehindId?: string
  setModalLeaveBehindId: React.Dispatch<
    React.SetStateAction<string | undefined>
  >
  modalLeaveBehindSlug?: string
  setModalLeaveBehindSlug: React.Dispatch<
    React.SetStateAction<string | undefined>
  >
  isModalLeaveBehindCard?: boolean
  setIsModalLeaveBehindCard: React.Dispatch<
    React.SetStateAction<boolean | undefined>
  >
  modalLeaveBehindTipSlug?: string
  setModalLeaveBehindTipSlug: React.Dispatch<
    React.SetStateAction<string | undefined>
  >
  shouldOpenModalLeaveBehindCapOneShopping?: boolean
  handleSetOpenModalLeaveBehind: () => void
  onCloseModalLeaveBehind: () => void
}

export const ModalLeaveBehindContext =
  React.createContext<IModalLeaveBehindContext>({
    modalLeaveBehindOrderId: null,
    setModalLeaveBehindOrderId: () => null,
    openModalLeaveBehind: false,
    setOpenModalLeaveBehind: () => null,
    openModalLeaveBehindExtension: false,
    setOpenModalLeaveBehindExtension: () => null,
    modalLeaveBehindId: '',
    setModalLeaveBehindId: () => null,
    modalLeaveBehindSlug: '',
    setModalLeaveBehindSlug: () => null,
    isModalLeaveBehindCard: false,
    setIsModalLeaveBehindCard: () => null,
    modalLeaveBehindTipSlug: '',
    setModalLeaveBehindTipSlug: () => null,
    handleSetOpenModalLeaveBehind: () => null,
    onCloseModalLeaveBehind: () => null,
  })

export const ModalLeaveBehindContextProvider: React.FC<
  React.PropsWithChildren
> = ({ children }) => {
  const [modalLeaveBehindOrderId, setModalLeaveBehindOrderId] = React.useState<
    string | null
  >()
  const [openModalLeaveBehind, setOpenModalLeaveBehind] =
    React.useState<boolean>()
  const [openModalLeaveBehindExtension, setOpenModalLeaveBehindExtension] =
    React.useState<boolean>()
  const [modalLeaveBehindId, setModalLeaveBehindId] = React.useState<string>()
  const [modalLeaveBehindSlug, setModalLeaveBehindSlug] =
    React.useState<string>()
  const [isModalLeaveBehindCard, setIsModalLeaveBehindCard] =
    React.useState<boolean>()
  const [modalLeaveBehindTipSlug, setModalLeaveBehindTipSlug] =
    React.useState<string>()
  const [
    shouldOpenModalLeaveBehindCapOneShopping,
    setShouldOpenModalLeaveBehindCapOneShopping,
  ] = React.useState<boolean>()

  /* ===== LBM ===== */
  const { logEvent } = useStatsigClient()

  /* ===== 2024.09: EXP-304 LBM Card on /tips/best-in-class-citi-dc/ ===== */
  const { experiment: configExp304, isLoading: isLoadingExp304 } =
    useExperiment('exp_304_lbt_double_cash')
  const variationExp304 = configExp304?.value?.name || 'control'

  /* ===== EXP-312 LBM NFTF ===== */
  const { experiment: configExp312, isLoading: isLoadingExp312 } =
    useExperiment('exp_312_lbm_nftf')
  const variationExp312 = configExp312?.value?.name || 'control'

  /* ===== EXP-313 LBM BEST LI ===== */
  const { experiment: configExp313, isLoading: isLoadingExp313 } =
    useExperiment('exp_313_lbm_best_li')
  const variationExp313 = configExp313?.value?.name || 'control'

  React.useEffect(() => {
    setShouldOpenModalLeaveBehindCapOneShopping(
      (!isLoadingExp312 && variationExp312 == 'v1-312-lbm') ||
        (!isLoadingExp313 && variationExp313 == 'v1-313-lbm'),
    )
  }, [
    isLoadingExp304,
    variationExp304,
    isLoadingExp312,
    variationExp312,
    isLoadingExp313,
    variationExp313,
  ])

  const sessionStorageExist = isStorageAccessible()

  const handleSetOpenModalLeaveBehind = React.useCallback(() => {
    if (
      sessionStorageExist &&
      shouldOpenModalLeaveBehindCapOneShopping &&
      modalLeaveBehindSlug === 'cap-one-shopping'
    ) {
      sessionStorage.setItem('modalLeaveBehindCapOne', 'true')
    }
    if (
      sessionStorageExist &&
      !shouldOpenModalLeaveBehindCapOneShopping &&
      modalLeaveBehindSlug &&
      modalLeaveBehindTipSlug &&
      !isLoadingExp304 &&
      variationExp304 === 'control'
    ) {
      sessionStorage.setItem(
        'modalLeaveBehind-' + modalLeaveBehindTipSlug,
        'true',
      )
    }
    if (!isLoadingExp304 && variationExp304 !== 'control') {
      setOpenModalLeaveBehind(false)
    } else {
      setOpenModalLeaveBehind(true)
    }
  }, [
    sessionStorageExist,
    setOpenModalLeaveBehind,
    shouldOpenModalLeaveBehindCapOneShopping,
    modalLeaveBehindSlug,
    isLoadingExp304,
    variationExp304,
  ])

  const onCloseModalLeaveBehind = React.useCallback(() => {
    setOpenModalLeaveBehind(false)
    logEvent('modal_leave_behind_closed')
  }, [setOpenModalLeaveBehind])

  const value = React.useMemo<IModalLeaveBehindContext>(
    () => ({
      modalLeaveBehindOrderId,
      setModalLeaveBehindOrderId,
      openModalLeaveBehind,
      setOpenModalLeaveBehind,
      openModalLeaveBehindExtension,
      setOpenModalLeaveBehindExtension,
      modalLeaveBehindId,
      setModalLeaveBehindId,
      modalLeaveBehindSlug,
      setModalLeaveBehindSlug,
      isModalLeaveBehindCard,
      setIsModalLeaveBehindCard,
      modalLeaveBehindTipSlug,
      setModalLeaveBehindTipSlug,
      shouldOpenModalLeaveBehindCapOneShopping,
      handleSetOpenModalLeaveBehind,
      onCloseModalLeaveBehind,
    }),
    [
      modalLeaveBehindOrderId,
      openModalLeaveBehind,
      modalLeaveBehindId,
      modalLeaveBehindSlug,
      openModalLeaveBehindExtension,
      isModalLeaveBehindCard,
      modalLeaveBehindTipSlug,
      shouldOpenModalLeaveBehindCapOneShopping,
    ],
  )

  return (
    <ModalLeaveBehindContext.Provider value={{ ...value }}>
      {children}
    </ModalLeaveBehindContext.Provider>
  )
}
