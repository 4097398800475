import React from 'react'
import Image from 'next/image'
import Link from 'next/link'
import { useNextSanityImage } from 'next-sanity-image'
import { createClient } from '@sanity/client'

interface Props {
  categories: any[]
  title: string
}

const client: any = createClient({
  dataset: process.env.SANITY_DATA_SET,
  projectId: process.env.SANITY_PROJECT_ID,
  apiVersion: '1',
  useCdn: true,
  useProjectHostname: false,
})

export const HubCategories = (props: Props) => {
  const { categories, title } = props
  return (
    <div className="relative z-10 / -mt-56 lg:-mt-40 / font-bold text-primary">
      <p className="mb-2">{title}</p>
      <div className="flex flex-wrap justify-between items-stretch / mb-8 / text-xs xs:text-fs13 sm:text-xs lg:text-sm">
        {categories.map((category: any) => {
          return (
            <CategoryTile
              image={category.image}
              key={category.slug}
              name={category.name}
              slug={category.slug}
            />
          )
        })}
      </div>
    </div>
  )
}

const CategoryTile = (category: { image: any; name: string; slug: string }) => {
  const { image, name, slug } = category
  const { src }: any = useNextSanityImage(client, image)

  return (
    <div className="flex items-stretch / w-48pct sm:w-19pct / mb-2 xs:mb-3 sm:mb-2 lg:mb-3">
      <Link
        href={`/credit-cards/${
          slug === 'best' || slug === 'business' ? `${slug}` : `best/${slug}`
        }`}
        className="flex flex-col items-center justify-start / w-full / p-2 md:py-4 md:px-0 lg:py-5 / text-center leading-snug / cursor-pointer bg-white rounded shadow / hover:text-primary-bright hover:shadow-lg / transition-all"
      >
        <div className="relative h-14 w-14">
          <Image
            alt={`${name} icon`}
            src={src}
            fill
            sizes="100vw"
            style={{ objectFit: 'contain' }}
          />
        </div>

        <span className="mt-1">{name}</span>
      </Link>
    </div>
  )
}
