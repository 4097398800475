import * as React from 'react'

export function MobileHamburger() {
  return (
    <svg
      width="25px"
      height="11px"
      viewBox="0 0 25 11"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="Symbols"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
      >
        <g
          id="nav/mobile/main"
          transform="translate(-13.000000, -17.000000)"
          stroke="#052F5E"
          strokeWidth={2}
        >
          <g id="burger-menu">
            <g transform="translate(13.500000, 18.000000)">
              <line
                x1="0.52173913"
                y1="0.5"
                x2="23.4782609"
                y2="0.5"
                id="Line-5"
              />
              <line
                x1="0.52173913"
                y1={9}
                x2="23.4782609"
                y2={9}
                id="Line-5-Copy"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
