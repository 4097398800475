import React from 'react'
import Link from 'next/link'
import Image from 'next/image'
import { nextImageHelper } from '../../utils'

interface ButtonProps {
  text: string
  icon: string
  href?: string
  height: number
  width: number
}

function Button(props: ButtonProps) {
  return (
    <Link
      href={props.href || '#'}
      className="rounded-lg cursor-pointer text-center center w-full border-2 / md:w-48 lg:mr-10 / shadow-button-1 h-20 mb-3 flex justify-center items-center text-md text-primary font-bold md:h-36 md:flex-col border-white bg-white / hover:text-primary-bright hover:shadow-button-2 / transition-all"
    >
      <div className="w-1/3 md:w-full flex justify-center items-center">
        <div className="h-16 w-16 flex justify-center items-center">
          <div className="w-full">
            <Image
              src={props.icon}
              alt={`${props.text} icon`}
              role="presentation"
              height={props.height}
              width={props.width}
              style={nextImageHelper.intrinsic}
            />
          </div>
        </div>
      </div>
      <span className="w-2/3 md:w-11/12 md:mt-1 text-md text-left md:text-center pl-2 md:pl-0">
        {' '}
        {props.text}
      </span>
    </Link>
  )
}
export function Hero() {
  return (
    <div
      id="hero"
      className="c-hero / lg:-mt-16 / pt-8 pb-16 lg:pt-28 md:pb-24 relative overflow-hidden"
    >
      <div className="c-hero__content / container-fluid relative">
        <div className="relative z-10 / mb-8 mx-auto / xl:px-4">
          <h1 className="c-hero__title / mb-4 lg:mb-6 / text-center lg:text-left text-primary font-display text-3xl xxs:text-4xl sm:text-5xl font-bold">
            No Games.
            <br />
            Just Comparisons.
            <sup className="relative font-sans">&#8480;</sup>
          </h1>
          <hr className="border-3 w-24 md:w-32 border-tetriary / mb-4 lg:mb-6 mx-auto lg:ml-0" />

          <p className="text-center mb-5 md:mb-8 / mx-auto lg:mx-0 / md:text-xl xl:text-2xl text-card-gray font-extralight lg:text-left ">
            Easily compare tons of options to find your&nbsp;best:
          </p>
          <div className="max-w-sm md:max-w-2xl mx-auto lg:ml-0 flex flex-col md:flex-row md:justify-around lg:justify-start">
            {[
              {
                text: 'Credit Cards',
                href: '/credit-cards/best',
                icon: '/static/icons/categories/credit-cards.svg',
                height: 64,
                width: 64,
              },
            ].map((button, i) => (
              <Button key={i} {...button} />
            ))}
          </div>
        </div>
        <div className="flex flex-col justify-center items-center mx-auto lg:container / xl:px-4">
          <div className="text-center lg:text-left lg:w-full font-bold text-hero-gray tracking-wider text-sm mb-4 lg:mx-auto uppercase">
            As Seen In
          </div>
          <div className="flex flex-row justify-around lg:justify-start items-center / w-full lg:max-w-2xl xl:max-w-3xl lg:self-start lg:h-6">
            <div className="custom-static-width-pbs / w-20 mr-2 lg:mr-6">
              <Image
                src="/static/as-seen-on/pbs.svg"
                alt="PBS logo"
                height={48}
                width={106}
                style={nextImageHelper.responsive}
                sizes="100vw"
              />
            </div>
            <div className="w-22 h-full / mr-2 lg:mr-6">
              <Image
                src="/static/as-seen-on/forbes.svg"
                alt="Forbes logo"
                height={24}
                width={88}
                style={nextImageHelper.responsive}
                sizes="100vw"
              />
            </div>
            <div className="custom-static-width-usa-today / w-28 sm:mr-2 lg:mr-6">
              <Image
                src="/static/as-seen-on/usa-today.svg"
                alt="Usa Today logo"
                height={26}
                width={141}
                style={nextImageHelper.responsive}
                sizes="100vw"
              />
            </div>
            <div className="hidden sm:block / h-full lg:height-auto / w-36 lg:w-48 / md:mr-2 lg:mr-6">
              <Image
                src="/static/as-seen-on/wsj.svg"
                alt="Wall Street Journal logo"
                height={24}
                width={192}
                style={nextImageHelper.responsive}
                sizes="100vw"
              />
            </div>
            <div className="w-12 h-full md:mr-2 lg:mr-6 / hidden md:block">
              <Image
                src="/static/as-seen-on/tc.svg"
                alt="Tech Crunch logo"
                height={24}
                width={48}
                style={nextImageHelper.responsive}
                sizes="100vw"
              />
            </div>
          </div>
        </div>
        <div className="absolute hidden lg:block c-hero__img-main">
          <Image
            src="/static/images/woman-comparing-products.svg"
            alt="Woman comparing financial products"
            height={566}
            width={576}
            style={nextImageHelper.responsive}
            sizes="100vw"
          />
        </div>
      </div>

      <div className="c-hero__bot / absolute bottom-0 / bg-no-repeat / w-full">
        <div className="lg:hidden">
          <Image
            src="/static/images/bg-gradient-bot-sm.png"
            className="c-hero__img-bg-curve"
            alt="wave image mask"
            role="presentation"
            height={132}
            width={1536}
            style={nextImageHelper.responsive}
            sizes="100vw"
          />
        </div>
        <div className="hidden lg:block">
          <Image
            src="/static/images/bg-bot-mask-white-lg.png"
            className="c-hero__img-bg-curve"
            alt="wave image mask"
            height={96}
            width={1383}
            style={nextImageHelper.responsive}
            sizes="100vw"
          />
        </div>
      </div>
      <style jsx>{`
        .c-hero {
          background: -moz-linear-gradient(
            top,
            rgba(240, 250, 255, 0.25) 0%,
            rgba(240, 250, 255, 1) 75%,
            rgba(240, 250, 255, 1) 100%
          );
          background: -webkit-linear-gradient(
            top,
            rgba(240, 250, 255, 0.25) 0%,
            rgba(240, 250, 255, 1) 75%,
            rgba(240, 250, 255, 1) 100%
          );
          background: linear-gradient(
            to bottom,
            rgba(240, 250, 255, 0.25) 0%,
            rgba(240, 250, 255, 1) 75%,
            rgba(240, 250, 255, 1) 100%
          );
          filter: unquote(
            "progid:DXImageTransform.Microsoft.gradient( startColorstr='#40f0faff', endColorstr='#f0faff',GradientType=0 )"
          );
          width: 100vw;
          position: relative;
          left: 50%;
          right: 50%;
          margin-left: -50vw;
          margin-right: -50vw;
        }
        .c-hero__img-main {
          width: 34rem;
          bottom: 1rem;
          right: -9%;
          z-index: 2;
        }
        @media (min-width: 1120px) {
          .c-hero__img-main {
            right: -1%;
          }
        }
        @media (min-width: 1280px) {
          .c-hero__img-main {
            width: 36rem;
            bottom: 2rem;
            right: -3rem;
          }
        }
        .c-hero__img-bg-curve {
          width: 150%;
          max-width: none;
          height: 3rem;
        }
        @media (min-width: 480px) {
          .c-hero__img-bg-curve {
            max-width: 100%;
          }
        }
        @media (min-width: 1024px) {
          .c-hero__img-bg-curve {
            height: 4rem;
          }
        }
        @media (min-width: 1280px) {
          .c-hero__img-bg-curve {
            height: 6rem;
          }
        }
        @media (min-width: 1024px) {
          .c-hero {
            padding-bottom: 7rem;
          }
        }
        .c-hero__title {
          line-height: 1.15;
        }
        .c-hero__title sup {
          top: -10px;
          font-size: 60%;
        }
        @media (min-width: 1152px) {
          .c-hero__title {
            font-size: 3.25rem;
          }
        }
        .c-hero__content {
          z-index: 2;
        }
        .c-hero__bot {
          z-index: 1;
        }
        .ml-2-neg {
          margin-left: -0.5rem;
        }
        @media (min-width: 375px) {
          .ml-2-neg {
            margin-left: 0;
          }
        }
        .custom-static-width-cnn {
          width: 50px;
        }
        .custom-static-width-wsj-mark {
          width: 45px;
        }
        .custom-static-width-huffpost {
          width: 149px;
        }
      `}</style>
    </div>
  )
}
