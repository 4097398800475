import React from 'react'
import Image from 'next/image'
import { blockSerializers } from './index'
import { SocialShareButtons } from '../'
import { CompareCredit } from '../../../types/compare-credit'

import {
  PortableText,
  PortableTextListComponent,
  PortableTextListItemComponent,
} from '@portabletext/react'

import {
  getCurrentMonth,
  getCurrentYear,
  nextImageHelper,
  supify,
} from '../../utils'

/* eslint-disable react/display-name */
const List = ({ children }: { children: any }) => (
  <ul className="c-list-disc / mb-2 pl-5">{children}</ul>
)

const ListItem = ({ children }: { children: any }) => {
  return (
    <li className={`c-list-disc__item`}>
      {children}{' '}
      <style jsx>
        {`
          .c-list-disc__item {
            margin-bottom: 0.25em;
          }
          .c-list-disc__item:last-child {
            margin-bottom: 0;
          }
        `}
      </style>
    </li>
  )
}

export const TipHeader =
  (data: {
    cardOrderIds: null | Record<string, string>
    referencedCards: null | Record<string, CompareCredit.Entities>
    categoryId: string
    externalId: string
  }) =>
  (props: {
    value: {
      author: CompareCredit.CardTip['author']
      reviewer?: CompareCredit.CardTip['author'][0]
      updatedDate: Date
      author_bar: {
        authorPicture: boolean
        hideAuthorBio: boolean
        dateVariation: boolean
        timeToRead: string
        reviewedBy: boolean
        showSocialIcons: boolean
      }
      borderBottom: boolean
      headline: string
      showAuthor: true
      subtitleEditorToggle: boolean
      subtitle: string
      subtitleAdvertorial: any
      underline: boolean
      location: string
      displayEditorialNote: boolean
    }
  }) => {
    const {
      author,
      reviewer,
      updatedDate,
      author_bar,
      borderBottom,
      headline,
      showAuthor,
      subtitleEditorToggle,
      subtitle,
      subtitleAdvertorial,
      underline,
      location,
    } = props.value

    const showReviewedBy =
      author_bar.reviewedBy &&
      reviewer &&
      reviewer.slug &&
      reviewer.authorBioToggle

    const { cardOrderIds, referencedCards, categoryId, externalId } = data

    return (
      <div className="c-header__container / flex flex-wrap / mx-auto">
        <div className="c-header__inner / mx-auto w-full max-w-6xl">
          <header className="c-header w-full pt-3">
            <div className="c-header__content / relative / md:flex md:flex-wrap md:justify-start max-w-6xl / mx-auto">
              <div
                className={`c-header__content-section / w-full / ${
                  borderBottom
                    ? 'mb-3 xs:mb-1 lg:mb-4 / pb-2 / border-b border-gray-400'
                    : ''
                }`}
              >
                <h1
                  className={`c-header__h1 / u-sup / mb-3 / font-serif text-3xl xxs:text-4xl text-primary font-bold leading-tight md:text-fs42 lg:text-fs38`}
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: supify(headline),
                    }}
                  />
                </h1>
                {underline ? (
                  <hr className="c-header__border / border-3 w-24 md:w-32 border-tetriary / mb-5" />
                ) : (
                  ''
                )}
                {subtitle || subtitleAdvertorial ? (
                  <div className="c-header__subtitle / mb-5 / text-gray-700 sm:text-lg lg:text-xl">
                    {subtitleEditorToggle ? (
                      subtitleAdvertorial ? (
                        <PortableText
                          value={subtitleAdvertorial}
                          components={{
                            ...blockSerializers({
                              cardOrderIds,
                              categoryId,
                              externalId,
                              referencedCards,
                            }),
                            list: List as PortableTextListComponent,
                            listItem: ListItem as PortableTextListItemComponent,
                          }}
                        />
                      ) : (
                        <></>
                      )
                    ) : subtitle ? (
                      <p className="c-header__subtitle-text">{subtitle}</p>
                    ) : (
                      <></>
                    )}
                  </div>
                ) : (
                  <></>
                )}
                {showAuthor && author && author.length > 0 ? (
                  <div className="flex items-start / mb-2">
                    <div className="c-author__container / flex items-start / w-full / mb-1">
                      {author_bar.authorPicture && (
                        <span className="c-author / w-10 h-10 xs:w-12 xs:h-12 / inline-block shrink-0 / bg-gray-300 rounded-full / overflow-hidden / mr-4" />
                      )}
                      <div className="c-author__content w-full">
                        <div
                          className={`flex flex-col sm:flex-row sm:justify-between sm:items-end / w-full ${
                            showReviewedBy ? '' : 'sm:items-end'
                          }`}
                        >
                          <div className="flex flex-col / text-fs13 text-gray-700">
                            <p className="c-author__byline font-bold / sm:mb-1">
                              By {author[0].name}
                              {updatedDate && !author_bar.dateVariation && (
                                <span className="inline-block w-full sm:w-auto / sm:ml-2 / italic text-gray-600">
                                  Updated on {getCurrentMonth(updatedDate)}{' '}
                                  {updatedDate.getDate()},{' '}
                                  {getCurrentYear(updatedDate)}
                                </span>
                              )}
                            </p>

                            {!author_bar.hideAuthorBio && (
                              <p className="hidden sm:flex">
                                {author[0].description}
                              </p>
                            )}
                            {author_bar.dateVariation && (
                              <p className="inline-block w-full sm:w-auto text-gray-600">
                                Updated on {getCurrentMonth(updatedDate)}{' '}
                                {updatedDate.getDate()},{' '}
                                {getCurrentYear(updatedDate)}
                                {author_bar.timeToRead && (
                                  <span className="inline-block ml-1">
                                    &#8226; {author_bar.timeToRead}
                                  </span>
                                )}
                              </p>
                            )}
                          </div>

                          <div
                            className={`flex flex-col shrink-0 items-start sm:items-end / sm:ml-4 ${
                              showReviewedBy
                                ? 'xs:flex-row sm:flex-col sm:-mt-3'
                                : ''
                            }`}
                          >
                            {showReviewedBy && (
                              <p className="sm:order-2 flex items-center w-full sm:w-auto / mb-1 sm:mb-0 xs:mt-1 / text-fs13 text-gray-600">
                                <span className="inline-block w-4 h-4 / mr-1">
                                  <Image
                                    src="/static/icons/icon-checkmark-secondary.svg"
                                    alt="checkmark"
                                    width={24}
                                    height={24}
                                    aria-hidden={true}
                                    style={nextImageHelper.responsive}
                                    sizes="100vw"
                                  />
                                </span>{' '}
                                Reviewed by{' '}
                                <a
                                  href={
                                    reviewer?.slug
                                      ? `/author/${reviewer.slug}`
                                      : '#'
                                  }
                                  className="inline-block ml-1 text-primary-mid font-bold / hover:text-primary-bright"
                                >
                                  {reviewer?.name}
                                </a>
                              </p>
                            )}
                            {author_bar.showSocialIcons && (
                              <div className="c-social-share / sm:order-1 flex flex-row justify-end items-end / sm:-mt-1">
                                <SocialShareButtons
                                  headline={headline}
                                  location={location}
                                  seoTitle={headline || undefined}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  updatedDate && (
                    <div>
                      <span className="c-author__date / flex items-start sm:items-center / -mt-2 sm:mb-2 / w-full sm:w-auto / italic / sm:flex-row sm:justify-between / flex-col / text-fs13 text-gray-600 / font-bold">
                        Updated on {getCurrentMonth(updatedDate)}{' '}
                        {updatedDate.getDate()}, {getCurrentYear(updatedDate)}
                      </span>
                    </div>
                  )
                )}
              </div>
            </div>
          </header>
        </div>
        <style jsx>{`
          .c-author {
            background-image: url(${author[0].image.url});
            background-size: 125% auto;
            background-position: center top;
          }
        `}</style>
      </div>
    )
  }
