import React from 'react'
import isEmpty from 'lodash/fp/isEmpty'
import propOr from 'lodash/fp/propOr'

import { AdvertiserDisclaimer } from '../'
import { CompareCredit } from '../../../types/compare-credit'
import { getCurrentMonth, getCurrentYear } from '../../utils'
import { CardPreview } from '../card-preview'
import { LoadingSkeleton } from './loading-skeleton'
import cn from 'utils/classnames'

export function BestCards(props: {
  cards: CompareCredit.Entities[]
  style: string
}) {
  return (
    <div
      className={cn(
        'pt-16 px-4 / md:px-2 lg:px-4 md:pt-12 / -mx-4 / lg:rounded-lg',
        {
          'mb-4 md:mb-12': props.style == 't-light',
          'c-best-cards-container md:-mt-24': props.style != 't-light',
        },
      )}
    >
      <div className="w-full mb-4 md:mb-8 md:px-2 / order-0 text-right">
        <AdvertiserDisclaimer />
      </div>
      <div className="relative text-center">
        <div className="inline-block mx-auto">
          <h2 className="mb-2 / font-serif font-bold / leading-tight text-primary text-center text-3xl lg:text-4xl">
            Best Credit Cards for {getCurrentMonth()} {getCurrentYear()}
          </h2>
          <p className="relative flex items-center px-6 mb-8 / text-gray-700 text-center">
            <span
              className={cn(
                'c-featured-text shrink-0 / relative z-2 / inline-block px-2 mx-auto',
                {
                  'bg-white': props.style === 't-light',
                  'c-featured-text__bg-green-light': props.style !== 't-light',
                },
              )}
            >
              Offers from Our Partners
            </span>
            <span className="absolute left-0 z-1 / inline-block w-full border-b-2 border-tetriary"></span>
          </p>
        </div>
        <div className="flex flex-col md:flex-row md:items-stretch justify-center lg:justify-between / mx-auto">
          {isEmpty(props.cards) && <LoadingSkeleton n={3} />}
          {props.cards.map((card, i) => {
            if (card._type !== 'card') return null
            const defaultListItems = [
              { label: 'Regular APR', value: card.aprPurchase },
              { label: 'Intro APR', value: card.aprIntroPurchase },
              { label: 'Intro BT APR', value: card.aprIntroBalanceTransfer },
            ]

            const overrideItem = card.featureOverrides.filter(
              (item) => item.categoryOverride === 'best',
            )

            const featuresToRender =
              overrideItem.length > 0
                ? overrideItem[0].features
                : propOr([], 'defaultFeatures', card).length > 0
                ? card.defaultFeatures
                : defaultListItems

            return (
              <CardWrapper key={i}>
                <CardPreview card={card} key={i} listItems={featuresToRender} />
              </CardWrapper>
            )
          })}
        </div>
      </div>
      <style jsx>{`
        .c-best-cards-container {
          background-image: linear-gradient(215deg, #f2fff3 0%, #f5fafc 81%);
        }
        .c-featured-text__bg-green-light {
          background: #f2fff3;
        }
      `}</style>
    </div>
  )
}

function CardWrapper(props: { children: React.ReactNode }) {
  return (
    <div className="md:flex md:items-stetch / w-full md:w-1/3 md:px-2 lg:mx-1">
      {props.children}
    </div>
  )
}
