import React from 'react'
import propOr from 'lodash/fp/propOr'

import { supify } from '../../utils'

export const AttributeRendererWithSlug = (referencedCards: {
  [key: string]: any
}) => {
  // eslint-disable-next-line react/display-name
  return (attrProps: {
    value: {
      attribute: string
      slug: string
    }
  }) => {
    const cardSlug = attrProps.value.slug
    const card = referencedCards.find(
      ({ slug }: { slug: string }) => slug === cardSlug,
    )
    return (
      card &&
      React.createElement('span', {
        dangerouslySetInnerHTML: {
          __html: supify(propOr('', attrProps.value.attribute, card)),
        },
      })
    )
  }
}
