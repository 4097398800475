import React, { useEffect } from 'react'
import { CompareCredit } from '../../types'
import {
  fetchEntities,
  useFetchCardsSlugSimpleReturn,
} from './use-fetch-cards-helper'

export function useFetchCardsSlugSimple(
  slugs: string[],
  cb?: (entities: CompareCredit.Entities[]) => CompareCredit.Entities[],
): useFetchCardsSlugSimpleReturn {
  const [entitiesToRender, setEntitiesToRender] = React.useState<
    CompareCredit.Entities[]
  >([])
  const [isFetching, setIsFetching] = React.useState<boolean>(true)
  const [error, setError] = React.useState<boolean>(false)

  useEffect(() => {
    if (!slugs || !Array.isArray(slugs) || slugs.length === 0) {
      return
    }

    fetchEntities(slugs).then((entities) => {
      setIsFetching(false)
      if (entities && !entities.error) {
        setEntitiesToRender(entities.cards)
      } else {
        setError(true)
      }
    })
  }, [JSON.stringify(slugs)])

  return {
    cards: cb ? cb(entitiesToRender) : entitiesToRender,
    isFetching,
    error,
  }
}
