export const fetchWithTimeout =
  (ms: number) =>
  async (
    url: RequestInfo,
    options?: RequestInit | undefined,
  ): Promise<Response> => {
    const controller = safe__abortController()
    const timeout = setTimeout(() => controller.abort(), ms)

    try {
      const response = await fetch(url, {
        ...options,
        signal: controller.signal,
      })
      clearTimeout(timeout)

      return response
    } catch {
      throw new Error('Network request timed-out by client')
    }
  }

function noop(): void {
  return undefined
}

const ABORT_CONTROLLER_STUB: AbortController = Object.freeze({
  abort: noop,
  signal: {
    aborted: false,
    dispatchEvent: () => false,
    throwIfAborted: () => false,
    reason: false,
    onabort: noop,
    addEventListener: noop,
    removeEventListener: noop,
  },
})

function safe__abortController() {
  try {
    return new AbortController()
  } catch {
    return ABORT_CONTROLLER_STUB
  }
}
