import React from 'react'

import { ClickawayListener, SortSelectContext } from '../'
import { ArrowDown, ArrowUp } from '../icons'
import { SORT_MAP } from '../../utils/sort/sort-map'
import { StatsigContext } from '@statsig/react-bindings'

export function SortSelect() {
  const [dropdownActive, setDropdownActive] = React.useState(false)

  const { client } = React.useContext(StatsigContext)

  const { sort, setSort } = React.useContext(SortSelectContext)

  const sortArrayName = Object.keys(SORT_MAP)
    .map((key: string) => {
      return key !== 'default' ? key : null
    })
    .filter((x) => x) as string[]

  const handleClickOnChange = () => setDropdownActive(!dropdownActive)

  const handleClickOnFilter = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const targetSelectedSort = event.currentTarget.value
      setSort(targetSelectedSort)
      setDropdownActive(false)
    },
    [setSort, setDropdownActive],
  )

  React.useEffect(() => {
    const moreBtn = document.getElementById('more-sort-btn')
    if (moreBtn) {
      if (dropdownActive || sort) {
        moreBtn.classList.remove(
          'bg-white',
          'text-gray-700',
          'hover:text-primary-bright',
          'hover:border-primary-light',
          'focus:text-primary-bright',
          'focus:border-primary-light',
        )
        moreBtn.classList.add(
          'bg-primary-bright',
          'text-white',
          'hover:text-white',
          'focus:text-white',
        )
      } else {
        moreBtn.classList.remove(
          'bg-primary-bright',
          'text-white',
          'hover:text-white',
          'focus:text-white',
        )
        moreBtn.classList.add(
          'bg-white',
          'text-gray-700',
          'hover:text-primary-bright',
          'hover:border-primary-light',
          'focus:text-primary-bright',
          'focus:border-primary-light',
        )
      }
    }
  }, [dropdownActive, sort])

  return client.loadingStatus === 'Ready' && sortArrayName.length > 0 ? (
    <div className="fixed z-80 top-0 left-0 / mt-16 lg:mt-20 lg:mb-0 -mb-14 / pl-4 hidden">
      <ClickawayListener setState={setDropdownActive}>
        <div className="relative / mt-2 xl:block">
          <button
            id="more-sort-btn"
            className="relative flex xl:flex-nowrap / py-2 py-1 pl-6 pr-5 / items-center text-center text-sm text-white font-semibold / bg-white shadow rounded-full / border border-transparent / focus:outline-none transition-all"
            onClick={handleClickOnChange}
          >
            <span className="pr-2">{sort}</span>
            <ArrowDown
              className={`${dropdownActive ? 'hidden' : 'block -mb-1'}`}
              color={dropdownActive || sort ? '#FFF' : '#a0aec0'}
            />
            <ArrowUp
              className={`${dropdownActive ? 'block' : 'hidden'}`}
              color="#FFF"
            />
          </button>
          <div
            className={`c-dropdown__menu / absolute / w-full / -mr-4 / z-20 ${
              dropdownActive ? '' : 'is-closed'
            }`}
          >
            <ul className="p-2 shadow-md rounded-lg bg-white">
              {sortArrayName.map((sortName) => (
                <li key={sortName}>
                  <button
                    className={`${
                      sort === sortName ? 'bg-primary-bright' : 'bg-white'
                    } py-2 px-4 / focus:outline-none rounded-full text-left text-sm ${
                      sort === sortName
                        ? 'text-white'
                        : 'text-gray-700 hover:bg-primary-light-bg focus:bg-primary-light-bg'
                    } w-full font-semibold / transition-all`}
                    value={sortName}
                    onClick={(e) => handleClickOnFilter(e)}
                  >
                    {sortName}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </ClickawayListener>
      <style jsx>{`
        .c-dropdown__menu {
          top: 100%;
          width: 16rem;
          max-height: 60rem;
          opacity: 1;
          overflow: visible;
          transition: all 0.25s ease-in-out;
        }
        .c-dropdown__menu.is-closed {
          max-height: 0;
          opacity: 0;
          overflow: hidden;
        }
        .c-btn-credit:hover .c-btn-credit__range {
          color: #fff;
        }
        .c-btn-credit--active .c-btn-credit__range {
          color: #fff;
        }
      `}</style>
    </div>
  ) : (
    <></>
  )
}
