import * as React from 'react'
import Image from 'next/image'

export function CustomBadge(props: {
  text: string
  theme?: string
  view?: string
}) {
  return (
    <div
      className={`c-custom-badge / text-center
        ${
          props.theme === 'center' || props.theme === 'center-single'
            ? ''
            : `-mb-8 md:-mb-5 lg:-mb-7 md:-ml-2 pt-2 / md:text-sm md:text-left`
        }
        ${props.theme === 'center' ? 'c-custom-badge--center' : ''}
        ${
          props.theme === 'center-single' ? 'c-custom-badge--center-single' : ''
        }
        ${
          props.view === 'modal'
            ? 'c-custom-badge--modal relative xs:-mb-10 md:-mb-6'
            : ''
        }
      `}
    >
      <p
        className={`c-ribbon c-ribbon--slimmer  / relative inline-block / mb-3 / font-bold text-primary text-fs13 tracking-wide leading-relaxed uppercase / bg-tetriary ${
          props.theme === 'center' || props.theme === 'center-single'
            ? 'md:px-2 md:text-fs13 leading-normal'
            : `md:c-ribbon--rt md:ml-2`
        }
        ${
          props.theme === 'center' ||
          props.theme === 'center-single' ||
          props.theme === 'modal'
            ? 'px-2'
            : 'px-4'
        }
        ${props.theme === 'center-single' ? 'lg:w-7/12' : ''}
        ${props.view === 'modal' ? 'c-ribbon--modal' : ''}
        `}
      >
        {props.text}
        <span
          className={`c-custom-badge__arrow / absolute / z-10 hidden xs:block ${
            props.theme === 'center' ? 'c-custom-badge__arrow--center' : ``
          } ${
            props.theme === 'center-single'
              ? 'c-custom-badge__arrow--center-single'
              : ``
          }
          `}
        >
          <span className="c-custom-badge__arrow-1 / inline-block md:hidden / h-12 w-10">
            <Image
              alt="arrow icon"
              fill
              sizes="100vw"
              src="/static/images/arrow-sketchy-gold-rt-dn-1.svg"
            />
          </span>
          <span className="c-custom-badge__arrow-2 / hidden md:inline-block lg:hidden / h-10 w-12 / -mt-2">
            <Image
              alt="arrow icon"
              fill
              sizes="100vw"
              src="/static/images/arrow-sketchy-gold-rt-dn-2.svg"
            />
          </span>
          <span className="c-custom-badge__arrow-3 h-8 w-14 hidden lg:inline-block / -mt-2">
            <Image
              alt="arrow icon"
              fill
              sizes="100vw"
              src="/static/images/arrow-sketchy-gold-rt-dn-3.svg"
            />
          </span>
        </span>
      </p>

      <style jsx>{`
        /* THEME: MODAL
        ======================================= */
        .c-custom-badge--modal {
          margin-top: -1rem;
          z-index: 1;
        }
        .c-ribbon--modal {
          min-width: 12rem;
          margin-bottom: 3rem;
          line-height: 1.5;
        }

        /* ARROW
        ======================================= */
        .c-custom-badge__arrow {
          top: 0.25rem;
          right: -3.5rem;
          transform: rotate(-15deg);
        }

        /* THEME: CENTER - Used on advertorials, modals, and category pages
        ======================================= */
        .c-custom-badge__arrow--center {
          top: 0.75rem;
          transform: rotate(15deg);
        }
        .c-custom-badge--center .c-custom-badge__arrow-1,
        .c-custom-badge--center .c-custom-badge__arrow-2 {
          display: none;
        }
        .c-custom-badge--center .c-custom-badge__arrow-3 {
          display: block;
          width: 3rem;
        }

        // RIBBON
        .c-custom-badge--center .c-ribbon {
          min-width: 12rem;
        }

        /* THEME: CENTER-SINGLE - Used on HPTO's
        ======================================= */
        .c-custom-badge__arrow--center-single {
          top: 0.5rem;
          right: -4.25rem;
        }

        /* MEDIA
           ======================================= */
        @media (min-width: 480px) {
          .c-ribbon--modal {
            min-width: 16rem;
          }
        }
        @media (min-width: 500px) {
          .c-custom-badge--modal .c-custom-badge__arrow {
            right: -4rem;
          }
        }
        @media (min-width: 768px) {
          .c-custom-badge__arrow {
            top: 0;
          }
          .c-custom-badge--modal {
            margin-top: -2rem;
          }
          .c-ribbon--modal {
            margin-bottom: 2.5rem;
          }

          /* THEME: MODAL
          ======================================= */
          .c-ribbon--modal {
            min-width: auto;
            padding-left: 2.5rem;
            padding-right: 2rem;
            font-size: 14px;
          }

          /* THEME: CENTER
          ======================================= */
          .c-custom-badge--center .c-custom-badge__arrow-3 {
            width: 2.5rem;
          }
          .c-custom-badge__arrow--center {
            top: 0.75rem;
            right: -2.75rem;
            transform: rotate(25deg);
          }

          /* THEME: CENTER-SINGLE
          ======================================= */
          .c-custom-badge__arrow--center-single {
            top: 0.5rem;
          }
          .c-custom-badge--center-single .c-custom-badge__arrow-1 {
            display: block;
          }
          .c-custom-badge--center-single .c-custom-badge__arrow-2 {
            display: none;
          }
        }
        @media (min-width: 1024px) {
          // BADGE
          .c-custom-badge {
            margin-bottom: -1.85rem;
          }

          .c-custom-badge__arrow {
            right: -4.5rem;
          }

          /* THEME: CENTER
          ======================================= */
          .c-custom-badge__arrow--center {
            top: 1rem;
            right: -2.75rem;
            transform: rotate(30deg);
          }
          .c-custom-badge--center .c-custom-badge__arrow-2 {
            display: block;
          }
          .c-custom-badge--center .c-custom-badge__arrow-3 {
            display: none;
          }

          /* THEME: CENTER-SINGLE
          ======================================= */
          .c-custom-badge__arrow--center-single {
            top: 0.75rem;
            right: -3.75rem;
            transform: rotate(10deg);
          }
          .c-custom-badge--center-single .c-custom-badge__arrow-2 {
            display: block;
          }
          .c-custom-badge--center-single .c-custom-badge__arrow-1,
          .c-custom-badge--center-single .c-custom-badge__arrow-3 {
            display: none;
          }

          /* THEME: MODAL
          ======================================= */
          .c-custom-badge--modal .c-custom-badge__arrow {
            right: -4.75rem;
          }

          // RIBBON
          .c-ribbon--modal {
            padding-left: 3rem;
          }
        }

        @media (min-width: 1152px) {
          /* THEME: MODAL
          ======================================= */
          .c-ribbon--modal {
            padding-left: 4.25rem;
          }
        }
      `}</style>
    </div>
  )
}
