import React from 'react'
import Image from 'next/image'
import { nextImageHelper } from '../../utils'
import cn from 'utils/classnames'
import { useExperiment } from 'utils/use-experiment-wrapper'
import { getViewsBySlug } from '../../fetch-data/utils/pageViews'

export const Trending = (props: {
  value: {
    title?: string
    content?: string
    number?: string
    theme?: string
  }
}) => {
  const { title, content, number, theme } = props.value
  const views = getViewsBySlug()
  if (!views && !number) return null // if there are no views and no number, don't render
  const nf = new Intl.NumberFormat('en-US')
  const viewCount = number ? nf.format(parseInt(number)) : nf.format(views)

  /* === 2024.05: EXP-295 Trending === */
  const { experiment: configExp295, isLoading: isLoadingExp295 } =
    useExperiment('exp_295_trending_now')
  const variationExp295 = configExp295?.value?.name || 'control'

  return (
    <>
      {!isLoadingExp295 && (
        <div
          className={cn('c-trending__container / mb-2', {
            'exp-295-0': variationExp295 == 'control',
            'exp-295-1': variationExp295 == 'v1-trending-top',
            'exp-295-2': variationExp295 == 'v2-trending-cta',
          })}
        >
          <div
            className={cn(
              'c-trending / max-w-[28rem] / mx-auto / px-3 py-2 / leading-tight text-sm / leading-snug rounded',
              theme == 'lightOrange'
                ? 'c-trending--light-orange'
                : 'c-trending--light-blue bg-primary-light-bg',
            )}
          >
            <div className="c-trending__inner / flex flex-row items-center justify-center">
              <span className="c-trending__icon / shrink-0 inline-block w-14">
                {theme == 'lightOrange' ? (
                  <Image
                    src="/static/icons/icon-bar-graph-up-right-tertiary.svg"
                    alt="trending bar graph"
                    width={48}
                    height={48}
                    style={nextImageHelper.responsive}
                    aria-hidden={true}
                  />
                ) : (
                  <Image
                    src="/static/icons/icon-bar-graph-up-right-primary-bright.svg"
                    alt="trending bar graph"
                    width={48}
                    height={48}
                    style={nextImageHelper.responsive}
                    aria-hidden={true}
                  />
                )}
              </span>
              <div className="c-trending__body / pl-2 pr-1">
                <div className="c-trending__title / font-bold uppercase tracking-wide text-primary-bright">
                  {title ? `${title}` : 'Trending'}
                </div>
                <div className="c-trending__content">
                  <b className="c-trending__number / text-primary">
                    {viewCount}
                  </b>{' '}
                  <span className="c-trending__text">
                    {content
                      ? `${content}`
                      : 'People Have Viewed this Offer in the Last Week'}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <style jsx>{`
            .c-trending--light-orange {
              background-color: #fff6dd;
            }
            .c-trending--light-orange .c-trending__title {
              color: #ebac00;
            }
          `}</style>
        </div>
      )}
    </>
  )
}
