import * as React from 'react'
import times from 'lodash/fp/times'
import toString from 'lodash/fp/toString'
import Link from 'next/link'
import { useExperiment } from 'utils/use-experiment-wrapper'

let popupTimeout: ReturnType<typeof setTimeout>

export function StarRating(props: {
  column?: boolean
  disableInput?: boolean
  marginBottom?: string
  positionTop?: boolean
  positionCenter?: boolean
  productSlug: string
  scale?: string
  stars: number
  userAvgRating: number
  visitorReviewsTotalCount: number
}) {
  const {
    column = false,
    disableInput = false,
    marginBottom,
    positionTop,
    positionCenter,
    scale,
    stars,
    visitorReviewsTotalCount,
  } = props

  const [hoveringOverStars, setHoveringOverStars] = React.useState(false)
  const [hoveringOverPopup, setHoveringOverPopup] = React.useState(false)
  const [starRating] = React.useState(4)

  const createScoreArry = (stars: number) => {
    const [wholeNum, halfNum] = toString(stars).split('.').map(Number)
    return times((x: number) => {
      const idx = x + 1
      if (idx <= wholeNum) return 'full'
      if (idx - wholeNum === 1 && halfNum >= 5) return 'half'
      return 'empty'
    }, 5)
  }

  const handlePopupMouseEnter = () => {
    if (popupTimeout) clearTimeout(popupTimeout)
    setHoveringOverPopup(true)
  }

  const handlePopupMouseLeave = () => {
    popupTimeout = setTimeout(() => {
      setHoveringOverStars(false)
      setHoveringOverPopup(false)
    }, 200)
  }

  // 2024.7: EXP-309 Multicard Tips User Review Count
  const { experiment: configExp309, isLoading: isLoadingExp309 } =
    useExperiment('exp_309_rating_number_multicard_tips')
  const variationExp309 = configExp309?.value?.name || 'control'

  // 2024.7: EXP-307 Category Page User Review Count
  const { experiment: configExp307, isLoading: isLoadingExp307 } =
    useExperiment('exp_307_rating_number_category')
  const variationExp307 = configExp307?.value?.name || 'control'

  return (
    <div className="inline-block">
      <div
        className={`c-product-rating / flex items-center justify-center md:justify-start / mx-auto md:ml-0 / lg:mb-0
        ${positionTop ? 'c-product-rating--top' : ''}
        ${marginBottom ? marginBottom : 'mb-3'}
      `}
      >
        <div
          className={`c-product-rating__inner / relative flex items-center ${
            column && visitorReviewsTotalCount > 0 ? 'flex-col' : ''
          }
          ${marginBottom ? marginBottom : 'mb-2'}
          `}
        >
          <ul
            className="c-product-rating__stars / flex"
            onMouseEnter={() => {
              setHoveringOverStars(!disableInput)
            }}
            onMouseLeave={() => {
              setHoveringOverStars(false)
            }}
          >
            {createScoreArry(stars)
              .map((star: string, i: number) => {
                if (hoveringOverStars || hoveringOverPopup) {
                  return i <= starRating ? 'full' : 'empty'
                } else {
                  return star
                }
              })
              .map((star: string, i: number) => {
                const getStarClass = (star: string) => {
                  switch (star) {
                    case 'full':
                      return 'c-product-rating__star full'
                    case 'half':
                      return 'c-product-rating__star half'
                    case 'empty':
                      return 'c-product-rating__star'
                  }
                }
                return (
                  <li
                    className={`c-product-rating__item flex ${
                      scale === 'small' ? 'h5' : 'h6'
                    }`}
                    key={i}
                  >
                    <span
                      className={`${getStarClass(
                        star,
                      )} inline-block / bg-cover bg-transparent bg-no-repeat ${
                        disableInput ? 'focus:outline-none cursor-default' : ''
                      } ${scale === 'small' ? 'w-5 h-5' : 'w-6 h-6'}`}
                      aria-label={`rate card ${i + 1} star`}
                    />
                  </li>
                )
              })}
          </ul>

          {(!isLoadingExp309 &&
            variationExp309 == `v1-rating-number` &&
            visitorReviewsTotalCount > 0) ||
          (!isLoadingExp307 &&
            variationExp307 == `v1-rating-number` &&
            visitorReviewsTotalCount > 0) ? (
            <p
              className={`c-product-rating__total text-fs13 text-gray-600 / self-center font-semibold ${
                column ? '' : 'ml-10px'
              }`}
            >
              {`(${stars.toLocaleString()} out of 5)`}
            </p>
          ) : (
            ''
          )}
          <div
            className={`c-rating-popup ${
              column && visitorReviewsTotalCount > 0
                ? 'c-column'
                : visitorReviewsTotalCount > 0
                ? 'c-review-num'
                : ''
            } ${hoveringOverPopup || hoveringOverStars ? 'block' : 'hidden'}
            ${positionTop ? 'c-rating-popup--top' : ''}
            ${positionTop ? 'c-arrow-css--south' : ''}
            ${positionCenter ? 'c-rating-popup--center' : ''}
            c-arrow-css / absolute z-10 text-center / bg-white shadow-xl rounded-lg`}
            aria-hidden={!hoveringOverPopup && !hoveringOverStars}
            onMouseEnter={handlePopupMouseEnter}
            onMouseLeave={handlePopupMouseLeave}
          >
            <p className="px-2 py-1.5 / text-sm text-gray-700 / border-b border-gray-200">
              Rated <b className="text-primary">{stars}/5</b> stars by our
              content team
            </p>

            <div className="px-2 py-1">
              <div className="mt-1.5 / pt-0.5 pb-1 px-2">
                <p className="text-left text-fs12 text-gray-600 italic">
                  CompareCredit{' '}
                  <Link
                    href={`/ratings`}
                    rel="noopener noreferrer"
                    target="_blank"
                    className="text-primary-bright transition-all hover:text-primary-mid underline"
                  >
                    star ratings
                  </Link>{' '}
                  are determined by reviewing a variety of factors including
                  bonus offers, rewards rates, and card benefits.{' '}
                  <Link
                    href={`/partners`}
                    rel="noopener noreferrer"
                    target="_blank"
                    className="text-primary-bright transition-all hover:text-primary-mid underline"
                  >
                    Our partners
                  </Link>
                  {` `}
                  do not influence how we rate the cards.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
