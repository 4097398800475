import React from 'react'
import Image from 'next/image'
import { nextImageHelper } from '../../utils'

export const AsSeenInBanner = () => (
  <div className="flex / mb-12 / border-gray-200 border-t border-b">
    <div className="w-full py-3 px-2">
      <p className="w-full mb-2 / font-semibold uppercase text-fs13 text-gray-500 text-center leading-tight / sm:hidden">
        As&nbsp;Seen&nbsp;In
      </p>
      <ul className="flex flex-row items-center justify-around / mx-auto list-none text-center">
        <li className="font-semibold uppercase text-fs13 text-gray-500 text-center leading-tight / hidden sm:flex items-center justify-center">
          As&nbsp;Seen&nbsp;In
        </li>
        <li className="flex items-center justify-center / w-1/4 sm:w-1/5 mr-1">
          <Image
            src="/static/as-seen-on/pbs.svg"
            className="inline-block opacity-25"
            alt="pbs logo"
            height={32}
            width={64}
            loading="lazy"
            style={nextImageHelper.intrinsic}
          />
        </li>
        <li className="flex items-center justify-center / w-1/4 sm:w-1/5 mx-1">
          <Image
            src="/static/as-seen-on/forbes-black.svg"
            className="inline-block opacity-25"
            alt="forbes logo"
            height={32}
            width={64}
            loading="lazy"
            style={nextImageHelper.intrinsic}
          />
        </li>
        <li className="flex items-center justify-center / w-1/4 sm:w-1/5 mx-1">
          <Image
            src="/static/as-seen-on/wsj-mark.svg"
            className="inline-block opacity-25"
            alt="wsj logo"
            height={32}
            width={64}
            loading="lazy"
            style={nextImageHelper.intrinsic}
          />
        </li>
        <li className="flex items-center justify-center / w-1/4 sm:w-1/5 ml-1 xs:mx-2">
          <Image
            src="/static/as-seen-on/tech-crunch-wordmark-b.svg"
            className="inline-block opacity-25"
            alt="tech crunch logo"
            height={32}
            width={80}
            loading="lazy"
            style={nextImageHelper.intrinsic}
          />
        </li>
        <li className="items-center justify-center / sm:w-1/5 hidden xs:flex">
          <Image
            src="/static/as-seen-on/usa-today-black.svg"
            className="inline-block opacity-25"
            alt="usa today logo"
            height={32}
            width={96}
            loading="lazy"
            style={nextImageHelper.intrinsic}
          />
        </li>
      </ul>
    </div>
  </div>
)
