import React from 'react'
import Link from 'next/link'

export function SelectItem(props: {
  children?: React.ReactNode
  selected?: boolean
  onClick?: () => void
  href?: string
  value?: string | number
}) {
  const { selected, onClick } = props
  const Button = () => {
    return (
      <button
        className={`${
          selected ? 'bg-primary-light-bg' : 'bg-white'
        } focus:outline-none / w-full / py-2 px-4 / xs:pr-2 / rounded-full text-sm text-slate-700
          text-left cursor-pointer / hover:text-primary-bright hover:bg-primary-light-bg / transition-all`}
        onClick={onClick}
      >
        {props.children}
      </button>
    )
  }
  return (
    <li className="w-full xs:w-1/2 / xs:px-4 / mb-1">
      {props.href ? (
        <Link href={props.href}>
          <Button />
        </Link>
      ) : (
        <Button />
      )}
    </li>
  )
}
