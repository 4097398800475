import * as React from 'react'
import { CompareCredit } from '../../types'

export function PanelContainer({
  children,
  isDetail,
  view,
  isCollapsed,
  ...passThruProps
}: {
  children: React.ReactElement
  isDetail: boolean
  view: CompareCredit.CardPanelProps['view']
  isCollapsed: boolean
}) {
  const isModalOrDetail = view === 'details' || view === 'modal'

  return (
    <div
      className={`c-results / ${isModalOrDetail && 'relative'} ${
        isDetail && 'pt-6'
      }
       / ${view !== 'list' && ''}`}
      {...passThruProps}
    >
      <div
        className={`c-product-panel c-product-panel--base / mb-5 / bg-white shadow-lg rounded-lg /
        ${view === 'list' && 'hover:shadow-xl'} ${
          isCollapsed ? 'is-closed' : ''
        }
        `}
      >
        {children}
      </div>
    </div>
  )
}
