/* eslint-disable react/display-name */
import React from 'react'
import { v4 as uuid } from 'uuid'
import Image from 'next/image'
import { LabelAd } from '../label-ad'
import { CardArt } from '../card-art'
import { clickPromotion } from '../../clients/segment'
import { supify, useFetchCardsSlug } from '../../utils'
import { CompareCredit } from '../../../types'
import Link from 'next/link'
import cn from 'utils/classnames'
import { useRouter } from 'next/router'
import { isMobileOnly } from 'react-device-detect'

export default function AlertUpsell(props: {
  upsellBanner: CompareCredit.NavUpsellBanner
  promotion: CompareCredit.Promotion
  setShowAlertUpsell: React.Dispatch<React.SetStateAction<boolean>>
}) {
  const { upsellBanner, setShowAlertUpsell } = props
  const [alertShow, setAlertShow] = React.useState(true)
  const [cardNavUpsell, setCardNavUpsell] =
    React.useState<CompareCredit.FormattedCard | null>(null)

  const router = useRouter()

  const handleAlert = () => {
    setAlertShow(!alertShow)
    setShowAlertUpsell(!alertShow)
    window.sessionStorage.setItem(
      `alertUpsellExp-${window.location.pathname}-${upsellBanner.card.slug}`,
      'false',
    )
  }

  const { cards } = useFetchCardsSlug([upsellBanner.card.slug])
  React.useEffect(() => {
    async function getCardToNavUpsell() {
      const cardToNavUpsell = cards[0] as CompareCredit.FormattedCard
      if (cardToNavUpsell) {
        setCardNavUpsell(cardToNavUpsell)
      }
    }
    getCardToNavUpsell()
  }, [cards])

  React.useEffect(() => {
    // Check to see if upsell has been closed
    // if so don't show it again during the session
    if (
      window &&
      window.sessionStorage.getItem(
        `alertUpsellExp-${window.location.pathname}-${upsellBanner.card.slug}`,
      ) === null
    ) {
      setAlertShow(true)
      setShowAlertUpsell(true)
    } else {
      setAlertShow(false)
      setShowAlertUpsell(false)
    }
  }, [upsellBanner.card.slug])

  const [orderId, setOrderId] = React.useState<string | null>(null)
  React.useEffect(() => {
    const id = uuid()
    setOrderId(id)
  }, [])

  const href =
    cardNavUpsell &&
    `/credit-cards/${cardNavUpsell.issuer.slug.current}/${cardNavUpsell.slug}`

  const handleClick = (e: any) => {
    e.preventDefault()
    if (href) {
      if (isMobileOnly) {
        router.push(href)
      } else {
        window.open(href, '_ blank')
      }
    }
    clickPromotion({
      name: props.promotion.name,
      promotion_id: props.promotion.id,
    })
  }

  return (
    <div
      className={`c-alert-upsell gradient-bg relative / items-center / h-24 xs:h-20 sm:h-14 / border-b border-gray-200 ${
        alertShow ? 'flex' : 'hidden'
      }`}
    >
      <div className="container-fluid / flex justify-center / pl-4 pr-6 sm:pr-10 py-1">
        <div className="c-alert-upsell__inner / flex flex-col sm:flex-row sm:items-center mx-auto">
          <div
            className={cn(
              'c-alert-upsell__ribbon / flex items-center /  mb-1 sm:mb-0 md:mr-2 lg:mr-4 / sm:pr-4 / text-left leading-tight font-bold text-primary',
              {
                'text-fs12': upsellBanner.upsellBannerContent.length > 30,
                'text-fs13 xs:text-sm':
                  upsellBanner.upsellBannerContent.length < 31 &&
                  upsellBanner.upsellBannerContent.length > 20,
                'text-sm': upsellBanner.upsellBannerContent.length < 21,
              },
            )}
          >
            <p className="c-alert-upsell__ribbon-text / c-ribbon-bg / hidden sm:flex items-center justify-center  / h-10 pl-2 pr-7">
              {upsellBanner.upsellBannerContent}
            </p>
            <p className="inline-block">
              <span className="c-alert-upsell__ribbon-text / c-ribbon c-ribbon--slimmer c-ribbon--rt sm:hidden / relative flex items-center / w-auto / pl-2 pr-3 / bg-tetriary">
                {upsellBanner.upsellBannerContent}
              </span>
            </p>
          </div>

          <div className="c-alert-upsell__card / flex flex-row items-start xs:items-center">
            <div className="c-alert-upsell__card-art / flex items-center justify-center">
              <div className="c-card-art w-16 mx-auto / text-center flex items-center justify-center">
                <div className="w-full relative / rounded-sm shadow / overflow-hidden">
                  {cardNavUpsell && (
                    <Link
                      href={`/credit-cards/${cardNavUpsell.issuer.slug.current}/${cardNavUpsell.slug}`}
                      className="relative flex / w-full / overflow-hidden rounded-sm shadow z-0"
                      onClick={handleClick}
                    >
                      <span className="c-card-art__content / absolute / flex flex-wrap content-center items-center / z-10 left-0 / w-full h-full text-white lg:text-lg">
                        <span className="inline-block w-full font-semibold leading-4 text-xs">
                          Learn
                          <br /> More
                        </span>
                      </span>
                      {cardNavUpsell && (
                        <CardArt
                          _rev={cardNavUpsell._rev}
                          categoryId="703"
                          externalId="703"
                          cardArt={cardNavUpsell.cardArt}
                          imgClasses="relative z-0 w-full h-full object-cover top-0  bg-dark"
                          customCodeSnippets={cardNavUpsell.customCodeSnippets}
                          issuer={cardNavUpsell.issuer.slug.current}
                          name={cardNavUpsell.name}
                          orderId={orderId}
                          slug={cardNavUpsell.slug}
                        />
                      )}
                    </Link>
                  )}
                </div>
              </div>
            </div>
            <div className="c-alert-upsell__card-info / xs:flex xs:flex-row xs:items-center / pl-2">
              <p className="c-alert-upsell__card-name / max-w-xs xs:w-48 sm:w-auto md:w-56 / mb-1 xs:mb-0 xs:mr-2 md:mr-4 / leading-3 sm:leading-4">
                {cardNavUpsell && (
                  <Link
                    href={`/credit-cards/${cardNavUpsell.issuer.slug.current}/${cardNavUpsell.slug}`}
                    className="text-primary-mid font-semibold text-fs11 xxs:text-fs13 md:text-sm / hover:text-primary-bright"
                    dangerouslySetInnerHTML={{
                      __html: supify(cardNavUpsell.name),
                    }}
                    onClick={handleClick}
                  />
                )}
              </p>

              {cardNavUpsell && (
                <Link
                  href={`/credit-cards/${cardNavUpsell.issuer.slug.current}/${cardNavUpsell.slug}`}
                  className="c-alert-upsell__btn / inline-block / px-3 xxs:px-4 md:px-6 lg:px-8 py-1 xxs:py-1.5 lg:py-2 / shadow rounded-sm bg-primary-bright font-semibold text-white text-fs13 md:text-sm text-center / hover:bg-primary-mid"
                  onClick={handleClick}
                >
                  Learn More
                  <img
                    className="inline-block ml-1 w-3 / sm:hidden md:inline-block"
                    src="/static/icons/icon-chevron-r-white.svg"
                    alt="chevron right"
                    aria-hidden={true}
                  />
                </Link>
              )}
            </div>
          </div>
          <button
            className="c-alert-upsell__close-btn absolute / top-[.25rem] sm:top-[0.25rem] md:top-[.1rem] right-0 / w-8 h-8 / mr-1 / opacity-40 transition-all / hover:opacity-75"
            onClick={handleAlert}
            type="button"
            aria-label="Close"
          >
            <Image
              alt="close icon"
              role="presentation"
              src="/static/icons/icon-close-circle-black.svg"
              fill
              sizes="100vw"
            />
          </button>
          <div className="c-alert-upsell__label / absolute right-[0.5rem] bottom-[0.25rem] sm:-bottom-0 scale-[.9]">
            <LabelAd />
          </div>
        </div>
      </div>
      <style jsx>{`
        .c-ribbon--slimmer {
          height: 18px;
        }
        .c-ribbon--slimmer:before {
          left: -9px;
          border-width: 9px 0 9px 9px;
        }
        .c-ribbon--slimmer:after {
          right: -9px;
          border-width: 9px 9px 9px 0;
        }
        .c-ribbon-bg {
          background-image: url(/static/images/bg-ribbon-rt-tetriary.png);
          background-size: auto 100%;
          background-position: right center;
          background-repeat: no-repeat;
        }
        @media (min-width: 640px) {
          .c-alert-upsell__btn {
            min-width: 7rem;
          }
          .c-alert-upsell__ribbon {
            max-width: 12.5rem;
          }
        }
      `}</style>
    </div>
  )
}
