import * as React from 'react'
import Link from 'next/link'
import Image from 'next/image'
import { nextImageHelper } from '../../../../utils'

import { AdvertiserDisclaimer } from '../../../../components'

type Item = { text: string; href?: string }
interface Props {
  items: Item[]
}

export function Breadcrumbs({ items }: Props) {
  const Item = ({ text, href }: Item) => {
    return href ? (
      <li className="flex items-center">
        <Link
          href={href}
          className="text-gray-600 sm:text-gray-500 / hover:text-primary-bright"
        >
          {text}
        </Link>{' '}
        <span className="inline-block mx-1 opacity-25">
          <Image
            alt=""
            src="/static/icons/icon-chevron-r-black.svg"
            width={12}
            height={12}
            style={nextImageHelper.intrinsic}
          />
        </span>
      </li>
    ) : (
      <li>{text}</li>
    )
  }
  return (
    <div className="sm:flex sm:items-center sm:justify-between / sm:pt-4">
      <div className="pt-3 pb-2 sm:p-0 / -mx-4 xs:-mx-8 mb-2 sm:mb-0 sm:mx-0 / bg-gray-100 / border-b border-gray-200 / sm:border-0 sm:bg-transparent">
        <ul className="relative z-10 / flex flex-wrap / w-full sm:w-auto / px-4 xs:px-8 sm:px-0 / uppercase tracking-wide sm:tracking-normal sm:normal-case text-xs sm:text-fs13 md:text-sm text-gray-800 sm:text-gray-700">
          <li className="flex items-center / mr-1">
            <a
              className="flex items-center opacity-40 / hover:opacity-80"
              href="/"
            >
              <span className="inline-block w-4">
                <Image
                  src="/static/icons/icon-home-primary.svg"
                  height={24}
                  width={24}
                  alt="home"
                  style={nextImageHelper.responsive}
                  sizes="100vw"
                />
              </span>
            </a>
            <span className="inline-block mx-1 opacity-25">
              <Image
                alt=""
                src="/static/icons/icon-chevron-r-black.svg"
                width={12}
                height={12}
                style={nextImageHelper.intrinsic}
              />
            </span>
          </li>
          {items.map(({ text, href }: Item) => {
            return <Item key={text} text={text} href={href} />
          })}
        </ul>
      </div>
      <div className="hidden sm:block sm:pl-8 text-right sm:shrink-0">
        <AdvertiserDisclaimer alignPopup="t-disclosure-right" />
      </div>
    </div>
  )
}
