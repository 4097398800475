export const LabelsMap = [
  {
    label: 'Business Credit Card Reviews',
    value: 'business-credit-card-reviews',
  },
  {
    label: 'Business Guides and Tips',
    value: 'business-guides-and-tips',
  },
  { label: 'Credit Card Reviews', value: 'credit-card-reviews' },
  { label: 'Established Businesses', value: 'established-businesses' },
  { label: 'Focused Multicard', value: 'focused-multicard' },
  { label: 'Guides & Tips', value: 'guides-and-tips' },
  { label: 'In Testing', value: 'in-testing' },
  { label: 'Maven', value: 'maven' },
  { label: 'Multicard Advertorial', value: 'multicard-advertorial' },
  { label: 'Personal Finance', value: 'personal-finance' },
  { label: 'Reco Credit Widget', value: 'reco-credit-widget' },
  { label: 'Optimizing Business Usage', value: 'optimizing-business-usage' },
  { label: 'SEO', value: 'seo' },
  { label: 'Single Card Advertorial', value: 'single-card' },
  { label: 'Small Business', value: 'small-business' },
  { label: 'Sole Proprietors', value: 'sole-proprietors' },
  { label: 'Speakeasy', value: 'speakeasy' },
  { label: 'Student', value: 'student' },
  { label: 'Travel', value: 'travel' },
]
