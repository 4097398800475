export function removeImpressionTags() {
  const tags = document.getElementsByClassName('impression-tag')
  if (
    !tags ||
    tags === null ||
    tags === undefined ||
    !Array.isArray(tags) ||
    tags.length === 0
  ) {
    return
  }
  for (let i = 0; i < tags.length; i++) {
    if (tags[i] && tags[i].parentNode) {
      tags[i]?.parentNode?.removeChild(tags[i])
    }
  }
}
