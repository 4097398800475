import React from 'react'
import Image from 'next/image'
import { Tag } from './index'
import { ClickawayListener } from 'components/'
import { ArrowDown, ArrowUp } from 'components/icons'
import { CreditDisclosure } from 'components/credit-disclosure'
import { nextImageHelper } from 'utils/'
import { useExperiment } from 'utils/use-experiment-wrapper'
import cn from 'utils/classnames'

interface Props {
  dropdownItemSelected: boolean
  handleFilter: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    idx: number,
  ) => void
  maxShown: number
  tags: Tag[]
  selectedTag: string
  isCreditScoreFilter?: boolean
}

export function TabletAndDesktopFilter(props: Props) {
  const [dropdownActive, setDropdownActive] = React.useState(false)

  const {
    dropdownItemSelected,
    handleFilter,
    maxShown,
    tags,
    selectedTag,
    isCreditScoreFilter,
  } = props

  /**************************************************************************
   * Show/hide dropdown menus and change "More" button bg color
   */
  React.useEffect(() => {
    const moreBtn = document.getElementById('more-btn')
    if (moreBtn) {
      if (dropdownActive || dropdownItemSelected) {
        moreBtn.classList.remove(
          'bg-white',
          'text-gray-700',
          'hover:text-primary-bright',
          'hover:border-primary-light',
          'focus:text-primary-bright',
          'focus:border-primary-light',
        )
        moreBtn.classList.add(
          'bg-primary-bright',
          'text-white',
          'hover:text-white',
          'focus:text-white',
        )
      } else {
        moreBtn.classList.remove(
          'bg-primary-bright',
          'text-white',
          'hover:text-white',
          'focus:text-white',
        )
        moreBtn.classList.add(
          'bg-white',
          'text-gray-700',
          'hover:text-primary-bright',
          'hover:border-primary-light',
          'focus:text-primary-bright',
          'focus:border-primary-light',
        )
      }
    }
  }, [dropdownActive, dropdownItemSelected])

  const handleClickOnMore = () => setDropdownActive(!dropdownActive)

  const handleClickOnFilter = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    idx: number,
  ) => {
    handleFilter(event, idx)
    setDropdownActive(false)
  }

  const { experiment } = useExperiment('credit_filter_best_page_v2')
  const variation = experiment?.value?.name || 'control'

  return (
    <div
      className={`${
        variation === 'control-mobile-treatment' ? 'lg:flex' : 'sm:flex'
      } ${
        isCreditScoreFilter
          ? 'sm:flex-row sm:items-center sm:justify-center / w-full / px-4 lg:px-2 pt-2 pb-3 sm:py-2 / bg-primary-light-bg rounded sm:rounded-full / hidden lg:flex flex-grow'
          : 'bg-gray-100  font-body hidden justify-between / overflow-x-auto xl:overflow-visible / py-3 px-3 xl:px-3 rounded-full scrollbar-none -webkit-scrollbar:w-0 -webkit-scrollbar:h-0 w-full whitespace-nowrap'
      }`}
    >
      {isCreditScoreFilter && (
        <div className="relative z-30 / mb-1 sm:mr-3 lg:mr-0 xl:mr-4 / xxs:px-2 / text-sm lg:text-fs13 xl:text-sm text-center">
          <span className="font-semibold text-primary">
            Filter offers by your credit score
          </span>{' '}
          <span className="inline-block xs:w-auto">
            <CreditDisclosure
              theme="t-align-rt"
              adjustPopup="lg-center"
              id="filter-credit-disclosure"
            />
          </span>
        </div>
      )}
      {!isCreditScoreFilter ? (
        tags.map((tag, idx, arr) => (
          <button
            key={tag.id}
            className={`${
              selectedTag === tag.id
                ? 'bg-primary-bright hover:border-primary-bright'
                : 'bg-white text-gray-700 / hover:text-primary-bright hover:shadow-lg hover:border-primary-light focus:text-primary-bright focus:shadow-lg focus:border-primary-light'
            } inline-block focus:outline-none py-2 / font-semibold / border border-transparent / transition-all ${
              idx >= maxShown ? 'xl:hidden' : ''
            } ${
              idx > 0 && idx < arr.length - 1
                ? 'mx-2 xl:mx-1'
                : idx === 0
                ? 'mr-2'
                : 'ml-2'
            } py-1 px-6 rounded-full ${
              selectedTag === tag.id ? 'shadow-lg' : 'shadow'
            } text-center text-sm ${
              selectedTag === tag.id ? 'text-white' : 'text-gray-700'
            }`}
            value={tag.id}
            onClick={(e) => handleClickOnFilter(e, idx)}
          >
            {tag.label}
          </button>
        ))
      ) : (
        <div className="c-category-desk / flex-grow">
          <ul className="flex flex-row justify-between / w-full / text-fs13 xl:text-sm">
            {tags.map((tag, idx) => (
              <li key={tag.id}>
                <button
                  className={cn(
                    'c-btn-credit / flex flex-row items-center justify-center / w-full / px-2 xl:px-4 py-1.5 / bg-white shadow rounded-full / transition-all hover:shadow-lg hover:bg-primary-bright hover:text-white',
                    `trk_credit-select-${tag.id}`,
                    {
                      'c-btn-credit--active bg-primary-bright text-white':
                        selectedTag === tag.id,
                    },
                  )}
                  type="button"
                  value={tag.id}
                  name={tag.id}
                  onClick={(e) => handleClickOnFilter(e, idx)}
                >
                  <span className="flex flex-row items-center justify-start">
                    {tag.icon && (
                      <span className="inline-block / w-6 / mr-2">
                        <Image
                          alt="credit gauge"
                          src={tag.icon}
                          role="presentation"
                          aria-hidden={true}
                          width={16}
                          height={16}
                          style={nextImageHelper.responsive}
                          sizes="100vw"
                        />
                      </span>
                    )}
                    {tag.label}&nbsp;
                    <span className="c-btn-credit__range / inline-block ml-1 text-gray-600 text-fs12">
                      {tag.range}
                    </span>
                  </span>
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}

      {/* The following div is a workaround to an issue where right-side padding/margin doesn't work
          when overflow-x: scroll. This invisible div takes up the correct amount of space in the UI to make
          it appear like there's padding. */}
      <div className="invisible p-3 hidden sm:inline-block xl:hidden" />
      {tags.length > maxShown && (
        <ClickawayListener setState={setDropdownActive}>
          <div className="relative / hidden xl:block">
            <button
              id="more-btn"
              className="relative / hidden xl:flex xl:flex-nowrap / py-2 ml-2 py-1 pl-6 pr-5 / items-center text-center text-sm text-white font-semibold / bg-white shadow rounded-full / border border-transparent / focus:outline-none transition-all"
              onClick={handleClickOnMore}
            >
              <span className="pr-2">More</span>
              <ArrowDown
                className={`${dropdownActive ? 'hidden' : 'block -mb-1'}`}
                color={
                  dropdownActive || dropdownItemSelected ? '#FFF' : '#a0aec0'
                }
              />
              <ArrowUp
                className={`${dropdownActive ? 'block' : 'hidden'}`}
                color="#FFF"
              />
            </button>
            {/* dropdown menu */}
            <div
              className={`c-dropdown__menu / absolute right-0 / -mr-4 / z-20 ${
                dropdownActive ? '' : 'is-closed'
              }`}
            >
              <ul className="p-2 shadow-md rounded-lg bg-white">
                {tags.map(
                  (tag, idx) =>
                    idx >= maxShown && (
                      <li key={tag.id}>
                        <button
                          className={`${
                            selectedTag === tag.id
                              ? 'bg-primary-bright'
                              : 'bg-white'
                          } py-2 px-4 / focus:outline-none rounded-full text-left text-sm ${
                            selectedTag === tag.id
                              ? 'text-white'
                              : 'text-gray-700 hover:bg-primary-light-bg focus:bg-primary-light-bg'
                          } w-full font-semibold / transition-all`}
                          value={tag.id}
                          onClick={(e) => handleClickOnFilter(e, idx)}
                        >
                          {tag.label}
                        </button>
                      </li>
                    ),
                )}
              </ul>
            </div>
          </div>
        </ClickawayListener>
      )}
      <style jsx>{`
        .c-dropdown__menu {
          top: 100%;
          width: 160%;
          max-height: 60rem;
          opacity: 1;
          overflow: visible;
          transition: all 0.25s ease-in-out;
        }
        .c-dropdown__menu.is-closed {
          max-height: 0;
          opacity: 0;
          overflow: hidden;
        }
        .c-btn-credit:hover .c-btn-credit__range {
          color: #fff;
        }
        .c-btn-credit--active .c-btn-credit__range {
          color: #fff;
        }
      `}</style>
    </div>
  )
}
