import React from 'react'
import { AsSeenInBanner } from '../as-seen-in-banner'
import { BTBanner, BTSavingsGraph } from '../bt-advertorial'
import { EditorialNote } from './editorialNote'

export const CustomComponentRenderer = (props: any) => {
  const { component } = props.value
  const customComponents: { [key: string]: React.ReactElement } = {
    'as-seen-in-banner': <AsSeenInBanner />,
    'balance-transfer-banner': <BTBanner />,
    'balance-transfer-graph': <BTSavingsGraph />,
    'editorial-note': <EditorialNote />,
  }
  return customComponents[component]
}
