export const getCurrentYear = (date?: Date) => {
  const currentTime = date || new Date()
  return currentTime.getFullYear()
}

export const getCurrentMonth = (date?: Date) => {
  const currentTime = date || new Date()
  const months: { [key: number]: string } = {
    0: 'January',
    1: 'February',
    2: 'March',
    3: 'April',
    4: 'May',
    5: 'June',
    6: 'July',
    7: 'August',
    8: 'September',
    9: 'October',
    10: 'November',
    11: 'December',
  }
  return months[currentTime.getMonth()]
}

// write function that takes date and returns date if within 5 days, else date 5 days ago
export const getDate = (date: string) => {
  const today = new Date()
  const updatedDate = new Date(date)
  const diffTime = Math.abs(today.getTime() - updatedDate.getTime())
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
  if (diffDays <= 5) {
    return updatedDate
  } else {
    // get date 5 days ago
    const fiveDaysAgo = new Date()
    fiveDaysAgo.setDate(fiveDaysAgo.getDate() - 5)
    return fiveDaysAgo
  }
}
