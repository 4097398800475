import React from 'react'
import Image from 'next/image'
import { nextImageHelper } from '../../utils'

export const HubUpsellAd2 = () => {
  return (
    <div className="pt-3 md:pt-5 pb-4 md:pb-6 / border-b border-gray-300">
      <p className="mb-1 / text-xs text-gray-700 uppercase">Advertisement</p>
      <div>
        <Image
          alt="ladying-holding-bofa-cash-rewards"
          height={150}
          src="/static/images/ladying-holding-bofa-cash-rewards-1.jpg"
          width={300}
          style={nextImageHelper.intrinsic}
        />
      </div>
    </div>
  )
}

export const HubUpsellAd3 = () => {
  return (
    <div className="mb-12">
      <div className="flex items-center justify-center">
        <div>
          <p className="mb-1 / text-xs text-gray-700 uppercase">
            Advertisement
          </p>
          <div>
            <Image
              alt="amazon-boxes"
              height={300}
              src="/static/images/amazon-boxes-1.jpg"
              width={500}
              style={nextImageHelper.intrinsic}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
