import * as React from 'react'

import { PortableText, PortableTextBlockComponent } from '@portabletext/react'

import { AddToCompareBtnTile } from '../..'
import { CardArt } from '../../card-art'
import { CompareCredit } from '../../../../types/compare-credit'
import Link from 'next/link'
import propOr from 'lodash/fp/propOr'
import { supify } from '../../../utils'
import cn from 'utils/classnames'

export function CardTile(props: {
  card: CompareCredit.FormattedCard
  listItems: any[]
  totalCardCount: number
  handleUpdateCart: (
    action: 'add' | 'remove',
    card?: CompareCredit.FormattedCard,
  ) => void
}) {
  const { card, totalCardCount, handleUpdateCart } = props

  const Normal = ({ children }: { children: any }) => <span>{children}</span>

  function AttributeRenderer<K extends keyof CompareCredit.Entity>(attrProps: {
    value: { attribute: K }
  }) {
    return React.createElement('span', {
      dangerouslySetInnerHTML: {
        __html: supify(propOr('', attrProps.value.attribute, card)?.toString()),
      },
    })
  }

  return (
    <>
      <div
        className={cn(
          'c-card-tile / relative / w-full sm:w-1/2 / md:max-w-md p-2 lg:p-4 xl:p-5 / bg-white shadow rounded-sm',
          {
            'mb-2 md:mb-6': totalCardCount > 1,
          },
        )}
      >
        <div className="flex flex-row items-center md:items-start / w-full md:mb-2">
          <div className="flex-shrink-0 / w-1/4 xs:w-24 sm:w-20">
            <div className="c-card-art / text-center flex items-center justify-center / mb-1">
              <div className="relative / max-w-xs w-10/12 md:w-48 / mt-4-neg / rounded-sm shadow">
                <Link
                  href={`/credit-cards/${card.issuer.slug.current}/${card.slug}`}
                  className="relative flex rounded-sm overflow-hidden"
                  target="_blank"
                >
                  <span className="c-card-art__content / absolute / flex flex-wrap content-center items-center / z-10 absolute left-0 / w-full h-full text-white">
                    <span className="w-full font-bold text-fs11 leading-snug">
                      Learn More
                    </span>
                  </span>
                  <CardArt
                    _rev={card._rev}
                    cardArt={card.cardArt}
                    customCodeSnippets={card.customCodeSnippets}
                    imgClasses="relative z-0 w-full h-full object-cover top-0"
                    issuer={card.issuer.slug.current}
                    name={card.name}
                    slug={card.slug}
                    categoryId={null}
                    externalId={null}
                  />
                </Link>
              </div>
            </div>
            {card.issuer.slug.current === 'american-express' && (
              <p className="text-fs10 text-gray-600 / text-center / mb-1 / w-full leading-4 / hidden md:block">
                Terms Apply
              </p>
            )}
          </div>

          <div className="flex flex-row items-center / w-full / px-2">
            <h3 className="flex flex-grow / w-full / pr-2 xs:pr-4 / font-sans font-bold text-left leading-4 text-fs13 sm:text-xs md:text-fs13 lg:text-sm">
              <Link
                href={`/credit-cards/${card.issuer.slug.current}/${card.slug}`}
                className="c-card-title / text-primary-mid hover:text-primary-bright / js-card-title"
                dangerouslySetInnerHTML={{ __html: supify(card.name) }}
              />
            </h3>
            <p className="flex-shrink-0 / text-center">
              <AddToCompareBtnTile
                card={card}
                inCart={false}
                compact
                updateCart={handleUpdateCart}
              />
            </p>
          </div>
        </div>
        <div className="w-full / hidden md:block">
          <ul className="c-list-none / text-xs lg:text-fs13 text-gray-700 / border-t-2 border-gray-200">
            {props.listItems.map((item, i) => {
              return (
                <li
                  key={i}
                  className={cn(
                    'flex flex-row flex-wrap justify-between / py-1.5 / text-left',
                    {
                      'border-b border-gray-200':
                        ++i !== props.listItems.length,
                    },
                  )}
                >
                  <label className="inline-block / w-full / mb-0.5 / font-bold">
                    {item.Title}
                  </label>
                  <span>
                    <PortableText
                      value={item.Description}
                      components={{
                        block: { normal: Normal as PortableTextBlockComponent },
                        types: {
                          cardAttribute: AttributeRenderer,
                        },
                      }}
                    />
                  </span>
                </li>
              )
            })}
          </ul>
        </div>
        <style jsx>{`
          @media (min-width: 640px) {
            .c-card-tile {
              width: 49%;
            }
          }
          @media (min-width: 768px) {
            .c-card-tile {
              width: 100%;
            }
          }
        `}</style>
      </div>
    </>
  )
}
