import { deObfuscate, getRprServiceParamValues } from './'
import { RPR_SERVICE_PARAMS } from './rpr/constants'
import { getValueForParam } from './urls'

export const getRprServiceParameters = (
  product: any,
  slug: string,
  href: string,
  anonymous_id: string,
): any => {
  const category_id = getValueForParam(href, 'category_ref') || ''
  const pathName = decodeURIComponent(getValueForParam(href, 'path_name') || '')

  const rprParams = getRprServiceParamValues(RPR_SERVICE_PARAMS, href)
  const {
    utm_medium: context_campaign_medium,
    utm_campaign: context_campaign_name,
    utm_source: context_campaign_source,
    ...rest
  } = rprParams
  const revenue =
    product._type === 'card'
      ? product.cpa || deObfuscate(product.antrev)
      : product.revenue
  const brand = product?.issuer?.slug.current

  const order_id = getValueForParam(href, 'order_id') || ''

  return {
    ...rest,
    anonymous_id,
    category_id,
    context_campaign_medium,
    context_campaign_name,
    context_campaign_source,
    context_page_path: pathName,
    order_id,
    page_path: pathName,
    brand,
    revenue,
    slug,
  }
}
