import React from 'react'
import Image from 'next/image'
import { nextImageHelper } from 'utils/'
import cn from 'utils/classnames'

export function BackToTop(props: { offset?: number | undefined }) {
  const [hasScrolled, setHasScrolled] = React.useState(false)

  const handleScroll = () => setHasScrolled(window.pageYOffset > 2080)

  React.useEffect(() => {
    handleScroll()
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const scrollToTop = () => window.scrollTo({ top: 0, behavior: 'smooth' })

  return (
    <div
      className={cn('c-back-top / fixed z-40 transition-all--25', {
        'is-shown': hasScrolled,
        raised: props.offset,
      })}
    >
      <button
        className="c-back-top__btn / flex flex-wrap items-center justify-center w-14 / rounded text-white leading-tight / focus:outline-none transition-all--25"
        onClick={scrollToTop}
      >
        <div className="inline-block rotate-180 w-6 h-6">
          <Image
            alt="back to top chevron"
            src="/static/icons/icon-chevron-d-white.svg"
            height={24}
            width={24}
            style={nextImageHelper.responsive}
            sizes="100vw"
          />
        </div>
        <span className="c-back-top__text inline-block / w-full">
          Back <br /> to Top
        </span>
      </button>
      <style jsx>
        {`
          .c-back-top {
            bottom: -5rem;
            right: 0.5rem;
            padding-top: 0.1rem;
            padding-bottom: 0.1rem;
            opacity: 0;
          }
          .c-back-top.is-shown {
            bottom: 0.5rem;
            opacity: 1;
          }
          .c-back-top.raised {
            bottom: ${props.offset}rem;
          }
          .c-back-top__btn {
            padding-bottom: 0.15rem;
            background-color: rgba(2, 28, 57, 0.55);
          }
          .c-back-top__btn:hover {
            background-color: rgba(2, 28, 57, 0.75);
          }
          .c-back-top__text {
            margin-top: -0.1rem;
            font-size: 11px;
          }
          @media (min-width: 768px) {
            .c-back-top {
              display: none;
            }
          }
        `}
      </style>
    </div>
  )
}
