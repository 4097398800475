import React from 'react'
import { supify } from '../../utils'

export function ArticleHeader(props: { title: string }) {
  return (
    <div className="">
      <h1
        className="u-sup / mb-6 xl:mb-8 font-serif text-3xl sm:text-4xl xl:text-5xl font-bold leading-tight text-primary"
        dangerouslySetInnerHTML={{
          __html: supify(props.title),
        }}
      ></h1>
      <hr className="border-4 w-24 sm:w-32 border-tetriary / mb-8 sm:mb-10 xl:mb-12" />
    </div>
  )
}
