import React from 'react'
import last from 'lodash/fp/last'
import { PortableText, PortableTextBlockComponent } from '@portabletext/react'

import { ClickawayListener } from 'components/clickaway-listener'
import { advertiserDisclosureClicked } from 'clients/segment'
import disclosure from 'ssg/disclosures/disclosures.json'
import cn from 'utils/classnames'

const Normal = ({ children }: { children: any }) => {
  return <div className="mb-4">{children}</div>
}

const Heading6 = ({ children }: { children: any }) => {
  return (
    <h6 className="mb-2 / text-lg xs:text-xl leading-snug font-bold text-primary-mid">
      {children}
    </h6>
  )
}

export function AdvertiserDisclaimer(props: {
  advertiserDisclaimer: { content: any[]; name: string }
  alignPopup: string
  colorLight?: boolean
  disclosureTextAlign?: 'left' | 'right'
  fontSizeBase?: boolean
}) {
  const [adDisclOpen, setAdDisclOpen] = React.useState(false)

  const handleAdDiscl = () => setAdDisclOpen(!adDisclOpen)

  return (
    <ClickawayListener setState={setAdDisclOpen}>
      <div
        className={cn(
          'c-advert-disclosure / text-sm relative',
          props.alignPopup,
          {
            'text-left': props.disclosureTextAlign === 'left',
            'text-right': props.disclosureTextAlign === 'right',
          },
        )}
      >
        <button
          className={cn(
            'c-popup / w-auto / text-primary-bright hover:text-primary-mid / transition-all',
            {
              'text-primary-light / hover:text-primary-light-bg':
                props.colorLight,
              'font-bold text-base': props.fontSizeBase,
              'text-sm': !props.fontSizeBase,
            },
          )}
          onClick={() => {
            handleAdDiscl()
            if (adDisclOpen === false) {
              advertiserDisclosureClicked()
            }
          }}
        >
          Advertiser Disclosure
        </button>
        <div
          className={cn(
            'c-popup__container / absolute cursor-default xs:right-0 max-w-md / w-full / font-normal text-left text-gray-700 / z-40',
            {
              block: adDisclOpen,
              hidden: !adDisclOpen,
            },
          )}
        >
          <div className="c-popup__content c-popup__arrow / relative / rounded-lg px-4 py-3 xs:px-6 xs:py-4 bg-white shadow-lg / z-20">
            <PortableText
              value={props.advertiserDisclaimer.content}
              components={{
                block: {
                  normal: Normal as PortableTextBlockComponent,
                  h6: Heading6 as PortableTextBlockComponent,
                },
              }}
            />
          </div>
        </div>
      </div>
    </ClickawayListener>
  )
}

AdvertiserDisclaimer.defaultProps = {
  alignPopup: '',
  advertiserDisclaimer: last(
    disclosure.filter((d) => d.name === 'Advertising Disclosure'),
  ),
}
