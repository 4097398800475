import React from 'react'
import {
  PortableText,
  PortableTextListComponent,
  PortableTextListItemComponent,
} from '@portabletext/react'
import { blockSerializers } from './index'
import { CompareCredit } from '../../../types/compare-credit'

/* eslint-disable react/display-name */
const List = ({ children }: { children: any }) => (
  <ul className="c-list-disc / pl-5">{children}</ul>
)

const ListItem = ({ children }: { children: any }) => {
  return (
    <li className={`c-list-disc__item`}>
      {children}{' '}
      <style>
        {`
            .c-list-disc__item {
              margin-bottom: 0.25em;
            }
            .c-list-disc__item:last-child {
              margin-bottom: 0;
            }
          `}
      </style>
    </li>
  )
}

export const ListProsCons =
  (data: {
    cardOrderIds: null | Record<string, string>
    referencedCards: null | Record<string, CompareCredit.Entities>
    categoryId: string
    externalId: string
  }) =>
  (props: {
    value: {
      title?: string
      unstack?: boolean
      borderTop?: boolean
      borderBottom?: boolean
      prosTitle: string
      consTitle: string
      prosContent: any
      consContent: any
    }
  }) => {
    const {
      title,
      unstack,
      borderTop,
      borderBottom,
      prosTitle,
      consTitle,
      prosContent,
      consContent,
    } = props.value
    const { cardOrderIds, referencedCards, categoryId, externalId } = data

    return (
      <div
        className={`c-list-pros-cons / mb-6 / ${
          borderTop ? 'mt-2 pt-4 border-t-2' : ''
        } ${borderBottom ? 'pb-2 border-b-2' : ''}`}
      >
        {title && (
          <h3 className="c-list-pro-cons__title / mb-1.5 / font-serif font-bold leading-snug text-2xl text-primary w-full">
            {title}
          </h3>
        )}
        <div className={`c-list-pro-cons__wrap / ${unstack ? 'md:flex' : ''}`}>
          <div
            className={`c-list-pro-cons__col / w-full / mb-4 ${
              unstack ? 'md:w-1/2 md:pr-4' : ''
            }`}
          >
            <p className="c-list-pro-cons__subtitle / text-sm uppercase text-secondary font-bold flex">
              <img
                className="c-list-pro-cons__icon / inline-block mr-2 w-5"
                alt="thumbs up"
                src="/static/icons/icon-thumbs-up-green.svg"
                aria-hidden={true}
              />{' '}
              {prosTitle}
            </p>
            <div className="c-list-pro-cons__content">
              <PortableText
                value={prosContent}
                components={{
                  ...blockSerializers({
                    cardOrderIds,
                    categoryId,
                    externalId,
                    referencedCards,
                  }),
                  list: List as PortableTextListComponent,
                  listItem: ListItem as PortableTextListItemComponent,
                }}
              />
            </div>
          </div>
          <div
            className={`c-list-pro-cons__col / w-full / mb-4 ${
              unstack ? 'md:w-1/2 md:pl-4' : ''
            }`}
          >
            <p className="c-list-pro-cons__subtitle / text-sm uppercase text-primary font-bold flex">
              <img
                className="c-list-pro-cons__icon / inline-block mr-2 w-5"
                alt="thumbs down"
                src="/static/icons/icon-thumbs-down-blue.svg"
                aria-hidden={true}
              />{' '}
              {consTitle}
            </p>
            <div className="c-list-pro-cons__content">
              <PortableText
                value={consContent}
                components={{
                  ...blockSerializers({
                    cardOrderIds,
                    categoryId,
                    externalId,
                    referencedCards,
                  }),
                  list: List as PortableTextListComponent,
                  listItem: ListItem as PortableTextListItemComponent,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
