/*
 * Returns an array of slugs to hide based on the experiments passed in
 * @param expNames - an array of experiment names
 * @returns an array of slugs to hide
 */

export const getExpSlugsToHide = (expNames: string[], getExperiment: any) => {
  let expSlugsToHide: string[] = []
  for (const expName of expNames) {
    const slugsToHide = getExperiment(expName).get(
      'exp_cards_to_hide',
      [],
    ) as string[]
    expSlugsToHide = [...expSlugsToHide, ...slugsToHide]
  }

  return expSlugsToHide
}
