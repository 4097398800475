import React from 'react'
import Link from 'next/link'

import { CompareCredit } from '../../../../types/compare-credit'
import { supify } from '../../../utils'
import { CardArt, StarRating } from '../../'
import { v4 as uuid } from 'uuid'
import { clickRatesAndFees } from '../../../clients/segment'

export function CardTile(props: {
  card: CompareCredit.FormattedCard
  categoryId: string
  externalId: string
}) {
  const [orderId, setOrderId] = React.useState<string | null>(null)
  const { card, categoryId, externalId } = props

  React.useEffect(() => {
    const id = uuid()
    setOrderId(id)
  }, [])

  return (
    <div
      key={card.slug}
      className={`c-related-card-tile / relative / w-full / px-3 xs:px-4 sm:px-6 pb-1 bg-white rounded shadow`}
    >
      <div className="c-card-art / text-center flex items-center justify-center / mb-2">
        <div className="relative / max-w-xs w-10/12 md:w-48 / -mt-4 / rounded-sm md:rounded shadow">
          <Link href={`/credit-cards/${card.issuer.slug.current}/${card.slug}`}>
            <div className="relative flex rounded-sm overflow-hidden">
              <span className="c-card-art__content / absolute / flex flex-wrap content-center items-center / z-10 left-0 / w-full h-full text-white">
                <span className="w-full font-bold text-sm">Learn More</span>
              </span>
              <CardArt
                _rev={props.card._rev}
                cardArt={props.card.cardArt}
                imgClasses="relative z-0 w-full h-full object-cover top-0"
                customCodeSnippets={props.card.customCodeSnippets}
                issuer={props.card.issuer.slug.current}
                name={props.card.name}
                orderId={orderId}
                slug={props.card.slug}
                categoryId={categoryId}
                externalId={externalId}
              />
            </div>
          </Link>
        </div>
      </div>

      <div className="c-related-card-tile__rating / flex justify-center mb-1 w-full">
        <StarRating
          column
          marginBottom="mb-0"
          productSlug={card.slug}
          stars={card.expertReviewRating}
          userAvgRating={card.visitorReviewsAverageRating}
          visitorReviewsTotalCount={card.visitorReviewsTotalCount}
        />
      </div>

      <h4 className="c-related-card-tile__name / flex justify-center items-start / md:min-h-2-1/2r / mb-2 / font-sans font-bold text-center leading-tight text-sm sm:text-base lg:text-sm xl:text-base">
        <Link
          href={`/credit-cards/${card.issuer.slug.current}/${card.slug}`}
          className="text-primary-mid hover:text-primary-bright"
          dangerouslySetInnerHTML={{
            __html: supify(card.name),
          }}
        />
      </h4>
      <div className="c-related-card-tile__inner / m-auto mb-5 sm:mb-8 / text-center">
        <div className="max-w-xs w-10/12 lg:w-full / mx-auto">
          <Link
            href={`/credit-cards/${card.issuer.slug.current}/${card.slug}`}
            className="c-related-card-tile__btn / c-btn c-btn--ghost / w-full / focus:outline-none"
          >
            Learn More <span className="sr-only">about the {card.name}</span>
          </Link>
          {card.ratesAndFees && card.ratesAndFees !== 'N/A' && (
            <span className="c-related-card-tile__rates-fees / block mt-0.5 -mb-2 sm:-mb-6">
              <a
                className="inline-block / w-full / text-xs text-center text-primary-bright leading-snug / hover:underline hover:text-primary-mid"
                href={card.ratesAndFees}
                onClick={() => {
                  clickRatesAndFees({
                    applyNowLink: card.applyNowLink,
                    component: 'Card Tile',
                    name: card.name,
                    url: window.location.href,
                  })
                }}
                rel="noopener noreferrer"
                target="_blank"
              >
                Rates &amp; Fees
              </a>
              {card.issuer.slug.current === 'american-express' && (
                <p className="mb-1 text-fs10 text-gray-600 / text-center / w-full leading-4">
                  Terms Apply
                </p>
              )}
            </span>
          )}
        </div>
      </div>
    </div>
  )
}
