/* eslint-disable @typescript-eslint/no-unused-vars */
import { createContext } from 'react'

import { CompareCredit } from '../../../types/compare-credit'

export interface IUserContext {
  comparisonCart: CompareCredit.FormattedCard[]
  comparisonCartCategoryBanner: CompareCredit.FormattedCard[]
  updateComparisonCart: (
    action: 'add' | 'remove' | 'reset' | 'replace',
    cards?: CompareCredit.FormattedCard[],
  ) => void
  updateComparisonCartCategoryBanner: (
    action: 'add' | 'reset',
    cards?: CompareCredit.FormattedCard[],
  ) => void
  clientLocation: {
    city: string
    country: string
    state: string
    status: string
  }
  gaSessionInfo: {
    ga_session_id: string
    ga_session_number: string
  }
  recommendedCard: CompareCredit.FormattedCard | null
  anonymousId: null | string
  navUpsellCard: CompareCredit.FormattedCard | null
  entryQuery?: Record<string, string> | null
  search: CompareCredit.URL['search'] | null
}

export const UserContext = createContext<IUserContext>({
  comparisonCart: [],
  comparisonCartCategoryBanner: [],
  updateComparisonCart: (
    action: 'add' | 'remove' | 'reset' | 'replace',
    cards?: CompareCredit.FormattedCard[],
  ) => null,
  updateComparisonCartCategoryBanner: (
    action: 'add' | 'reset',
    cards?: CompareCredit.FormattedCard[],
  ) => null,
  clientLocation: { city: '', country: '', state: '', status: '' },
  gaSessionInfo: { ga_session_id: '', ga_session_number: '' },
  recommendedCard: null,
  anonymousId: null,
  navUpsellCard: null,
  entryQuery: null,
  search: null,
})
