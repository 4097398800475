import { Segment } from '../../types'

export function transformExperiment(
  exp: Record<string, any>,
): Segment.ExperimentMetadata | null {
  if (exp == null) return null
  const { name, variation } = exp
  return {
    experiment_id: name,
    experiment_name: name,
    variation_id: variation,
    variation_name: variation,
  }
}
