import React from 'react'
import propOr from 'lodash/fp/propOr'
import Image from 'next/image'
import { isMobileOnly } from 'react-device-detect'

import { ClickawayListener } from '../'
import { CompareCredit } from '../../../types/compare-credit'
import { useFetchCardsSlug, nextImageHelper } from '../../utils'
import { CardTile, DropdownMenu } from './'
import { LoadingSkeleton } from './loading-skeleton'

export function PopularCards(props: {
  cards: { [key: string]: { slug: string; _id: string }[] }
  comparisonCart: CompareCredit.FormattedCard[]
  handleUpdateCart: (
    action: 'add' | 'remove',
    cards: CompareCredit.FormattedCard[],
  ) => void
}) {
  const { cards, comparisonCart, handleUpdateCart } = props
  const [dropdownActive, setDropdownActive] = React.useState(false)
  const [selectedCat, setSelectedCat] = React.useState({
    id: 'balance-transfer',
    label: 'Balance Transfer',
  })
  const allTopCards = cards
  const [popularSlugs, setPopularSlugs] = React.useState<string[]>([])
  const { cards: popularCards } = useFetchCardsSlug(popularSlugs)

  const handleCompareAll = () =>
    handleUpdateCart('add', popularCards as CompareCredit.FormattedCard[])

  React.useEffect(() => {
    const popularCardLength = isMobileOnly ? 2 : 3

    if (allTopCards[selectedCat.id]) {
      setPopularSlugs(
        allTopCards[selectedCat.id]
          .slice(0, popularCardLength)
          .map((card) => card.slug),
      )
    }
  }, [selectedCat, comparisonCart.length])

  return (
    <section className="c-popular-comparisons">
      <h2 className="flex flex-wrap justify-center / mb-6 lg:mb-8 / text-base xs:text-lg lg:text-xl text-primary font-semibold text-center">
        Popular&nbsp;
        <ClickawayListener setState={setDropdownActive}>
          <div className="c-popular-comparisons__dropdown / inline-block / ml-1.5 mr-2.5 / border-b-2 border-tetriary">
            <div className="relative / block">
              <button
                className="flex flex-nowrap items-center / font-semibold / mx-auto"
                type="button"
                aria-haspopup="true"
                aria-expanded="false"
                onClick={() => {
                  setDropdownActive(!dropdownActive)
                }}
              >
                <span className="inline-block / w-6 mr-2 / hidden xs:inline-block">
                  <Image
                    alt="category icon"
                    src={`/static/icons/categories/${selectedCat.id}.svg`}
                    height={24}
                    width={24}
                    aria-hidden={true}
                    style={nextImageHelper.responsive}
                    sizes="100vw"
                  />
                </span>
                <span
                  className={`${dropdownActive ? 'text-primary-bright' : ''}`}
                >
                  {selectedCat.label}
                </span>

                <div className="inline-block w-4 ml-2">
                  <Image
                    alt="dropdown arrow"
                    src="/static/icons/icon-chevron-d-tetriary.svg"
                    height={24}
                    width={24}
                    aria-hidden={true}
                    style={nextImageHelper.responsive}
                    sizes="100vw"
                  />
                </div>
              </button>
              <DropdownMenu
                dropdownActive={dropdownActive}
                setDropdownActive={setDropdownActive}
                selectedCat={selectedCat}
                setSelectedCat={setSelectedCat}
              />
            </div>
          </div>
        </ClickawayListener>
        {` `}Cards
      </h2>

      <div className="c-results">
        <div className="w-full / mb-2">
          <button
            className="c-btn c-btn--secondary / w-auto / px-10 py-2 md:py-3 / rounded-sm"
            onClick={handleCompareAll}
          >
            Compare All Cards
          </button>
        </div>
        <div className="c-results__cards / flex flex-col items-center md:flex-row md:items-stretch md:justify-center / pt-4 pb-8 / px-4 xs:px-0">
          {popularCards.length > 0 ? (
            popularCards.map((card, idx, arr) => {
              if (card._type !== 'card') return null

              const defaultListItems = [
                { label: 'Regular APR', value: card.aprPurchase },
                { label: 'Intro APR', value: card.aprIntroPurchase },
                { label: 'Intro BT APR', value: card.aprIntroBalanceTransfer },
              ]

              const overrideItem = card.featureOverrides.filter(
                (item) => item.categoryOverride === 'best',
              )

              const classes =
                arr.length === 1
                  ? ''
                  : (arr.length === 2 && idx === 0) ||
                    (arr.length === 3 && idx < 2)
                  ? 'md:mr-2 lg:mr-5 xl:mr-7'
                  : ''

              const featuresToRender =
                overrideItem.length > 0
                  ? overrideItem[0].features
                  : propOr([], 'defaultFeatures', card).length > 0
                  ? card.defaultFeatures
                  : defaultListItems

              return (
                <CardTile
                  key={(card as CompareCredit.FormattedCard).slug}
                  card={card as CompareCredit.FormattedCard}
                  classes={classes}
                  showStars
                  comparisonCart={comparisonCart}
                  listItems={featuresToRender}
                  updateCart={handleUpdateCart}
                />
              )
            })
          ) : (
            <LoadingSkeleton n={3} />
          )}
        </div>
      </div>
    </section>
  )
}
