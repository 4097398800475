import pathOr from 'lodash/fp/pathOr'
import pickBy from 'lodash/fp/pickBy'
import propOr from 'lodash/fp/propOr'

import { appendToQueryString, getRatesParam } from '.'
import parsers from './parsers'

const scaleCardArt = (url: string) => `${url}?w=448`
const isDefined = (thing: any): boolean => thing != null

export default function formatEntityData(data: any) {
  const formatCard = () => {
    const formatRatesAndFees = (
      ratesAndFees: string | undefined,
      ratesAndFeesLinkParameters: { name: string; parameter: string }[],
    ) => {
      if (!ratesAndFees) return null
      if (ratesAndFeesLinkParameters)
        return appendToQueryString(ratesAndFees, getRatesParam(data))
      return ratesAndFees
    }

    const applyNowLinkProfiles = (applyNowLinkProfiles: any[]) => {
      return applyNowLinkProfiles.reduce((acc, val) => {
        acc[val.id] = {
          applyNowLink: val.applyNowLink,
          applyNowLinkParameters: val.applyNowLinkParameters || [],
        }
        return acc
      }, {})
    }

    const res = {
      _id: data._id,
      _rev: data._rev,
      _type: data._type,
      highlight: data.highlight,
      applyNowLink: data.applyNowLink,
      applyNowLinkParameters: data.applyNowLinkParameters || [],
      applyNowLinkProfiles: applyNowLinkProfiles(
        data.applyNowLinkProfiles || [],
      ),
      approvalPerRedirectPageLevel: data.approvalPerRedirectPageLevel,
      approvalPerRedirectGlobal: data.approvalPerRedirectGlobal,
      approvalTarget: data.approvalTarget,
      aprBalanceTransfer: data.aprBalanceTransfer,
      aprCashAdvance: data.aprCashAdvance,
      aprIntroBalanceTransfer: data.aprIntroBalanceTransfer,
      aprIntroPurchase: data.aprIntroPurchase,
      aprPenalty: data.aprPenalty,
      aprPurchase: data.aprPurchase,
      author: data.author,
      best: data.best,
      bonusForNewCardholders: data.bonusForNewCardholders,
      bonusRewards1: data.bonusRewards1,
      bonusRewards2: data.bonusRewards2,
      bonusRewards3: data.bonusRewards3,
      bonusRewards4: data.bonusRewards4,
      bonusSignUp: data.bonusSignUp,
      cardArt: {
        url: scaleCardArt(pathOr('#', ['cardArt', 'url'], data)),
        ...data.cardArt,
      },
      consExplained: data.consExplained || [],
      customCodeSnippets: data.customCodeSnippets || [],
      image: data.image,
      cardType: data.cardType,
      regularApr: data.regularApr,
      antrev: parsers.cpa()(data) + 42746,
      counterOfferRev: data.cpaCounterOfferV2
        ? data.cpaCounterOfferV2 + 42746
        : undefined,
      creditNeeded: data.creditNeeded,
      creditRange: data.creditRange,
      customBadge: data.customBadge,
      date: data.date || '',
      defaultFeatures: propOr([], 'defaultFeatures', data),
      expertReviewBody: data.expertReviewBody || [],
      expertReviewCons: data.expertReviewCons || [],
      expertReviewHighlight: data.expertReviewHighlight || [],
      expertReviewPros: data.expertReviewPros || [],
      expertReviewRating: data.expertReviewRating,
      featureOverrides: propOr([], 'featureOverrides', data),
      feeAnnual: data.feeAnnual,
      feeBalanceTransfer: data.feeBalanceTransfer,
      feeCashAdvance: data.feeCashAdvance,
      feeLatePayment: data.feeLatePayment,
      feeForeignTransaction: data.feeForeignTransaction,
      hidden: data.hidden,
      introContent: data.introContent || [],
      introFeeAnnual: data.introFeeAnnual,
      issuer: data.issuer,
      issuerSecureSite: data.issuerSecureSite,
      marketingBullets: data.marketingBullets,
      moreInfoLink: data.moreInfoLink,
      name: data.name,
      ongoingFeeAnnual: data.ongoingFeeAnnual,
      phoneNumber: data.phoneNumber || '',
      phoneNumberVerbiage: data.phoneNumberVerbiage,
      popularArticles: data.popularArticles || [],
      prosExplained: data.prosExplained || [],
      ratesAndFees: formatRatesAndFees(
        data.ratesAndFees,
        data.ratesAndFeesLinkParameters,
      ),
      relatedCards: data.relatedCards,
      rewardsRate: data.rewardsRate,
      rpi: data.revenuePerImpression || 0,
      rpiData: data.rpiData,
      seoDescription: data.seoDescription,
      seoTitle: data.seoTitle,
      sixDayRpiLookBack: data.sixDayRpiLookBack,
      slug: pathOr('#', ['slug', 'current'], data),
      targetCreditRange: data.targetCreditRange,
      title: data.title || [],
      visitorReviewsAverageRating: data.visitorReviewsAverageRating,
      visitorReviewsTotalCount: data.visitorReviewsTotalCount,
      faqCard: propOr([], 'faqCard', data),
      warning: data.warning,
    }
    return pickBy(isDefined, res)
  }

  const formatProduct = () => {
    const res = {
      _id: data._id,
      _rev: data._rev,
      _type: data._type,
      brand: {
        affiliate: propOr('', ['brand', 'affiliate'], data),
        name: propOr('', ['brand', 'name'], data),
        slug: propOr('#', ['brand', 'slug'], data),
      },
      category: 'other',
      image: {
        ...data.logo,
        url: scaleCardArt(propOr('#', ['logo', 'url'], data)),
      },
      link: data.link,
      linkText: data.linkText,
      linkParameters: data.linkParameters,
      name: data.name,
      revenue: data.revenue,
      slug: propOr('#', ['slug', 'current'], data),
    }
    return pickBy(isDefined, res)
  }

  return data._type === 'card' || data._type === 'nonPaidCard'
    ? formatCard()
    : data._type === 'product'
    ? formatProduct()
    : data
}
