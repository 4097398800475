import prop from 'lodash/fp/prop'
import last from 'lodash/fp/last'
import settings from '../../ssg/settings/business-settings.json'

export const destinationUrls: { [key: string]: string } = {
  fair: prop('fairUrl', last(settings)) as unknown as string,
  poor: prop('poorUrl', last(settings)) as unknown as string,
  soleProprietorship: prop(
    'soleProprietorshipUrl',
    last(settings),
  ) as unknown as string,
}

export const step2CreditValues = ['good', 'excellent', 'unknown']
export const redirectCreditValues = ['poor', 'fair']
export const creditValues = [...step2CreditValues, ...redirectCreditValues]
export const legalEntityValues = ['soleProprietorship', 'allOtherLegalEntities']

export const initialState = {
  currentStep: 1,
  formData: {
    excellent: undefined,
    fair: undefined,
    good: undefined,
    poor: undefined,
    unknown: undefined,
    legalEntity: undefined,
  },
}

export function businessSegmentationReducer(
  state: BusinessSegmentationReducer['state'],
  action: BusinessSegmentationReducer['action'],
) {
  if (action.type === 'next_step') {
    return { ...state, currentStep: state.currentStep + 1 }
  } else if (action.type === 'prev_step') {
    return { ...state, currentStep: state.currentStep - 1 }
  } else if (action.type === 'navigate') {
    return { ...state, currentStep: (state.currentStep = 3) }
  } else {
    throw new Error("This action type isn't supported.")
  }
}

interface BusinessSegmentationReducer {
  state: {
    currentStep: number
    formData: {
      excellent: string | undefined
      fair: string | undefined
      good: string | undefined
      poor: string | undefined
      unknown: string | undefined
      legalEntity: string | undefined
    }
  }
  action: {
    type: string
  }
}
