import React from 'react'
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
} from 'react-share'

export function SocialShareButtons(props: {
  headline: string
  location: string
  seoTitle: string | undefined
}) {
  return (
    <>
      <TwitterShareButton
        url={props.location}
        title={props.seoTitle || `${props.headline} | Compare Credit`}
        className="mr-1"
      >
        <TwitterIcon size={32} round path="" />
      </TwitterShareButton>
      <FacebookShareButton url={props.location}>
        <FacebookIcon size={32} round path="" />
      </FacebookShareButton>
    </>
  )
}
