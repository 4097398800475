import React from 'react'
import Image from 'next/image'
import { getViewsBySlug } from '../../fetch-data/utils/pageViews'

export function CardPopularTab() {
  const views = getViewsBySlug()
  if (!views) return null // if there are no views and no number, don't render
  const nf = new Intl.NumberFormat('en-US')
  const viewCount = nf.format(views)

  return (
    <div className="c-card-tab / relative z-1 / text-left -mb-4 / overflow-hidden rounded-t-lg bg-primary-light-bg">
      <div className="c-card-tab__inner / flex flex-row items-center justify-center md:justify-start / w-auto / text-left text-fs13 sm:text-sm leading-[1.1]">
        <div className="c-card-tab__label / flex flex-row items-center / pl-1 md:pl-2 py-1 / text-primary-mid uppercase font-bold">
          <span className="c-card-tab__icon / shrink-0 / inline-block w-5 h-5 / mr-1 sm:mr-1.5">
            <Image
              src="/static/icons/icon-bar-graph-up-right-tertiary-dark.svg"
              alt="bar graph"
              width={48}
              height={48}
            />
          </span>
          <span>Popular&nbsp;Card</span>
        </div>
        <div className="c-card-tab__description / px-3 sm:pr-5 py-0.5 / text-slate-600">
          {viewCount} People have viewed this offer in the last&nbsp;week
        </div>
        <div
          className="c-card-label__shine / absolute z-2 / w-16 / rotate-12"
          role="presentation"
        ></div>
      </div>
      <style jsx>{`
        .c-card-tab {
          opacity: 0;
          animation-name: fadeInOpacity, animateTopNorthRelative;
          animation-duration: 1s;
          animation-fill-mode: forwards;
          animation-delay: 0.1s;
        }
        .c-card-label__shine {
          background: linear-gradient(
            to right,
            rgba(33, 163, 252, 0) 0%,
            rgba(33, 163, 252, 0.07) 100%
          );
          display: block;
          height: 100%;
          left: -75%;
          position: absolute;
          top: 0;
          transform: skewX(-25deg);
          width: 50%;
          animation: shine 6s 1;
          animation-delay: 1.5s;
        }
        @keyframes shine {
          0% {
            left: -75%;
          }
          30% {
            left: 125%;
          }
          100% {
            left: 125%;
          }
        }
      `}</style>
    </div>
  )
}
