export function segmentMiddleware({
  payload,
  next,
}: {
  payload: any
  next: any
}) {
  try {
    const isBot = (window as any).isBot

    if (isBot) return

    // attach messageId to the checkout started events in order to de-dupe
    if (
      payload?.obj?.event === 'Checkout Started' &&
      payload?.obj?.properties?.order_id
    ) {
      payload.obj.messageId = `checkout-started-${payload.obj.properties.order_id}`
    }
    next(payload)
  } catch (err) {
    console.log('isBotMiddleware', err)
  }
}
