export const setPositionBias = (sort: string): string => {
  switch (sort) {
    case 'decomposition_v2.0.0':
      return '1'
    case 'decomposition_v3.0.0':
      return '2'
    default:
      return '1'
  }
}
