import React from 'react'

interface FeaturedRibbonProps {
  layoutSlim: boolean
  featuredRibbonText: string
}

export default function FeaturedRibbon({
  layoutSlim,
  featuredRibbonText,
}: FeaturedRibbonProps) {
  return (
    <div
      className={`c-ribbon__container / flex -mt-8 text-center ${
        layoutSlim ? 'mb-2' : 'mb-8'
      }`}
    >
      <p
        className={`c-ribbon c-ribbon--slimmer / relative / inline-block / w-auto mx-auto -mt-1 px-4 py-1 / font-bold text-primary text-sm uppercase tracking-wide / bg-tetriary`}
      >
        <span className="c-ribbon__text relative / inline-block">
          {featuredRibbonText}
        </span>
      </p>
    </div>
  )
}
