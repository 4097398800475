import { VertexVersionMap } from './types'
import { VertexRprVersionMap } from '../rpr/rpr-service-helpers-types'

export const ALLOC = 'alloc'
export const VERTEX_VERSIONS: VertexVersionMap = {
  v12: '2292384986889715712', // Decomp v1 match score endpoint
  v13: '6904071005317103616', // Decomp v1 conversion score endpoint
  v18: '719595176007303168', // athena-v1 endpoint
  v19: '719595176007303168', // athena-v1-test endpoint
}
export const DECOMP_SORT_VERSIONS = ['v12', 'v13']
export const CUSTOM_MODEL_SORT_VERSIONS = ['v18', 'v19']
export const VERTEX_RPR_VERSIONS: VertexRprVersionMap = {
  v1: '8444266893505724416',
}
export const VERTEX_PROJECT_ID = 'comparecredit'
export const VERTEX_LOCATION = 'us-east4'
