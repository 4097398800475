import React from 'react'
import { useExperiment } from 'utils/use-experiment-wrapper'

export const TestImageAd = (props: {
  value: {
    imageStyling?: string
    statsigId: string
  }
}) => {
  const { imageStyling, statsigId } = props.value

  // @ts-ignore
  // STATSIG EXPERIMENT 2023.01
  const { experiment } = useExperiment(statsigId)
  const url = experiment.value.img_url as string
  const imgAd = url || null

  return (
    <div
      className={`c-image-ad
      ${imgAd === null ? 'c-image-ad--hidden' : ''}
      ${imageStyling ? `${imageStyling}` : 'mx-auto'}
    `}
    >
      {imgAd && (
        <img
          className="c-image-ad__img / w-full"
          alt="article_image"
          src={imgAd}
        />
      )}
      <style jsx>{`
        .c-image-ad--hidden {
          display: none;
        }
      `}</style>
    </div>
  )
}
