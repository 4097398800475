import React from 'react'
import Image from 'next/image'
import { createClient } from '@sanity/client'
import { useNextSanityImage } from 'next-sanity-image'
import { nextImageHelper } from '../../utils'

const client: any = createClient({
  dataset: process.env.SANITY_DATA_SET,
  projectId: process.env.SANITY_PROJECT_ID,
  apiVersion: '1',
  useCdn: true,
  useProjectHostname: false,
})

export const TipArticleInContent = (props: {
  value: {
    headline: string
    subtitle: string
    url: string
    image: any
  }
}) => {
  const { headline, subtitle, url, image } = props.value
  const imageProps: any = useNextSanityImage(client, image)

  return (
    <div className="max-w-xs md:max-w-none / mb-12 mx-auto md:ml-0 pt-10 / border-t border-solid border-gray-400">
      {/* eslint-disable react/jsx-no-target-blank */}
      <a
        href={url}
        className="c-article-link / flex flex-col md:flex-row md:items-center "
        target="_blank"
      >
        <span className="md:w-1/4 lg:w-1/3">
          <span className="block / shrink-0 / mb-2 md:mb-0 / bg-gray-200 rounded overflow-hidden shadow-lg bg-cover / transition-all">
            <span className="c-article-link__img / object-cover / transform / transition">
              <Image
                {...(imageProps as any)}
                priority
                placeholder="blur"
                style={nextImageHelper.responsive}
                sizes="100vw"
                blurDataURL={image.metadata.lqip}
              />
            </span>
          </span>
        </span>
        <span className="md:w-3/4 lg:w-3/4 / md:pl-4 / text-gray-700">
          <p className="c-article-link__title / text-lg text-primary font-bold / hover:text-primary-bright / transition-all">
            {headline}
          </p>
          <p className="text-sm">{subtitle}</p>
        </span>
      </a>
    </div>
  )
}
