import React from 'react'
import Image from 'next/image'
import { v4 as uuid } from 'uuid'

import { nextImageHelper } from 'utils/'
import cn from 'utils/classnames'

interface Message {
  text: string
  isUser: boolean
}

export const ChatbotComponent = () => {
  const [messages, setMessages] = React.useState<Message[]>([])
  const [isLoading, setIsLoading] = React.useState(false)
  const messageContainerRef = React.useRef<HTMLDivElement>(null)
  const [responseContent, setResponseContent] = React.useState<string>('')
  const responseContentRef = React.useRef<string>('')
  const [thread_id] = React.useState(uuid())

  React.useEffect(() => {
    const welcomeMessage: Message = {
      text: `Hi there, I'm Comparebot, what would you like to learn about CompareCredit?
        Please note that I am an experimental feature and may not be able to answer all of your questions yet.
        Please verify my output before executing.`,
      isUser: false,
    }
    setMessages([welcomeMessage])
  }, [])

  React.useEffect(() => {
    responseContentRef.current = responseContent
  }, [responseContent])

  const generateResponse = async (prompt: string) => {
    try {
      setResponseContent('')
      setIsLoading(true)
      const response = await fetch('/api/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          prompt,
          thread_id, // redis conversation id
        }),
      })
      if (!response.ok) {
        return `I'm sorry, I don't understand your question. Please provide more information.`
      }
      const data = response.body
      if (!data) {
        return
      }
      const reader = data.getReader()
      const decoder = new TextDecoder()
      let done = false

      while (!done) {
        const { value, done: doneReading } = await reader.read()
        done = doneReading
        const chunkValue = decoder.decode(value)
        setResponseContent((prev) => prev + chunkValue)
      }
      setIsLoading(false)
    } catch (error) {
      console.error(error)
    }
  }

  const handleSendMessage = React.useCallback(
    async (message: string) => {
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: message, isUser: true },
      ])
      await generateResponse(message)
      setIsLoading(false)

      setMessages((prevMessages) => [
        ...prevMessages,
        { text: responseContentRef.current, isUser: false },
      ])
    },
    [setMessages, generateResponse, setIsLoading],
  )

  React.useEffect(() => {
    if (messageContainerRef.current) {
      messageContainerRef.current.scrollTop =
        messageContainerRef.current.scrollHeight
    }
  }, [messages])

  return (
    <>
      <div className="mx-auto p-4 flex flex-col">
        <div className="bg-white border rounded shadow flex-grow flex flex-col">
          <div className="p-4 border-b">
            <h1 className="text-lg font-semibold">Chat with Comparebot</h1>
          </div>
          <div
            className="p-4 flex-grow overflow-y-scroll w-full c-chat-box"
            ref={messageContainerRef}
          >
            {messages.map((message, index) => (
              <div
                key={index}
                className={cn('my-2 p-2 rounded flex', {
                  'bg-blue-500 text-white justify-end': message.isUser,
                  'bg-gray-100 justify-start': !message.isUser,
                })}
              >
                {!message.isUser && (
                  <div className="mr-2">
                    <Image
                      alt="compare credit logomark"
                      src="/static/logos/compare-credit_logomark.svg"
                      height={37}
                      width={32}
                      style={nextImageHelper.intrinsic}
                    />
                  </div>
                )}
                <div>{message.text}</div>
                {message.isUser && (
                  <div className="ml-2">
                    <Image
                      alt="compare credit logomark"
                      src="/static/icons/icon-person-b.svg"
                      height={37}
                      width={32}
                      style={nextImageHelper.intrinsic}
                    />
                  </div>
                )}
              </div>
            ))}
            {isLoading && (
              <div className="mr-2 flex w-full / bg-gray-300 rounded-sm animate-pulse">
                <div className="m-2">
                  <Image
                    alt="compare credit logomark"
                    src="/static/logos/compare-credit_logomark.svg"
                    height={37}
                    width={32}
                    style={nextImageHelper.intrinsic}
                  />
                </div>
                <p className="pl-2 pt-4">{responseContent}</p>
              </div>
            )}
          </div>
          <div className="p-4 border-t">
            <input
              type="text"
              placeholder="What is CompareCredit about?"
              className="w-full px-3 py-2 border rounded"
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  const inputElement = e.target as HTMLInputElement
                  const value = inputElement.value
                  handleSendMessage(value)
                  inputElement.value = ''
                }
              }}
            />
          </div>
        </div>
      </div>
      <style jsx>{`
        .c-chat-box {
          height: 70vh;
        }
      `}</style>
    </>
  )
}
