import * as React from 'react'
import times from 'lodash/fp/times'
import toString from 'lodash/fp/toString'
import Link from 'next/link'

let popupTimeout: ReturnType<typeof setTimeout>

export function StarRatingEditor(props: {
  column?: boolean
  disableInput?: boolean
  marginBottom?: string
  positionTop?: boolean
  positionCenter?: boolean
  productSlug: string
  scale?: string
  starsEditor: number
  theme?: string
  userAvgRating: number
  visitorReviewsTotalCount: number
}) {
  const {
    column = false,
    disableInput = false,
    marginBottom,
    positionTop,
    positionCenter,
    scale,
    starsEditor,
    theme,
    visitorReviewsTotalCount,
  } = props

  const [hoveringOverStars, setHoveringOverStars] = React.useState(false)
  const [hoveringOverPopup, setHoveringOverPopup] = React.useState(false)
  const [starRating] = React.useState(4)

  const createScoreArry = (stars: number) => {
    const [wholeNum, halfNum] = toString(stars).split('.').map(Number)
    return times((x: number) => {
      const idx = x + 1
      if (idx <= wholeNum) return 'full'
      if (idx - wholeNum === 1 && halfNum >= 5) return 'half'
      return 'empty'
    }, 5)
  }

  const handlePopupMouseEnter = () => {
    if (popupTimeout) clearTimeout(popupTimeout)
    setHoveringOverPopup(true)
  }

  const handlePopupMouseLeave = () => {
    popupTimeout = setTimeout(() => {
      setHoveringOverStars(false)
      setHoveringOverPopup(false)
    }, 200)
  }

  return (
    <div className="inline-block w-full">
      <div
        className={`c-product-rating / flex  / mx-auto md:ml-0 / lg:mb-0
        ${positionTop ? 'c-product-rating--top' : ''}
        ${marginBottom ? marginBottom : 'mb-3'}
        ${
          theme == 't-mc-tips'
            ? 'justify-start md:justify-center / pl-2 xs:pl-4 md:pl-2 md:pb-0.5 lg:pb-1.5'
            : 'items-center justify-center md:justify-start'
        }
      `}
      >
        <div
          className={`c-rating-editor relative / flex flex-row / pr-4 / leading-snug ${
            theme == 'column'
              ? 'items-center mb-1 pb-1.5 border-b border-slate-200'
              : 'max-w-none'
          }`}
          onMouseEnter={() => {
            setHoveringOverStars(!disableInput)
          }}
          onMouseLeave={() => {
            setHoveringOverStars(false)
          }}
        >
          <div className="c-product-editor__score / flex items-end justify-center / -mt-1">
            <span className="c-product-editor__score-main / inline-block / text-[32px] font-bold text-primary">
              {starsEditor.toFixed(1)}
            </span>{' '}
          </div>
          <div className="c-product-editor__label / pl-2.5 / ">
            <ul className="c-product-rating__stars / flex">
              {createScoreArry(starsEditor)
                .map((star: string, i: number) => {
                  if (hoveringOverStars || hoveringOverPopup) {
                    return i <= starRating ? 'full' : 'empty'
                  } else {
                    return star
                  }
                })
                .map((star: string, i: number) => {
                  const getStarClass = (star: string) => {
                    switch (star) {
                      case 'full':
                        return 'c-product-rating__star full'
                      case 'half':
                        return 'c-product-rating__star half'
                      case 'empty':
                        return 'c-product-rating__star'
                    }
                  }
                  return (
                    <li
                      className={`c-product-rating__item flex ${
                        scale === 'small' ? 'h5' : 'h6'
                      }`}
                      key={i}
                    >
                      <span
                        className={`${getStarClass(
                          star,
                        )} inline-block / bg-cover bg-transparent bg-no-repeat ${
                          disableInput
                            ? 'focus:outline-none cursor-default'
                            : ''
                        } ${scale === 'small' ? 'w-5 h-5' : 'w-6 h-6'}`}
                        aria-label={`rate card ${i + 1} star`}
                      />
                    </li>
                  )
                })}
            </ul>
            <div className="text-fs12 text-slate-600 text-left leading-[1.1]">
              CompareCredit Score
            </div>
          </div>
          <div
            className={`c-rating-popup ${
              column && visitorReviewsTotalCount > 0
                ? 'c-column'
                : visitorReviewsTotalCount > 0
                ? 'c-review-num'
                : ''
            } ${hoveringOverPopup || hoveringOverStars ? 'block' : 'hidden'}
            ${positionTop ? 'c-rating-popup--top' : ''}
            ${positionTop ? 'c-arrow-css--south' : ''}
            ${positionCenter ? 'c-rating-popup--center' : ''}
            c-arrow-css / absolute z-10 text-center / bg-white shadow-xl rounded`}
            aria-hidden={!hoveringOverPopup && !hoveringOverStars}
            onMouseEnter={handlePopupMouseEnter}
            onMouseLeave={handlePopupMouseLeave}
          >
            <p className="px-2 py-1.5 / text-sm text-gray-700 / border-b border-gray-200">
              Rated{' '}
              <b className="text-primary">
                {starsEditor.toFixed(1)}
                <span className="font-normal text-[12px] text-slate-700">
                  /5
                </span>
              </b>{' '}
              stars by our content team
            </p>

            <div className="px-2 py-1">
              <div className="mt-1.5 / pt-0.5 pb-1 px-2">
                <p className="text-left text-fs12 text-gray-600 italic">
                  CompareCredit{' '}
                  <Link
                    href={`/ratings`}
                    rel="noopener noreferrer"
                    target="_blank"
                    className="text-primary-bright transition-all hover:text-primary-mid underline"
                  >
                    star ratings
                  </Link>{' '}
                  are determined by reviewing a variety of factors including
                  bonus offers, rewards rates, and card benefits.{' '}
                  <Link
                    href={`/partners`}
                    rel="noopener noreferrer"
                    target="_blank"
                    className="text-primary-bright transition-all hover:text-primary-mid underline"
                  >
                    Our partners
                  </Link>
                  {` `}
                  do not influence how we rate the cards.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <style jsx>{`
        .c-rating-popup.c-column {
          left: -4rem;
        }
        .c-product-rating--column {
          margin-bottom: 0;
        }
        @media (min-width: 768px) {
          .c-product-rating--column .c-rating-popup {
            left: -4.25rem;
          }
        }
        @media (min-width: 1152px) {
          .c-product-rating--column .c-rating-popup {
            left: -3.5rem;
          }
        }
      `}</style>
    </div>
  )
}
