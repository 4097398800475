import { convertSearchParamsToObject } from './convertSearchParamsToObject'
import { CompareCredit } from '../../types'

export const getUrl = (): CompareCredit.URL => {
  let url
  if (typeof window !== 'undefined') {
    url = new URL(window.location.href)
    return {
      pathname: url.pathname,
      query: convertSearchParamsToObject(url.searchParams),
      search: url.search,
    }
  } else {
    return {
      pathname: null,
      query: null,
      search: null,
    }
  }
}
