import React from 'react'
import Link from 'next/link'

import { CompareCredit } from '../../../types/compare-credit'
import prop from 'lodash/fp/prop'
import cn from 'utils/classnames'
import { CardArt, StarRating } from '../'
import { useRouter } from 'next/router'
import { isMobileOnly } from 'react-device-detect'

/* eslint-disable react/display-name */
export const ImageCardArt =
  (data: {
    referencedCards: null | Record<string, CompareCredit.Entities>
    externalId: string
    categoryId: string
  }) =>
  (props: {
    value: {
      card: {
        _id: string
        slug: string
      }
      cardLink?: boolean
      cardName?: boolean
      cardNameLink?: boolean
      cardRating?: boolean

      smStyling?: string
      mdStyling?: string
      lgStyling?: string
    }
  }) => {
    const {
      card,
      cardLink,
      cardName,
      cardNameLink,
      cardRating,
      smStyling,
      mdStyling,
      lgStyling,
    } = props.value

    const {
      referencedCards,
      externalId: tagId,
      categoryId: categoryTagId,
    } = data

    const router = useRouter()

    const product = prop(
      card.slug,
      referencedCards,
    ) as CompareCredit.FormattedCard
    const externalId = tagId ?? '80'
    const categoryId = categoryTagId ?? '80'

    const href = product
      ? `/credit-cards/${product.issuer.slug.current}/${product.slug}`
      : null

    const handleClick = (e: any, href: string) => {
      e.preventDefault()
      if (href) {
        if (isMobileOnly) {
          router.push(href)
        } else {
          window.open(href, '_ blank')
        }
      }
    }
    const isCard = product ? product._type === 'card' : null

    return (
      <div
        className={cn(
          'c-image-card-art / mb-2',
          smStyling,
          mdStyling,
          lgStyling,
        )}
      >
        {product ? (
          <div className="c-image-card-art__art / relative / mb-1 / w-full / rounded">
            {cardLink && href ? (
              <div
                className={`c-card-art / w-full / text-center / flex items-center justify-center / mx-auto`}
              >
                <Link
                  href={href}
                  className="c-card-art__link / inline-block w-full / text-primary-bright / font-semibold / transition-all / hover:text-primary-mid"
                  onClick={(e) => {
                    handleClick(e, href)
                  }}
                >
                  <button className="c-card-art_image / relative flex w-full rounded overflow-hidden">
                    <span className="c-card-art__content / absolute / flex flex-wrap content-center items-center / z-10 left-0 / w-full h-full text-white">
                      <span className="w-full font-bold">Learn More</span>
                    </span>
                    <CardArt
                      _rev={product._rev}
                      cardArt={product.cardArt}
                      categoryId={categoryId}
                      externalId={externalId}
                      customCodeSnippets={product.customCodeSnippets}
                      issuer={product.issuer.slug.current}
                      name={product.name}
                      orderId={null}
                      slug={product.slug}
                      imgClasses="w-full"
                    />
                  </button>
                </Link>
              </div>
            ) : (
              <CardArt
                _rev={product._rev}
                cardArt={product.cardArt}
                categoryId={categoryId}
                externalId={externalId}
                customCodeSnippets={product.customCodeSnippets}
                issuer={product.issuer.slug.current}
                name={product.name}
                orderId={null}
                slug={product.slug}
              />
            )}
          </div>
        ) : (
          <div className="c-content-placeholder-bg / w-full / mb-1 / pt-[60%] / bg-gray-200 rounded"></div>
        )}
        {cardName && href && product && (
          <>
            {cardNameLink ? (
              <div className="c-image-card-art__name / mb-0.5 / text-center text-sm font-semibold text-slate-600 leading-snug">
                <Link
                  href={href}
                  className="transition-all / hover:underline hover:text-primary-mid"
                  onClick={(e) => {
                    handleClick(e, href)
                  }}
                >
                  {product.name}
                </Link>
              </div>
            ) : (
              <div className="c-image-card-art__name / mb-0.5 / text-center text-fs13 font-semibold text-slate-600">
                {product.name}
              </div>
            )}
          </>
        )}
        {cardRating && isCard && (
          <div className="c-image-card-art__rating / flex justify-center items-center">
            <StarRating
              column
              marginBottom="mb-0"
              productSlug={card.slug}
              stars={product.expertReviewRating}
              userAvgRating={product.visitorReviewsAverageRating}
              visitorReviewsTotalCount={product.visitorReviewsTotalCount}
            />
          </div>
        )}
      </div>
    )
  }
