import React from 'react'
import { CreditDisclosure } from './credit-disclosure'

import cn from 'utils/classnames'

export function CreditRatingRequirement(props: { creditNeeded: string }) {
  const { creditNeeded } = props

  return (
    <div
      className={cn('c-card-tile__credit', {
        'pb-4 mb-5': creditNeeded.includes('limited'),
        'pb-4 mb-3': !creditNeeded.includes('limited'),
      })}
    >
      <div className="relative z-10 / mb-2 / text-fs13">
        <b className="tracking-wide uppercase text-primary">
          Recommended Credit Score:
        </b>{' '}
        <span className="block xs:inline-block / xs:ml-1.5 / capitalize">
          {creditNeeded}
          <div className="relative top-1 / inline-block">
            <CreditDisclosure />
          </div>
        </span>
      </div>
      <div className="c-credit-rating / max-w-full w-64">
        <div className="relative / h-2 / bg-gray-200 rounded-full / text-fs11 text-gray-500">
          {creditNeeded.startsWith('excellent') && (
            <div
              className={cn(
                'absolute top-0 / h-2 / text-primary-mid / bg-primary-light rounded-full right-0',
                {
                  'c-credit-rating__good': creditNeeded.includes('good'),
                  'c-credit-rating__excellent': !creditNeeded.includes('good'),
                },
              )}
            >
              <span className="absolute top-0 right-0 / mt-2 -mr-2">850</span>
              <span className="absolute top-0 left-0 / mt-2 -ml-2">
                {creditNeeded.includes('good') ? '660' : '720'}
              </span>
            </div>
          )}
          {creditNeeded.startsWith('good') && (
            <div
              className={`absolute top-0 / h-2 / text-primary-mid / bg-primary-light rounded-full / c-credit-rating__good right-0`}
            >
              <span className="absolute top-0 right-0 / mt-2 -mr-2">850</span>
              <span className="absolute top-0 left-0 / mt-2 -ml-2">660</span>
            </div>
          )}
          {creditNeeded.startsWith('average') ||
          creditNeeded.startsWith('fair') ? (
            <div
              className={cn(
                'absolute top-0 / h-2 / text-primary-mid / bg-primary-light rounded-full',
                {
                  'c-credit-rating__average right-0':
                    !creditNeeded.includes('bad') &&
                    !creditNeeded.includes('poor'),
                  'c-credit-rating__poor left-0':
                    creditNeeded.includes('bad') ||
                    creditNeeded.includes('poor'),
                  'c-credit-rating__limited right-0':
                    creditNeeded.includes('limited'),
                },
              )}
            >
              <span className="absolute top-0 right-0 / mt-2 -mr-2 / text-right leading-3">
                {creditNeeded.includes('bad') || creditNeeded.includes('poor')
                  ? '< 620'
                  : '850'}{' '}
                {creditNeeded.includes('limited') && (
                  <span className="block w-24">or New&nbsp;to&nbsp;Credit</span>
                )}
              </span>
              <span className="absolute top-0 left-0 / mt-2 -ml-2">
                {creditNeeded.includes('bad') || creditNeeded.includes('poor')
                  ? ''
                  : '620'}
              </span>
            </div>
          ) : null}
          {creditNeeded.startsWith('bad') || creditNeeded.startsWith('poor') ? (
            <div
              className={cn(
                'absolute top-0 / h-2 / text-primary-mid / bg-primary-light rounded-full',
                {
                  'c-credit-rating__poor left-0':
                    !creditNeeded.includes('limited'),
                  'c-credit-rating__limited right-0':
                    creditNeeded.includes('limited'),
                },
              )}
            >
              <span className="absolute top-0 right-0 / mt-2 -mr-2 / text-right leading-3">
                &#x3C; 620
                {creditNeeded.includes('limited') && (
                  <span className="block w-24">or New&nbsp;to&nbsp;Credit</span>
                )}
              </span>
            </div>
          ) : null}
          {creditNeeded.startsWith('limited') && (
            <div
              className={`absolute top-0 / h-2 / text-primary-mid / bg-primary-light rounded-full`}
            >
              <span className="absolute top-0 left-0 / mt-2 -mr-2">
                New&nbsp;to&nbsp;Credit
              </span>
            </div>
          )}
        </div>
      </div>
      <style jsx>{`
        .c-credit-rating__excellent {
          width: 15.3%;
        }
        .c-credit-rating__good {
          width: 22.4%;
        }
        .c-credit-rating__average {
          width: 27%;
        }
        .c-credit-rating__poor {
          width: 73%;
        }
      `}</style>
    </div>
  )
}
