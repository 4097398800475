import sortBy from 'lodash/sortBy'
import { sortResponseInterface } from '../types'

export function handleSort(
  entities: { cards: any[] },
  sortedEntities: sortResponseInterface,
) {
  // remove non-cards from sort, like our cap-one-shopping product
  entities.cards = entities.cards.filter((obj) => obj._type !== 'product')
  const entitiesWithPosition = entities.cards.map((entity) => {
    return {
      ...entity,
      sortPosition: sortedEntities.entities[entity.slug as string].position,
    }
  })

  const sorted = sortBy(entitiesWithPosition, ['sortPosition'])

  return sorted
}
