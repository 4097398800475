import React from 'react'

export const HubNewsletter = () => {
  return (
    <div className="mb-12">
      <div className="flex flex-col items-center justify-center / py-6 px-6 / shadow-lg rounded-lg">
        <h3 className="mb-2 / text-sm text-gray-600 tracking-wider uppercase">
          Newsletter
        </h3>
        <hr className="w-16 / mx-auto mb-2 / border-b-3 border-tetriary" />
        <h4 className="mb-2 / font-bold text-gray-800 text-lg text-center">
          Join Over 50,000 Subscribers!
        </h4>
        <form className="w-full / mb-2">
          <div className="mb-2">
            <label className="sr-only" htmlFor="email">
              Your Email
            </label>
            <input
              className="w-full / py-2 px-4 / text-center text-gray-600 / border border-gray-400 rounded"
              id="email"
              placeholder="Your Email Address..."
              type="text"
            />
          </div>
          <div className="mb-4">
            <button className="inline-block / w-full / py-2 px-4 / font-bold text-white / rounded bg-primary-bright">
              Sign Up
            </button>
          </div>
          <div className="flex / text-sm">
            <input
              className="form-check-input"
              id="exampleCheck1"
              type="checkbox"
            />
            <label
              className="ml-2 / text-fs13 text-gray-700"
              htmlFor="exampleCheck1"
            >
              We’ll never sell your email address. Read more in our{' '}
              <a
                className="text-primary-bright / hover:text-primary-mid hover:underline"
                href="/privacy"
              >
                Privacy Policy
              </a>
            </label>
          </div>
        </form>
      </div>
    </div>
  )
}
