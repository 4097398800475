import React from 'react'
import Image from 'next/image'
import { CompareCredit } from '../../../types/compare-credit'
import { createClient } from '@sanity/client'
import { useNextSanityImage } from 'next-sanity-image'
import { nextImageHelper } from '../../utils'

const client: any = createClient({
  useProjectHostname: false,
  projectId: process.env.SANITY_PROJECT_ID,
  dataset: process.env.SANITY_DATA_SET,
  apiVersion: '1',
  useCdn: true,
})

const TipAuthorReviewerImage = ({
  image,
  name,
}: {
  image: { url: string; metadata: { lqip: string } }
  name: string
}) => {
  const authorImage = useNextSanityImage(client, image)
  return (
    <Image
      src={authorImage.src}
      height={authorImage.height}
      width={authorImage.width}
      alt={name}
      aria-hidden={true}
      blurDataURL={image.metadata.lqip}
      style={nextImageHelper.responsive}
      sizes="100vw"
      placeholder="blur"
    />
  )
}

const TipAuthorReviewerSocial = ({
  twitterUrl,
  linkedinUrl,
}: {
  twitterUrl?: string
  linkedinUrl?: string
}) => {
  return twitterUrl || linkedinUrl ? (
    <ul className="flex flex-row">
      {linkedinUrl && (
        <li>
          <a
            href={linkedinUrl}
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Visit Linkedin"
            className="opacity-40 / hover:opacity-75"
          >
            <span className="inline-block / w-5 lg:w-6 / ml-2">
              <Image
                alt="Linkedin"
                width={16}
                height={16}
                src="/static/icons/linkedin.svg"
                style={nextImageHelper.responsive}
                sizes="100vw"
              />
            </span>
          </a>
        </li>
      )}
      {twitterUrl && (
        <li className="mr-1">
          <a
            href={twitterUrl}
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Visit Twitter"
            className="opacity-40 / hover:opacity-75"
          >
            <span className="inline-block / w-5 lg:w-6 / ml-2">
              <Image
                alt="Twitter"
                width={16}
                height={16}
                src="/static/icons/twitter.svg"
                style={nextImageHelper.responsive}
                sizes="100vw"
              />
            </span>
          </a>
        </li>
      )}
    </ul>
  ) : (
    <></>
  )
}

export const TipAuthorReviewer = ({
  value,
}: {
  value: {
    author: CompareCredit.Author
    reviewer: CompareCredit.Author
    showReviewedBy: boolean
  }
}) => {
  const { author, reviewer, showReviewedBy } = value
  const authorFirstName = author.name.split(' ')[0]
  const reviewerFirstName = reviewer?.name && reviewer.name.split(' ')[0]

  return (
    <div
      className={`c-author-bio / my-6 / p-4 sm:px-6 sm:py-5 md:px-7 md:py-6 / rounded-sm bg-primary-light-bg-2`}
    >
      <div
        className={`c-author-bio__section ${
          showReviewedBy ? 'mb-5 pb-5 border-b border-slate-200' : ''
        }`}
      >
        <div className="c-author-bio__byline / relative z-2 / flex flex-row sm:items-center / xs:mb-0.5 sm:mb-2.5">
          {author.image && (
            <span className="inline-block / w-16 h-16 xxs:w-20 xxs:h-20 / shrink-0 / mr-4 / bg-gray-300 rounded-full shadow-lg / overflow-hidden">
              {author.authorBioToggle && author.slug ? (
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`/author/${author.slug}`}
                >
                  <TipAuthorReviewerImage
                    image={author.image}
                    name={author.name}
                  />
                </a>
              ) : (
                <span>
                  <TipAuthorReviewerImage
                    image={author.image}
                    name={author.name}
                  />
                </span>
              )}
            </span>
          )}
          <div className="sm:flex sm:flex-row sm:justify-between sm:items-end / w-full">
            <div>
              <p className="leading-snug text-gray-600 text-fs12">Written by</p>
              <h1 className="mb-0.5 / text-primary font-bold leading-tight">
                {author.authorBioToggle && author.slug ? (
                  <a
                    href={`/author/${author.slug}`}
                    target="_blank"
                    rel="noreferrer"
                    className="text-primary-mid / hover:text-primary-bright"
                  >
                    {author.name}
                  </a>
                ) : (
                  <p className="text-primary-mid">{author.name}</p>
                )}
              </h1>
              <p className="mb-0.5 / text-gray-600 uppercase text-fs11 font-semibold leading-tight tracking-wider">
                {author.title}
              </p>
            </div>
            <div className="sm:flex sm:flex-col sm:items-end / mb-1">
              <TipAuthorReviewerSocial
                linkedinUrl={author.linkedinUrl}
                twitterUrl={author.twitterUrl}
              />
              {author.authorBioToggle && author.slug && (
                <a
                  href={`/author/${author.slug}`}
                  target="_blank"
                  rel="noreferrer"
                  className="c-btn c-btn--ghost / inline-block / w-auto / px-4 py-0.5 / text-fs13 / hover:bg-primary-bright hover:text-white / hidden sm:inline-block"
                >
                  Read more From {authorFirstName}
                </a>
              )}
            </div>
          </div>
        </div>
        <p
          className={`c-author-bio__bio mb-2 / text-fs13 md:text-sm leading-normal text-slate-600 ${
            reviewer?.authorBioToggle ? '' : 'sm:mb-0'
          }`}
        >
          {author.description}{' '}
          {author.authorBioToggle && author.slug && (
            <a
              href={`/author/${author.slug}`}
              target="_blank"
              rel="noreferrer"
              className="inline-block / text-primary-bright font-bold"
            >
              <span className="flex items-center">
                Full Bio{' '}
                <span className="inline-block / w-3 h-3 / ml-0.5">
                  <Image
                    src="/static/icons/icon-chevron-r-primary-bright.svg"
                    height={32}
                    width={32}
                    alt="chevron right"
                    aria-hidden={true}
                    style={nextImageHelper.responsive}
                    sizes="100vw"
                  />
                </span>
              </span>
            </a>
          )}
        </p>
        {author.authorBioToggle && author.slug && (
          <a
            href={`/author/${author.slug}`}
            className="c-btn c-btn--ghost / inline-block / w-auto / px-4 p-1 / text-fs13 / hover:bg-primary-bright hover:text-white / sm:hidden"
          >
            Read more From {authorFirstName}
          </a>
        )}
      </div>
      {showReviewedBy && reviewer && (
        <div className="c-author-bio__section">
          <div className="c-author-bio__byline-reviewed-by / relative z-2 / flex flex-row sm:items-center / xs:mb-0.5 sm:mb-2.5">
            {reviewer.image && (
              <span className="inline-block / w-16 h-16 xxs:w-20 xxs:h-20 / shrink-0 / mr-4 / bg-gray-300 rounded-full shadow-lg / overflow-hidden">
                {reviewer.authorBioToggle && reviewer.slug ? (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`/author/${reviewer.slug}`}
                  >
                    <TipAuthorReviewerImage
                      image={reviewer.image}
                      name={reviewer.name}
                    />
                  </a>
                ) : (
                  <span>
                    <TipAuthorReviewerImage
                      image={reviewer.image}
                      name={reviewer.name}
                    />
                  </span>
                )}
              </span>
            )}
            <div className="sm:flex sm:flex-row sm:justify-between sm:items-end / w-full">
              <div>
                <p className="leading-snug text-gray-600 text-fs12">
                  Reviewed by
                </p>
                <h1 className="mb-0.5 / text-primary font-bold leading-tight">
                  {reviewer.authorBioToggle && reviewer.slug ? (
                    <a
                      href={`/author/${reviewer.slug}`}
                      target="_blank"
                      rel="noreferrer"
                      className="text-primary-mid / hover:text-primary-bright"
                    >
                      {reviewer.name}
                    </a>
                  ) : (
                    <p className="text-primary-mid">{reviewer.name}</p>
                  )}
                </h1>
                <p className="mb-0.5 / text-gray-600 uppercase text-fs11 font-semibold leading-tight tracking-wider">
                  {reviewer.title}
                </p>
              </div>
              <div className="sm:flex sm:flex-col sm:items-end / mb-1">
                <TipAuthorReviewerSocial
                  linkedinUrl={reviewer.linkedinUrl}
                  twitterUrl={reviewer.twitterUrl}
                />
                {reviewer.authorBioToggle && reviewer.slug && (
                  <a
                    href={`/author/${reviewer.slug}`}
                    target="_blank"
                    rel="noreferrer"
                    className="c-btn c-btn--ghost / inline-block / w-auto / px-4 py-0.5 / text-fs13 / hover:bg-primary-bright hover:text-white / hidden sm:inline-block"
                  >
                    Read more From {reviewerFirstName}
                  </a>
                )}
              </div>
            </div>
          </div>
          <p className="c-author-bio__bio mb-1.5 / text-fs13 md:text-sm leading-normal text-slate-600">
            {reviewer.description}{' '}
            {reviewer.authorBioToggle && reviewer.slug && (
              <a
                href={`/author/${reviewer.slug}`}
                target="_blank"
                rel="noreferrer"
                className="inline-block / text-primary-bright font-bold"
              >
                <span className="flex items-center">
                  Full Bio{' '}
                  <span className="inline-block / w-3 h-3 / ml-0.5">
                    <Image
                      src="/static/icons/icon-chevron-r-primary-bright.svg"
                      height={32}
                      width={32}
                      alt="chevron right"
                      aria-hidden={true}
                      style={nextImageHelper.responsive}
                      sizes="100vw"
                    />
                  </span>
                </span>
              </a>
            )}
          </p>
          {reviewer.authorBioToggle && reviewer.slug && (
            <a
              href={`/author/${reviewer.slug}`}
              className="c-btn c-btn--ghost / inline-block / w-auto / px-4 p-1 / text-fs13 / hover:bg-primary-bright hover:text-white / sm:hidden"
            >
              Read more From {reviewerFirstName}
            </a>
          )}
        </div>
      )}
    </div>
  )
}
