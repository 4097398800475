import React from 'react'
import { endsInS } from '../../utils'

export const SecureSite = ({
  issuerSecureSite,
  issuerName,
}: {
  issuerSecureSite?: string | null | undefined
  issuerName: string
}) => {
  return (
    <>
      {issuerSecureSite
        ? issuerSecureSite
        : `on ${issuerName}'${!endsInS(issuerName) ? `s` : ''} secure site`}
      <style jsx>{`
        .c-secure__icon {
          top: 2px;
        }
      `}</style>
    </>
  )
}
