import compose from 'lodash/fp/compose'
import split from 'lodash/fp/split'

const MARKS_TO_SUP: { [key: string]: string } = Object.freeze({
  '&reg;': '&reg;',
  '®': '&reg;',
  '&#8480;': '&#8480;',
  '℠': '&#8480;',
  '&trade;': '&trade;',
  '™': '&trade;',
})

export function supify(corpus?: string) {
  if (corpus == null) return ''
  return Object.keys(MARKS_TO_SUP).reduce(
    (result, k) => splitAndSup(k, MARKS_TO_SUP[k], result),
    corpus,
  )
}

function splitAndSup(search: string, replace: string, corpus: string) {
  return corpus.indexOf(search) >= 0
    ? compose(supButNotTwice(replace, corpus), split(search))(corpus)
    : corpus
}

function supButNotTwice(replace: string, original: string) {
  return (substrs: string[]) =>
    substrs.some((str) => str.slice(0, 6) === '</sup>')
      ? original
      : substrs.join(`<sup>${replace}</sup>`)
}
