import React from 'react'
import Link from 'next/link'
import Image from 'next/image'
import { nextImageHelper } from '../../utils'

export function Tile(props: {
  href: string
  icon: string
  label: string
  height: number
  width: number
}) {
  return (
    <Link href={props.href}>
      <div className="flex md:flex-col items-center justify-start / w-full md:h-28z / py-2 px-2 md:py-4 md:px-0 lg:py-5 / text-left font-bold text-primary md:text-xs md:leading-snug lg:text-sm / cursor-pointer bg-white rounded shadow / hover:text-primary-bright hover:shadow-lg / transition-all">
        <span className="flex justify-center items-center / w-1/3 md:w-full / md:mb-1 lg:mb-3">
          <span className="flex justify-center items-center / w-16 sm:w-12 lg:w-16">
            <div
              className={`inline-block w-full ${
                props.icon !== '/static/icons/categories/cash-back.svg'
                  ? ''
                  : ''
              }`}
            >
              <Image
                src={props.icon}
                alt={props.label}
                width={props.width}
                height={props.height}
                style={nextImageHelper.responsive}
                sizes="100vw"
              />
            </div>
          </span>
        </span>

        <p className="w-2/3 md:w-11/12 / pl-2 md:pl-0 / text-md text-left md:text-center">
          {props.label}
        </p>
      </div>
    </Link>
  )
}
