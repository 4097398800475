import React from 'react'
import propOr from 'lodash/fp/propOr'
import { PortableText, PortableTextBlockComponent } from '@portabletext/react'

import { CompareCredit } from '../../../../../types/compare-credit'
import { CompareCredit as CC } from '../../types'
import { supify } from 'utils/'
import { getFeaturesToRender } from 'utils/getFeaturesToRender'

export function Stats(props: {
  entity: CompareCredit.Entity
  view: 'list' | 'details'
  category: string | undefined
}) {
  const { entity } = props

  const Normal = ({ children }: { children: any }) => <span>{children}</span>

  const AttributeRenderer = (attrProps: { value: { attribute: string } }) => {
    const { attribute } = attrProps.value
    // @ts-ignore
    return React.createElement('span', {
      dangerouslySetInnerHTML: {
        __html: supify(propOr('', attribute, props.entity)),
      },
    })
  }

  const featuresToRender = getFeaturesToRender(entity, props.category)

  // remove all non letters and lowercase
  const formattedCardType = (propOr('', 'cardType', entity) as string)
    .replace(/[^A-Z]/gi, '')
    .replace(/ +/g, ' ')
    .toLowerCase()

  return (
    <ul
      className={`c-highlight__list / md:flex md:flex-wrap lg:flex / text-xs xxs:text-fs13 sm:text-sm  lg:text-sm / ${
        props.view === 'details' ? '' : 'md:text-fs13'
      }`}
    >
      {featuresToRender.map((feature, i) => {
        return (
          <li
            className={`c-highlight__item / flex flex-wrap md:flex-col / ${
              props.view === 'details' ? 'md:w-1/3' : 'md:w-1/3 lg:pb-4'
            }  lg:w-1/3 / px-6 xs:px-8 md:px-2 lg:px-3 / py-2 sm:py-3 md:pt-0 lg:pt-0`}
            key={i}
          >
            <label
              className={`c-highlight__label / w-full xs:w-1/3 sm:w-1/4 md:w-full / font-bold text-primary`}
              htmlFor="intro-bt-apr"
            >
              <span dangerouslySetInnerHTML={{ __html: feature.Title }} />
            </label>
            {feature._type === 'custom' ? (
              <span>{feature.value}</span>
            ) : (
              <span
                className={`c-highlight__description / w-full xs:w-2/3 xs:pl-4 sm:w-3/4 md:w-full md:pl-0 / text-card-gray`}
              >
                <PortableText
                  value={feature.Description as any}
                  components={{
                    block: { normal: Normal as PortableTextBlockComponent },
                    types: {
                      cardAttribute: AttributeRenderer,
                    },
                  }}
                />
              </span>
            )}
          </li>
        )
      })}
      {(props.entity._type === 'card' ||
        props.entity._type === 'nonPaidCard') && (
        <CardFeaturesRow
          entity={props.entity}
          view={props.view}
          formattedCardType={formattedCardType}
        />
      )}
      <style jsx>{`
        .c-highlight__item:nth-child(odd) {
          background-color: #f7f7f7;
        }
        @media (min-width: 768px) {
          .c-highlight__item:nth-child(odd) {
            background-color: transparent;
          }
        }
      `}</style>
    </ul>
  )
}

function CardFeaturesRow({
  entity,
  view,
  formattedCardType,
}: {
  entity: CompareCredit.FormattedCard | CompareCredit.NonPaidCard
  view: CC.CardPanelProps['view']
  formattedCardType: string
}) {
  const icons = {
    visa: '/static/icons/issuer/visa.svg',
    mastercard: '/static/icons/issuer/mastercard.svg',
    discover: '/static/icons/issuer/discover.svg',
    amex: '/static/icons/issuer/american-express.svg',
  } as { [key: string]: string }

  const cardBrandPath = icons[formattedCardType] || entity.cardType

  return (
    <>
      {entity.feeAnnual && (
        <li
          id={`card-highlight1-${entity.slug}`}
          className={`c-highlight__item c-highlight__item--hidden / flex flex-wrap md:flex-col items-center / ${
            view === 'details' ? 'md:w-1/3' : 'md:w-1/3'
          } lg:w-1/3 / px-6 xs:px-8 md:px-2 lg:px-3 / py-2 sm:py-3 md:pt-0 lg:pt-0`}
        >
          <label
            className="c-highlight__label / w-full xs:w-1/3 sm:w-2/5 md:w-full / font-bold text-primary"
            htmlFor="annual-fee"
          >
            Annual Fee
          </label>
          <span className="c-highlight__description / w-full xs:w-2/3 xs:pl-4 sm:w-3/5 md:w-full md:pl-0 / text-card-gray">
            {entity.feeAnnual}
          </span>
        </li>
      )}

      <li
        id={`card-highlight2-${entity.slug}`}
        className={`c-highlight__item c-highlight__item--hidden / flex flex-wrap md:flex-col / ${
          view === 'details' ? 'md:w-1/3' : 'md:w-1/3'
        } lg:w-1/3 / px-6 xs:px-8 md:px-2 lg:px-3 / py-2 sm:py-3 md:pt-0 lg:pt-0`}
      >
        <label
          className="c-highlight__label / w-full xs:w-1/3 sm:w-2/5 md:w-full / font-bold text-primary"
          htmlFor="recommended-credit"
        >
          Credit Score Needed
        </label>
        <span className="c-highlight__description / w-full xs:w-2/3 xs:pl-4 sm:w-3/5 md:w-full md:pl-0 / text-card-gray">
          {entity.creditNeeded}
        </span>
      </li>
      {entity.cardType && (
        <li
          id={`card-highlight3-${entity.slug}`}
          className={`c-highlight__item c-highlight__item--hidden / flex flex-wrap items-center md:flex-col / ${
            view === 'details' ? 'md:w-1/3' : 'md:w-1/3'
          } lg:w-1/3 / px-6 xs:px-8 md:px-2 lg:px-3 / py-2 sm:py-3 md:pt-0 lg:pt-0`}
        >
          <label
            className="c-highlight__label / w-full xs:w-1/3 sm:w-2/5 md:w-full / font-bold text-primary"
            htmlFor="annual-fee"
          >
            Card Brand
          </label>
          <span className="c-highlight__description / w-full xs:w-2/3 xs:pl-4 sm:w-3/5 md:w-full md:pl-0 / text-card-gray">
            <img
              src={cardBrandPath}
              className={`inline-block
              ${formattedCardType === 'mastercard' ? 'mt-1 ' : null}
              ${
                formattedCardType === 'discover' || formattedCardType === 'amex'
                  ? 'w-5'
                  : 'w-10'
              }
              `}
              alt={entity.cardType}
            />
            {formattedCardType === 'discover' ? (
              <span className="ml-1">{entity.cardType}</span>
            ) : null}
            {formattedCardType === 'amex' ? (
              <span className="ml-1">{entity.cardType}</span>
            ) : null}
          </span>
        </li>
      )}
      <style jsx>{`
        .c-highlight__item:nth-child(odd) {
          background-color: #f7f7f7;
        }
        @media (min-width: 768px) {
          .c-highlight__item:nth-child(odd) {
            background-color: transparent;
          }
        }
      `}</style>
    </>
  )
}
