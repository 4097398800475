import React from 'react'
import Image from 'next/image'

interface CardMoreDetail {
  handleModal: (value: React.SetStateAction<boolean>) => void
  modalOpen: boolean
}

export default function CardMoreDetails(props: CardMoreDetail) {
  const { handleModal, modalOpen } = props
  return (
    <div className="c-card-tile__details-btn-container / w-full">
      <hr className="mb-2 mx-auto / border-b-2 / w-16" />
      <div className="text-center cursor-pointer">
        <button
          onClick={() => {
            handleModal(!modalOpen)
          }}
          onKeyDown={() => {
            handleModal(!modalOpen)
          }}
          tabIndex={0}
          className="c-card-tile__details-btn / flex w-full items-center justify-center / py-1 / text-center text-gray-600 text-fs13 / hover:text-primary-bright"
        >
          More Details
          <span className="relative / h-3 w-3 / ml-2 / opacity-50">
            <Image
              alt="expand plus icon"
              src="/static/icons/icon-plus-black.svg"
              aria-hidden={true}
              fill
              sizes="100vw"
            />
          </span>
        </button>
      </div>
    </div>
  )
}
