import { ApplyNowLink, ProsAndCons, link } from '../'
import {
  PortableText,
  PortableTextBlockComponent,
  PortableTextListComponent,
  PortableTextListItemComponent,
  PortableTextMarkComponent,
} from '@portabletext/react'

import { CompareCredit } from '../../../types/compare-credit'
import { RatesAndFeesLink } from '../sanity-serializers/ratesAndFeesLink'
import React from 'react'
import propOr from 'lodash/fp/propOr'
import { supify } from '../../utils'

export function ExpertReview(props: {
  card: CompareCredit.FormattedCard | CompareCredit.NonPaidCard
  externalId?: string
  orderId?: string
}) {
  const { card, externalId, orderId } = props

  const [starHeight, setStarHeight] = React.useState(100)

  React.useEffect(
    () => setStarHeight((card.expertReviewRating / 5) * 100),
    [card.expertReviewRating],
  )

  const list = ({ children }: { children: React.ReactElement }) => (
    <ul className="pl-5 py-1 text-sm">{children}</ul>
  )

  const listItem = ({ children }: { children: React.ReactElement }) => (
    <li className="c-list-disc__item mb-2 pr-2">{children}</li>
  )

  const strong = ({
    children,
  }: {
    value: string
    children: React.ReactElement
  }) => {
    return <strong className="text-primary">{children}</strong>
  }

  const underline = ({
    children,
  }: {
    value: string
    children: React.ReactElement
  }) => {
    return <span className="border-b-2 border-tetriary">{children}</span>
  }

  const Normal = ({ children }: { children: any }) => (
    <p className="mb-6">{children}</p>
  )

  function AttributeRenderer<K extends keyof CompareCredit.Entity>(attrProps: {
    value: { attribute: K }
  }) {
    return React.createElement('span', {
      dangerouslySetInnerHTML: {
        __html: supify(
          propOr('', attrProps.value.attribute, props.card)?.toString(),
        ),
      },
    })
  }

  const ExpertReviewHighlight = ({
    children,
  }: {
    children: React.ReactElement
  }) => <span>{children}</span>

  return (
    <div className="my-8">
      <div className="flex flex-col items-center md:items-start / w-full / text-center md:text-left">
        <div className="flex justify-center md:justify-between items-center / w-full">
          <h2 className="font-bold mb-2 text-sm text-gray-600 tracking-wider uppercase">
            Our Expert Review
          </h2>
          <div className="hidden md:inline-block / mb-2">
            <a
              href="#editorial-disclosure-footer"
              className="inline-block / text-sm / text-primary-bright hover:text-primary-mid transition-all"
            >
              Editorial Disclosure
            </a>
          </div>
        </div>

        <h3 className="font-serif font-bold leading-snug mb-6 text-fs40 text-primary w-full">
          Why We Like&nbsp;It
        </h3>
        <hr className="border-4 w-40 border-tetriary / mb-8 / mx-auto md:ml-0" />
      </div>

      <div className="flex flex-col sm:block / text-card-gray leading-relaxed">
        <div className="-mt-4 sm:mb-8 text-center / md:hidden">
          <a
            href="#editorial-disclosure-footer"
            className="inline-block / text-sm / text-primary-bright hover:text-primary-mid transition-all"
          >
            Editorial Disclosure
          </a>
        </div>
        <div className="sm:order-2 sm:float-right / mb-6 sm:mb-3 sm:ml-6 md:ml-10 pt-4 sm:pt-0 / sm:w-1/2 / border-b">
          <div className="mb-6 max-w-xs	mx-auto">
            <div className="shadow rounded-lg px-2 py-4 sm:py-6 / flex justify-between items-center">
              <div className=" w-1/3">
                <div className="c-star w-20 h-20 relative / bg-gray-200">
                  <div className="c-star__mask / inline-block w-20 h-20 relative z-10" />
                  <div className="c-star__fill-container / absolute bottom-0 w-full z-0">
                    <div
                      className="c-star__fill bg-tetriary / absolute bottom-0 w-full z-0"
                      style={{ height: starHeight + '%' }}
                    />
                  </div>
                </div>
              </div>

              <div className="w-2/3 pl-1 leading-tight">
                <p className="uppercase text-gray-600 font-bold text-xs tracking-wider">
                  Offer Rating
                </p>
                <p className="font-serif leading-tight mb-1">
                  <span className="text-primary-mid text-5xl">
                    {card.expertReviewRating}
                  </span>
                  <span className="text-gray-600 text-3xl">/ 5</span>
                </p>
                <p className="uppercase text-gray-600 font-bold text-xs tracking-wider">
                  By Our Content&nbsp;Team
                </p>
              </div>
            </div>
          </div>
          <div className="w-full text-center / mb-3">
            <p className="c-ribbon c-ribbon--slimmer / relative / w-auto mx-auto px-2 py-1 / font-bold text-primary text-xs tracking-wide uppercase / bg-tetriary / hidden sm:inline-block lg:hidden">
              Key Benefit
            </p>
          </div>

          <div className="px-4 pb-6 text-center">
            <div className="inline-block w-full mb-4 font-serif text-xl md:text-2xl text-primary-mid font-normal leading-snug">
              <PortableText
                value={card.expertReviewHighlight as any}
                components={{
                  block: ExpertReviewHighlight as PortableTextBlockComponent,
                  types: {
                    cardAttribute: AttributeRenderer,
                  },
                }}
              />
            </div>

            <hr className="border-2 border-tetriary w-12 mx-auto mb-4" />

            <div className="flex justify-center align-center w-full / font-sans text-center text-sm uppercase font-bold text-gray-600 tracking-wider">
              <img
                alt="Compare Credit logomark"
                role="presentation"
                style={{ height: '22px' }}
                className="w-4 mx-2"
                src="/static/logos/compare-credit_logomark.svg"
              />
            </div>
          </div>
        </div>
        <PortableText
          value={card.expertReviewBody as any}
          components={{
            marks: {
              applyNowLink: ApplyNowLink(
                externalId,
                orderId,
              ) as PortableTextMarkComponent,
              link: link as PortableTextMarkComponent,
              ratesAndFeesLink: RatesAndFeesLink as PortableTextMarkComponent,
              strong: strong as PortableTextMarkComponent,
              underline: underline as PortableTextMarkComponent,
            },
            block: { normal: Normal as PortableTextBlockComponent },
            types: {
              cardAttribute: AttributeRenderer,
            },
          }}
        />

        <div className="border-t-2 border-b-2 pt-6 pb-4 mb-4">
          <h3 className="font-serif font-bold leading-snug mb-4 text-2xl text-primary w-full">
            Pros &amp; Cons
          </h3>
          <ProsAndCons card={card}></ProsAndCons>
        </div>

        <h4
          className={`${
            card.prosExplained.length ? 'block' : 'hidden'
          } font-bold mb-2 pt-4 text-sm text-primary tracking-wider uppercase`}
        >
          Pros Explained
        </h4>
        <PortableText
          value={card.prosExplained as any}
          components={{
            list: list as PortableTextListComponent,
            listItem: listItem as PortableTextListItemComponent,
            marks: {
              strong: strong as PortableTextMarkComponent,
              underline: underline as PortableTextMarkComponent,
            },
            block: { normal: Normal as PortableTextBlockComponent },
            types: {
              cardAttribute: AttributeRenderer,
            },
          }}
        />
        <h4
          className={`${
            card.consExplained.length ? 'block' : 'hidden'
          } font-bold mb-2 pt-4 text-sm text-primary tracking-wider uppercase`}
        >
          Cons Explained
        </h4>
        <PortableText
          value={card.consExplained as any}
          components={{
            list: list as PortableTextListComponent,
            listItem: listItem as PortableTextListItemComponent,
            marks: {
              strong: strong as PortableTextMarkComponent,
              underline: underline as PortableTextMarkComponent,
            },
            block: { normal: Normal as PortableTextBlockComponent },
            types: {
              cardAttribute: AttributeRenderer,
            },
          }}
        />

        <div className={`${card.bonusForNewCardholders ? 'block' : 'hidden'}`}>
          <h2 className="font-serif font-bold leading-snug mb-6 text-2xl text-primary w-full">
            Bonus for New Cardholders
          </h2>
          <PortableText
            value={(card.bonusForNewCardholders as any) || []}
            components={{
              list: list as PortableTextListComponent,
              listItem: listItem as PortableTextListItemComponent,
              marks: {
                strong: strong as PortableTextMarkComponent,
                underline: underline as PortableTextMarkComponent,
              },
              block: { normal: Normal as PortableTextBlockComponent },
              types: {
                cardAttribute: AttributeRenderer,
              },
            }}
          />
        </div>

        <div
          className={`${
            card.warning ? 'block' : 'hidden'
          } bg-primary-light-bg text-sm py-2 px-4`}
        >
          <img
            className="inline-block mr-2 w-6"
            alt="alert icon"
            role="presentation"
            src="/static/icons/icon-alert-circle-tetriary.svg"
          />
          <strong>Warning: </strong>
          {card.warning}
        </div>
      </div>

      <style jsx>{`
        .c-star__mask {
          background-image: url('/static/images/review-star-mask.png');
          background-repeat: none;
          background-color: transparent;
          background-size: 100% 100%;
        }
        .c-star__fill-container {
          height: ${starHeight}%;
        }
      `}</style>
    </div>
  )
}
