import React from 'react'
import Image from 'next/image'
import { CompareCredit } from '../../types'
import { nextImageHelper } from '../../../../utils'

export function BestBadge({
  view,
  best,
}: {
  view: CompareCredit.CardPanelProps['view']
  best: boolean
}) {
  return view === 'details' && best ? (
    <div className="c-badge-best / absolute z-10">
      <div className="w-16 h-16 sm:w-20 sm:h-20 / shadow-lg rounded-full / lg:hidden">
        <Image
          alt="best of the best"
          src="/static/icons/badge-best-of-best-v1-sm.svg"
          height={80}
          width={80}
          style={nextImageHelper.responsive}
          sizes="100vw"
        />
      </div>
      <div className="w-24 h-24 / shadow-lg rounded-full / hidden lg:block">
        <Image
          alt="best of the best"
          src="/static/icons/badge-best-of-best-v1.svg"
          height={94}
          width={94}
          style={nextImageHelper.responsive}
          sizes="100vw"
        />
      </div>
      <style jsx>{`
        .c-badge-best {
          top: 0.75rem;
          right: -0.5rem;
        }
        @media (min-width: 640px) {
          .c-badge-best {
            top: 1rem;
            right: -1rem;
          }
        }
      `}</style>
    </div>
  ) : null
}
