import React from 'react'
import Link from 'next/link'
import Image from 'next/image'
import { nextImageHelper } from '../../utils'

interface ButtonProps {
  text: string
  icon: string
  href?: string
  height: number
  width: number
}

function Button(props: ButtonProps) {
  return (
    <Link
      href={props.href || '#'}
      className="flex md:flex-col justify-start items-center / w-full md:w-18pct lg:w-44 / mb-3 lg:mx-2 / py-1 md:py-3 lg:py-5 / 
       text-center text-primary font-bold / rounded cursor-pointer border border-white bg-white shadow-lg / 
       hover:text-primary-bright hover:shadow-xl hover:border-primary-bright hover:bg-primary-light-bg-2 / transition-all"
    >
      <div className="w-12 lg:w-14 / md:mb-1.5 lg:mb-2 / ml-6 mr-4">
        <Image
          src={props.icon}
          alt={`${props.text} icon`}
          role="presentation"
          height={props.height}
          width={props.width}
          style={nextImageHelper.intrinsic}
        />
      </div>
      <span className="text-lg md:text-sm lg:text-base">{props.text}</span>
    </Link>
  )
}

export function ProductsLayout() {
  return (
    <div className="pb-4 md:pb-6 pt-16 md:pt-20">
      <div className="flex flex-col justify-center items-center mx-auto lg:container / mb-10 lg:mb-18 / lg:px-4">
        <div className="c-title-border / w-full / mx-auto / text-center font-bold text-gray-600 tracking-wider text-sm uppercase / border-t border-gray-200">
          <span className="c-title-border__inner / relative / inline-block w-auto / px-4 -mt-3 / bg-white ">
            As Seen In
          </span>
        </div>
        <ul className="flex flex-row justify-between items-center / w-full pb-4 / text-center / border-b border-gray-200">
          <li className="flex flex-grow justify-center px-2">
            <a
              href="https://www.inc.com/profile/compare-credit"
              target="_blank"
              rel="noreferrer"
              aria-label="visit inc 5000"
              className="w-16 xs:w-14 md:w-18"
            >
              <Image
                src="/static/as-seen-on/badge-inc-5000.png"
                height={72}
                width={72}
                alt="INC 5000"
                aria-hidden={true}
                style={nextImageHelper.responsive}
                sizes="100vw"
              />
            </a>
          </li>
          <li className="flex flex-grow justify-center px-2">
            <span className="w-24 xs:w-20 md:w-24">
              <Image
                src="/static/as-seen-on/pbs.svg"
                height={48}
                width={105}
                alt="PBS"
                aria-hidden={true}
                style={nextImageHelper.responsive}
                sizes="100vw"
              />
            </span>
          </li>
          <li className="flex flex-grow justify-center px-2">
            <span className="w-24 md:w-24 lg:w-28">
              <Image
                src="/static/as-seen-on/forbes.svg"
                height={23}
                width={84}
                alt="Forbes"
                aria-hidden={true}
                style={nextImageHelper.responsive}
                sizes="100vw"
              />
            </span>
          </li>
          <li className="flex flex-grow justify-center px-2 hidden xs:flex">
            <span className="w-28 md:w-32 lg:w-40">
              <Image
                src="/static/as-seen-on/usa-today.svg"
                height={23}
                width={84}
                alt="USA Today"
                aria-hidden={true}
                style={nextImageHelper.responsive}
                sizes="100vw"
              />
            </span>
          </li>
          <li className="flex-grow justify-center px-2 hidden sm:flex">
            <span className="w-36 lg:w-40">
              <Image
                src="/static/as-seen-on/wsj.svg"
                height={16}
                width={183}
                alt="Wall Street Journal"
                aria-hidden={true}
                style={nextImageHelper.responsive}
                sizes="100vw"
              />
            </span>
          </li>
          <li className="flex-grow justify-center px-2 hidden md:flex">
            <span className="w-10 md:w-14">
              <Image
                src="/static/as-seen-on/tc.svg"
                height={24}
                width={48}
                alt="Tech Crunch"
                aria-hidden={true}
                style={nextImageHelper.responsive}
                sizes="100vw"
              />
            </span>
          </li>
        </ul>
      </div>
      <div className="mb-4 mx-auto">
        <h1 className="c-hero__title / mb-6 / text-center leading-tight text-primary font-display text-4xl lg:text-5xl font-bold">
          No Games.&nbsp;{` `}
          <span className="block md:inline">
            Just Comparisons.
            <span className="font-sans text-2xl absolute mt-1 -ml-2">
              &#8480;
            </span>
          </span>
        </h1>
        <hr className="border-4 w-24 md:w-32 border-tetriary / mb-6 md:mb-8 mx-auto" />

        <h3 className="max-w-md lg:max-w-lg / mx-auto mb-4 / text-center text-card-gray font-extralight lg:text-lg">
          Explore our most popular card categories to find credit cards with the
          benefits you&apos;re looking for.
        </h3>
        <div className="max-w-sm md:max-w-none mx-auto / flex flex-col md:flex-row md:justify-between lg:justify-around">
          {[
            {
              text: 'Top Credit Cards',
              href: '/credit-cards/best',
              icon: '/static/icons/categories/top-cc.svg',
              height: 64,
              width: 64,
            },
            {
              text: 'Balance Transfer',
              href: '/credit-cards/best/balance-transfer',
              icon: '/static/icons/categories/balance-transfer.svg',
              height: 64,
              width: 64,
            },
            {
              text: 'Low Interest',
              href: '/credit-cards/best/low-interest',
              icon: '/static/icons/categories/low-interest.svg',
              height: 64,
              width: 64,
            },
            {
              text: 'Rewards',
              href: '/credit-cards/best/rewards',
              icon: '/static/icons/categories/rewards.svg',
              height: 64,
              width: 64,
            },
            {
              text: 'Cash Back',
              href: '/credit-cards/best/cash-back',
              icon: '/static/icons/categories/cash-back.svg',
              height: 64,
              width: 64,
            },
          ].map((button, i) => (
            <Button key={i} {...button} />
          ))}
        </div>
      </div>

      <style jsx>{`
        .c-title-border__inner {
          top: -0.75em;
        }
        .custom-static-width-wsj-mark {
          width: 45px;
        }
        .custom-static-width-forbes {
          width: 117px;
        }
        .custom-static-width-cnn {
          width: 66px;
        }
        .custom-static-width-pbs {
          width: 106px;
        }
        .custom-static-width-usa-today {
          width: 141px;
        }
        .custom-static-width-huffpost {
          width: 149px;
        }
      `}</style>
    </div>
  )
}
