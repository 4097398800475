import compose from 'lodash/fp/compose'

export const encode = (params: any) =>
  compose(
    (json: string) => Buffer.from(json).toString('base64'),
    JSON.stringify,
  )(params)

export const decode = (params: any) =>
  compose(JSON.parse, (str: string) => Buffer.from(str, 'base64').toString())(
    params,
  )
