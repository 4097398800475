import React from 'react'
import Image from 'next/image'
import { nextImageHelper } from '../../utils'

export function PartnerLogosPl() {
  return (
    <div className="flex flex-col justify-center items-center / mx-auto sm:mb-1 sm:pt-1 md:mb-2 / lg:container">
      <div className="c-title-border / w-full / mx-auto md:mb-2 / text-center font-bold text-gray-600 tracking-wider text-sm uppercase / border-t border-gray-200">
        <span className="c-title-border__inner / relative / inline-block w-auto / px-4 -mt-3 / bg-white ">
          Partners
        </span>
      </div>
      <div className="flex flex-row flex-wrap justify-around items-center / w-full / pb-4 md:pb-5">
        <div className="c-partner-logos w-full">
          <Image
            alt="prosper"
            src="/static/partners/prosper.svg"
            width={108}
            height={18}
            style={nextImageHelper.responsive}
            sizes="100vw"
          />
        </div>
        <div className="c-partner-logos w-full">
          <Image
            alt="lendingpoint"
            src="/static/partners/lendingpoint.svg"
            width={144}
            height={25}
            style={nextImageHelper.responsive}
            sizes="100vw"
          />
        </div>
        <div className="relative c-partner-logos sm:w-22 w-18 h-8">
          <Image
            alt="sofi"
            src="/static/partners/sofi.svg"
            fill
            sizes="100vw"
            style={{ objectFit: 'contain' }}
          />
        </div>
        <div className="c-partner-logos w-full sm:max-w-24 hidden sm:block">
          <Image
            alt="upgrade"
            src="/static/partners/upgrade.svg"
            width={108}
            height={25}
            style={nextImageHelper.responsive}
            sizes="100vw"
          />
        </div>
        {/* <img
          data-src="/static/partners/marcus-by-goldman-sachs.svg"
          className="c-partner-logos / hidden md:block"
        /> */}
      </div>
      <style jsx>{`
        .c-title-border__inner {
          top: -0.75em;
        }
        .c-partner-logos {
          max-width: 28%;
          max-height: 1.5rem;
          margin-bottom: 0.25rem;
        }
        @media (min-width: 640px) {
          .c-partner-logos {
            max-width: 20%;
          }
        }
        @media (min-width: 768px) {
          .c-partner-logos {
            max-width: 16%;
            max-height: 2rem;
          }
        }
      `}</style>
    </div>
  )
}
