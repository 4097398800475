import { RprServiceQueryParams } from './rpr/constants'
import { getValueForParam } from './urls'

export const getRprServiceParamValues = (
  rprServiceParams: string[],
  href: string,
): Record<RprServiceQueryParams, string | null> => {
  const urlParams = rprServiceParams.reduce((acc, param) => {
    const value = getValueForParam(href, param)
    return {
      ...acc,
      [param]: value ? decodeURIComponent(value) : '',
    }
  }, {})
  return urlParams
}
