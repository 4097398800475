import React from 'react'
import Link from 'next/link'
import Image from 'next/image'
import { navData } from '../navigation/navItems'
import { getCategoriesToRender, buildCategoryMap } from './helpers'
import { CompareCredit } from '../../../types/compare-credit'
import { nextImageHelper } from '../../utils'

const categoryMap = buildCategoryMap(navData)

const Item = ({
  category,
}: {
  category: CompareCredit.CategoryThatReferencesCard
}) => {
  const mappingKey = getMappingKey(category)

  if (!categoryMap[mappingKey]) return null

  const { as, linkText, href } = categoryMap[mappingKey]

  return (
    <li className="w-full sm:w-1/2 lg:w-1/3 / mb-2 sm:px-2">
      <Link
        href={as || href || '#'}
        className="flex / w-full / text-primary hover:text-primary-bright underline"
      >
        <span className="shrink-0 inline-block / mr-2 w-6">
          <Image
            alt=""
            src="/static/icons/icon-badge-trophy-gold.svg"
            width={24}
            height={24}
            style={nextImageHelper.responsive}
            sizes="100vw"
          />
        </span>{' '}
        {linkText}
      </Link>
    </li>
  )
}

export const TopCategories = ({
  categories,
}: {
  categories: CompareCredit.CardDetailProps['categoriesThatReferenceCard']
}) => {
  const renderCategories = categories && checkToRenderCategories(categories)

  return renderCategories ? (
    <div className="mb-10">
      <h2 className="font-serif text-2xl font-bold text-primary / mb-1">
        Top Rated For
      </h2>
      <ul className="flex flex-wrap / text-sm">
        {getCategoriesToRender(categories).map(
          (category: CompareCredit.CategoryThatReferencesCard, i: number) => (
            <Item key={i} category={category} />
          ),
        )}
      </ul>
    </div>
  ) : null
}

function checkToRenderCategories(
  categories: CompareCredit.CategoryThatReferencesCard[],
) {
  const containsCategoriesToRender = categories.map((category) => {
    const mappingKey = getMappingKey(category)
    return !!categoryMap[mappingKey]
  })

  return containsCategoriesToRender.includes(true)
}

function getMappingKey(category: CompareCredit.CategoryThatReferencesCard) {
  return category.type === 'business'
    ? `business_${category.slug.current}`
    : category.slug.current
}
