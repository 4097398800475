import React from 'react'

// from https://docs.google.com/spreadsheets/d/1_dn5Oa2B114Q04OHU7r_nUNveqGmv2h5imrvZP3i8GQ/
const LabelsByUrl = {
  // Category URL
  '/credit-cards/best/balance-transfer': 'Balance Transfer',
  '/credit-cards/best/low-interest': 'Low Interest',
  '/credit-cards/best/no-annual-fee': 'No Annual Fee',
  '/credit-cards/best/cash-back': 'Cash Back',
  '/credit-cards/best/rewards': 'Rewards',
  '/credit-cards/best/travel': 'Travel',
  '/credit-cards/best/no-foreign-transaction-fee': 'No Foreign Transaction Fee',
  '/credit-cards/best/gas': 'Gas',
  '/credit-cards/best/dining': 'Dining',
  '/credit-cards/best/student': 'Student',
  // Business Pages
  '/credit-cards/business/cash-back/': 'Business Cash Back',
  '/credit-cards/business/travel/': 'Business Travel',
  '/credit-cards/business/0-intro-apr/': 'Business 0% Intro APR',
  '/credit-cards/business/no-annual-fee/': 'Business No Annual Fee',
  // Issuer Pages
  '/credit-cards/citibank': 'Citi',
  '/credit-cards/capital-one': 'Capital One',
  '/credit-cards/chase': 'Chase',
  '/credit-cards/bank-of-america': 'Bank of America',
  '/credit-cards/wells-fargo': 'Wells Fargo',
  '/credit-cards/american-express': 'American Express',
  '/credit-cards/discover': 'Discover',
  '/credit-cards/visa': 'Visa',
  '/credit-cards/mastercard': 'MasterCard',
  // Credit Range
  '/credit-cards/credit-range/excellent': 'Excellent Credit Score',
  '/credit-cards/credit-range/good': 'Good Credit Score',
  '/credit-cards/credit-range/fair': 'Fair Credit Score',
  '/credit-cards/credit-range/poor': 'Poor Credit Score',
  '/credit-cards/credit-range/limited': 'Limited Credit Score',
  // Fallback
  '/credit-cards/best': 'Popular',
  '/credit-cards/business': 'Business',
} as const

export function CardLabelPill() {
  const url = window.location.pathname as keyof typeof LabelsByUrl
  const label = Object.entries(LabelsByUrl).find(([key]) => url.includes(key))
  if (!label) return null
  return (
    <div className="c-card-label-pill / flex justify-center md:justify-start / -mb-5 xs:-mb-8 md:-mb-3 lg:-mb-5 / px-4 lg:px-6 xl:px-8 pt-2 / text-fs13 leading-snug / rounded-t-lg">
      <div className="c-card-label-pill__pill / relative / px-3 py-0.5 / font-semibold text-white text-center / rounded-full bg-primary-bright">
        {`${label[1]} Credit Card`}
      </div>
      <style jsx>{`
        .c-card-label-pill__pill {
          opacity: 0;
          animation-name: fadeInOpacity, animateTopNorthRelative;
          animation-duration: 1s;
          animation-fill-mode: forwards;
          animation-delay: 0.05s;
        }
      `}</style>
    </div>
  )
}
