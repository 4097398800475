/* eslint-disable jsx-a11y/label-has-associated-control */
import * as React from 'react'
import range from 'lodash/range'

interface Props {
  /**
   * Number of loading skeleton's to render.
   */
  n: number
}

export function LoadingSkeleton({ n }: Props) {
  return (
    <>
      {range(n).map((idx) => (
        <div
          key={idx}
          className="c-loan-panel--placeholder / flex flex-col justify-center / w-full max-w-lg md:max-w-none / mx-auto mb-6 py-4 md:py-6 lg:py-8 / text-center / border border-gray-300 / bg-white rounded-lg transition-25s"
          data-testid="c-loan-panel--placeholder"
        >
          <div className="flex flex-col md:flex-row md:flex-wrap justify-center / w-full / px-4 xs:px-8 mb-1">
            <div className="md:w-28pct lg:w-3/12 / p-4 md:pl-0 md:pr-8 md:py-0 / mb-3 md:mb-0">
              <div className="c-content-placeholder-bg / w-48 h-28 md:w-full / mx-auto md:mb-4 bg-gray-200"></div>
              <p className="hidden md:block lg:hidden">
                <span className="c-content-placeholder-bg / block h-6 w-3/4 mx-auto / bg-gray-200 rounded"></span>
              </p>
            </div>
            <div className="md:w-2/3 lg:w-9/12">
              <div className="lg:flex lg:flex-wrap lg:justify-between">
                <div className="c-content-placeholder-bg / w-48 h-8 lg:order-1 lg:w-5/12 lg:pr-6 / mb-3 md:mb-6 / mx-auto md:ml-0 / pl-6 pr-5 text-center bg-gray-200"></div>
                <p className="lg:order-2 lg:w-4/12 lg:px-0 / mb-4 lg:mb-4 / md:hidden lg:block">
                  <span className="c-content-placeholder-bg / inline-block w-56 h-12 xs:w-64 lg:w-full / bg-gray-200 rounded"></span>
                </p>
              </div>
              <div className="pb-2 sm:pb-3 lg:pb-0">
                <div className="flex items-stretch / leading-snug md:text-left">
                  <div className="flex flex-col items-stretch content-between w-1/3">
                    <label className="c-content-placeholder-bg / block w-8/12 h-4 / mx-auto mb-2 md:ml-0 / bg-gray-200 rounded-sm1"></label>
                    <p className="c-content-placeholder-bg / w-10/12 h-8 mx-auto mt-auto md:ml-0 / bg-gray-200 rounded-sm1"></p>
                  </div>
                  <div className="flex flex-col items-stretch w-1/3">
                    <label className="c-content-placeholder-bg / block w-8/12 h-4 / mx-auto mb-2 md:ml-0 / bg-gray-200 rounded-sm1"></label>
                    <p className="c-content-placeholder-bg / w-10/12 h-8 mx-auto mt-auto md:ml-0 / bg-gray-200 rounded-sm1"></p>
                  </div>
                  <div className="flex flex-col items-stretch content-between w-1/3">
                    <label className="c-content-placeholder-bg / block w-8/12 h-4 / mx-auto mb-2 md:ml-0 / bg-gray-200 rounded-sm1"></label>
                    <p className="c-content-placeholder-bg / w-10/12 h-8 mx-auto mt-auto md:ml-0 / bg-gray-200 rounded-sm1"></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <style jsx>{`
            .c-content-placeholder-bg {
              position: relative;
              overflow: hidden;
            }
            .c-content-placeholder-bg:before {
              content: '';
              position: absolute;
              left: 0;
              top: 0;
              display: block;
              width: 70%;
              height: inherit;
              will-change: transform;
              background: -moz-linear-gradient(
                left,
                rgba(26, 32, 44, 0) 0%,
                rgba(26, 32, 44, 1) 50%,
                rgba(255, 255, 255, 0.3) 85%,
                rgba(255, 255, 255, 0) 100%
              );
              background: -webkit-linear-gradient(
                left,
                rgba(26, 32, 44, 0) 0%,
                rgba(26, 32, 44, 1) 50%,
                rgba(255, 255, 255, 0.3) 85%,
                rgba(255, 255, 255, 0) 100%
              );
              background: linear-gradient(
                to right,
                rgba(26, 32, 44, 0) 0%,
                rgba(26, 32, 44, 1) 50%,
                rgba(255, 255, 255, 0.3) 85%,
                rgba(255, 255, 255, 0) 100%
              );
              filter: unquote(
                "progid:DXImageTransform.Microsoft.gradient( startColorstr='#001a202c', endColorstr='#00ffffff',GradientType=1 )"
              );
              opacity: 0.04;
              animation: content-placeholder-animation 1.35s linear infinite;
            }
            @keyframes content-placeholder-animation {
              0% {
                transform: translateX(-80%);
              }
              100% {
                transform: translateX(200%);
              }
            }
          `}</style>
        </div>
      ))}
    </>
  )
}
