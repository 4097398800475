/* eslint-disable react/display-name */
import React from 'react'
import ErrorBoundary from '../../error-boundary'
import { PortableText, PortableTextListComponent } from '@portabletext/react'

interface ProductHighlightsProps {
  attributeRenderer: any
  applyNowLinkRenderer: any
  content: any
  cardOrderIds: null | Record<string, string>
  categoryId: string
}

export default function ProductHighlights(props: ProductHighlightsProps) {
  const { attributeRenderer, content, applyNowLinkRenderer } = props
  return (
    <ErrorBoundary>
      <div className="c-list-check list-none / mb-5 / leading-snug text-sm text-primary">
        <PortableText
          value={content}
          components={{
            block: {
              normal: ({ children }) => <li className="mb-4">{children}</li>,
            },
            list: {
              bullet: ((props: { children: React.ReactElement }) => (
                <ul className="c-list-check / mb-4 pl-8 / leading-snug text-sm text-primary">
                  {props.children}
                </ul>
              )) as PortableTextListComponent,
            },
            marks: {
              applyNowLink: applyNowLinkRenderer,
            },
            types: {
              advertorialAttribute: attributeRenderer,
            },
          }}
        />
      </div>
    </ErrorBoundary>
  )
}
