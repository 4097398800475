import * as React from 'react'
import { useStatsigClient } from '@statsig/react-bindings'
import { LinkToRedirect } from 'components/'
import { supify } from 'utils/'
import { CompareCredit } from '../../types'
import { CompareCredit as CC } from '../../../../../types/compare-credit'
import Link from 'next/link'

export function Title({
  entity,
  externalId = '00',
  isCollapsed,
  linkParams = [],
  name,
  setCollapsed,
  orderId,
  slug,
  view,
}: {
  externalId?: string
  isCollapsed: boolean
  entity: CC.FormattedCard | CC.NonPaidCard
  linkParams?: CC.FormattedCard['applyNowLinkParameters']
  name: string
  setCollapsed: (arg1: boolean) => void
  orderId: string | null
  position?: number
  slug: string
  view: CompareCredit.CardPanelProps['view']
}) {
  const isCard = entity._type === 'card'
  const { logEvent } = useStatsigClient()

  return (
    <h2
      className={`t-card-name-base / mb-2 text-center md:text-left text-xl xs:text-2xl font-sans font-bold leading-tight ${
        view === 'list' ? 'c-product-panel__title' : 'c-card-detail__title'
      }
      `}
    >
      {view === 'details' ? (
        isCard ? (
          <LinkToRedirect
            externalId={externalId}
            linkParams={linkParams}
            orderId={orderId}
            slug={slug}
          >
            <div
              className={`c-card-title / text-primary-mid hover:text-primary-bright cursor-pointer / transition-all`}
              dangerouslySetInnerHTML={{ __html: supify(name) }}
            />
          </LinkToRedirect>
        ) : (
          <Link href={entity.moreInfoLink}>
            <div
              className={`c-card-title / text-primary-mid / transition-all`}
              dangerouslySetInnerHTML={{ __html: supify(name) }}
            />
          </Link>
        )
      ) : (
        <div
          className={`c-card-title / text-primary-mid hover:text-primary-bright cursor-pointer / transition-all`}
          onClick={() => {
            setCollapsed(!isCollapsed)
            logEvent('entity_panel_title')
          }}
          onKeyDown={() => {
            setCollapsed(!isCollapsed)
            logEvent('entity_panel_title')
          }}
          role="button"
          tabIndex={0}
          dangerouslySetInnerHTML={{ __html: supify(name) }}
        />
      )}
      <style jsx>{`
        .c-card-title sup {
          font-family: 'Open Sans', sans-serif;
          font-size: 60%;
          font-weight: normal;
        }

        .t-card-name-base.c-product-panel__title {
          font-size: 18px;
        }

        @media (min-width: 480px) {
          .t-card-name-base.c-product-panel__title {
            font-size: 21px;
          }
        }
        @media (min-width: 768px) {
          .t-card-name-base.c-product-panel__title {
            font-size: 20px;
          }
        }

        @media (min-width: 880px) {
          .t-card-name-base.c-product-panel__title {
            font-size: 24px;
          }
        }

        @media (min-width: 1024px) {
          .t-card-name-base.c-product-panel__title {
            font-size: 20px;
          }
        }

        /* CARD DETAIL TITLE */
        @media (min-width: 1024px) {
          .t-card-name-base .c-card-detail__title {
            font-size: 27px;
          }
        }
        @media (min-width: 1152px) {
          .t-card-name-base .c-card-detail__title {
            font-size: 29px;
          }
        }
      `}</style>
    </h2>
  )
}
