import React from 'react'

export const link = (props: any) => {
  const { children, value } = props
  return (
    <a
      className="text-primary-bright / hover:text-primary-mid"
      href={value.href}
      rel="noopener noreferrer"
      target="_blank"
    >
      {children}
    </a>
  )
}
