import { CompareCredit } from '../../types'
import * as React from 'react'

export function FeaturedFlag({
  view,
  featured,
}: {
  view: CompareCredit.CardPanelProps['view']
  featured: boolean | undefined
}) {
  return view === 'list' && featured ? (
    <div className="w-full text-center md:text-left">
      <p className="c-ribbon c-ribbon--slimmer md:c-ribbon--rt / relative inline-block / w-auto mb-2 px-4 md:px-3 pt-1 / leading-none font-bold text-primary text-fs13 tracking-wider uppercase / bg-tetriary">
        Featured
      </p>
    </div>
  ) : null
}
