import React from 'react'
import { CompareCredit } from '../../../../types/compare-credit'
import { CardPanel } from '../../entity-panel/card'
import { Modal } from '../../modal'
import MobileViewContainer from './mobile-view-container'
import StickyAside from './sticky-aside'

interface ProductTipCardProps {
  cardTileAsideRef: React.RefObject<HTMLDivElement>
  cardTileIsSticky: boolean
  asideCardTileMargin: string
  isSticky: boolean
  asideOverflow: boolean
  unstickCardTile: boolean
  featuredRibbon: boolean
  featuredRibbonText: string
  floatRight: boolean
  productTip: any
  categoryId: string
  externalId: string
  orderId: string | null
  query: string
  displayName: boolean
  recommendedCredit: boolean
  clientLocation: {
    city: string
    country: string
    state: string
    status: string
  }
  referencedCards: null | Record<string, CompareCredit.Entities>
  cardOrderIds: null | Record<string, string>
  singleCardTip: {
    card: {
      _id: string
      slug: string
    }
    content: {
      children: any
    }[]
  }
  setModalOpen: (value: React.SetStateAction<boolean>) => void
  modalOpen: boolean
  hideRatingCount: boolean
  hideMoreDetails: boolean
  hideTrustLabel: boolean
  hideLeadingBadge: boolean
  layoutSlim: boolean
}

export default function ProductTipCard(props: ProductTipCardProps) {
  const {
    cardTileAsideRef,
    cardTileIsSticky,
    asideCardTileMargin,
    isSticky,
    asideOverflow,
    unstickCardTile,
    featuredRibbon,
    featuredRibbonText,
    floatRight,
    productTip,
    categoryId,
    externalId,
    orderId,
    query,
    displayName,
    recommendedCredit,
    clientLocation,
    referencedCards,
    cardOrderIds,
    singleCardTip,
    setModalOpen,
    modalOpen,
    hideRatingCount,
    hideMoreDetails,
    hideTrustLabel,
    hideLeadingBadge,
    layoutSlim,
  } = props
  return (
    <div
      className={`c-card-tile-article / lg:flex lg:w-full lg:h-full / flex items-stretch / h-full / mt-10 sm:mt-8 lg:mt-0 sm:mb-4 ${
        floatRight ? 'sm:float-right sm:ml-8' : 'sm:float-left sm:mr-8'
      }`}
    >
      <div className="c-card-tile__modal-container / relative z-80">
        <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
          <CardPanel
            categoryId={categoryId}
            externalId={externalId}
            onClose={() => setModalOpen(false)}
            view="modal"
            featured={false}
            entity={productTip}
            propsOrderId={orderId}
          />
        </Modal>
      </div>

      {/* MOBILE VIEW START */}
      <MobileViewContainer
        layoutSlim={layoutSlim}
        featuredRibbon={featuredRibbon}
        featuredRibbonText={featuredRibbonText}
        productTip={productTip}
        categoryId={categoryId}
        externalId={externalId}
        orderId={orderId}
        query={query}
        displayName={displayName}
        hideRatingCount={hideRatingCount}
        recommendedCredit={recommendedCredit}
        clientLocation={clientLocation}
        referencedCards={referencedCards}
        cardOrderIds={cardOrderIds}
        singleCardTip={singleCardTip}
        setModalOpen={setModalOpen}
        modalOpen={modalOpen}
        hideMoreDetails={hideMoreDetails}
      />
      <StickyAside
        cardTileAsideRef={cardTileAsideRef}
        cardTileIsSticky={cardTileIsSticky}
        asideCardTileMargin={asideCardTileMargin}
        isSticky={isSticky}
        asideOverflow={asideOverflow}
        unstickCardTile={unstickCardTile}
        featuredRibbon={featuredRibbon}
        featuredRibbonText={featuredRibbonText}
        productTip={productTip}
        categoryId={categoryId}
        externalId={externalId}
        orderId={orderId}
        query={query}
        displayName={displayName}
        recommendedCredit={recommendedCredit}
        clientLocation={clientLocation}
        referencedCards={referencedCards}
        cardOrderIds={cardOrderIds}
        singleCardTip={singleCardTip}
        setModalOpen={setModalOpen}
        modalOpen={modalOpen}
        hideMoreDetails={hideMoreDetails}
        hideTrustLabel={hideTrustLabel}
        hideLeadingBadge={hideLeadingBadge}
      />
    </div>
  )
}
