import React from 'react'
import Link from 'next/link'
import { InstagramEmbed } from 'react-social-media-embed'

import { CompareCredit } from '../../../../types/compare-credit'

export const QuickTips = ({
  quicktips,
}: {
  quicktips: CompareCredit.HubData['quickTips']
}) => {
  return (
    <>
      <section className="mb-8 / p-4 rounded bg-primary-light-bg-2">
        <p className="font-bold tracking-wider uppercase text-center">
          Quick Tips
        </p>
        <div className="lg:overflow-scroll mt-4">
          <ul className="c-video-social / flex lg:flex-row flex-col justify-center max-h-">
            {quicktips.map((quicktip) => (
              <li key={quicktip._key} className="c-video-social__item">
                <InstagramEmbed url={quicktip.url} />
              </li>
            ))}
          </ul>
        </div>
        <p className="py-2 mt-4 text-center">
          <Link
            href="https://www.instagram.com/compare.credit/"
            className="px-6 py-2.5 / text text-white font-bold / rounded-sm bg-primary-bright"
            target="_blank"
          >
            Visit Our Instagram Page for More Tips
          </Link>
        </p>
      </section>
      <style jsx>
        {`
          .c-video-social__item {
            min-width: 220px;
            margin-right: 1rem;
          }
          .c-video-social__item:last-child {
            margin-right: 0;
          }
          @media (min-width: 1024px) {
            .c-video-social__item {
              max-height: 640px;
            }
          }
        `}
      </style>
    </>
  )
}
