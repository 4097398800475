import * as React from 'react'
import range from 'lodash/range'

interface Props {
  /**
   * Number of loading skeleton's to render.
   */
  n: number
}
export function LoadingSkeleton({ n }: Props) {
  return (
    <>
      {range(n).map((idx) => (
        <div className="inline-block w-full mb-8" key={idx}>
          <h2 className="pl-4 mb-12 md:mb-4 xxs:mr-2 / text-xl font-bold font-sans leading-tight text-center md:text-left">
            <div className="c-content-placeholder-bg w-6/12 h-8 mx-auto mt-auto md:ml-0 / bg-gray-200 rounded-sm1"></div>
          </h2>
          <div className="relative m-auto max-w-xs md:w-64 md:float-left / mb-6 md:mr-10 md:mt-8 / px-8 pb-3 / bg-white shadow-lg rounded-lg">
            <div className="text-center flex items-center justify-center / mb-3">
              <div className="relative / w-full -mt-6 / overflow-hidden">
                <div className="c-content-placeholder-bg / relative w-48 h-32 rounded-lg shadow bg-gray-200 / md:mx-auto ml-8"></div>
              </div>
            </div>
            <div className="flex items-center justify-center mb-3">
              <div className="inline-block">
                <div className="c-content-placeholder-bg w-32 h-6 mx-auto mt-auto md:ml-0 / bg-gray-200 rounded-sm1"></div>
                <div className="c-content-placeholder-bg w-20 h-5 mx-auto mt-auto / bg-gray-200 rounded-sm1"></div>
              </div>
            </div>
            <div className="flex items-center justify-center mb-2">
              <div className="flex-col items-stretch content-between">
                <div className="visible c-content-placeholder-bg w-48 h-10 mx-auto mt-auto md:ml-0 / bg-gray-200 rounded"></div>
              </div>
            </div>
            <div className="flex items-center justify-center mb-2">
              <div className="flex flex-col items-stretch content-between">
                <p className="c-content-placeholder-bg w-24 h-5 mx-auto mt-auto md:ml-0 / bg-gray-200 rounded-sm1"></p>
              </div>
            </div>
          </div>

          <div className="py-2 sm:pb-3 lg:pb-0">
            <div className="flex flex-col items-stretch content-between w-10/10">
              <div className="c-content-placeholder-bg / w-10/12 h-4 mx-auto mb-2 mt-auto md:ml-0 / bg-gray-200 rounded-sm1"></div>
              <div className="c-content-placeholder-bg / w-10/12 h-4 mx-auto mb-2 mt-auto md:ml-0 / bg-gray-200 rounded-sm1"></div>
              <div className="c-content-placeholder-bg / w-10/12 h-4 mx-auto mb-2 mt-auto md:ml-0 / bg-gray-200 rounded-sm1"></div>
              <div className="c-content-placeholder-bg / w-10/12 h-4 mx-auto mb-2 mt-auto md:ml-0 / bg-gray-200 rounded-sm1"></div>
              <div className="c-content-placeholder-bg / w-8/12 h-4 / mx-auto mb-2 md:ml-0 / bg-gray-200 rounded-sm1"></div>
            </div>
          </div>

          <div className="pt-6 sm:pb-3 lg:pb-0">
            <div className="flex flex-col items-stretch content-between w-10/10">
              <div className="mb-2 c-content-placeholder-bg / w-10/12 h-4 mx-auto mt-auto md:ml-0 / bg-gray-200 rounded-sm1"></div>
              <div className="mb-2 c-content-placeholder-bg / w-10/12 h-4 mx-auto mt-auto md:ml-0 / bg-gray-200 rounded-sm1"></div>
              <div className="mb-2 c-content-placeholder-bg / w-10/12 h-4 mx-auto mt-auto md:ml-0 / bg-gray-200 rounded-sm1"></div>
              <div className="mb-2 c-content-placeholder-bg / w-10/12 h-4 mx-auto mt-auto md:ml-0 / bg-gray-200 rounded-sm1"></div>
              <div className="c-content-placeholder-bg / w-8/12 h-4 / mx-auto mb-2 md:ml-0 / bg-gray-200 rounded-sm1"></div>
            </div>
          </div>

          <style jsx>{`
            .c-content-placeholder-bg {
              position: relative;
              overflow: hidden;
            }
            .c-content-placeholder-bg:before {
              content: '';
              position: absolute;
              left: 0;
              top: 0;
              display: block;
              width: 70%;
              height: inherit;
              will-change: transform;
              background: -moz-linear-gradient(
                left,
                rgba(26, 32, 44, 0) 0%,
                rgba(26, 32, 44, 1) 50%,
                rgba(255, 255, 255, 0.3) 85%,
                rgba(255, 255, 255, 0) 100%
              );
              background: -webkit-linear-gradient(
                left,
                rgba(26, 32, 44, 0) 0%,
                rgba(26, 32, 44, 1) 50%,
                rgba(255, 255, 255, 0.3) 85%,
                rgba(255, 255, 255, 0) 100%
              );
              background: linear-gradient(
                to right,
                rgba(26, 32, 44, 0) 0%,
                rgba(26, 32, 44, 1) 50%,
                rgba(255, 255, 255, 0.3) 85%,
                rgba(255, 255, 255, 0) 100%
              );
              filter: unquote(
                "progid:DXImageTransform.Microsoft.gradient( startColorstr='#001a202c', endColorstr='#00ffffff',GradientType=1 )"
              );
              opacity: 0.04;
              animation: content-placeholder-animation 1.35s linear infinite;
            }
            @keyframes content-placeholder-animation {
              0% {
                transform: translateX(-80%);
              }
              100% {
                transform: translateX(200%);
              }
            }
          `}</style>
        </div>
      ))}
    </>
  )
}
