import React from 'react'
import pick from 'lodash/fp/pick'
import placeholder from 'lodash/fp/placeholder'
import {
  PortableTextBlockComponent,
  PortableTextListComponent,
  PortableTextMarkComponent,
} from '@portabletext/react'

import { CompareCredit } from '../../../types/compare-credit'
import { AsSeenInWrapper } from '../as-seen-in-wrapper'
import { ApplyNowLinkV2 } from './applyNowLinkV2'
import { ArticleHeaderSideLines } from './articleHeaderSideLines'
import { AttributeRendererWithSlugV2 } from './attributeRendererV2'
import { AuthorHeader } from './authorHeader'
import { BannerCardContent } from './bannerCardContent'
import { ButtonRenderer } from './buttonRenderer'
import { CalloutContent } from './calloutContent'
import { CalloutRenderer } from './calloutRenderer'
import { CardComparison } from './cardComparison'
import { CardComparisonTableVertical } from './cardComparisonTableVertical'
import { CardListCallout } from './cardListCallout'
import { CardRecommendedList } from './cardRecommendedList'
import { CardRelatedList } from './cardRelatedList'
import { CardSummary } from './cardSummary'
import { CardSummaryBlock } from './cardSummaryBlock'
import { Centered } from './centered'
import { ContentHighlight } from './contentHighlight'
import { CustomComponentRenderer } from './customComponentRenderer'
import { DropCap } from './dropCap'
import { Footnote } from './footnote'
import { Heading3 } from './heading3'
import { HighlightModule } from './highlightModule'
import { HighlightRenderer } from './highlightRenderer'
import { ImageCardArt } from './imageCardArt'
import { InstagramPost } from './instagramPost'
import { JumpLink } from './jumpLink'
import { JumpLinkSection } from './jumpLinkSection'
import { LinkV2 } from './linkV2'
import { ListBullet } from './listBullet'
import { ListNumber } from './listNumber'
import { ListProsCons } from './listProsCons'
import { Normal } from './normal'
import { PhotoRenderer } from './photoRenderer'
import { PreviewCards } from './previewCards'
import { Quote } from './quote'
import { RatesAndFeesLink } from './ratesAndFeesLink'
import { SmallTextBorder } from './smallTextBorder'
import { TestImageAd } from './testImageAd'
import { TipArticleInContent } from './tipArticleInContent'
import { TipAuthorReviewer } from './tipAuthorReviewer'
import { TipCardTileList } from './tipCardTileList'
import { TipHeader } from './tipHeader'
import { TipRecommendedArticles } from './tipRecommendedArticles'
import { TipSingleCardColumnTile } from './tipSingleCardColumnTile'
import { TipSingleCardTile } from './tipSingleCardTile'
import { Superscript } from './superscript'
import { Trending } from './trending'
import { VideoTile } from './videoTile'

const serializerMap: any = {}
export const blockSerializers = (props: {
  cardOrderIds: null | Record<string, string>
  categoryId: string | undefined
  externalId: string | undefined
  referencedCards: null | Record<string, CompareCredit.Entities>
  exitModalRef?: React.RefObject<any>
  exitModalMultiCardTipPosition?: number
}) => {
  if (serializerMap.h) {
    return serializerMap.h
  }
  const {
    cardOrderIds,
    categoryId = '00',
    externalId = '00',
    referencedCards,
    exitModalRef,
    exitModalMultiCardTipPosition,
  } = props

  const data = pick(placeholder, {
    cardOrderIds,
    referencedCards,
    categoryId,
    externalId,
    exitModalRef,
    exitModalMultiCardTipPosition,
  })
  const returnValue = {
    block: {
      centered: Centered as PortableTextBlockComponent,
      footnote: Footnote as PortableTextBlockComponent,
      h3: Heading3 as PortableTextBlockComponent,
      normal: Normal as PortableTextBlockComponent,
    },
    types: {
      // eslint-disable-next-line react/display-name
      horizontalRule: () => <hr />,
      trustCol: AsSeenInWrapper,
      button: ButtonRenderer(
        data(['referencedCards', 'cardOrderIds', 'externalId', 'categoryId']),
      ),
      callout: CalloutRenderer,
      calloutContent: CalloutContent(
        data(['referencedCards', 'cardOrderIds', 'categoryId', 'externalId']),
      ),
      custom: CustomComponentRenderer,
      quote: Quote,
      smallTextBorder: SmallTextBorder,
      testImageAd: TestImageAd,
      cardComparison: CardComparison(data(['referencedCards', 'cardOrderIds'])),
      cardComparisonTableVertical: CardComparisonTableVertical(
        data(['referencedCards', 'cardOrderIds']),
      ),
      cardRecommendedList: CardRecommendedList(
        data(['referencedCards', 'cardOrderIds']),
      ),
      cardRelatedList: CardRelatedList(data(['referencedCards'])),
      cardListCallout: CardListCallout(
        data(['referencedCards', 'cardOrderIds']),
      ),
      cardSummary: CardSummary(data(['referencedCards', 'cardOrderIds'])),
      cardSummaryBlock: CardSummaryBlock,
      contentHighlight: ContentHighlight(
        data(['referencedCards', 'cardOrderIds', 'categoryId', 'externalId']),
      ),
      bannerCardContent: BannerCardContent(
        data(['referencedCards', 'cardOrderIds', 'categoryId', 'externalId']),
      ),
      articleHeaderSideLines: ArticleHeaderSideLines,
      image: PhotoRenderer,
      imageCardArt: ImageCardArt(
        data(['referencedCards', 'cardOrderIds', 'categoryId', 'externalId']),
      ),
      previewCards: PreviewCards,
      photo: PhotoRenderer,
      authorHeader: AuthorHeader,
      jumpLinkSection: JumpLinkSection,
      tipHeader: TipHeader(
        data(['referencedCards', 'cardOrderIds', 'categoryId', 'externalId']),
      ),
      tipSingleCardColumnTile: TipSingleCardColumnTile(
        data(['referencedCards', 'cardOrderIds']),
      ),
      tipCardTileList: TipCardTileList(
        data([
          'referencedCards',
          'cardOrderIds',
          'exitModalRef',
          'exitModalMultiCardTipPosition',
        ]),
      ),
      tipRecommendedArticles: TipRecommendedArticles,
      tipArticleInContent: TipArticleInContent,
      tipAuthorReviewer: TipAuthorReviewer,
      tipSingleCardTile: TipSingleCardTile(
        data(['referencedCards', 'cardOrderIds']),
      ),
      trending: Trending,
      listProsCons: ListProsCons(
        data(['referencedCards', 'cardOrderIds', 'categoryId', 'externalId']),
      ),
      highlightModule: HighlightModule(
        data(['referencedCards', 'cardOrderIds', 'categoryId', 'externalId']),
      ),
      advertorialAttribute: AttributeRendererWithSlugV2(
        data(['referencedCards']),
      ),
      instagramPost: InstagramPost,
      videoTile: VideoTile,
    },
    marks: {
      highlight: HighlightRenderer as PortableTextMarkComponent,
      link: LinkV2 as PortableTextMarkComponent,
      dropCap: DropCap as PortableTextMarkComponent,
      applyNowLink: ApplyNowLinkV2(
        data(['cardOrderIds', 'referencedCards', 'categoryId', 'externalId']),
      ) as PortableTextMarkComponent,
      ratesAndFeesLink: RatesAndFeesLink as PortableTextMarkComponent,
      jumpLink: JumpLink as PortableTextMarkComponent,
      superscript: Superscript,
    },
    list: {
      number: ListNumber as PortableTextListComponent,
      bullet: ListBullet as PortableTextListComponent,
    },
  }

  if (cardOrderIds != null && props.referencedCards != null) {
    serializerMap.h = returnValue
  }
  return returnValue
}
