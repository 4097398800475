import * as React from 'react'
import range from 'lodash/range'

interface Props {
  /**
   * Number of loading skeleton's to render.
   */
  n: number
}

export function LoadingSkeleton({ n }: Props) {
  return (
    <>
      {range(n).map((idx) => (
        <div
          key={idx}
          className="flex md:items-stretch / w-full md:w-1/3 / max-w-sm md:max-w-none / md:mx-2"
        >
          <div className="relative flex flex-col justify-between / w-full / text-center flex items-center justify-center / mb-4 / p-3 xs:p-5 md:p-3 lg:p-5 xl:p-6 / rounded-sm shadow">
            <div className="relative / flex flex-col / w-full">
              {/* card image */}
              <div className="flex flex-row / mb-4">
                <div className="relative flex flex-shrink-0 / w-1/4 / h-12 / mr-4 rounded-sm bg-gray-300 animate-pulse"></div>
                <div className="flex flex-col justify-center items-start / w-full">
                  <div className="w-full / h-6 mb-2 rounded-sm bg-gray-300 animate-pulse"></div>
                  <div className="w-24 / h-4 rounded-sm bg-gray-300 animate-pulse"></div>
                </div>
              </div>
              <div className="w-full">
                {/* title */}
                <div className="h-4 mb-3 rounded-sm bg-gray-300 animate-pulse"></div>
                <div className="h-4 mb-3 rounded-sm bg-gray-300 animate-pulse"></div>
                <div className="h-4 mb-3 rounded-sm bg-gray-300 animate-pulse"></div>
                <div className="h-10 w-full / rounded-sm bg-gray-300 animate-pulse"></div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  )
}
