import React from 'react'
import Image from 'next/image'
import { isMobileOnly } from 'react-device-detect'
import { nextImageHelper } from '../../utils'
import cn from 'utils/classnames'

export function ExitModal(props: { name: string }) {
  const { name } = props

  const [browserWidth, setBrowserWidth] = React.useState({
    width: 0,
  })
  const setHideModal = () => setShowModal(false)
  const [mainContentArea, setMainContentArea] = React.useState(false)
  const [modalReady, setModalReady] = React.useState(false)
  const [position, setPosition] = React.useState({ x: 0, y: 0 })
  const [showModal, setShowModal] = React.useState(false)
  const [sideTargetArea, setSideTargetArea] = React.useState(false)

  // Tracks mouse position
  React.useEffect(() => {
    const setFromEvent = (e: { x: number; y: number }) =>
      setPosition({ x: e.x, y: e.y })
    window.addEventListener('mousemove', setFromEvent)

    return () => {
      window.removeEventListener('mousemove', setFromEvent)
    }
  }, [])

  // capture window width when component mounts or when browser is re-sized
  React.useEffect(() => {
    setBrowserWidth({
      width: window.innerWidth,
    })
    const handleResize = () =>
      setBrowserWidth({
        width: window.innerWidth,
      })
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const leftTarget = browserWidth.width * 0.25
  const rightTarget = browserWidth.width * 0.75

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setModalReady(true)
    }, 7000)
    return () => clearTimeout(timer)
  }, [])

  // Checks if cursor indicates exit intent
  React.useEffect(() => {
    if (position.y > 100) {
      setMainContentArea(true)
    }
    if (
      (position.x < leftTarget || position.x > rightTarget) &&
      position.y < 100
    ) {
      setSideTargetArea(true)
    }
    if (
      position.y < 100 &&
      mainContentArea &&
      sideTargetArea &&
      !isMobileOnly &&
      modalReady &&
      window.sessionStorage.getItem(`${name}HasShown`) === null
    ) {
      window.sessionStorage.setItem(`${name}HasShown`, 'true')
      setShowModal(true)
    }
  }, [position.x, position.y])

  // close modal with esc key
  React.useEffect(() => {
    const closeModal = (e: any) => {
      if (e.keyCode === 27) {
        setHideModal()
      }
    }
    window.addEventListener('keydown', closeModal)
    return () => window.removeEventListener('keydown', closeModal)
  }, [])

  return (
    <div
      className={showModal ? 'block' : 'hidden'}
      aria-hidden={showModal ? 'false' : 'true'}
    >
      <div
        className={cn(
          'c-modal / fixed w-full h-full top-0 left-0 / z-80 / px-4 / overflow-scroll',
          {
            hidden: !showModal,
          },
        )}
      >
        <div className="c-modal__container / relative top-0 / max-w-3xl w-full / mx-auto my-6 / shadow-lg rounded-lg overflow-scroll z-80">
          <div
            className="c-modal__content / relative / flex flex-col items-stretch justify-center / w-full / mx-auto / bg-white"
            tabIndex={-1}
            role="dialog"
          >
            <button
              className="absolute top-0 right-0 / w-8 h-8 / mt-2 mr-2 / cursor-pointer / opacity-50 sm:opacity-25 / hover:opacity-75 focus:outline-none / transition-all--25"
              onClick={() => setHideModal()}
              aria-label="close modal"
              type="button"
            >
              <Image
                className="w-8"
                alt="close icon"
                role="presentation"
                src="/static/icons/icon-close-circle-black.svg"
                fill
                sizes="100vw"
              />
            </button>
            <div className="c-modal__body / sm:flex sm:items-stretch">
              <div className="c-modal__banner / sm:w-5/12 / h-48 sm:h-auto / bg-gray-200 / mb-4 sm:mb-0 / overflow-hidden rounded-t-lg sm:rounded-tr-none sm:rounded-bl-lg"></div>
              <div className="sm:w-7/12 / px-4 xs:px-8 sm:px-10 sm:py-8 md:py-10 pb-6">
                <p className="mb-2 sm:mb-3 / font-bold leading-tight text-xl xxs:text-2xl">
                  <a
                    className="text-primary / hover:text-primary-bright"
                    href="https://cos-rd.com/zf/46/6916?campaign=SOV11_c"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Before You Renew, Amazon Prime, Read&nbsp;This
                  </a>
                </p>
                <p className="mb-5 / text-gray-800">
                  If you shop on Amazon under the impression that it has the
                  best prices, then you may not get the most out of your money.
                </p>
                <p className="max-w-xs mx-auto sm:ml-0 text-center">
                  <a
                    href="https://cos-rd.com/zf/46/6916?campaign=SOV11_c"
                    className="c-btn c-btn--primary / mb-4 py-2 / text-lg"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Learn More
                    <span className="ml-2">
                      <Image
                        alt="right chevron"
                        src="/static/icons/icon-chevron-r-white.svg"
                        role="presentation"
                        aria-hidden="true"
                        width="16"
                        height="16"
                        style={nextImageHelper.intrinsic}
                      />
                    </span>
                  </a>
                  <button
                    className="text-sm text-primary-bright underline / hover:text-primary-mid"
                    onClick={() => setHideModal()}
                    aria-label="close modal"
                    type="button"
                  >
                    No, Thanks
                  </button>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          aria-label="close modal"
          className={cn('c-modal__bg / fixed top-0 left-0 / w-full h-full', {
            'modal-in': showModal,
          })}
          onClick={() => setHideModal()}
          onKeyDown={() => setHideModal()}
          role="button"
          tabIndex={0}
        />
      </div>
      <style jsx>
        {`
          /*===== MODAL =====*/
          .c-modal__banner {
            background-image: url(/static/images/amazon-boxes-doorstep.jpg);
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
          }
        `}
      </style>
    </div>
  )
}
