import { useEffect } from 'react'
import * as Sentry from '@sentry/nextjs'
import { CompareCredit } from '../../types'
import { useFetchCardsSlugComplex } from './use-fetch-cards-slug-complex'
import { useFetchCardsSlugSimple } from './use-fetch-cards-slug-simple'

export function useFetchCardsSlug(
  slugs: string[],
  sortData?: { categoryId: string },
  cb?: (
    entities: CompareCredit.Entities[],
    sort?: string,
  ) => CompareCredit.Entities[],
) {
  useEffect(() => {
    if (
      !slugs ||
      !Array.isArray(slugs) ||
      !slugs.length ||
      slugs.length === 0
    ) {
      return
    }
    if (slugs && slugs.length === 1 && slugs[0] == null) {
      Sentry.captureException(new Error('Slug is possibly undefined'))
      return
    }
  }, [JSON.stringify(slugs)])

  const simpleProcess =
    (slugs && slugs.length && slugs.length === 1) || !sortData

  return simpleProcess
    ? useFetchCardsSlugSimple(slugs, cb)
    : useFetchCardsSlugComplex(slugs, sortData, cb)
}
