import { CompareCredit } from '../../../../types'
import { isPinned } from '../../isPinnedSortCards'

export interface Entity {
  rpiUsed?: number
  isPinned?: boolean
  rpi?: number
  rpiData?: {
    six: Record<string, { rpi: number }>
    thirty: Record<string, { rpi: number }>
  }
  _type?: string
  sixDayRpiLookBack?: boolean
  slug?: any
  cardSlug?: string
  position?: number
  pinnedPosition?: number
}

/**
 * It takes an array of entities and a map of pinned products, and returns an object with two arrays:
 * one for pinned products and one for non-pinned products
 * @param {Entity[]} entities - The array of entities that we want to separate into pinned and
 * non-pinned
 * @param pinnedProducts - The list of pinned products.
 * @param pinnedPositions - original positions of cards
 * @returns An object with two properties:
 * - pinned: An array of objects that are pinned
 * - nonPinned: An array of objects that are not pinned
 */
export const separateEntities = (
  entities: Entity[],
  pinnedProducts: CompareCredit.PinnedProductFormatted,
  pinnedPositions: Record<string, number>,
) => {
  return entities.reduce(
    (acc, val): any => {
      const slug = val.slug || val.cardSlug || ''
      if (
        (val._type === 'card' || val._type === 'cardTip') &&
        isPinned(pinnedProducts[slug])
      ) {
        // keep track of idx so we can insert it into place later
        acc.pinned.push([val, pinnedPositions[slug]])
      } else if (val._type !== 'card' && val._type !== 'cardTip') {
        acc.pinned.push([val, pinnedPositions[slug]])
      } else {
        acc.nonPinned.push(val)
      }
      return acc
    },
    { pinned: [], nonPinned: [] } as {
      pinned: Array<[Entity, number]>
      nonPinned: Entity[]
    },
  )
}

/**
 * Insert the pinned entities into the original index
 * @param pinnedEntities - an array of [Entity, index] pairs.
 * @param {Entity[]} sortedNonPinned - The list of entities that are not pinned.
 * @returns An array of entities, sorted by rpi with pinned entities.
 */
export const insertPinnedIntoOriginalIdx = (
  pinnedEntities: Array<[Entity, number]>,
  sortedNonPinned: Entity[],
) => {
  return (
    pinnedEntities
      // insert from the smallest idx up, otherwise it could get out of order
      .sort((a, b) => {
        return a[1] - b[1]
      })
      .reduce((acc, val) => {
        const [value, i] = val
        // insert the pinned card in it's original spot
        acc.splice(i, 0, value)
        return acc
      }, sortedNonPinned)
  )
}
