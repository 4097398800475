import * as React from 'react'
import path from 'lodash/fp/path'
import some from 'lodash/fp/some'
import { isMobileOnly } from 'react-device-detect'
import { v4 as uuid } from 'uuid'
import { useExperiment } from 'utils/use-experiment-wrapper'

import { CardPopularTab, UserContext } from '../'
import { CompareCredit as CC } from '../../../types/compare-credit'
import { clickProduct, viewProduct } from '../../clients/segment'
import {
  BottomSection,
  CollapseDetails,
  CustomBadge,
  LeftSection,
  PanelContainer,
  PanelContents,
  TopSection,
} from './sections'
import { CompareCredit } from './types'
import { CreditCardLinkedData } from '../linked-data/card'

// TODO: having some types in this package and others in `packages/types` is a
// bit confusing. Might be worth going with one route or the other and sticking
// to it. One thought is having the types in `packages/types` be the base types
// that come straight out of Sanity, because those are the types likely to be
// shared across other packages (ETL, etc). Then anything that relates to UI
// would be co-located with the UI.
type Props = {
  categoryId: string
  externalId: string
  entity: CC.FormattedCard | CC.NonPaidCard
  sendOrderId?: (orderId: string) => void
  collapsed?: boolean
  propsOrderId?: string | null
  sortable?: boolean
} & Partial<CompareCredit.CardPanelProps>

export function CardPanel(props: Props) {
  const { collapsed = false } = props
  const {
    categoryId,
    externalId,
    entity,
    view = 'list',
    position,
    arrangementId,
  } = props
  const [cardIsInCart, setCardIsInCart] = React.useState(false)
  const [orderId, setOrderId] = React.useState<string | null>(null)
  const [isCollapsed, setCollapsed] = React.useState(
    view === 'list' ? collapsed : false,
  )
  const { comparisonCart, updateComparisonCart } = React.useContext(UserContext)

  const handleCollapseDetails = (collapsed: boolean) => {
    if (!collapsed) {
      clickProduct({
        brand: issuerName,
        name: entity.name,
        sku: entity.slug,
      })
    }
    setCollapsed(collapsed)
  }

  const handleUpdateCart = (action: 'add' | 'remove') => {
    updateComparisonCart(action, [entity as CC.FormattedCard])
  }

  React.useEffect(() => {
    setCardIsInCart(
      some(
        (obj: Partial<CompareCredit.CardPanelTypes>) =>
          obj.slug === entity.slug,
        // @ts-ignore
        comparisonCart,
      ),
    )
  }, [comparisonCart.length, entity.name])
  React.useEffect(() => {
    const id = props.propsOrderId || uuid()
    setOrderId(id)
    const productDetails = {
      brand: entity.issuer.slug.current,
      id: entity.slug,
      product_id: entity.slug,
      name: entity.name,
      sku: entity.slug,
    }

    if (view === 'details' || view === 'modal') {
      viewProduct(productDetails)
    }
  }, [])

  React.useEffect(() => {
    if (orderId && props.sendOrderId) {
      props.sendOrderId(orderId)
    }
  })

  const isDetail = view === 'details'
  const isModalOrDetail = view === 'details' || view === 'modal'
  const issuerName = path(['issuer', 'name'], props)

  // 2024.08: EXP-324 Category Flag
  const { experiment: configExp324, isLoading: isLoadingExp324 } =
    useExperiment('exp_324_category_flag_v2')
  const variationExp324 = configExp324?.value?.name || 'control'

  return (
    <PanelContainer isDetail={isDetail} view={view} isCollapsed={isCollapsed}>
      <>
        {!isLoadingExp324 &&
          variationExp324 == 'v13-popular-tab' &&
          position == 1 && <CardPopularTab />}
        {props.onClose && (
          <button onClick={props.onClose}>
            <img
              alt=""
              aria-label="Close"
              className="cursor-pointer absolute top-0 right-0 / w-8 / mt-1 mr-1 md:mt-4 md:mr-4 / opacity-25 / hover:opacity-75 focus:outline-none / transition-all z-10"
              src="/static/icons/icon-close-circle-black.svg"
            />
          </button>
        )}
        {entity.customBadge && view !== 'details' && (
          <CustomBadge text={entity.customBadge} view={view} />
        )}
        <PanelContents view={view}>
          <LeftSection
            categoryId={categoryId}
            externalId={externalId}
            orderId={orderId}
            updateCart={handleUpdateCart}
            inCart={cardIsInCart}
            featured={props.featured}
            entity={entity}
            show={view === 'list'}
            comparisonDisabled={props.comparisonDisabled}
            position={position}
            arrangementId={arrangementId}
            view={props.view}
            sortable={view === 'list'}
            isCollapsed={isCollapsed}
            setCollapsed={handleCollapseDetails}
          />
          <TopSection
            categoryId={categoryId}
            externalId={externalId}
            orderId={orderId}
            entity={entity}
            featured={isModalOrDetail ? false : props.featured}
            updateCart={handleUpdateCart}
            inCart={cardIsInCart}
            view={view}
            isCollapsed={isCollapsed}
            setCollapsed={handleCollapseDetails}
            category={props.category || ''}
            isMobile={isMobileOnly}
            comparisonDisabled={props.comparisonDisabled}
            position={position}
          />
          <BottomSection
            orderId={orderId}
            entity={entity}
            setCollapsed={setCollapsed}
            isCollapsed={view === 'list' ? isCollapsed : false}
            view={view}
          />
        </PanelContents>
        {view === 'list' && (
          <CollapseDetails
            orderId={orderId}
            entity={entity}
            isCollapsed={isCollapsed}
            setCollapsed={handleCollapseDetails}
          />
        )}
        <CreditCardLinkedData card={entity} />
      </>
    </PanelContainer>
  )
}
