import React from 'react'

export function Forward() {
  return (
    <svg
      width="8px"
      height="14px"
      viewBox="0 0 8 14"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>forward </title>
      <desc>Created with Sketch.</desc>
      <g
        id="_components"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          id="forward-"
          transform="translate(-5.000000, 1.000000)"
          stroke="#052F5E"
          strokeWidth="1.5"
        >
          <polyline
            id="forward"
            transform="translate(6.000000, 6.000000) rotate(-45.000000) translate(-6.000000, -6.000000) "
            points="10 2 10 10 2 10"
          />
        </g>
      </g>
    </svg>
  )
}
