import React from 'react'
import { ClickawayListener } from '../'

export function Modal(props: {
  onClose: () => void
  open: boolean
  children: React.ReactElement
}) {
  return props.open ? (
    <div className="c-modal / modal modal-active / fixed w-full h-full top-0 left-0 / flex items-center justify-center z-70 / overflow-scroll">
      <div className="modal-overlay fixed w-full h-full bg-gray-900 opacity-50 / top-0 left-0" />

      <div className="c-modal__content container-fluid / absolute top-0 left-0 right-0 / w-full mb-6 mt-20 / overflow-hidden z-50">
        <div
          style={{ overflowX: 'hidden', overflowY: 'visible' }}
          className="modal-container max-w-5xl mx-auto rounded z-50 overflow-y-auto"
        >
          <ClickawayListener setState={props.onClose}>
            <div className="modal-content text-left">
              <div
                className="flex justify-end items-end pb-3 / focus:outline-none"
                onClick={props.onClose}
                onKeyDown={props.onClose}
                role="button"
                tabIndex={0}
              >
                <div className="modal-active cursor-pointer z-50" />
              </div>
              {props.children}
            </div>
          </ClickawayListener>
        </div>
      </div>
    </div>
  ) : null
}
