import React from 'react'
import range from 'lodash/range'

export const SkeletonPreview = (props: { n: number }) => {
  return (
    <div>
      {range(props.n).map((idx: number) => (
        <div
          key={idx}
          className="flex flex-col sm:flex-row / py-2 / border-b border-gray-300"
        >
          <div className="c-content-placeholder-bg / w-2/5 sm:w-1/3 h-5 / mb-1 sm:mb-0">
            &nbsp;
          </div>
          <div className="w-full sm:w-2/3 sm:pl-14">
            <div className="c-content-placeholder-bg / w-2/3 sm:w-5/6 h-5">
              &nbsp;
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}
