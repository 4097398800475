import * as React from 'react'
import { CompareCredit } from '../../types'

export function PanelContents({
  children,
  view,
}: {
  children: React.ReactNode
  view: CompareCredit.CardPanelProps['view']
}) {
  const isModalOrDetail = view === 'details' || view === 'modal'
  const isDetails = view === 'details'
  const isModal = view === 'modal'

  return (
    <div
      className={`md:flex md:w-full md:flex-wrap
          ${
            isModalOrDetail
              ? 'md:flex-col / mb-20 / md:px-10 md:pb-2 / lg:px-12 lg:pb-4 / xl:px-16 '
              : 'md:px-6 md:pt-6 / lg:px-8 lg:pt-7 / xl:px-10 '
          } ${isDetails ? 'pb-6 md:pt-8 lg:pt-10 xl:pt-12' : ''} ${
            isModal ? 'pt-0 pb-2 leading-normal' : 'pt-7 xs:pt-10'
          } / px-6 xs:px-8 /
          md:pb-3 lg:pb-0 lg:pb-4 
          `}
    >
      {children}
    </div>
  )
}
