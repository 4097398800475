// FIX IT LATER
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import Image from 'next/image'
import Link from 'next/link'

export const PopularArticles = ({
  popularArticles,
}: {
  popularArticles: { headline: string; slug: string }[]
}) => {
  return (
    <>
      <div className="c-header / relative / w-full-vw / mb-2 md:mb-0 / pb-2 md:pb-4">
        <div className="container-fluid / relative z-10 / pt-3 mb-2.5">
          <div className="c-pop-guides">
            <p className="c-pop-guides__header / mb-1.5 / text-sm sm:text-base font-bold text-slate-700 leading-snug">
              Popular Articles
            </p>
            <div>
              <ul className="flex flex-row flex-wrap / text-fs12 lg:text-fs13 font-semibold">
                {popularArticles.map(({ headline, slug }) => (
                  <li key={slug} className="c-pop-guides__item">
                    <Link
                      href={`/credit-cards/tips/${slug}`}
                      className="flex items-center / px-3 py-1 / rounded-full shadow bg-white / text-gray-700 / hover:shadow-lg hover:text-primary-bright"
                    >
                      {headline}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="c-hero__bot / absolute bottom-0 z-1 / w-full / bg-no-repeat overflow-hidden">
          <div className="h-8 md:h-9 w-full / -mb-px">
            <div className="md:hidden">
              <Image
                alt="background mask"
                fill
                src="/static/images/bg-bot-mask-white-sm.png"
              />
            </div>
            <div className="hidden md:block">
              <Image
                alt="background mask"
                fill
                src="/static/images/bg-bot-mask-white-lg.png"
              />
            </div>
          </div>
        </div>
      </div>
      <style jsx>
        {`
          .c-header {
            background: linear-gradient(92.43deg, #f5fafc 0.53%, #f2fff3 99.9%);
          }
          .c-pop-guides__item {
            margin-right: 0.5rem;
            margin-bottom: 0.25rem;
          }
          .c-pop-guides__item:last-child {
            margin-right: 0;
          }
          @media (min-width: 1024px) {
            .c-article-latest__item {
              margin-right: 1.5rem;
            }
          }
        `}
      </style>
    </>
  )
}
