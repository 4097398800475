import { SORT_CALL_DATA } from '../types'
import { fireNetworkRequest } from '../../../clients/segment'
import { decode } from '../../encode'
import * as Sentry from '@sentry/nextjs'

export async function fetchSorts(data: SORT_CALL_DATA) {
  const timestamp_initiated = performance.now()
  const date_time_initiated = new Date()

  try {
    const response = await fetch(
      `/api/entities/sort/${data.id}/${data.version}/${data.encodedParams}/`,
    )
    const responseJson = await response.json()

    if (response.ok) {
      const timestamp_completed = performance.now()

      fireNetworkRequest({
        timestamp_initiated,
        date_time_initiated,
        timestamp_completed,
        latency: timestamp_completed - timestamp_initiated,
        url: response.url,
        sort: `${data.id}:${data.version}`,
        pathName: window.location.pathname,
        decodedParams: decode(data.encodedParams),
        response_status: response.status,
        response_body: responseJson,
      })

      return responseJson
    } else {
      if (responseJson && Object.keys(responseJson).length === 0) {
        console.log(`Error fetch-sorts response is empty`)
      } else {
        console.log(
          `Error fetch-sorts ${JSON.stringify(
            responseJson ? responseJson : '',
          )}`,
        )
      }

      const errStatusTextResponse =
        response.statusText || 'Error in fetchSorts function'
      throw new Error(errStatusTextResponse)
    }
  } catch (err: any) {
    const timestamp_completed = performance.now()

    fireNetworkRequest({
      timestamp_initiated,
      date_time_initiated,
      timestamp_completed,
      latency: timestamp_completed - timestamp_initiated,
      url: window.location.href,
      sort: `${data.id}:${data.version}`,
      pathName: window.location.pathname,
      decodedParams: decode(data.encodedParams),
      response_status: 500,
      error: err || 'Error in fetchSorts function',
    })

    Sentry.captureException(err, {
      contexts: {
        data: {
          fileName: __filename,
        },
      },
    })
    throw err
  }
}
