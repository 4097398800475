import React from 'react'
import { isMobileOnly } from 'react-device-detect'
import prop from 'lodash/fp/prop'
import propOr from 'lodash/fp/propOr'
import { useStatsigClient } from '@statsig/react-bindings'
import { v4 as uuid } from 'uuid'
import { PortableText } from '@portabletext/react'
import { useExperiment } from 'utils/use-experiment-wrapper'

import { firstIsVowel, nextImageHelper, supify } from '../../../utils'
import { CallToApply } from '../../call-to-apply'
import { CardArt } from '../../card-art'
import { CardRecommendedCredit } from '../../card-recommended-credit'
import { StarRating } from '../../star-rating'
import { LoadingSkeleton } from './loading-skeleton'
import { CompareCredit } from '../../../../types'
import { CustomBadge } from '../../entity-panel/sections'
import { StarRatingEditor } from '../../star-rating-editor'

import Image from 'next/image'
import Link from 'next/link'
import { LinkToRedirect } from '../../link-to-redirect'
import { blockSerializers } from '../../sanity-serializers'
import { SecureSite } from '../../secure-site'
import { clickRatesAndFees } from '../../../clients/segment'
import { CreditCardLinkedData } from 'components/linked-data/card'

export const Card = ({
  clientLocation,
  modalOpen,
  referencedCards,
  setModalCard,
  setModalOpen,
  tag,
  categoryTag,
  tip,
  cardOrderIds,
  position,
  modalTriggerPosition,
  recommendedCredit,
  exitModalRef,
  arrangementId,
}: {
  clientLocation: any
  modalOpen: any
  referencedCards: null | Record<string, CompareCredit.Entities>
  setModalCard: any
  setModalOpen: any
  tag: any
  categoryTag: any
  tip: any
  cardOrderIds: null | Record<string, string>
  position: number
  modalTriggerPosition?: number
  recommendedCredit?: boolean
  exitModalRef?: React.RefObject<any>
  arrangementId?: string
}) => {
  const [orderId, setOrderId] = React.useState<string | null>(null)
  const [currentSlug, setCurrentSlug] = React.useState<string>('')
  const { logEvent } = useStatsigClient()

  React.useEffect(() => {
    const id = prop(tip.cardSlug, cardOrderIds) || uuid()
    setOrderId(id)
  }, [])

  const externalId = tag ? tag.id : '00'
  const categoryId = categoryTag ? categoryTag.id : externalId

  const card = propOr(
    null,
    tip.cardSlug,
    referencedCards,
  ) as CompareCredit.Entities
  const isCard = card && card._type === 'card'
  const issuerName = isCard ? card.issuer.name : ''
  const issuerSecureSite = isCard ? card.issuerSecureSite : null
  const {
    buttonText,
    linkText,
    content: tipContent,
    tipContentBlockDisplay,
    tipContentBlockSelected,
  } = tip

  const [readToggle, setReadToggle] = React.useState(false)
  const handleSummaryToggle = () => {
    setReadToggle(!readToggle)
    if (readToggle == true) {
      logEvent('truncation_read_more_closed')
    } else {
      logEvent('truncation_read_more_open')
    }
  }

  const handleTruncationCtaPdp = () => {
    logEvent('truncation_cta_pdp')
  }

  React.useEffect(() => {
    const getCurrentSlug = () => {
      if (card) {
        const currentSlug =
          card._type === 'product'
            ? card?.brand?.slug?.current
            : card?.issuer?.slug?.current

        return currentSlug || ''
      }
      return ''
    }

    const currentSlug = getCurrentSlug()
    setCurrentSlug(currentSlug)
  }, [card])

  // 2024.9: EXP-310 Category Page User Review Count v2
  const { experiment: configExp308, isLoading: isLoadingExp308 } =
    useExperiment('exp_308_cc_score_mc_tip')
  const variationExp308 = configExp308?.value?.name || 'control'

  return (
    <>
      {card && (card._type === 'card' || card._type === 'nonPaidCard') ? (
        <div
          id={'card-' + card.slug}
          className={`c-card-tile__wrap / inline-block w-full py-3 lg:py-4`}
          key={card.slug}
        >
          <h2
            className={`c-card-tile__header / text-xl font-bold font-sans text-center md:text-left leading-tight
              ${card.customBadge ? 'mb-14 md:mb-9' : 'mb-5 md:mb-3'}`}
            ref={position === modalTriggerPosition ? exitModalRef : null}
          >
            {card._type === 'card' ? (
              <LinkToRedirect
                externalId={externalId}
                linkParams={card.applyNowLinkParameters}
                orderId={orderId}
                position={position}
                slug={card.slug}
              >
                <span
                  className="text-primary-mid / hover:text-primary-bright"
                  dangerouslySetInnerHTML={{
                    __html: supify(card.name),
                  }}
                />
              </LinkToRedirect>
            ) : (
              <Link href={card.moreInfoLink}>
                <span
                  className="text-primary-mid / hover:text-primary-bright"
                  dangerouslySetInnerHTML={{
                    __html: supify(card.name),
                  }}
                />
              </Link>
            )}
          </h2>

          <div
            className="c-card-tile / relative / flex flex-row items-start flex-wrap /
            max-w-md md:w-72 / md:float-left /
            mb-6 mx-auto md:mr-10 -mt-3 md:mt-8 /
            px-4 xs:px-5 md:px-6 / pb-3 pt-3 xs:pt-4 sm:pt-3 md:pt-0 /
            bg-white shadow-lg rounded-lg leading-normal"
          >
            {card.customBadge && (
              <div className="c-card-tile__badge / absolute left-0 right-0 / mx-auto -mt-12 md:-mt-14">
                <CustomBadge text={card.customBadge} theme="center" />
              </div>
            )}
            <div className="c-card-art / text-center flex items-center justify-center / xxs:w-28pct md:w-full md:-mt-6 / mb-3 sm:mb-2 w-1/4">
              <div className="relative / w-full / rounded-sm shadow / overflow-hidden">
                {isCard ? (
                  <LinkToRedirect
                    externalId={externalId}
                    linkParams={card.applyNowLinkParameters}
                    orderId={orderId}
                    position={position}
                    slug={card.slug}
                  >
                    <div className="relative flex rounded-sm overflow-hidden">
                      <span className="c-card-art__content / absolute z-10 left-0 / flex flex-wrap content-center items-center / w-full h-full text-white">
                        <span className="c-card-art__icon / relative / h-8 w-full / mb-2">
                          <Image
                            alt="Lock Icon"
                            src="/static/icons/icon-lock-round-w.svg"
                            fill
                            sizes="100vw"
                          />
                        </span>
                        <span className="w-full font-bold">
                          {buttonText || 'Apply Now'}
                        </span>
                      </span>
                      <CardArt
                        _rev={card._rev}
                        cardArt={card.cardArt}
                        imgClasses="relative z-0 w-full h-full object-cover top-0"
                        customCodeSnippets={card.customCodeSnippets}
                        issuer={currentSlug}
                        name={card.name}
                        orderId={orderId}
                        slug={card.slug}
                        categoryId={categoryId}
                        externalId={externalId}
                        position={position}
                        sortable
                        sort={card?.context?.sort}
                        arrangementId={arrangementId}
                      />
                    </div>
                  </LinkToRedirect>
                ) : (
                  <Link href={card.moreInfoLink}>
                    <div className="relative flex rounded-sm overflow-hidden">
                      <span className="c-card-art__content / absolute z-10 left-0 / flex flex-wrap content-center items-center / w-full h-full text-white">
                        <span className="w-full font-bold">Learn More</span>
                      </span>
                      <CardArt
                        _rev={card._rev}
                        cardArt={card.cardArt}
                        imgClasses="relative z-0 w-full h-full object-cover top-0"
                        customCodeSnippets={card.customCodeSnippets}
                        issuer={currentSlug}
                        name={card.name}
                        orderId={orderId}
                        slug={card.slug}
                        categoryId={categoryId}
                        externalId={externalId}
                        position={position}
                        sortable
                        arrangementId={arrangementId}
                      />
                    </div>
                  </Link>
                )}
              </div>
            </div>
            <div className="flex flex-col items-start justify-center md:items-center md:justify-center / w-3/4 xxs:w-72pct md:w-full / pl-2 xxs:pl-3 md:pl-0 / pt-1.5 xs:pt-2.5 md:pt-0">
              {!isLoadingExp308 &&
              isCard &&
              variationExp308 == 'v1-cc-number-rating' ? (
                <div className="w-full">
                  <StarRatingEditor
                    theme="t-mc-tips"
                    column={true}
                    marginBottom="mb-1"
                    productSlug={card.slug}
                    starsEditor={card.expertReviewRating}
                    userAvgRating={card.visitorReviewsAverageRating}
                    visitorReviewsTotalCount={card.visitorReviewsTotalCount}
                  />
                </div>
              ) : (
                <StarRating
                  column
                  productSlug={card.slug}
                  stars={card.expertReviewRating}
                  userAvgRating={card.visitorReviewsAverageRating}
                  visitorReviewsTotalCount={card.visitorReviewsTotalCount}
                  marginBottom="mb-0.5"
                />
              )}
            </div>

            {recommendedCredit ? (
              <div className="flex items-stretch justify-center / w-full mb-2.5 / text-center">
                <CardRecommendedCredit card={card} maxW="max-w-none" />
              </div>
            ) : null}
            <div
              className={`w-full / ${
                isCard &&
                card.phoneNumber &&
                isMobileOnly &&
                clientLocation.country === 'US'
                  ? 'mb-1.5'
                  : 'mb-2'
              }`}
            >
              {isCard ? (
                <LinkToRedirect
                  externalId={externalId}
                  linkParams={card.applyNowLinkParameters}
                  orderId={orderId}
                  position={position}
                  slug={card.slug}
                >
                  <div className="c-btn c-btn--primary / inline-block / w-full max-w-none md:max-w-xs py-1.5 / text-center text-lg">
                    {buttonText || 'Apply Now'}
                    <span className="c-btn__icon / relative top-0.5 / inline-block / h-4 w-4 / ml-2">
                      <Image
                        alt="Lock Icon"
                        src="/static/icons/icon-lock-round-w.svg"
                        fill
                        sizes="100vw"
                      />
                    </span>
                  </div>
                </LinkToRedirect>
              ) : (
                <Link href={card.moreInfoLink}>
                  <div
                    className={`c-btn c-btn--secondary  / inline-block / w-full max-w-none md:max-w-xs py-1.5 / text-center text-lg / trk_card-detail_cta trk_card-detail_cta_${card.slug}`}
                  >
                    Learn More
                  </div>
                </Link>
              )}
            </div>
            <p className="w-full / text-fs13 text-gray-600 / text-center leading-tight / mb-2.5">
              {isCard && (
                <SecureSite
                  issuerName={issuerName}
                  issuerSecureSite={issuerSecureSite}
                />
              )}
            </p>
            {isCard &&
              card.phoneNumber &&
              isMobileOnly &&
              clientLocation.country === 'US' && (
                <div className="w-full mt-3">
                  <p className="mb-3 text-center">
                    <CallToApply
                      classes="max-w-xs"
                      entity={card as CompareCredit.FormattedCard}
                    />
                  </p>

                  <p className="text-xs text-gray-600 / text-center / -mt-1 mb-4 / md:self-start w-full max-w-xs leading-4">
                    with {firstIsVowel(issuerName) ? 'an' : 'a'} {issuerName}{' '}
                    representative
                  </p>
                </div>
              )}
            <hr className="mb-1 / border-b-2 mx-auto w-16 hidden" />
            {isCard && card.ratesAndFees && card.ratesAndFees !== 'N/A' && (
              <div className="w-full mb-1 / text-center leading-tight">
                <a
                  className="text-primary-bright text-xs"
                  href={card.ratesAndFees}
                  onClick={() => {
                    clickRatesAndFees({
                      applyNowLink: card.applyNowLink,
                      component: 'Card',
                      name: card.name,
                      url: window.location.href,
                    })
                  }}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Rates &amp; Fees
                </a>
                <>
                  {card.issuer.slug.current === 'american-express' && (
                    <p className="text-fs10 text-gray-600 / text-center / mb-3 md:mb-1 / w-full leading-4">
                      Terms Apply
                    </p>
                  )}
                </>
              </div>
            )}
            <div className="w-full / text-center cursor-pointer">
              <button
                onClick={() => {
                  setModalCard(card)
                  setModalOpen(!modalOpen)
                }}
                onKeyDown={() => {
                  setModalCard(card)
                  setModalOpen(!modalOpen)
                }}
                tabIndex={0}
                className="flex w-full items-center justify-center / py-1 / text-center text-gray-600 text-fs13 / hover:text-primary-bright focus:outline-none"
              >
                More Details
                <span className="relative / h-3 w-3 / ml-2 opacity-50">
                  <Image
                    alt="Expand Plus Icon"
                    src="/static/icons/icon-plus-black.svg"
                    fill
                    sizes="100vw"
                  />
                </span>
              </button>
            </div>
          </div>

          {isMobileOnly ? (
            <div>
              <div
                className={`c-card-content / c-slidedown / relative md:contents ${
                  readToggle ? '' : 'c-slidedown--hidden'
                }`}
              >
                {referencedCards &&
                  tip &&
                  (tipContentBlockDisplay
                    ? tipContentBlockSelected.content.map(
                        (contentBlock: any, idx: number) => {
                          return (
                            <PortableText
                              key={idx}
                              value={contentBlock}
                              components={blockSerializers({
                                cardOrderIds,
                                categoryId,
                                externalId,
                                referencedCards,
                              })}
                            />
                          )
                        },
                      )
                    : tipContent.map((content: any, idx: number) => {
                        return (
                          <PortableText
                            key={idx}
                            value={content}
                            components={blockSerializers({
                              cardOrderIds,
                              categoryId,
                              externalId,
                              referencedCards,
                            })}
                          />
                        )
                      }))}

                <div className="flex flex-col items-start justify-start / mb-5 / md:hidden">
                  <Link
                    href={`/credit-cards/${card.issuer.slug.current}/${card.slug}`}
                  >
                    <button
                      className="c-btn c-btn--secondary / inline-block / px-6 / text-white leading-snug text-inherit / hover:bg-primary-mid"
                      onClick={handleTruncationCtaPdp}
                    >
                      See More Details
                    </button>
                  </Link>
                </div>
                <div
                  className={`c-summary__bg-gradient / absolute z-2 / bottom-0 left-0 / w-full h-1/3 / bg-gradient-to-t from-white / opacity-90 / md:hidden ${
                    readToggle ? 'hidden' : null
                  }`}
                ></div>
              </div>

              <button
                className="flex flex-row items-center / text-sm text-primary-bright-dark font-semibold / hover:text-primary-bright hover:underline / md:hidden"
                onClick={handleSummaryToggle}
                aria-label={`${readToggle ? 'Show' : 'Hide'} summary`}
                aria-expanded={readToggle ? false : true}
                aria-controls="our-pick-summary"
              >
                {readToggle ? 'Read Less' : 'Read More'}{' '}
                {readToggle ? (
                  <span className="inline-block w-4 ml-1">
                    <Image
                      alt="expand"
                      src="/static/icons/icon-minus-primary-bright.svg"
                      height={24}
                      width={24}
                      aria-hidden={true}
                      style={nextImageHelper.responsive}
                      sizes="100vw"
                    />
                  </span>
                ) : (
                  <span className="inline-block w-4 ml-1">
                    <Image
                      alt="expand"
                      src="/static/icons/icon-plus-primary-bright.svg"
                      height={24}
                      width={24}
                      aria-hidden={true}
                      style={nextImageHelper.responsive}
                      sizes="100vw"
                    />
                  </span>
                )}
              </button>
            </div>
          ) : (
            <div>
              {referencedCards &&
                tip &&
                (tipContentBlockDisplay
                  ? tipContentBlockSelected.content.map(
                      (contentBlock: any, idx: number) => {
                        return (
                          <PortableText
                            key={idx}
                            value={contentBlock}
                            components={blockSerializers({
                              cardOrderIds,
                              categoryId,
                              externalId,
                              referencedCards,
                            })}
                          />
                        )
                      },
                    )
                  : tipContent.map((content: any, idx: number) => {
                      return (
                        <PortableText
                          key={idx}
                          value={content}
                          components={blockSerializers({
                            cardOrderIds,
                            categoryId,
                            externalId,
                            referencedCards,
                          })}
                        />
                      )
                    }))}
            </div>
          )}
          <CreditCardLinkedData card={card} />
        </div>
      ) : (card as CompareCredit.ProductGeneric) && card._type === 'product' ? (
        <div
          className={`c-card-tile__wrap / inline-block w-full py-3 lg:py-4`}
          key={card.slug}
        >
          <h2
            className={`
              c-card-tile__header / text-xl font-bold font-sans text-center md:text-left leading-tight mb-12 md:mb-4
              
              `}
            ref={position === modalTriggerPosition ? exitModalRef : null}
          >
            <LinkToRedirect
              externalId={externalId}
              linkParams={card.linkParameters}
              orderId={orderId}
              slug={card.slug}
            >
              <div className="text-primary-mid / hover:text-primary-bright">
                {card.name}
                <sup>&trade;</sup>
              </div>
            </LinkToRedirect>
          </h2>
          <div className="c-card-tile / relative m-auto max-w-xs md:w-72 md:float-left / mb-6 md:mr-10 md:mt-8 / px-6 py-5 / bg-white shadow-lg rounded-lg">
            <div className="c-card-tile__logo / w-10/12 / mb-3 mx-auto">
              <LinkToRedirect
                externalId={externalId}
                linkParams={card.linkParameters}
                orderId={orderId}
                slug={card.slug}
              >
                <div>
                  <CardArt
                    _rev={card._rev}
                    cardArt={card.image}
                    imgClasses="relative z-0 w-full h-full object-cover top-0"
                    issuer={currentSlug}
                    name={card.name}
                    orderId={orderId}
                    slug={card.slug}
                    categoryId={categoryId}
                    externalId={externalId}
                    position={position}
                    sortable
                    sort={card?.context?.sort}
                    arrangementId={arrangementId}
                  />
                </div>
              </LinkToRedirect>
            </div>

            <LinkToRedirect
              externalId={externalId}
              linkParams={card.linkParameters}
              orderId={orderId}
              slug={card.slug}
            >
              <div className="c-btn c-btn--primary max-w-xs py-1 text-lg">
                {linkText ? (
                  <>
                    <span>{linkText}</span>
                    <span className="c-btn__icon / relative top-0.5 / inline-block / h-4 w-4 / ml-2">
                      <Image
                        alt="Lock Icon"
                        src="/static/icons/icon-lock-round-w.svg"
                        fill
                        sizes="100vw"
                      />
                    </span>
                  </>
                ) : (
                  'Sign Up'
                )}
              </div>
            </LinkToRedirect>
          </div>
          {referencedCards &&
            tip &&
            tip.content &&
            tip.content.map((content: any, idx: number) => {
              return (
                <PortableText
                  key={idx}
                  value={content}
                  components={blockSerializers({
                    cardOrderIds,
                    categoryId,
                    externalId,
                    referencedCards,
                  })}
                />
              )
            })}
        </div>
      ) : (
        <LoadingSkeleton n={1} />
      )}

      <style jsx>{`
        .text-inherit {
          font-size: inherit;
        }
        .c-card-tile__logo {
          max-width: 11rem;
        }
        .c-slidedown.c-slidedown--hidden {
          min-height: 14rem;
          max-height: 14rem;
          opacity: 1;
        }
        @media (min-width: 768px) {
          .c-slidedown,
          .c-slidedown.c-slidedown--hidden {
            height: auto;
            min-height: auto;
            max-height: none;
            opacity: 1;
          }
        }
      `}</style>
    </>
  )
}
