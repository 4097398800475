import React from 'react'
import * as Sentry from '@sentry/nextjs'

export function NoOffers() {
  React.useEffect(() => {
    Sentry.captureException(new Error('No offers error shown'))
  }, [])
  return (
    <h2 className="text-2xl lg:text-3xl font-serif font-bold leading-tight mt-12 mb-24 text-center text-primary">
      No offers at this time. Check back soon!
    </h2>
  )
}
