import * as React from 'react'
import pathOr from 'lodash/fp/pathOr'
import propOr from 'lodash/fp/propOr'
import Image from 'next/image'
import { PortableText, PortableTextBlockComponent } from '@portabletext/react'

import { supify, nextImageHelper } from '../../../../utils'
import { CompareCredit } from '../../types'

const ICON_MAP: { [key: string]: string } = {
  stopwatch: '/static/icons/icon-stopwatch-primary.svg',
  cash: '/static/icons/icon-cash-primary.svg',
  present: '/static/icons/icon-present-primary.svg',
  plane: '/static/icons/icon-plane-primary.svg',
  plate: '/static/icons/icon-dinner-plate-primary.svg',
  calendar: '/static/icons/icon-calendar-primary.svg',
  'credit-cards': '/static/icons/icon-credit-cards-primary.svg',
  suitcase: '/static/icons/icon-suitcase-primary.svg',
}
export function CurrentOffer({
  view,
  card,
}: {
  view: CompareCredit.CardPanelProps['view']
  card: CompareCredit.CardPanelTypes
}) {
  const Normal = ({ children }: { children: any }) => <span>{children}</span>

  const AttributeRenderer = (attrProps: { value: { attribute: string } }) => {
    const { attribute } = attrProps.value
    // @ts-ignore
    return React.createElement('span', {
      dangerouslySetInnerHTML: {
        __html: supify(propOr('', attribute, card)),
      },
    })
  }
  const highlight = pathOr(null, ['highlight', 0], card)

  return view === 'details' && highlight ? (
    <div className="w-full / mb-4 lg:mb-0">
      <div className="flex / px-4 pt-2.5 pb-3 / rounded-sm bg-primary-light-bg">
        <span className="flex shrink-0 w-6 mr-3">
          <div className="inline-block w-6 h-6">
            <Image
              alt=""
              height={24}
              width={24}
              src={ICON_MAP[highlight.icon]}
              style={nextImageHelper.intrinsic}
            />
          </div>
        </span>
        <span className="text-sm text-gray-700">
          <b className="uppercase font-bold text-primary">{highlight.title}</b>
          <br />
          <PortableText
            value={highlight.description}
            components={{
              block: { normal: Normal as PortableTextBlockComponent },
              types: {
                cardAttribute: AttributeRenderer,
              },
            }}
          />
        </span>
      </div>
    </div>
  ) : null
}
