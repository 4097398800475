import * as React from 'react'
import Image from 'next/image'
import { nextImageHelper } from 'utils/'

export default function MinimalNav() {
  return (
    <div className="c-nav-main__as-seen">
      <div className="w-full">
        <p className="mb-1 / font-semibold uppercase text-fs13 text-gray-600 text-center leading-tight">
          As Seen In
        </p>
        <ul className="flex items-center justify-around / w-[480px] / mx-auto text-center h-auto">
          <li className="flex justify-center items-center">
            <a
              href="https://www.inc.com/profile/compare-credit"
              target="_blank"
              rel="noreferrer"
              aria-label="visit inc 5000"
              className="w-10 opacity-50 hover:opacity-100"
            >
              <Image
                src="/static/as-seen-on/badge-inc-5000-bw.png"
                height={72}
                width={72}
                alt="INC 5000"
                aria-hidden={true}
                style={nextImageHelper.responsive}
                sizes="100vw"
                className="c-nav-main__as-seen-inc-5000"
              />
            </a>
          </li>
          <li>
            <div className="inline-block w-12">
              <Image
                src="/static/as-seen-on/wsj-mark.svg"
                alt="Wall Street Journall logo"
                height={34}
                width={64}
                style={nextImageHelper.responsive}
                sizes="100vw"
              />
            </div>
          </li>
          <li>
            <div className="inline-block w-16">
              <Image
                src="/static/as-seen-on/forbes.svg"
                alt="Forbes logo"
                height={23}
                width={84}
                style={nextImageHelper.responsive}
                sizes="100vw"
              />
            </div>
          </li>
          <li>
            <div className="inline-block w-16">
              <Image
                src="/static/as-seen-on/pbs.svg"
                alt="PBS logo"
                height={48}
                width={106}
                style={nextImageHelper.responsive}
                sizes="100vw"
              />
            </div>
          </li>
          <li>
            <div className="inline-block w-24">
              <Image
                src="/static/as-seen-on/usa-today.svg"
                alt="Usa Today logo"
                height={26}
                width={141}
                style={nextImageHelper.responsive}
                sizes="100vw"
              />
            </div>
          </li>
          <li>
            <div className="inline-block w-12 m-auto">
              <Image
                src="/static/as-seen-on/cnn.svg"
                alt="CNN logo"
                height={26}
                width={54}
                style={nextImageHelper.responsive}
                sizes="100vw"
              />
            </div>
          </li>
        </ul>
      </div>
    </div>
  )
}
