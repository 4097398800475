import * as React from 'react'
import Link from 'next/link'
import { UserContext } from '../UserContext'

export default function NavLink(props: {
  text: string
  children?: any
  href: string
  i?: number
  menu?: string
}) {
  const { text } = props
  const [hover, toggleHover] = React.useState(false)

  // DEV NOTE: Variable to display the nav upsell card
  const { navUpsellCard } = React.useContext(UserContext)

  return (
    <li
      className={`c-nav-link relative / flex items-center flex-row / rounded py-4 px-4 nav-link ml-2 / text-primary / hover:bg-primary-light-bg ${
        navUpsellCard ? 'c-nav-link--upsell' : ''
      }`}
      onMouseEnter={() => toggleHover(true)}
      onMouseLeave={() => toggleHover(false)}
      aria-controls={`${props.menu}-dropdown`}
    >
      <div
        className="flex items-center justify-center"
        onClick={() => toggleHover(false)}
        role="button"
        aria-hidden="true"
      >
        <Link
          href={props.href}
          className={`c-nav-dropdown-link / relative ${
            hover ? 'c-arrow-css' : ''
          }`}
        >
          <span className="cursor-pointer flex items-center">
            <span className="c-nav-link__text font-semibold text-fs13 xl:text-sm">
              {text}
            </span>
            {props.children && (
              <span
                className={`c-icon-arrow transition-all ${
                  hover ? 'rotate-180' : ''
                } `}
              />
            )}
          </span>
        </Link>

        {hover && (
          <div
            id={`${props.menu}-dropdown`}
            className="c-nav-dropdown absolute z-10 w-full"
            style={{
              top: '100%',
            }}
          >
            <div className="c-nav-dropdown__inner / relative rounded bg-white shadow-lg / flex w-full">
              {props.children}
            </div>
          </div>
        )}
      </div>
      <style jsx>{`
        /* ====== ICON ARROW ====== */
        .c-icon-arrow {
          display: inline-block;
          width: 0.75rem;
          height: 0.75rem;
          background-image: url('/static/icons/icon-chevron-d-primary.svg');
          background-size: auto 100%;
          background-position: center center;
          background-repeat: no-repeat;
        }

        /* ====== NAV-LINK ====== */
        .c-nav-link {
          transition: background-color 0.25s ease;
        }
        .c-nav-link:nth-child(2),
        .c-nav-link:nth-child(3),
        .c-nav-link:nth-child(4) {
          position: relative;
        }
        .c-nav-link__text {
          margin-right: 0.5rem;
        }

        /* ====== NAV-DROPDOWN ====== */
        .c-nav-dropdown {
          margin-left: auto;
          margin-right: auto;
          padding-left: 2rem;
          padding-right: 2rem;
          margin-top: -0.2rem;
        }
        .c-nav-link:first-child {
          position: relative;
        }
        .c-nav-link:first-child .c-nav-dropdown {
          left: -22rem;
          right: 0;
          width: 52rem;
          padding-top: 0.8rem;
        }

        /* ====== UPSELL VARIANT ====== */
        .c-nav-link--upsell:first-child {
          position: static;
        }
        .c-nav-link--upsell:first-child .c-nav-dropdown {
          left: 0;
          max-width: 72rem;
          width: auto;
          margin-top: -1.1rem;
        }

        .c-nav-link:nth-child(2) .c-nav-dropdown {
          left: 0;
          right: 0;
          max-width: 52rem;
        }
        .c-nav-link:nth-child(2) .c-nav-dropdown,
        .c-nav-link:nth-child(3) .c-nav-dropdown,
        .c-nav-link:nth-child(4) .c-nav-dropdown {
          padding-top: 0.8rem;
          width: auto;
        }

        .c-nav-link:nth-child(2) .c-nav-dropdown {
          left: -20rem;
          min-width: 48rem;
        }
        .c-nav-link:nth-child(3) .c-nav-dropdown {
          min-width: 18rem;
        }
        .c-nav-link:nth-child(4) .c-nav-dropdown {
          min-width: 22rem;
        }

        @media (min-width: 1152px) {
          .c-nav-dropdown {
            margin-top: -0.1rem;
            padding-left: 1rem;
            padding-right: 1rem;
          }
          .c-nav-link:first-child .c-nav-dropdown {
            left: -21.5rem;
          }
          .c-nav-link--upsell:first-child {
            position: relative;
          }
          .c-nav-link--upsell:first-child .c-nav-dropdown {
            width: 1152px;
            left: auto;
            right: -28.6rem;
            margin-top: -3px;
          }
        }
      `}</style>
    </li>
  )
}
