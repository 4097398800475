import React from 'react'
import Link from 'next/link'
import { Select } from '../select'
import { SelectItem } from '../select/item'
import cn from 'utils/classnames'

export const navOptionsBusiness = [
  {
    text: 'Top Credit Cards',
    value: 'best',
    href: '/credit-cards/business/best',
    slug: 'business_best',
  },
  {
    text: 'Cash Back',
    value: 'cash-back',
    href: '/credit-cards/business/cash-back',
    slug: 'business_cash-back',
  },
  {
    text: 'Travel',
    value: 'travel',
    href: '/credit-cards/business/travel',
    slug: 'business_travel',
  },
  {
    text: '0% Intro APR',
    value: '0-intro-apr',
    href: '/credit-cards/business/0-intro-apr',
    slug: 'business_0-intro-apr',
  },
  {
    text: 'No Annual Fee',
    value: 'no-annual-fee',
    href: '/credit-cards/business/no-annual-fee',
    slug: 'business_no-annual-fee',
  },
]

export function BusinessNav(props: { currentPage: string }) {
  return (
    <div
      className="business-nav relative pt-4 pb-5 lg:pb-4"
      style={{ backgroundColor: '#F5FAFC' }}
    >
      <div className="container-fluid">
        <h2 className="mb-1 md:mb-2 lg:pl-2 / text-center lg:text-left text-gray-700 uppercase text-sm font-bold font-sans">
          Business Categories
        </h2>
        <div className="md:hidden">
          <Select value={props.currentPage}>
            {navOptionsBusiness.map((item, i) => (
              <SelectItem key={i} value={item.value} href={item.href}>
                {item.text}
              </SelectItem>
            ))}
          </Select>
        </div>
        <div className="hidden md:block / -mx-1 lg:-mx-3 xl:-mx-4">
          <div className="flex flex-row mx-auto w-full">
            {navOptionsBusiness.map((item, i) => (
              <div
                className="px-1 lg:px-3 xl:px-4 cursor-pointer"
                style={{
                  width: `${(1 / navOptionsBusiness.length) * 100}%`,
                  borderRadius: 18,
                }}
                key={i}
              >
                <Link href={item.href}>
                  <span
                    className={cn(
                      'inline-block w-full text-center p-2 / text-[13px] lg:text-sm font-semibold / border border-transparent rounded-full shadow transition-all',
                      {
                        'bg-primary-bright text-white / hover:text-white hover:bg-primary-bright':
                          props.currentPage === item.value,
                        'bg-white text-gray-600 / hover:text-primary-bright hover:border-primary-light hover:shadow-lg':
                          props.currentPage !== item.value,
                      },
                    )}
                  >
                    {item.text}
                  </span>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
      <style jsx>{`
        .business-nav {
          width: 100vw;
          position: relative;
          left: 50%;
          right: 50%;
          margin-left: -50vw;
          margin-right: -50vw;
        }
      `}</style>
    </div>
  )
}
