import React from 'react'
import Image from 'next/image'
import { createClient } from '@sanity/client'
import { CompareCredit } from '../../../types'
import { useFetchCardsSlug } from 'utils/use-fetch-cards-slug'
import { useNextSanityImage } from 'next-sanity-image'
import { AuthorCredentials } from './author-credentials'
import { TipAuthorReviewerSocial } from './author-social'
import { CardTile } from './card-tile/index'
import { TipsList } from './tips-list'
import { v4 as uuid } from 'uuid'
import { CardTileLoadingSkeleton } from './card-tile/loading-skeleton'
import { nextImageHelper } from 'utils/'
import cn from 'utils/classnames'

const client: any = createClient({
  useProjectHostname: false,
  projectId: process.env.SANITY_PROJECT_ID,
  dataset: process.env.SANITY_DATA_SET,
  apiVersion: '1',
  useCdn: true,
})

export function Author({
  author,
  tips,
}: {
  author: CompareCredit.Author
  tips: {
    articles: CompareCredit.HubTipsList[]
    reviews: CompareCredit.HubTipsList[]
  }
}) {
  const { cards } = useFetchCardsSlug([author.featuredCard?.slug])
  const [card] = cards
  const { height, src, width }: any = useNextSanityImage(client, author.image)

  const [orderId, setOrderId] = React.useState<string | null>(null)
  React.useEffect(() => {
    const id = uuid()
    setOrderId(id)
  }, [])

  return (
    <>
      <section
        className={cn(
          'relative z-2 / flex flex-col md:flex-row md:flex-wrap md:items-start / w-full / pt-3 md:pt-4 lg:pt-6 xl:px-4 /',
          {
            'pb-4': tips.articles.length === 0 && tips.reviews.length === 0,
          },
        )}
      >
        <article className="w-full md:w-2/3 lg:w-72pct / md:pr-12 lg:pr-16">
          <header className="relative z-2 / mb-14">
            <div className="c-author__byline / relative z-2 / flex flex-row md:items-center / max-w-lg md:max-w-none / mx-auto mb-2 md:mb-4 lg:mb-2">
              <span className="inline-block / w-12 h-12 xs:w-20 xs:h-20 md:w-28 md:h-28 lg:w-40 lg:h-40 xl:w-44 xl:h-44 / shrink-0 / mr-4 lg:mr-8 xl:mr-6 / bg-gray-300 rounded-full shadow-lg / overflow-hidden">
                <Image
                  src={src}
                  height={height}
                  width={width}
                  alt={author.name}
                  aria-hidden={true}
                  style={nextImageHelper.responsive}
                  sizes="100vw"
                  placeholder="blur"
                  blurDataURL={author.image.metadata.lqip}
                />
              </span>
              <div>
                <h1 className="font-serif text-2xl xs:text-3xl lg:text-4xl xl:text-5xl text-primary font-bold">
                  {author.name}
                </h1>
                <p className="mb-2 lg:mb-4 / text-gray-600 uppercase text-fs13 lg:text-sm xl:text-lg font-semibold leading-tight tracking-widest">
                  {author.title}
                </p>
                <hr className="w-20 xs:w-28 / mb-2 lg:mb-4 / border-2 lg:border-3 border-tetriary" />
                <TipAuthorReviewerSocial
                  linkedinUrl={author.linkedinUrl}
                  twitterUrl={author.twitterUrl}
                />
              </div>
            </div>
            <div className="c-author__cred / relative z-2 / max-w-lg md:max-w-none / mx-auto / lg:pl-48">
              <p className="mb-4 / text-xl xs:text-2xl md:text-xl lg:text-2xl text-primary font-serif italic font-bold leading-snug">
                {author.quote}
              </p>
              <p className="mb-6 / text-sm lg:text-base text-gray-800 leading-relaxed">
                {author.description}
              </p>
              <div className="md:hidden">
                <AuthorCredentials
                  education={author.education}
                  expertise={author.expertise}
                />
                {!card && author.featuredCard?.slug && (
                  <CardTileLoadingSkeleton />
                )}
                {card && (
                  <CardTile
                    orderId={orderId}
                    card={card as CompareCredit.FormattedCard}
                    authorName={author.name}
                    featuredCardQuote={author.featuredCardQuote}
                  />
                )}
              </div>
            </div>
          </header>
          {(tips.articles.length > 0 || tips.reviews.length > 0) && (
            <div className="max-w-lg md:max-w-none / mb-16 mx-auto">
              <h2 className="mb-4 / text-2xl text-primary-mid font-bold">
                Latest Posts
              </h2>
              <TipsList tips={tips} />
            </div>
          )}
        </article>

        <aside className="w-1/3 lg:w-28pct / pl-2 lg:pl-0 pt-32 / hidden md:block">
          <AuthorCredentials
            education={author.education}
            expertise={author.expertise}
          />
          {!card && author.featuredCard?.slug && <CardTileLoadingSkeleton />}
          {card && (
            <CardTile
              orderId={orderId}
              card={card as CompareCredit.FormattedCard}
              authorName={author.name}
              featuredCardQuote={author.featuredCardQuote}
            />
          )}
        </aside>
      </section>
      <div className="c-bg-wave__bot / absolute z-1 top-0 left-0 / w-full-vw h-48 xs:h-56 md:h-64 lg:h-72 xl:h-80 / bg-gradient-blue-green-270 overflow-hidden">
        <img
          src="/static/images/bg-bot-mask-white-sm.png"
          className="c-bg-wave__bg-mask / h-10 sm:h-16 / md:hidden"
          alt="background mask"
          aria-hidden={true}
        />
        <img
          src="/static/images/bg-bot-mask-white-lg.png"
          className="c-bg-wave__bg-mask / h-18 / hidden md:block"
          alt="background mask"
          aria-hidden={true}
        />
      </div>
      <style jsx>{`
        /*===== BG WAVE =====*/
        .c-bg-wave__bot {
          position: absolute;
        }
        .c-bg-wave__bg-mask {
          position: absolute;
          bottom: 0;
          width: 100%;
          margin-bottom: -1px;
        }
      `}</style>
    </>
  )
}
