import React from 'react'
import isEmpty from 'lodash/fp/isEmpty'
import propOr from 'lodash/fp/propOr'
import { CompareCredit } from '../../../../types/compare-credit'
import { CardSkeleton } from './card-skeleton'
import { CardTile } from './card-tile'

/* eslint-disable react/display-name */
export const CardRecommendedList =
  (data: {
    cardOrderIds: null | Record<string, string>
    referencedCards: null | Record<string, CompareCredit.Entities>
  }) =>
  (props: {
    value: {
      cardTips: CompareCredit.Tip[]
      tag: CompareCredit.CategoryTag
      categoryTag: CompareCredit.CategoryTag
      title: string
      subtitle: string
      border?: boolean
      pinnedCardTips?: CompareCredit.PinnedProduct[]
      cardOrderIds: null | Record<string, string>
    }
  }) => {
    const { tag, categoryTag } = props.value

    const externalId = tag ? tag.id : '00'
    const categoryId = categoryTag ? categoryTag.id : '00'

    const { referencedCards } = data

    // 1. CARDS
    const cardSlugs = props.value.cardTips.map((cardTip) => cardTip.card.slug)
    const cards = isEmpty(referencedCards)
      ? []
      : cardSlugs
          .map((slug) => propOr(null, slug, referencedCards))
          .filter((x) => x)

    const cardTipsMapping = props.value.cardTips.reduce(
      (acc: Record<string, CompareCredit.Tip>, val: CompareCredit.Tip) => {
        acc[val.card.slug] = val
        return acc
      },
      {},
    )

    return (
      <section
        className={`c-card-rec / flex flex-col justify-center items-center / mb-8 md:mb-10 lg:mb-12`}
      >
        <h3
          className={`c-card-rec__title / w-full / text-center font-serif text-2xl md:text-3xl font-bold text-primary leading-tight ${
            props.value.subtitle ? 'mb-0.5' : 'mb-2'
          }`}
        >
          {props.value.title}
        </h3>

        {props.value.subtitle && (
          <p className="c-card-rec__sub-title / mt-2 mb-2 / leading-snug text-center text-base md:text-lg">
            {props.value.subtitle}
          </p>
        )}
        {props.value.border && (
          <hr className=" mb-4 w-24 / mx-auto / border-t-4 border-b-2 border-tetriary / hidden md:block" />
        )}
        <div className="c-card-rec__card-wrap / -ml-2 md:ml-auto md:mr-auto / w-full max-w-4xl / overflow-scroll md:overflow-visible">
          <ul
            className={`c-card-rec__list / flex flex-row md:flex-col / w-full`}
          >
            {cards && cards.length > 0 ? (
              cards.map((card: any, i: number) => (
                <li
                  key={i}
                  className={`c-card-rec__item / flex shrink-0 items-stretch w-56 md:w-full / md:mb-3 / p-2 md:p-0`}
                >
                  <CardTile
                    categoryId={categoryId}
                    externalId={externalId}
                    card={card}
                    title={`${cardTipsMapping[card.slug].title} - ${card.name}`}
                    description={cardTipsMapping[card.slug].description}
                  />
                </li>
              ))
            ) : (
              <>
                <CardSkeleton n={3} />
              </>
            )}
          </ul>
        </div>
        <style jsx>{`
          .c-card-rec__title {
            line-height: 1.15;
          }
        `}</style>
      </section>
    )
  }
