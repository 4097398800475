import React from 'react'

export const ListRenderer = (props: {
  children: React.ReactElement
  type: string
}) => {
  const { children, type } = props
  if (type === 'number') {
    return <ol className="pl-4 / mb-3 / list-decimal">{children}</ol>
  }
  return <ul className="w-full / mb-3 pl-6 / list-disc">{children}</ul>
}
