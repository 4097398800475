export interface TagCategories {
  [key: string]: {
    code: string
    icon: string
    id: string
    label: string
    range?: string
    href?: string
    cards?: string[]
  }
}

export const bestTags: TagCategories = {
  best: {
    code: '108',
    icon: '/static/icons/categories/top-cc.svg',
    id: 'best',
    label: 'Top Cards',
  },
  'balance-transfer': {
    code: '13',
    icon: '/static/icons/categories/balance-transfer.svg',
    id: 'balance-transfer',
    label: 'Balance Transfer',
  },
  'low-interest': {
    code: '12',
    icon: '/static/icons/categories/low-interest.svg',
    id: 'low-interest',
    label: 'Low Interest',
  },
  travel: {
    code: '16',
    icon: '/static/icons/categories/travel.svg',
    id: 'travel',
    label: 'Travel',
  },
  'no-annual-fee': {
    code: '48',
    icon: '/static/icons/categories/no-annual-fee.svg',
    id: 'no-annual-fee',
    label: 'No Annual Fee',
  },
  rewards: {
    code: '15',
    icon: '/static/icons/categories/rewards.svg',
    id: 'rewards',
    label: 'Rewards',
  },
  'cash-back': {
    code: '17',
    icon: '/static/icons/categories/cash-back.svg',
    id: 'cash-back',
    label: 'Cash Back',
  },
  student: {
    code: '21',
    icon: '/static/icons/categories/student.svg',
    id: 'student',
    label: 'Student',
  },
  secured: {
    code: '22',
    icon: '/static/icons/categories/secured.svg',
    id: 'secured',
    label: 'Secured',
  },
  'no-foreign-transaction-fee': {
    code: '226',
    icon: '/static/icons/categories/no-foreign-fees.svg',
    id: 'no-foreign-transaction-fee',
    label: 'No Foreign Fees',
  },
  dining: {
    code: '23',
    icon: '/static/icons/categories/dining.svg',
    id: 'dining',
    label: 'Dining',
  },
  gas: {
    code: '24',
    icon: '/static/icons/categories/gas.svg',
    id: 'gas',
    label: 'Gas',
  },
  // business: {
  //   code: '20',
  //   icon: '/static/icons/categories/business.svg',
  //   id: 'business',
  //   label: 'Business',
  // },
}

export const creditScoreTags: TagCategories = {
  excellent: {
    code: '30',
    icon: '/static/icons/credit-quality/excellent.svg',
    id: 'excellent',
    label: 'Excellent',
    range: '(720+)',
    href: '/credit-cards/credit-range/excellent/',
  },
  good: {
    code: '31',
    icon: '/static/icons/credit-quality/good.svg',
    id: 'good',
    label: 'Good',
    range: '(660 - 719)',
    href: '/credit-cards/credit-range/good/',
  },
  fair: {
    code: '68',
    icon: '/static/icons/credit-quality/fair.svg',
    id: 'fair',
    label: 'Fair',
    range: '(620-659)',
    href: '/credit-cards/credit-range/fair/',
  },
  poor: {
    code: '32',
    icon: '/static/icons/credit-quality/poor.svg',
    id: 'poor',
    label: 'Poor',
    range: '(< 620)',
    href: '/credit-cards/credit-range/poor/',
  },
  limited: {
    code: '33',
    id: 'limited',
    // icon: '/static/icons/credit-quality/limited.svg',
    icon: '/static/icons/credit-quality/empty.svg',
    label: "Don't Know",
    range: '',
    href: '/credit-cards/credit-range/fair/',
  },
}

export const travelTags: TagCategories = {
  travel: {
    code: '16',
    icon: '/static/icons/categories/top-cc.svg',
    id: 'travel',
    label: 'Top Travel Cards',
    cards: [
      'venture',
      'travel-rewards',
      'premier',
      'sapphire-preferred',
      'ventureone',
      'venture-x',
      'gold-card',
      'discover-it-miles',
      'freedom-unlimited',
      'autograph',
      'alaska-airlines',
      'platinum-card',
      'autograph-journey',
    ],
  },
  travel_miles: {
    code: '16',
    icon: '/static/icons/categories/travel.svg',
    id: 'travel_miles',
    label: 'Miles',
    cards: [
      'ventureone',
      'venture',
      'venture-x',
      'discover-it-miles',
      'gold-card',
      'platinum-card',
      'premier',
      'alaska-airlines',
      'sapphire-preferred',
    ],
  },
  travel_points: {
    code: '16',
    icon: '/static/icons/categories/rewards.svg',
    id: 'travel_points',
    label: 'Points',
    cards: [
      'travel-rewards',
      'gold-card',
      'platinum-card',
      'autograph',
      'premier',
      'sapphire-preferred',
      'autograph-journey',
    ],
  },
  'travel_no-annual-fee': {
    code: '16',
    icon: '/static/icons/categories/no-annual-fee.svg',
    id: 'travel_no-annual-fee',
    label: 'No Annual Fee',
    cards: [
      'travel-rewards',
      'ventureone',
      'autograph',
      'freedom-unlimited',
      'discover-it-miles',
    ],
  },
  'travel_extra-perks': {
    code: '16',
    icon: '/static/icons/icon-money-bag.svg',
    id: 'travel_extra-perks',
    label: 'Premium Perks',
    cards: [
      'gold-card',
      'platinum-card',
      'premier',
      'venture',
      'venture-x',
      'alaska-airlines',
      'sapphire-preferred',
    ],
  },
}

export const rewardsTags: TagCategories = {
  rewards: {
    code: '15',
    icon: '/static/icons/categories/top-cc.svg',
    id: 'rewards',
    label: 'Top Rewards Cards',
    cards: [
      'unlimited-cash-rewards',
      'venture',
      'freedom-unlimited',
      'cash-rewards',
      'quicksilver',
      'autograph',
      'gold-card',
      'ventureone',
      'blue-cash-preferred',
      'discover-it-cash-back',
      'venture-x',
      'active-cash',
      'travel-rewards',
      'premier',
      'discover-it-miles',
      'double-cash',
      'custom-cash',
      'rewards-plus',
      'autograph-journey',
    ],
  },
  rewards_miles: {
    code: '15',
    icon: '/static/icons/categories/travel.svg',
    id: 'rewards_miles',
    label: 'Miles',
    cards: [
      'ventureone',
      'venture',
      'venture-x',
      'discover-it-miles',
      'gold-card',
      'premier',
    ],
  },
  points: {
    code: '15',
    icon: '/static/icons/categories/rewards.svg',
    id: 'points',
    label: 'Points',
    cards: [
      'travel-rewards',
      'gold-card',
      'autograph',
      'premier',
      'rewards-plus',
      'autograph-journey',
    ],
  },
  'cash-back': {
    code: '15',
    icon: '/static/icons/categories/cash-back.svg',
    id: 'cash-back',
    label: 'Cash Back',
    cards: [
      'active-cash',
      'freedom-unlimited',
      'discover-it-cash-back',
      'cash-rewards',
      'blue-cash-preferred',
      'quicksilver',
      'unlimited-cash-rewards',
      'double-cash',
      'custom-cash',
    ],
  },
}
