import React from 'react'
import { AsSeenInBanner, TrustCol } from '..'

export function AsSeenInWrapper(props: any) {
  return (
    <div className="-mt-3">
      {props.value.orientation === 'horizontal' ? (
        <AsSeenInBanner />
      ) : (
        <TrustCol responsiveCol={props.value.responsiveCol} />
      )}
    </div>
  )
}
