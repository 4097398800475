import React from 'react'
import range from 'lodash/range'

export function CardSkeleton(props: { n: number }) {
  return (
    <li className="flex flex-row md:flex-col / w-full">
      {range(props.n).map((idx: number) => (
        <div
          key={idx}
          className="flex items-stretch / shrink-0 / w-56 md:w-full / md:mb-3 / p-2 md:p-0"
        >
          <div className="flex flex-col justify-between items-stretch / w-full / mb-1 / p-3 md:p-0 / rounded shadow bg-white">
            <div className="md:flex md:flex-row md:items-stretch">
              <div className="c-content-placeholder-bg / flex items-stretch / shrink-0 / w-full md:w-36 lg:w-48 h-24 md:h-auto / mb-2 md:mb-0 / rounded overflow-hidden transition-all / md:rounded-br-none md:rounded-tr-none"></div>
              <div className="md:flex md:flex-col / md:w-full / md:px-8 md:py-4">
                <div className="c-content-placeholder-bg c-tile__title / w-full h-8 / mb-2 md:mb-1"></div>
                <div className="c-content-placeholder-bg c-tile__title / w-full h-14 / mb-2 md:mb-1"></div>
                <div className="c-content-placeholder-bg c-tile__title / w-1/2 h-6"></div>
              </div>
            </div>
          </div>
        </div>
      ))}
      <style jsx>{`
        .c-content-placeholder-bg {
          position: relative;
          overflow: hidden;
        }
        .c-content-placeholder-bg:before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          display: block;
          width: 70%;
          height: 100%;
          will-change: transform;
          background: -moz-linear-gradient(
            left,
            rgba(26, 32, 44, 0) 0%,
            rgba(26, 32, 44, 1) 50%,
            rgba(255, 255, 255, 0.3) 85%,
            rgba(255, 255, 255, 0) 100%
          );
          background: -webkit-linear-gradient(
            left,
            rgba(26, 32, 44, 0) 0%,
            rgba(26, 32, 44, 1) 50%,
            rgba(255, 255, 255, 0.3) 85%,
            rgba(255, 255, 255, 0) 100%
          );
          background: linear-gradient(
            to right,
            rgba(26, 32, 44, 0) 0%,
            rgba(26, 32, 44, 1) 50%,
            rgba(255, 255, 255, 0.3) 85%,
            rgba(255, 255, 255, 0) 100%
          );
          filter: unquote(
            "progid:DXImageTransform.Microsoft.gradient( startColorstr='#001a202c', endColorstr='#00ffffff',GradientType=1 )"
          );
          opacity: 0.04;
          animation: content-placeholder-animation 1.35s linear infinite;
        }
        @keyframes content-placeholder-animation {
          0% {
            transform: translateX(-80%);
          }
          100% {
            transform: translateX(200%);
          }
        }
      `}</style>
    </li>
  )
}
