import React from 'react'

export const JumpLink = ({
  children,
  value,
}: {
  children: React.ReactElement
  value: { sectionId: string }
}) => {
  return (
    <a
      className="text-primary-bright / hover:text-primary-mid hover:underline"
      href={`#${value.sectionId || ''}`}
    >
      {children}
    </a>
  )
}
