import React from 'react'
import { supify } from '../../utils'

export const Superscript = ({
  children,
  value,
}: {
  children: any
  value: { type: string }
}) => {
  return (
    <>
      {children}
      {React.createElement('span', {
        dangerouslySetInnerHTML: {
          __html: supify(value.type),
        },
      })}
    </>
  )
}
