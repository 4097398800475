import React from 'react'
import Image from 'next/image'
import { nextImageHelper } from 'utils/'

export const TrustCol = (props: any) => {
  return (
    <div
      className={`c-trust / flex flex-col / mb-12 lg:mb-16 px-4 py-6 / text-center / shadow-lg rounded-lg ${
        props.responsiveCol
          ? 'md:flex-row md:justify-center lg:flex-col md:px-8 lg:px-4'
          : ''
      }`}
    >
      <div className={`${props.responsiveCol ? 'md:w-3/4 lg:w-full' : ''}`}>
        <p className="mb-5 md:mb-6 lg:mb-5 / uppercase font-bold text-sm tracking-wider text-gray-500">
          As Seen In
        </p>
        <ul
          className={`mb-4 / opacity-25 text-center ${
            props.responsiveCol
              ? 'xs:flex xs:flex-row xs:items-stretch xs:justify-between lg:flex-col / md:mr-6 lg:mr-0 / md:border-r-2 md:border-gray-700 lg:border-0'
              : ''
          }`}
        >
          <li
            className={`mb-4 lg:mb-5 ${
              props.responsiveCol
                ? 'xs:flex xs:items-center / xs:mb-0 xs:mr-4 / lg:mr-0 lg:mb-4'
                : ''
            }`}
          >
            <img
              alt="PBS Logo"
              src="/static/as-seen-on/pbs.svg"
              loading="lazy"
              role="presentation"
              className="inline-block mx-auto w-24 max-w-full"
            />
          </li>
          <li
            className={`mb-4 lg:mb-5 ${
              props.responsiveCol
                ? 'xs:flex xs:items-center / xs:mb-0 xs:mr-4 / lg:mr-0 lg:mb-4'
                : ''
            }`}
          >
            <img
              alt="Forbes Logo"
              src="/static/as-seen-on/forbes-black.svg"
              role="presentation"
              loading="lazy"
              className="inline-block mx-auto w-24 max-w-full"
            />
          </li>
          <li
            className={`mb-4 lg:mb-5 ${
              props.responsiveCol
                ? 'xs:flex xs:items-center / xs:mb-0 xs:mr-4 / lg:mr-0 lg:mb-4'
                : ''
            }`}
          >
            <img
              alt="USA Today Logo"
              src="/static/as-seen-on/usa-today-black.svg"
              role="presentation"
              loading="lazy"
              className="inline-block mx-auto w-24 max-w-full"
            />
          </li>
          <li
            className={`mb-4 lg:mb-5 ${
              props.responsiveCol
                ? 'xs:flex xs:items-center / xs:mb-0 xs:mr-4 / lg:mr-0 lg:mb-4 / md:hidden lg:block'
                : ''
            }`}
          >
            <img
              alt="Wall Street Journal Logo"
              src="/static/as-seen-on/wsj-mark.svg"
              role="presentation"
              loading="lazy"
              className="inline-block mx-auto w-20 max-w-full"
            />
          </li>
          <li
            className={`${
              props.responsiveCol
                ? 'xs:flex xs:items-center xs:mr-4 lg:mr-0'
                : ''
            }`}
          >
            <img
              alt="Techcrunch Logo"
              src="/static/as-seen-on/tech-crunch-black.svg"
              role="presentation"
              loading="lazy"
              className="inline-block mx-auto w-16 max-w-full"
            />
          </li>
        </ul>
        <hr
          className={`my-6 / border-b-2 mx-auto w-16 ${
            props.responsiveCol ? 'md:hidden lg:block' : ''
          }`}
        />
      </div>

      <div className={`${props.responsiveCol ? 'md:flex-shrink-1' : ''}`}>
        <p className="mb-4 / uppercase font-bold text-sm tracking-wider text-gray-500">
          Trust &amp; Secure
        </p>
        <ul
          className={`flex flex-col justify-center items-center / mb-0 ${
            props.responsiveCol
              ? 'xs:flex-row xs:justify-center / lg:flex-col'
              : ''
          }`}
        >
          <li className={`w-full mb-2 ${props.responsiveCol ? 'w-auto' : ''}`}>
            <a
              href="https://www.inc.com/profile/compare-credit"
              target="_blank"
              rel="noreferrer"
              aria-label="visit inc 5000"
              className={`inline-block w-24 xl:w-28 / opacity-40 hover:opacity-100 ${
                props.responsiveCol ? 'md:w-16 lg:w-24' : ''
              }`}
            >
              <Image
                src="/static/as-seen-on/badge-inc-5000-bw.png"
                height={72}
                width={72}
                alt="INC 5000"
                aria-hidden={true}
                style={nextImageHelper.responsive}
                sizes="100vw"
              />
            </a>
          </li>
          <li className={`${props.responsiveCol ? 'xs:w-auto' : ''}`}>
            <span
              className={`inline-block relative w-28 opacity-25 ${
                props.responsiveCol ? 'xs:w-32 md:w-28 / xs:ml-4 lg:ml-0' : ''
              }`}
            >
              <Image
                src="/static/logos/norton-black.svg"
                height={77}
                width={144}
                alt="Norton secured"
                aria-hidden={true}
                style={nextImageHelper.responsive}
                sizes="100vw"
              />
            </span>
          </li>
        </ul>
      </div>
    </div>
  )
}
