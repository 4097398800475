import React from 'react'
import Image from 'next/image'
import { nextImageHelper } from '../../utils'

export const TipAuthorReviewerSocial = ({
  twitterUrl,
  linkedinUrl,
}: {
  twitterUrl?: string
  linkedinUrl?: string
}) => {
  return twitterUrl || linkedinUrl ? (
    <ul className="flex flex-row">
      {linkedinUrl && (
        <li>
          <a
            href={linkedinUrl}
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Visit Linkedin"
            className="opacity-40 / hover:opacity-75"
          >
            <span className="inline-block / w-5 lg:w-6 / ml-2">
              <Image
                alt="Linkedin"
                width={16}
                height={16}
                src="/static/icons/linkedin.svg"
                style={nextImageHelper.responsive}
                sizes="100vw"
              />
            </span>
          </a>
        </li>
      )}
      {twitterUrl && (
        <li className="mr-1">
          <a
            href={twitterUrl}
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Visit Twitter"
            className="opacity-40 / hover:opacity-75"
          >
            <span className="inline-block / w-5 lg:w-6 / ml-2">
              <Image
                alt="Twitter"
                width={16}
                height={16}
                src="/static/icons/twitter.svg"
                style={nextImageHelper.responsive}
                sizes="100vw"
              />
            </span>
          </a>
        </li>
      )}
    </ul>
  ) : (
    <></>
  )
}
