import omit from 'lodash/fp/omit'

import { Segment } from '../../types/segment'

export function omitLocationStatus(
  data: Omit<
    Segment.EventData['Impression Made'],
    'nonInteraction' | 'version'
  >,
) {
  if (!data.location) {
    return { ...data, location: { city: '', state: '', country: '' } }
  }
  return { ...data, location: omit(['status'], data.location) }
}
