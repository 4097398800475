import React from 'react'
import Image from 'next/image'
import { Tile } from './tile'
import { nextImageHelper } from '../../utils'
// import pageRoutes from '../../pageRoutes.json'

export function WhyChooseUs() {
  return (
    <div className="flex flex-col items-center pt-24 sm:pt-32 lg:pt-40 pb-2">
      <h2 className="font-bold font-serif leading-snug mb-4 text-center text-fs32 lg:text-fs40 text-primary w-full">
        Why Choose Us?
      </h2>
      <div className=" mb-6 sm:mb-10 w-24 / border-t-4 border-b-2 border-tetriary" />
      <div className="flex flex-col sm:flex-row items-center sm:items-start / w-full max-w-3xl / mb-6 sm:mb-10 / text-card-gray">
        <p className="sm:w-1/2 md:w-3/5 max-w-lg lg:max-w-lg / mb-8 lg:mr-7 sm:mb-0 / lg:text-left">
          With CompareCredit, searching for the best credit card or loan is
          simple and straightforward. No gimmicks or games – just comparisons
          that clearly show what is different about each card or account, the
          advantages they offer and how to learn&nbsp;more.
        </p>
        <ul className="sm:w-1/2 md:w-2/5 sm:pl-8">
          {[
            'No registration required',
            'All the top cards and offers',
            'Side-by-side comparisons',
          ].map((item, idx) => (
            <li key={idx} className="mb-3">
              <div className="flex">
                <div className="mr-3 md:mr-4 w-5 h-6">
                  <Image
                    src="/static/icons/checkmark-green.svg"
                    alt="checkmark"
                    width={20}
                    height={24}
                    style={nextImageHelper.responsive}
                    sizes="100vw"
                  />
                </div>
                {item}
              </div>
            </li>
          ))}
        </ul>
      </div>
      <div className="flex flex-col sm:flex-row justify-center md:justify-between w-full md:max-w-3xl / md:-mx-2 lg:-mx-4 xl:-mx-6">
        {[
          {
            button: 'Start Saving',
            href: '/credit-cards/best',
            icon: 'icon-credit-card',
            label: 'Credit Cards',
            description:
              'Low interest, balance transfer, cash bonus, cash back, introductory APR, airline miles, no annual fee, no foreign transaction fees, and more',
            height: 80,
            width: 80,
          },
        ].map((tile) => (
          <div
            key={tile.icon}
            className="c-product-tile / md:flex md:justify-center md:items-stretch sm:w-1/2 / mx-auto mb-4 sm:mb-0 / sm:px-2 md:px-4 lg:px-4 xl:px-6 / first:pl-0 last:pr-0"
          >
            <Tile
              button={tile.button}
              href={tile.href}
              icon={tile.icon}
              label={tile.label}
              description={tile.description}
              height={tile.height}
              width={tile.width}
            />
          </div>
        ))}
      </div>
      <style jsx>{`
        @media (min-width: 640px) {
          .c-product-tile:first-child {
            padding-left: 0;
          }
          .c-product-tile:last-child {
            padding-right: 0;
          }
        }
      `}</style>
    </div>
  )
}
