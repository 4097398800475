import * as React from 'react'
import Cookies from 'js-cookie'
import Image from 'next/image'
import { createClient } from '@sanity/client'
import { useInView } from 'react-intersection-observer'
import { makeImpression } from '../../clients/segment'
import {
  getExperiment,
  injectImpressionTag,
  removeImpressionTags,
  nextImageHelper,
} from '../../utils'
import { CompareCredit } from '../../../types/compare-credit'
import { useNextSanityImage } from 'next-sanity-image'
import { UserContext } from '../UserContext'
import useNetworkState from '../../utils/use-network-status'

const client: any = createClient({
  dataset: process.env.SANITY_DATA_SET,
  projectId: process.env.SANITY_PROJECT_ID,
  apiVersion: '1',
  useCdn: true,
  useProjectHostname: false,
})

const CardArtImage = (props: {
  cardArt: CompareCredit.FormattedCard['cardArt']
  name?: string
  priority?: boolean
}) => {
  const { cardArt, name, priority = false } = props
  const { height, src, width } = useNextSanityImage(client, cardArt)

  return (
    <Image
      alt={name || ''}
      height={height}
      priority={priority}
      placeholder="blur"
      blurDataURL={cardArt.metadata.lqip}
      src={src}
      loading={!priority ? 'lazy' : undefined}
      width={width}
      style={nextImageHelper.responsive}
      sizes="100vw"
    />
  )
}

export function CardArt(props: {
  cardArt: CompareCredit.FormattedCard['cardArt']
  customCodeSnippets?: CompareCredit.FormattedCard['customCodeSnippets']
  imgClasses?: string
  issuer?: string | undefined
  name?: string
  orderId?: string | null
  slug?: string
  type?: 'card' | 'nonPaidCard' | 'loanCategory'
  categoryId: string | null
  externalId: string | null
  position?: number
  arrangementId?: string
  preventImpressionEvent?: boolean
  imagePriority?: boolean
  _rev: string | null
  sortable?: boolean
  sort?: string
}) {
  const {
    cardArt,
    customCodeSnippets = [],
    imgClasses = '',
    issuer,
    name,
    orderId = null,
    slug = '',
    type = 'card',
    categoryId,
    externalId,
    position,
    arrangementId,
    preventImpressionEvent = false,
    imagePriority = position && position <= 3 ? true : false,
    _rev,
    sortable,
    sort,
  } = props

  const Context = React.createContext<CompareCredit.ProductListContext>({
    latency: null,
    experiment: null,
    params: null,
    userModel: null,
  })

  const networkInfo = useNetworkState()

  const productListContext = React.useContext(Context)

  const { clientLocation, gaSessionInfo } = React.useContext(UserContext)

  // has all necessary props for firing Segment `makeImpression` function
  const impressionReady = !!issuer && !!name && !!slug && type === 'card'

  const { ref, inView } = useInView({
    threshold: 0.9,
    triggerOnce: true,
  })
  // do we want to make this available for nonPaidCards?
  React.useEffect(() => {
    if (inView && !preventImpressionEvent && type === 'card')
      customCodeSnippets.forEach((codeSnippet) =>
        injectImpressionTag(codeSnippet?.code?.code),
      )
  }, [inView])

  React.useEffect(() => () => removeImpressionTags(), [cardArt.url])

  React.useEffect(() => {
    if (impressionReady && inView && !preventImpressionEvent) {
      const experiment = getExperiment()
      const ga_client_id = Cookies.get('_ga') || null

      makeImpression({
        ga_client_id,
        ga_session_id: gaSessionInfo.ga_session_id,
        ga_session_number: gaSessionInfo.ga_session_number,
        arrangementId: arrangementId || null,
        brand: issuer,
        category: 'credit-card' as const,
        categoryId: categoryId || externalId || null,
        experiment,
        id: slug,
        latency: productListContext.latency,
        location: clientLocation,
        name: name,
        network: networkInfo,
        order_id: orderId,
        params: productListContext.params,
        product_id: slug,
        sku: slug,
        userModel: productListContext.userModel,
        ...(position && { position }),
        ...(sort && { sort }),
        _rev,
        sortable,
      })
    }
  }, [impressionReady, inView])

  return (
    <div className={`${imgClasses} background`} ref={ref}>
      <CardArtImage priority={imagePriority} cardArt={cardArt} name={name} />
    </div>
  )
}
