/**
 * Generates the year for the "Last Updated On" dates on Privacy Policy and Terms & Conditions
 * Should always show a date within the past year, never in the future
 */
export const generateYear = (month: number, day: number, refDate?: Date) => {
  const currentDate = refDate || new Date()
  const compareDate = new Date(currentDate.getFullYear(), month - 1, day)
  return currentDate < compareDate
    ? currentDate.getFullYear() - 1
    : currentDate.getFullYear()
}
