import { CompareCredit } from '../../types/compare-credit'

export function getFeaturesToRender(
  entity: CompareCredit.Entity,
  category?: string,
): CompareCredit.FeatureBlock[] {
  return categoryOverride(entity, category) || defaultFeatures(entity)
}

function categoryOverride(
  entity: CompareCredit.Entity,
  category?: string,
): CompareCredit.FeatureBlock[] | null {
  if (entity.featureOverrides == null) return null

  const categoryOverride = entity.featureOverrides.filter(
    (override) => override.categoryOverride === category,
  )[0]

  return categoryOverride && categoryOverride.enableOverride
    ? categoryOverride.features
    : null
}

function defaultFeatures(
  entity: CompareCredit.Entity,
): CompareCredit.FeatureBlock[] {
  const isCardOrNonPaidCard =
    entity._type === 'card' || entity._type === 'nonPaidCard'

  if (isCardOrNonPaidCard)
    return entity.defaultFeatures && entity.defaultFeatures.length > 0
      ? entity.defaultFeatures
      : [
          {
            Title: 'Intro Balance Transfer&nbsp;APR',
            value: entity.aprIntroBalanceTransfer,
            _type: 'custom',
          },
          {
            Title: 'Intro Purchase APR',
            value: entity.aprIntroPurchase,
            _type: 'custom',
          },
          {
            Title: 'Regular APR',
            value: entity.aprPurchase,
            _type: 'custom',
          },
        ]
  return entity.defaultFeatures && entity.defaultFeatures.length > 0
    ? entity.defaultFeatures
    : []
}
