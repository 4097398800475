import React from 'react'
import Image from 'next/image'
import { nextImageHelper } from '../../utils'
import cn from 'utils/classnames'

export const HubPagination = ({
  page,
  setPage,
  pageCount,
}: {
  page: number
  setPage: any
  pageCount: number
}) => {
  const paginationArrayFunction = (selectedPage: number) => {
    const pageRangeDisplayed = 2
    const marginPagesDisplayed = 2
    const items = []
    if (pageCount <= pageRangeDisplayed) {
      items.push(...Array.from(Array(pageCount).keys()))
    } else {
      let leftSide = 1
      let rightSide = 1

      if (selectedPage > pageCount - 1) {
        rightSide = pageCount - selectedPage
        leftSide = pageRangeDisplayed - rightSide
      } else if (selectedPage < 1) {
        leftSide = selectedPage
        rightSide = pageRangeDisplayed - leftSide
      }

      let index
      let page

      for (index = 0; index < pageCount; index++) {
        page = index + 1

        if (page <= marginPagesDisplayed) {
          items.push(index)
          continue
        }

        if (page > pageCount - marginPagesDisplayed) {
          items.push(index)
          continue
        }

        if (
          index >= selectedPage - leftSide &&
          index <= selectedPage + rightSide
        ) {
          items.push(index)
          continue
        }

        if (items[items.length - 1] !== '...') {
          items.push('...')
        }
      }
    }

    return items
  }

  const [paginationArray, setPaginationArray] = React.useState<
    (string | number)[]
  >([])

  React.useEffect(() => {
    setPaginationArray(paginationArrayFunction(page))
  }, [page, setPaginationArray, pageCount])

  return (
    <div className="flex items-center justify-center / pt-12">
      <nav aria-label="article navigation">
        <ul className="flex flex-row / text-sm">
          <li>
            <button
              className="flex items-center justify-center / w-7 h-7 / rounded-full focus:outline-none focus:border-transparent / bg-gray-100 hover:bg-primary-bright"
              type="button"
              aria-label="Previous"
              onClick={(e) => setPage(e, page - 1)}
            >
              <Image
                className="inline-block / w-4 / transform rotate-90 my-auto opacity-25"
                alt="previous list tips icon"
                src="/static/icons/icon-chevron-d-black.svg"
                role="presentation"
                aria-hidden={true}
                width={16}
                height={16}
                style={nextImageHelper.intrinsic}
              />
              <span className="sr-only">Previous</span>
            </button>
          </li>
          {paginationArray.map((pageLabel, index) => (
            <li key={index}>
              <button
                className={cn(
                  'flex items-center justify-center / w-7 h-7 / mx-0.5 / text-center  / rounded-full focus:outline-none focus:border-transparent',
                  {
                    'bg-primary-bright text-white font-bold':
                      pageLabel === page,
                    'text-gray-600 cursor-default': pageLabel === '...',
                    'hover:text-white hover:bg-primary-bright text-gray-600':
                      pageLabel !== page && pageLabel !== '...',
                  },
                )}
                type="button"
                onClick={(e) => setPage(e, pageLabel)}
              >
                {typeof pageLabel === 'string' ? pageLabel : pageLabel + 1}
              </button>
            </li>
          ))}
          <li>
            <button
              className="flex items-center justify-center / w-7 h-7 / rounded-full focus:outline-none focus:border-transparent / bg-gray-100 hover:bg-primary-bright"
              type="button"
              aria-label="Next"
              onClick={(e) => setPage(e, page + 1)}
            >
              <Image
                className="inline-block / w-4 h-4 / transform -rotate-90 my-auto opacity-25"
                alt="next list tips icon"
                src="/static/icons/icon-chevron-d-black.svg"
                role="presentation"
                aria-hidden={true}
                width={16}
                height={16}
                style={nextImageHelper.intrinsic}
              />
              <span className="sr-only">Next</span>
            </button>
          </li>
        </ul>
      </nav>
    </div>
  )
}
