import React from 'react'
import Image from 'next/image'
import { nextImageHelper } from '../../utils'

export const BTSavingsGraph = () => {
  return (
    <div className="my-10">
      <h3 className="relative / flex items-center justify-center / mb-4 px-6 / font-bold text-primary text-center leading-tight tracking-wider">
        <span className="relative z-10 / flex px-4 / uppercase / bg-white">
          A Balance Transfer Card Can Save Over $1,000
        </span>
        <span className="absolute left-0 / inline-block w-full / border-t border-solid border-primary" />
      </h3>
      <div className="mb-4">
        <div className="inline-block sm:hidden / w-full">
          <Image
            alt="chart"
            src="/static/images/graph-bt-sm_v1.0.png"
            width={708}
            height={612}
            role="presentation"
            style={nextImageHelper.responsive}
            sizes="100vw"
          />
        </div>
        <div className="hidden sm:block lg:hidden / w-full">
          <Image
            alt="chart"
            src="/static/images/graph-bt-md_v1.0.png"
            width={1388}
            height={536}
            role="presentation"
            style={nextImageHelper.responsive}
            sizes="100vw"
          />
        </div>
        <div className="hidden lg:block / w-full">
          <Image
            alt="chart"
            src="/static/images/graph-bt-lg_v1.0.png"
            width={1776}
            height={536}
            role="presentation"
            style={nextImageHelper.responsive}
            sizes="100vw"
          />
        </div>
      </div>

      <div className="sm:flex sm:flex-wrap / sm:justify-between / text-sm md:text-base">
        <h3 className="w-full / mb-1 / font-bold text-primary uppercase tracking-wide">
          Total savings from new card:
        </h3>
        <div className="sm:flex / w-full / font-bold text-black">
          <div className="flex justify-between / sm:w-1/2 / mb-2 sm:mb-0 sm:mr-4 / py-2 px-4 sm:px-5 / bg-tetriary">
            <span>Financial savings:</span>
            <span>$1,431</span>
          </div>
          <div className="flex justify-between / sm:w-1/2 / py-2 px-4 sm:px-5 / bg-tetriary">
            <span>Pay Off Debt Faster By:</span>
            <span>3 months</span>
          </div>
        </div>
      </div>
    </div>
  )
}
