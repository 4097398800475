import React from 'react'
import { BTGraphic } from '../components'

export const getHeadlineGraphic = (graphic: string) => {
  switch (graphic) {
    case 'balance-transfer':
      return <BTGraphic />
    default:
      return null
  }
}
