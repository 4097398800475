import React from 'react'

export const Quote = (props: {
  value: {
    author?: string
    authorTitle?: string
    quote: string
    alignment?: string
  }
}) => {
  const { author, authorTitle, quote, alignment = '' } = props.value

  const alignmentClasses =
    {
      smLeft: 'sm:float-left sm:w-1/2 sm:mr-6',
      smRight: 'sm:float-right sm:w-1/2 sm:ml-6',
      mdLeft: 'md:float-left md:w-1/2 md:mr-6',
      mdRight: 'md:float-right md:w-1/2 md:ml-6',
      lgLeft: 'lg:float-left lg:w-1/2 lg:mr-6',
      lgRight: 'lg:float-right lg:w-1/2 lg:ml-6',
    }[alignment] || ''

  return (
    <div
      className={`c-quote / relative / max-w-2xl / mb-4 mx-auto / pt-1 / ${alignmentClasses}`}
    >
      <p
        className={`c-quote__quote / relative / mb-3 px-10 / text-primary-mid font-serif text-center text-xl leading-tight`}
      >
        {quote}
      </p>
      <hr className="c-quote__brdr w-12 mx-auto mb-2 w-20 mx-auto / border-2 border-tetriary" />
      <p className="flex flex-col / text-sm text-center leading-normal">
        <b className="c-quote__author / text-primary">{author}</b>
        <span className="c-quote__title / uppercase text-fs12 text-gray-600 tracking-widest">
          {authorTitle}
        </span>
      </p>
      <style jsx>
        {`
          .c-quote__quote {
            font-size: 132%;
          }
          .c-quote::before,
          .c-quote::after {
            display: inline-block;
            content: '';
            position: absolute;
            top: 0;
            width: 1.75rem;
            height: 1.75rem;
            opacity: 0.1;
            background-size: cover;
            background-position: center center;
          }
          .c-quote::before {
            left: 0;
            background-image: url(/static/icons/icon-quote-start-black.svg);
          }
          .c-quote::after {
            right: 0;
            background-image: url(/static/icons/icon-quote-end-black.svg);
          }
        `}
      </style>
    </div>
  )
}
