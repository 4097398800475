import React from 'react'
import { useRouter } from 'next/router'
import { VideoWrapper } from '../'
import { tipVideoWatched } from '../../clients/segment'

export const VideoTile = (props: {
  value: {
    title: string
    titleDisplay: boolean
    footer?: string
    url: string
    videoWidthSm?: string
    videoFloatSm?: string
    videoWidthMd?: string
    videoFloatMd?: string
    videoWidthLg?: string
    videoFloatLg?: string
  }
}) => {
  const {
    title,
    titleDisplay,
    footer,
    url,
    videoWidthSm,
    videoFloatSm,
    videoWidthMd,
    videoFloatMd,
    videoWidthLg,
    videoFloatLg,
  } = props.value

  const urlObj = new URL(url)

  const path = urlObj.pathname

  const videoIdMatch = path.match(/embed\/([a-zA-Z0-9_-]+)/)
  const videoId = videoIdMatch ? videoIdMatch[1] : null

  const router = useRouter()
  const { slug } = router.query

  const handleVideoPlay = React.useCallback(() => {
    tipVideoWatched(slug as string, videoId as string)
  }, [slug, videoId])

  const onPlayerStateChange = React.useCallback(
    (event: any) => {
      // console.log('tip onPlayerStateChange')
      // @ts-ignore
      if (event.data === YT.PlayerState.PLAYING) {
        handleVideoPlay()
        // console.log('tipVideoWatched')
      }
    },
    [videoId, slug],
  )

  return (
    <div
      className={`c-video-tile__wrapper ${
        videoWidthSm ? `${videoWidthSm}` : ''
      } ${videoFloatSm ? `${videoFloatSm}` : ''} ${
        videoWidthMd ? `${videoWidthMd}` : ''
      } ${videoFloatMd ? `${videoFloatMd}` : ''} ${
        videoWidthLg ? `${videoWidthLg}` : ''
      } ${videoFloatLg ? `${videoFloatLg}` : ''}`}
    >
      <div className="c-video-tile / mb-4 pt-2 pb-2 / leading-tight / border-b border-slate-200">
        {title && titleDisplay && (
          <div className="c-video-tile__title / mb-1 / font-bold text-primary text-center text-xl md:text-2xl">
            {title}
          </div>
        )}
        <div className="c-video-wrapper u-shadow / mb-3 / border border-slate-400 rounded overflow-hidden">
          {videoId && url && (
            <VideoWrapper
              url={url}
              title={title}
              videoId={videoId}
              onPlayerStateChange={onPlayerStateChange}
            />
          )}
        </div>

        {footer && (
          <div className="c-video-tile__footer / text-center text-fs13 / text-slate-700">
            {footer}
          </div>
        )}
      </div>
    </div>
  )
}
