import React from 'react'
import Image from 'next/image'
import Link from 'next/link'
import {
  PortableText,
  PortableTextListComponent,
  PortableTextListItemComponent,
} from '@portabletext/react'
import prop from 'lodash/fp/prop'
import range from 'lodash/range'

import { blockSerializers } from './index'
import { CardArt, CardRecommendedCredit, LinkToRedirect, StarRating } from '../'
import { CompareCredit } from '../../../types/compare-credit'
import { getFeaturesToRender } from '../../utils/getFeaturesToRender'
import { nextImageHelper, supify } from '../../utils'
import { clickRatesAndFees } from '../../clients/segment'

/* eslint-disable react/display-name */
const List = ({ children }: { children: any }) => (
  <ul className="c-list-disc / pl-5">{children}</ul>
)

const ListItem = ({ children }: { children: any }) => {
  return (
    <li className={`c-list-disc__item`}>
      {children}{' '}
      <style>
        {`
            .c-list-disc__item {
              margin-bottom: 0.25em;
            }
            .c-list-disc__item:last-child {
              margin-bottom: 0;
            }
          `}
      </style>
    </li>
  )
}

export const CardSummary =
  (data: {
    cardOrderIds: null | Record<string, string>
    referencedCards: null | Record<string, CompareCredit.Entities>
  }) =>
  (props: {
    value: {
      card: {
        _id: string
        slug: string
      }
      title: string
      subtitle?: string
      description: any
      tag: CompareCredit.CategoryTag
      categoryTag: CompareCredit.CategoryTag
      redirectLinks?: boolean
    }
  }) => {
    const {
      card,
      title,
      subtitle,
      description,
      tag,
      categoryTag,
      redirectLinks,
    } = props.value
    const { cardOrderIds, referencedCards } = data
    const productTip = prop(card.slug, referencedCards)
    const orderId = prop(card.slug, cardOrderIds) as any

    const externalId = tag ? tag.id : '80'
    const categoryId = categoryTag ? categoryTag.id : '80'

    return (
      <div className="c-card-sum c-product-rating--hide-review-count / mb-8 / leading-normal text-base">
        {productTip &&
        (productTip._type === 'card' || productTip._type === `nonPaidCard`) ? (
          <div className="c-card-sum__container">
            <div className="c-card-sum__header / md:flex md:flex-row / mb-1">
              <div className="c-card-sum__rating / lg:shrink-0 / mb-1">
                <p className="c-card-sum__title / -mb-1 / uppercase font-bold text-sm tracking-wider text-slate-700">
                  {title}
                </p>
                <div className="c-card-sum__rating / flex flex-row flex-wrap items-center / lg:pr-6">
                  <p className="inline-block / mb-1 pr-2 / leading-none font-bold">
                    <span className="c-card-sum__rating-num / text-primary-bright text-6xl leading-0">
                      {productTip.expertReviewRating}
                    </span>
                    <span className="c-card-sum__rating-max / text-gray-600 text-lg font-normal">
                      / 5
                    </span>
                  </p>
                  <div className="c-card-sum__rating-subtitle / inline-block / text-gray-700 text-xs">
                    CompareCredit Editorial&nbsp;Rating
                    <div>
                      <StarRating
                        column
                        marginBottom="mb-0"
                        productSlug={card.slug}
                        stars={productTip.expertReviewRating}
                        userAvgRating={productTip.visitorReviewsAverageRating}
                        visitorReviewsTotalCount={
                          productTip.visitorReviewsTotalCount
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-0.5">
                <p className="font-bold text-gray-700 text-sm tracking-wider uppercase">
                  {subtitle}
                </p>
                <div className="text-gray-700">
                  <PortableText
                    value={description}
                    components={{
                      ...blockSerializers({
                        cardOrderIds,
                        categoryId: categoryId,
                        externalId: externalId,
                        referencedCards,
                      }),
                      list: List as PortableTextListComponent,
                      listItem: ListItem as PortableTextListItemComponent,
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="c-card-sum__card / border-3 rounded border-slate-200">
              <div className="c-card-sum__card-header / flex flex-row items-center md:items-stretch / md:mb-1.5 / p-3 md:pb-0 lg:px-4 lg:pt-4">
                <div className="c-card-art / sm:shrink-0 / w-1/3 sm:w-24 md:w-28 lg:w-36 / pr-4 / text-center flex items-center justify-center text-xs">
                  <div className="w-full relative / rounded-sm shadow / overflow-hidden">
                    {redirectLinks ? (
                      <div className="relative flex rounded-sm overflow-hidden">
                        <CardArt
                          _rev={productTip._rev}
                          cardArt={productTip.cardArt}
                          imgClasses="w-full"
                          issuer={productTip.issuer.slug.current}
                          name={productTip.name}
                          orderId={orderId}
                          slug={productTip.slug}
                          categoryId={categoryId}
                          externalId={externalId}
                        />
                      </div>
                    ) : productTip._type === 'card' ? (
                      <LinkToRedirect
                        externalId={externalId}
                        linkParams={productTip.applyNowLinkParameters}
                        orderId={orderId}
                        slug={productTip.slug}
                      >
                        <div
                          className="relative flex rounded-sm overflow-hidden"
                          aria-label={`Apply for the ${productTip.name} card`}
                        >
                          <span className="c-card-art__content / absolute / flex flex-wrap content-center items-center / z-10 left-0 / w-full h-full text-white">
                            <span className="w-full font-bold">Apply Now</span>
                          </span>
                          <CardArt
                            _rev={productTip._rev}
                            cardArt={productTip.cardArt}
                            imgClasses="w-full"
                            issuer={productTip.issuer.slug.current}
                            name={productTip.name}
                            orderId={orderId}
                            slug={productTip.slug}
                            categoryId={categoryId}
                            externalId={externalId}
                          />
                        </div>
                      </LinkToRedirect>
                    ) : (
                      <Link href={productTip.moreInfoLink}>
                        <div
                          className="relative flex rounded-sm overflow-hidden"
                          aria-label={`Apply for the ${productTip.name} card`}
                        >
                          <span className="c-card-art__content / absolute / flex flex-wrap content-center items-center / z-10 left-0 / w-full h-full text-white">
                            <span className="w-full font-bold">Learn More</span>
                          </span>
                          <CardArt
                            _rev={productTip._rev}
                            cardArt={productTip.cardArt}
                            imgClasses="w-full"
                            issuer={productTip.issuer.slug.current}
                            name={productTip.name}
                            orderId={orderId}
                            slug={productTip.slug}
                            categoryId={categoryId}
                            externalId={externalId}
                          />
                        </div>
                      </Link>
                    )}
                  </div>
                </div>
                <div className="c-card-sum__header-content / sm:flex sm:items-center / w-2/3 sm:w-full / md:pb-2 / md:border-b md:border-slate-200">
                  {redirectLinks ? (
                    <p
                      className="inline-block / w-full mb-0.5 xxs: mb-1 / text-primary-mid leading-snug font-bold text-sm xxs:text-lg lg:text-xl"
                      dangerouslySetInnerHTML={{
                        __html: supify(productTip.name),
                      }}
                    />
                  ) : productTip._type === 'card' ? (
                    <LinkToRedirect
                      externalId={externalId}
                      linkParams={productTip.applyNowLinkParameters}
                      slug={card.slug}
                      orderId={orderId}
                    >
                      <div
                        className="inline-block / w-full mb-0.5 xxs: mb-1 / text-primary-mid leading-snug font-bold text-sm xxs:text-lg lg:text-xl / hover:text-primary-bright cursor-pointer / transition-all"
                        dangerouslySetInnerHTML={{
                          __html: supify(productTip.name),
                        }}
                      />
                    </LinkToRedirect>
                  ) : (
                    <Link href={productTip.moreInfoLink}>
                      <div
                        className="inline-block / w-full mb-0.5 xxs: mb-1 / text-primary-mid leading-snug font-bold text-sm xxs:text-lg lg:text-xl / hover:text-primary-bright cursor-pointer / transition-all"
                        dangerouslySetInnerHTML={{
                          __html: supify(productTip.name),
                        }}
                      />
                    </Link>
                  )}

                  <div className="sm:flex sm:flex-col sm:items-center sm:justify-center / sm:w-48 lg:w-56 / sm:text-center">
                    {redirectLinks ? null : (
                      <div>
                        {productTip._type === 'card' ? (
                          <LinkToRedirect
                            externalId={externalId}
                            linkParams={productTip.applyNowLinkParameters}
                            slug={card.slug}
                            orderId={orderId}
                          >
                            <div className="flex flex-row items-center justify-center / max-w-xs sm:w-full sm:max-w-none / mb-1 / px-4 sm:px-2 lg:px-6 py-1 lg:py-1.5 rounded-sm / border border-secondary font-semibold text-white sm:text-sm lg:text-base / bg-secondary hover:bg-secondary-dark">
                              Apply Now
                              <span className="c-btn__icon / ml-1.5 flex">
                                <Image
                                  src="/static/icons/icon-lock-round-w.svg"
                                  alt="lock"
                                  width={16}
                                  height={16}
                                  role="presentation"
                                  aria-hidden={true}
                                  style={nextImageHelper.intrinsic}
                                />
                              </span>
                            </div>
                          </LinkToRedirect>
                        ) : (
                          <Link href={productTip.moreInfoLink}>
                            <div className="flex flex-row items-center justify-center / max-w-xs sm:w-full sm:max-w-none / mb-1 / px-4 sm:px-2 lg:px-6 py-1 lg:py-1.5 rounded-sm / border border-secondary font-semibold text-white sm:text-sm lg:text-base / bg-secondary hover:bg-secondary-dark">
                              Learn More
                            </div>
                          </Link>
                        )}
                        <p>
                          {productTip._type === 'card' &&
                            productTip.ratesAndFees &&
                            productTip.ratesAndFees !== 'N/A' && (
                              <a
                                className="flex items-center justify-center / w-full / text-fs11 text-primary-bright leading-snug / hover:underline hover:text-primary-mid"
                                href={productTip.ratesAndFees}
                                onClick={() => {
                                  clickRatesAndFees({
                                    applyNowLink: productTip.applyNowLink,
                                    component: 'Card Summary',
                                    name: productTip.name,
                                    url: window.location.href,
                                  })
                                }}
                                rel="noopener noreferrer"
                                target="_blank"
                              >
                                Rates &amp; Fees
                                <span className="inline-block / w-3 ml-1">
                                  <Image
                                    height={16}
                                    width={16}
                                    alt="redirect"
                                    role="presentation"
                                    aria-hidden={true}
                                    src="/static/icons/icon-redirect-primary-bright.svg"
                                    style={nextImageHelper.responsive}
                                    sizes="100vw"
                                  />
                                </span>
                              </a>
                            )}
                        </p>

                        {productTip.issuer.slug.current ===
                          'american-express' && (
                          <p className="text-fs10 text-gray-600 / text-center / mb-3 md:mb-1 / w-full leading-4">
                            Terms Apply
                          </p>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="c-card-sum__attributes">
                <ul className="c-card-sum__list / md:flex md:flex-row / w-full / md:pb-0.5 lg:pb-1 md:px-1 lg:px-2.5 / text-fs13 xxs:text-sm md:text-fs13 lg:text-sm xl:text-base text-slate-700 leading-snug">
                  <CardFeatures
                    categoryId={categoryId}
                    card={productTip}
                    orderId={orderId}
                    referencedCards={referencedCards}
                  />
                  <li className="c-card-sum__list-item / flex md:flex-col md:shrink-0 / md:w-28 lg:w-32 / px-3 md:px-3 py-1 / bg-slate-50 md:bg-transparent">
                    <span className="c-card-sum__label / w-1/3 md:w-full / pr-4 / font-bold lg:text-sm">
                      Annual Fee
                    </span>
                    <span className="c-card-sum__attribute / w-2/3 md:w-full / md:pl-0">
                      {productTip.feeAnnual}
                    </span>
                  </li>
                  <li className="c-card-sum__list-item / flex md:flex-col md:shrink-0 / md:w-56 / px-3 md:pl-1.5 py-1">
                    <span className="c-card-sum__label / w-1/3 md:w-full / pr-4 / font-bold lg:text-sm">
                      Recomm. Credit Score
                    </span>
                    <span className="c-card-sum__attribute / w-2/3 md:w-full / md:pl-0">
                      <CardRecommendedCredit
                        card={productTip}
                        align="left"
                        style="bg-white"
                      />
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        ) : (
          <CardSummarySkeleton n={1} />
        )}
      </div>
    )
  }

function CardFeatures(props: {
  orderId: string | null
  card: CompareCredit.FormattedCard | CompareCredit.NonPaidCard
  categoryId: string
  referencedCards: null | Record<string, CompareCredit.Entities>
}) {
  const { card, categoryId } = props
  const featuresToRender = getFeaturesToRender(card, categoryId)

  return (
    <>
      {featuresToRender.map((feature, i) => {
        return (
          i < 2 && (
            <li
              className={`c-card-sum__list-item / flex md:flex-col / md:w-full / px-3 md:px-1.5 py-1 ${
                i === 0 && 'bg-slate-50 md:bg-transparent'
              }`}
              key={i}
            >
              <span
                className="c-card-sum__label / w-1/3 md:w-full / pr-4 / font-bold lg:text-sm"
                dangerouslySetInnerHTML={{ __html: feature.Title }}
              />
              {feature._type === 'custom' ? (
                <span>{feature.value}</span>
              ) : (
                <span className="c-card-sum__attribute / w-2/3 md:w-full / md:pl-0">
                  <PortableText
                    value={feature.Description as any}
                    components={{
                      block: {
                        span: ({ children }) => <span>{children}</span>,
                      },
                      types: {
                        cardAttribute: ({
                          value,
                        }: {
                          value: { attribute: any }
                        }) => {
                          const { attribute } = value
                          //@ts-ignore
                          return <span>{card[attribute]}</span>
                        },
                      },
                    }}
                  />
                </span>
              )}
            </li>
          )
        )
      })}
    </>
  )
}

function CardSummarySkeleton(props: { n: number }) {
  return (
    <div className="flex flex-row md:flex-col / w-full">
      {range(props.n).map((idx: number) => (
        <div key={idx} className="w-full / mb-6">
          <div className="md:flex md:flex-row / mb-1">
            <div className="lg:shrink-0 / md:w-1/4 lg:w-1/3 / mb-2">
              <div className="c-content-placeholder-bg / w-20 h-4 / mb-1"></div>
              <div className="flex flex-row md:flex-wrap lg:flex-nowrap items-center lg:justify-center / lg:pr-6">
                <div className="c-content-placeholder-bg / shrink-0 / w-24 h-14 / mb-1 mr-4"></div>
                <div className="block / w-full">
                  <div className="c-content-placeholder-bg / w-32 h-3 / mb-1"></div>
                  <div className="c-content-placeholder-bg / w-24 h-3"></div>
                </div>
              </div>
            </div>
            <div className="w-full / mb-3">
              <div className="c-content-placeholder-bg / w-32 h-4 / mb-1"></div>
              <div className="c-content-placeholder-bg / h-16 / mb-1"></div>
              <div className="c-content-placeholder-bg / w-20 h-3"></div>
            </div>
          </div>
          <div className="flex flex-col justify-between items-stretch / w-full / mb-1 / rounded shadow bg-white">
            <div className="flex flex-row items-center / p-3">
              <div className="c-content-placeholder-bg / sm:shrink-0 / w-1/3 sm:w-24 md:w-28 h-16 / mr-4 / text-center flex items-center justify-center text-xs rounded-sm"></div>
              <div className="sm:flex sm:items-center sm:justify-between / w-2/3 sm:w-full / md:pb-2 / md:border-b md:border-slate-200">
                <div className="c-content-placeholder-bg / w-3/4 sm:w-48 md:w-64 lg:w-72 h-4 md:h-6 / mb-1"></div>
                <div className="sm:flex sm:flex-col sm:items-center sm:justify-center / sm:w-48 lg:w-56 / sm:pl-4">
                  <div className="c-content-placeholder-bg / w-full h-8 sm:h-10 / mb-1 rounded-sm"></div>
                </div>
              </div>
            </div>
            <div className="md:flex md:flex-row / pb-2 px-3">
              <div className="c-content-placeholder-bg / w-full h-6 md:h-14 / mb-1 md:mr-1"></div>
              <div className="c-content-placeholder-bg / w-full h-6 md:h-14 / mb-1 md:mx-1"></div>
              <div className="c-content-placeholder-bg / w-full h-6 md:h-14 / mb-1 md:mx-1"></div>
              <div className="c-content-placeholder-bg / w-full h-6 md:h-14 / mb-1 md:ml-1"></div>
            </div>
          </div>
        </div>
      ))}
      <style jsx>{`
        .c-content-placeholder-bg {
          position: relative;
          overflow: hidden;
        }
        .c-content-placeholder-bg:before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          display: block;
          width: 100%;
          height: 100%;
          will-change: transform;
          background: -moz-linear-gradient(
            left,
            rgba(26, 32, 44, 0) 0%,
            rgba(26, 32, 44, 1) 50%,
            rgba(255, 255, 255, 0.3) 85%,
            rgba(255, 255, 255, 0) 100%
          );
          background: -webkit-linear-gradient(
            left,
            rgba(26, 32, 44, 0) 0%,
            rgba(26, 32, 44, 1) 50%,
            rgba(255, 255, 255, 0.3) 85%,
            rgba(255, 255, 255, 0) 100%
          );
          background: linear-gradient(
            to right,
            rgba(26, 32, 44, 0) 0%,
            rgba(26, 32, 44, 1) 50%,
            rgba(255, 255, 255, 0.3) 85%,
            rgba(255, 255, 255, 0) 100%
          );
          filter: unquote(
            "progid:DXImageTransform.Microsoft.gradient( startColorstr='#001a202c', endColorstr='#00ffffff',GradientType=1 )"
          );
          opacity: 0.04;
          animation: content-placeholder-animation 1.35s linear infinite;
        }
        @keyframes content-placeholder-animation {
          0% {
            transform: translateX(-80%);
          }
          100% {
            transform: translateX(200%);
          }
        }
      `}</style>
    </div>
  )
}
