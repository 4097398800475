import React from 'react'

export const ArticleHeaderSideLines = (props: any) => {
  return (
    <h2 className="c-article-header-side-lines / relative / flex items-center justify-center / my-8 md:px-6 / font-bold font-serif / text-2xl sm:text-3xl text-primary text-center leading-tight">
      <span className="relative z-10 / inline-block px-4 / bg-white">
        {props.value.title}
      </span>
      <span className="absolute left-0 / inline-block w-full / border-t border-solid border-primary" />
    </h2>
  )
}
