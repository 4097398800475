import { encode } from '../../encode'
import { SORT_CALL_DATA, SORT_DATA_ITEMS } from '../types/experimentation-types'

/**
 * Returns the values that are needed to execute the fetch call to the sort service
 * @param sortData
 * @param sort
 */
export const getSortData = (
  sortData: {
    [key: string]: string | string[] | number | null | boolean
    slugs: string[]
  },
  sort: { version: string; id: string; data: SORT_DATA_ITEMS[] },
): SORT_CALL_DATA => {
  const { version, id, data } = sort

  const dataToEncode = data.reduce(
    (
      acc: {
        [key: string]: string | string[] | number | null | boolean
      },
      val: SORT_DATA_ITEMS,
    ) => {
      acc[val] = sortData[val]
      return acc
    },
    {},
  )

  return {
    id,
    version,
    encodedParams: encode(dataToEncode),
  }
}
