import React from 'react'
import { CompareCredit } from '../../../types/compare-credit'

export const CreditCardLinkedData = (props: {
  card: CompareCredit.FormattedCard | CompareCredit.NonPaidCard
}) => {
  const { card } = props

  const data = {
    '@context': 'http://schema.org',
    '@type': 'CreditCard',
    name: card.name,
    description: card.seoDescription,
    annualPercentageRate: card.aprPurchase,
    feesAndCommissionsSpecification: card.ratesAndFees,
    offers: {
      '@type': 'Offer',
      offeredBy: {
        '@type': 'BankOrCreditUnion',
        name: card.issuer.name,
      },
      priceSpecification: {
        '@type': 'UnitPriceSpecification',
        price: card.feeAnnual,
        priceCurrency: 'USD',
        referenceQuantity: {
          '@type': 'QuantitativeValue',
          value: '1',
          unitCode: 'ANN',
        },
      },
    },
  }
  return (
    <script
      key={`jsonLd-${card._id}`}
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(data) }}
    />
  )
}
