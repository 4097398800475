import React from 'react'
import last from 'lodash/fp/last'
import { PortableText, PortableTextBlockComponent } from '@portabletext/react'

import { ClickawayListener } from 'components/'
import disclosure from 'ssg/disclosures/disclosures.json'
import cn from 'utils/classnames'

const Normal = ({ children }: { children: any }) => {
  return <div className="mb-4">{children}</div>
}

const Heading6 = ({ children }: { children: any }) => {
  return <h6 className="mb-1 / text-base leading-snug font-bold">{children}</h6>
}

export function AdvertiserDisclaimerTop(props: {
  advertiserDisclaimer: { content: any[]; name: string }
}) {
  const [edDisclOpen, setEdDisclOpen] = React.useState(false)
  const handleEdDisc = () => setEdDisclOpen(!edDisclOpen)

  return (
    <ClickawayListener setState={setEdDisclOpen}>
      <div className="c-ad-disc-top / relative z-1 / flex flex-row justify-center items-center / h-6 / px-4">
        <div className="c-ad-disc-top__container / relative / flex justify-center / w-full">
          <button
            className="c-ad-disc-top__btn / h-6 px-4 / leading-snug text-white text-fs12 text-center font-semibold / focus:outline hover:text-primary-light transition-all"
            onClick={handleEdDisc}
            type="button"
          >
            Advertiser Disclosure
          </button>
          <div
            className={cn(
              'c-ad-disc-top__content / c-slidedown / absolute top-full / max-w-lg / px-2 xs:px-4 pt-2 pb-1 / text-fs12 leading-tight text-white / rounded-b-sm',
              {
                'c-slidedown--hidden': !edDisclOpen,
              },
            )}
          >
            <PortableText
              value={props.advertiserDisclaimer.content}
              components={{
                block: {
                  normal: Normal as PortableTextBlockComponent,
                  h6: Heading6 as PortableTextBlockComponent,
                },
              }}
            />
          </div>
        </div>
      </div>
      <style jsx>{`
        .c-ad-disc-top,
        .c-ad-disc-top__content {
          background-color: #1a1b1d;
        }
      `}</style>
    </ClickawayListener>
  )
}

AdvertiserDisclaimerTop.defaultProps = {
  advertiserDisclaimer: last(
    disclosure.filter((d) => d.name === 'Advertising Disclosure'),
  ),
}
