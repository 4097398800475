import React from 'react'
import Image from 'next/image'
import { isMobileOnly } from 'react-device-detect'
import { CompareCredit } from '../../../types/compare-credit'
import { UserContext } from '../UserContext'
import { nextImageHelper } from '../../utils'
import cn from 'utils/classnames'

export function AddToCompareBtn2(props: {
  card?: CompareCredit.FormattedCard
  inCart: boolean
  updateCart: (
    arg0: 'add' | 'remove',
    card?: CompareCredit.FormattedCard,
  ) => void
}) {
  const [checkmarkIcon, setCheckmarkIcon] = React.useState(
    'icon-checkmark-black',
  )
  const [addIcon, setAddIcon] = React.useState('icon-plus-primary-bright')
  const [opacity, setOpacity] = React.useState('opacity-25')
  const [showAlert, setShowAlert] = React.useState(false)
  const { comparisonCart } = React.useContext(UserContext)
  const disabled = isMobileOnly
    ? comparisonCart.length >= 2
    : comparisonCart.length >= 3

  const handleUpdateCart = (action: 'add' | 'remove') =>
    props.card ? props.updateCart(action, props.card) : props.updateCart(action)

  return (
    <>
      <button
        className={cn(
          'flex items-center justify-center / w-full / mx-auto / p-2 / text-sm text-primary-bright font-bold / rounded-sm border border-solid border-primary-bright / transition-all / hover:border-primary-bright hover:bg-primary-bright hover:text-white',
          {
            'border-gray-400 text-primary-bright / c-compare-btn--added':
              props.inCart,
            'opacity-50 cursor-default': disabled && !props.inCart,
          },
        )}
        onClick={() => {
          if (props.inCart) {
            handleUpdateCart('remove')
          } else {
            if (!disabled) {
              handleUpdateCart('add')
              setCheckmarkIcon('icon-checkmark-primary-bright')
              setOpacity('opacity-100')
            } else {
              setShowAlert(true)
            }
          }
        }}
        onMouseEnter={() => {
          setCheckmarkIcon('icon-checkmark-primary-bright')
          setAddIcon('icon-plus-white')
          setOpacity('opacity-100')
        }}
        onMouseLeave={() => {
          setCheckmarkIcon('icon-checkmark-black')
          setAddIcon('icon-plus-primary-bright')
          setOpacity('opacity-25')
        }}
      >
        {props.inCart ? 'Added to Compare' : 'Add to Compare'}
        <span className={`ml-2`}>
          <img
            className={`w-3 ${props.inCart && `w-4 ${opacity}`}`}
            alt="icon"
            src={`/static/icons/${props.inCart ? checkmarkIcon : addIcon}.svg`}
            aria-hidden={true}
          />
        </span>
      </button>
      {showAlert && (
        <div
          className={
            'c-cart__alert / absolute z-10 left-0 / w-full / mx-auto mt-2 / px-4 md:px-0 /transition-25s'
          }
        >
          <div
            className={
              'relative / flex items-center justify-center / max-w-sm md:max-w-xs / mx-auto md:-mx-2 lg:-mx-4 / py-2 pl-6 pr-8 xxs:px-10 md:pl-4 md:pr-8 / text-fs13 text-left leading-tight text-primary / rounded-sm border-gray-300 shadow-lg bg-primary-light-bg'
            }
          >
            <p>
              You can only compare up to {isMobileOnly ? `2` : `3`} cards at
              once. Remove one or more cards before adding another to compare.
            </p>
            <button
              aria-label="close alert"
              className="flex items-center justify-center flex-shrink-0 w-5 h-5 / absolute mt-1 mr-1 xxs:mt-2 xxs:mr-2 / top-0 right-0 / cursor-pointer / opacity-50 / border border-black rounded-full / hover:opacity-75 / transition-all--25"
              onClick={() => setShowAlert(false)}
            >
              <div className="jsx-1835740362 w-3">
                <Image
                  alt="close icon"
                  role="presentation"
                  src="/static/icons/icon-close-black.svg"
                  height={12}
                  width={12}
                  style={nextImageHelper.responsive}
                  sizes="100vw"
                />
              </div>
            </button>
          </div>
        </div>
      )}
    </>
  )
}
