import React from 'react'
import Link from 'next/link'
import { createClient } from '@sanity/client'
import Image from 'next/image'
import { useNextSanityImage } from 'next-sanity-image'

import { nextImageHelper, getDate } from '../../utils'
import { CompareCredit } from '../../../types/compare-credit'

const client: any = createClient({
  useProjectHostname: false,
  projectId: process.env.SANITY_PROJECT_ID,
  dataset: process.env.SANITY_DATA_SET,
  apiVersion: '1',
  useCdn: true,
})

export const ArticlesFeaturedV2 = ({
  featuredGuide,
  trendingPosts,
  featuredImageBanner,
  hideMoreArticlesLink,
  latestArticles,
}: {
  featuredGuide: CompareCredit.Article
  trendingPosts: CompareCredit.Article[]
  featuredImageBanner?: string
  hideMoreArticlesLink?: boolean
  latestArticles: { value: string; label: string }[]
}) => {
  return (
    <div>
      <h2 className="mb-2 / font-serif font-bold text-4xl text-center lg:text-left text-primary leading-tight">
        Featured Articles
      </h2>
      <div className="md:flex md:flex-row / mx-auto">
        <FeaturedArticle
          featuredGuide={featuredGuide}
          featuredImageBanner={featuredImageBanner}
          latestArticles={latestArticles}
        />
        <TrendingPosts
          trendingPosts={trendingPosts}
          hideMoreArticlesLink={hideMoreArticlesLink}
          latestArticles={latestArticles}
        />
      </div>
    </div>
  )
}

function TrendingPosts({
  trendingPosts,
  hideMoreArticlesLink,
  latestArticles,
}: {
  trendingPosts: CompareCredit.Article[]
  hideMoreArticlesLink?: boolean
  latestArticles: { value: string; label: string }[]
}) {
  return (
    <div className="md:w-1/2 lg:w-5/12 / md:pl-4 lg:pl-6">
      <div className="lg:-mt-px">
        {trendingPosts.map((post) => {
          const {
            _type,
            _updatedAt,
            author,
            headline,
            image,
            labels,
            slug,
            contentPreview,
          } = post
          const path = _type === 'tipV2' && 'credit-cards/tips'

          const { height, src, width }: any = useNextSanityImage(client, image)
          const href = `/${path}/${slug.current}`

          const categoryLabel = labels?.length && labels[0].label
          const categoryLabelLink =
            labels?.length &&
            // only show the link to category label if within on LabelsObject
            latestArticles
              .map(({ value }) => value)
              .includes(labels[0].label) &&
            `/credit-cards/${labels[0].label}`

          const dateObj = getDate(String(_updatedAt))
          const dateString = `${dateObj.toLocaleString('en-US', {
            month: 'short',
          })}. ${dateObj.getDate()}, ${dateObj.getFullYear()}`

          const [filteredContentPreview] =
            contentPreview &&
            contentPreview
              .reduce((acc: any, { columns }: { columns: any }) => {
                return columns.map(({ blocks }: { blocks: any }) => {
                  return blocks.map((block: any) => {
                    return block.content
                  })
                })
              }, [])
              .map((x: any) => x[0])
              .filter((x: any) => x.length > 0)

          const content =
            filteredContentPreview &&
            filteredContentPreview
              .map(({ children }: any) => {
                return children[0].text
              })
              .find((text: string) => text && text !== '')

          return (
            <div key={href} className="flex flex-col items-stretch">
              <article className="c-article-trending / flex flex-row items-stretch justify-center / mb-3 md:mb-1 / pb-1 lg:pb-1.5">
                <div className="c-article-link / relative / flex items-start / transition">
                  <span className="flex items-stretch / w-1/4 lg:w-1/3 max-h-14 xs:max-h-16 sm:max-h-20 lg:max-h-20 / mb-2 / rounded-sm / border border-gray-400 overflow-hidden">
                    <Link href={href}>
                      <Image
                        alt={headline}
                        className="c-article-link__img / object-cover / transform / transition / rounded-sm"
                        height={height}
                        placeholder="blur"
                        blurDataURL={image.metadata.lqip}
                        src={src}
                        width={width}
                        style={nextImageHelper.intrinsic}
                      />
                    </Link>
                  </span>
                  <div className="w-3/4 / pb-1 px-5">
                    {categoryLabel && categoryLabelLink && (
                      <Link href={categoryLabelLink}>
                        <p className="text-fs12 uppercase font-bold text-primary-mid tracking-wider">
                          <span className="hover:text-primary-bright">
                            {categoryLabel}
                          </span>
                        </p>
                      </Link>
                    )}
                    <Link href={href}>
                      <h3 className="c-article-link__title / mb-0.5 / text-primary text-sm xs:text-base md:text-sm lg:text-sm font-bold leading-[120%] transition">
                        {headline}
                      </h3>
                      {content ? (
                        <p className="mb-0.5 text-slate-700 text-fs13 / hidden sm:block">
                          {content.trim().split(/\s+/).length <= 20
                            ? `${content} ...`
                            : `${content
                                .trim()
                                .split(/\s+/)
                                .slice(0, 12)
                                .join(' ')} ...`}
                        </p>
                      ) : (
                        <p className="mb-2 text-slate-700 text-fs13 / hidden sm:block">
                          ...
                        </p>
                      )}
                      <div className="c-author__byline / relative z-2 / flex flex-row md:items-center / w-full / mx-auto">
                        <p className="flex flex-row flex-wrap items-center / w-full / text-fs12 text-slate-500 / sm:mb-1">
                          {author[0] ? (
                            author[0].slug && author[0].authorBioToggle ? (
                              <Link
                                href={`/author/${author[0].slug}`}
                                className="inline-block / mr-1 / hover:text-primary-bright"
                              >
                                {author[0].name}
                              </Link>
                            ) : (
                              <span className="inline-block / mr-1">
                                {author[0].name}
                              </span>
                            )
                          ) : (
                            <></>
                          )}{' '}
                          | {dateString}
                        </p>
                      </div>
                    </Link>
                  </div>
                </div>
              </article>
            </div>
          )
        })}
      </div>

      {!hideMoreArticlesLink && (
        <div className="pt-4">
          <Link
            href={`/credit-cards`}
            className="text-sm text-primary-bright / cursor-pointer / hover:text-primary-mid"
          >
            Read More Articles{' '}
            <div className="inline-block w-3 h-3 ml-1">
              <Image
                src="/static/icons/icon-chevron-r-primary-bright.svg"
                height={12}
                width={12}
                alt="Icon Chevron Right"
                style={nextImageHelper.responsive}
                sizes="100vw"
              />
            </div>
          </Link>
        </div>
      )}

      <style jsx>{`
        .c-article-link:hover .c-article-link__img {
          transform: scale(1.05);
        }
        .c-article-link:hover .c-article-link__title {
          color: #21a3fc;
        }
        .c-article-trending {
          min-height: 4.5rem;
        }
      `}</style>
    </div>
  )
}

function FeaturedArticle({
  featuredGuide,
  featuredImageBanner,
  latestArticles,
}: {
  featuredGuide: CompareCredit.Article
  featuredImageBanner?: string
  latestArticles: { value: string; label: string }[]
}) {
  const {
    _updatedAt,
    _type,
    author,
    contentPreview,
    headline,
    image,
    labels,
    slug,
    subtitle,
  } = featuredGuide
  const path = _type === 'tipV2' && 'credit-cards/tips'

  const { height, src, width } = useNextSanityImage(client, image)

  const href = `/${path}/${slug.current}`

  const categoryLabel = labels?.length && labels[0].label
  const categoryLabelLink =
    labels?.length &&
    // only show the link to category label if within on LabelsObject
    latestArticles.map(({ value }) => value).includes(labels[0].value) &&
    `/credit-cards/${labels[0].value}`

  const dateObj = getDate(String(_updatedAt))
  const dateString = `${dateObj.toLocaleString('en-US', {
    month: 'short',
  })}. ${dateObj.getDate()}, ${dateObj.getFullYear()}`

  const [filteredContentPreview] =
    contentPreview &&
    contentPreview
      .reduce((acc: any, { columns }: { columns: any }) => {
        return columns.map(({ blocks }: { blocks: any }) => {
          return blocks.map((block: any) => {
            return block.content
          })
        })
      }, [])
      .map((x: any) => x[0])
      .filter((x: any) => x.length > 0)

  const content =
    filteredContentPreview &&
    filteredContentPreview
      .map(({ children }: any) => {
        return children[0].text
      })
      .find((text: string) => text && text !== '')

  return (
    <div className="md:w-1/2 lg:w-7/12 / mb-6 lg:mb-0 / lg:pr-6">
      <article>
        <div className="c-article-link / relative / inline-block w-full / transition">
          <Link href={href}>
            {featuredImageBanner && (
              <span className="c-ribbon c-ribbon--slim c-ribbon--rt / absolute top-0 left-0 z-10 / inline-block / py-1.5 px-2 mt-4 / font-bold text-primary leading-snug uppercase text-fs13 / bg-tetriary">
                {featuredImageBanner}
              </span>
            )}
            <span className="c-article-link__image-wrap block / mb-2 / rounded overflow-hidden">
              <Image
                alt="Featured tip image"
                className="c-article-link__img / w-full / object-cover / rounded transform transition"
                height={height}
                placeholder="blur"
                blurDataURL={image.metadata.lqip}
                src={src}
                width={width}
                style={nextImageHelper.intrinsic}
              />
            </span>
          </Link>

          <div>
            {categoryLabel && categoryLabelLink && (
              <Link href={categoryLabelLink}>
                <p className="text-fs12 uppercase font-bold text-primary-mid tracking-wider">
                  <span className="hover:text-primary-bright">
                    {categoryLabel}
                  </span>
                </p>
              </Link>
            )}
            <Link href={href} className="c-article-link__title">
              <h3 className="c-article-link__title / mb-1 / text-primary hover:text-primary-bright text-xl xs:text-2xl font-bold leading-tight / transition">
                {headline}
              </h3>
            </Link>
            <p className="mb-2 text-gray-700 text-sm">{subtitle}</p>
            {content ? (
              <p className="mb-2 text-slate-700 text-fs13 / hidden sm:block">
                {content.trim().split(/\s+/).length <= 20
                  ? `${content} ...`
                  : `${content.trim().split(/\s+/).slice(0, 30).join(' ')} ...`}
              </p>
            ) : (
              <p className="mb-2 text-slate-700 text-fs13 / hidden sm:block">
                ...
              </p>
            )}
            <div className="c-author__byline / relative z-2 / flex flex-row md:items-center / w-full / mx-auto">
              <span className="inline-block / w-8 h-8 / shrink-0 / mr-2 / bg-gray-300 rounded-full shadow-lg / overflow-hidden">
                <img
                  src={author[0].image.url}
                  alt="author"
                  aria-hidden={true}
                />
              </span>
              <p className="flex flex-row flex-wrap items-center / w-full / text-fs13 text-slate-600 / sm:mb-1">
                {author[0] ? (
                  author[0].slug && author[0].authorBioToggle ? (
                    <Link
                      href={`/author/${author[0].slug}`}
                      className="inline-block / mr-1 / hover:text-primary-bright"
                    >
                      {author[0].name}
                    </Link>
                  ) : (
                    <span className="inline-block / mr-1">
                      {author[0].name}
                    </span>
                  )
                ) : (
                  <></>
                )}{' '}
                | {dateString}
              </p>
            </div>
          </div>
        </div>
      </article>
      <style jsx>{`
        .c-article-link:hover .c-article-link__img {
          transform: scale(1.05);
        }
        .c-article-link:hover .c-article-link__title {
          color: #21a3fc;
        }
        .c-article-link__image-wrap {
          max-height: 21rem;
        }
        @media (min-width: 1024px) {
          .c-article-link__image-wrap {
            max-height: 22.75rem;
          }
        }
      `}</style>
    </div>
  )
}
