import { SWAP_MAP, CardSwapMap } from './get-swap-maps'

export const GET_SWAP_MAPS_SLUGS = (swapMap: CardSwapMap = SWAP_MAP) => {
  const values = Object.values(swapMap)
  const mappedValues = values.map((obj) => Object.values(obj))
  const flattenedValues = mappedValues.reduce((acc, val) => acc.concat(val), [])

  const mappedResult = flattenedValues.map((obj) => Object.values(obj))
  const flattenedResult = mappedResult.reduce((acc, val) => acc.concat(val), [])

  return flattenedResult
}
