export const checkErrorInstance = (err: any): Error => {
  if (err instanceof Error) {
    return err
  }
  if (typeof err === 'string') {
    return new Error(err)
  }
  if (err.message && typeof err.message === 'string') {
    return new Error(err.message)
  }

  return new Error('Unknown error has occurred')
}
