import { CompareCredit } from '../../types'
import * as React from 'react'

export function TopContainer({
  view,
  children,
}: {
  view: CompareCredit.CardPanelProps['view']
  children: React.ReactNode
}) {
  return (
    <div className={`${view === 'list' && 'md:w-72pct lg:w-9/12 / lg:pl-10'}`}>
      {children}
    </div>
  )
}
