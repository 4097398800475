import React from 'react'
import Image from 'next/image'
import { CompareCredit } from '../../../types'
import { nextImageHelper } from '../../utils'

export function AuthorCredentials({
  education,
  expertise,
}: {
  education: CompareCredit.Author['education']
  expertise: CompareCredit.Author['expertise']
}) {
  return (
    <div>
      {education?.length > 0 && (
        <div className="mb-6">
          <p className="flex flex-row items-center justify-start / text-2xl md:text-xl lg:text-2xl font-serif font-bold text-primary">
            <span className="inline-block / w-8 md:w-6 lg:w-8 / mr-2">
              <Image
                src="/static/icons/icon-student.svg"
                height={16}
                width={16}
                alt="education"
                aria-hidden={true}
                style={nextImageHelper.responsive}
                sizes="100vw"
              />
            </span>
            Education
          </p>
          <ul className="lg:ml-10 text-sm">
            {education.map(({ _key, degreeAreaOfStudy, institutionName }) => (
              <li
                key={_key}
                className="flex flex-col / py-2 lg:py-3 / border-b border-gray-200"
              >
                <strong className="text-primary">{institutionName}</strong>
                <span className="text-gray-600 italic text-fs13">
                  {degreeAreaOfStudy}
                </span>
              </li>
            ))}
          </ul>
        </div>
      )}
      {expertise?.length > 0 && (
        <div className="mb-10">
          <p className="flex flex-row items-center justify-start / mb-2 / text-2xl md:text-xl lg:text-2xl font-serif font-bold text-primary">
            <span className="inline-block / w-8 md:w-6 lg:w-8 / mr-2">
              <Image
                src="/static/icons/icon-award-paper-ribbon.svg"
                height={16}
                width={16}
                alt="award ribbon"
                aria-hidden={true}
                style={nextImageHelper.responsive}
                sizes="100vw"
              />
            </span>
            Expertise
          </p>
          <ul className="c-list-disc / pl-6 lg:pl-14 / text-sm text-gray-800 leading-relaxed">
            {expertise.map(({ _key, expertise }) => (
              <li key={_key}>{expertise}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  )
}
