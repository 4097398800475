import * as React from 'react'
import range from 'lodash/range'

interface Props {
  /**
   * Number of loading skeleton's to render.
   */
  n: number
}

export function LoadingSkeleton({ n }: Props) {
  return (
    <>
      {range(n).map((idx) => (
        <div
          key={idx}
          className="px-5 w-full shadow-xl rounded-sm mt-20 md:mt-8 md:mx-5"
        >
          <div className="rounded-sm px-10">
            {/* card image */}
            <div className="h-32 -mt-8 mb-5 bg-gray-300 rounded animate-pulse"></div>
            <div className="px-13 mb-7">
              {/* title */}
              <div className="h-4 my-3 rounded-sm bg-gray-300 animate-pulse"></div>
              <div className="h-4 my-3 rounded-sm bg-gray-300 animate-pulse"></div>
              <div className="h-4 my-3 rounded-sm bg-gray-300 animate-pulse"></div>
            </div>
          </div>
          {/* card attributes */}
          <div className="w-full / my-7 py-1 px-7 / border-t-2 border-b-2 border-gray-300">
            <div className="p-3">
              {/* title */}
              <div className="h-4 w-1/2 mb-3 rounded-sm bg-gray-300 animate-pulse"></div>
              {/* content */}
              <div className="h-4 mb-2 rounded-sm bg-gray-300 animate-pulse"></div>
              <div className="h-4 mb-2 rounded-sm bg-gray-300 animate-pulse"></div>
            </div>
            <div className="p-3">
              {/* title */}
              <div className="h-4 w-1/2 mb-3 rounded-sm bg-gray-300 animate-pulse"></div>
              {/* content */}
              <div className="h-4 mb-2 rounded-sm bg-gray-300 animate-pulse"></div>
              <div className="h-4 mb-2 rounded-sm bg-gray-300 animate-pulse"></div>
            </div>
            <div className="p-3">
              {/* title */}
              <div className="h-4 w-1/2 mb-3 rounded-sm bg-gray-300 animate-pulse"></div>
              {/* content */}
              <div className="h-4 mb-2 rounded-sm bg-gray-300 animate-pulse"></div>
              <div className="h-4 mb-2 rounded-sm bg-gray-300 animate-pulse"></div>
            </div>
          </div>
          <div className="w-full">
            <div className="h-10 flex items-center justify-center w-full max-w-xs mx-auto mb-10 px-1 py-2 rounded bg-gray-300 animate-pulse"></div>
          </div>
        </div>
        // {/* </div> */}
      ))}
    </>
  )
}
