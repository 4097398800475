import * as React from 'react'
import { StarRating, StarRatingEditor, UserContext } from '../..'
import { Stats } from './components/stats'
import { Title, TopContainer } from './'
import { isMobileOnly } from 'react-device-detect'
import { useExperiment } from 'utils/use-experiment-wrapper'

import {
  BestBadge,
  CTAs,
  CurrentOffer,
  DetailCardArt,
  ListCTAs,
} from './components'
import { CompareCredit } from '../../../../types/compare-credit'
import cn from 'utils/classnames'

export function TopSection(props: {
  categoryId: string
  externalId: string
  entity: CompareCredit.FormattedCard | CompareCredit.NonPaidCard
  updateCart: (arg0: 'add' | 'remove') => void
  featured: boolean | undefined
  inCart: boolean
  view: 'list' | 'details' | 'modal'
  isCollapsed: boolean
  setCollapsed: (collapsed: boolean) => void
  category: string | undefined
  isMobile: boolean
  comparisonDisabled?: boolean
  orderId: string | null
  position?: number
}) {
  const { clientLocation } = React.useContext(UserContext)
  const { categoryId, externalId, entity, updateCart, orderId, position } =
    props

  const isModalOrDetail = props.view === 'details' || props.view === 'modal'
  const isModal = props.view === 'modal'
  const isDetail = props.view === 'details'
  const isCard = entity._type === 'card'

  // 2024.9: EXP-310 Category Page User Review Count v2
  const { experiment: configExp310, isLoading: isLoadingExp310 } =
    useExperiment('exp_310_cc_score_category_pdp')
  const variationExp310 = configExp310?.value?.name || 'control'

  // 2024.9: EXP-310 Category Page User Review Count v2
  const { experiment: configExp308, isLoading: isLoadingExp308 } =
    useExperiment('exp_308_cc_score_mc_tip')
  const variationExp308 = configExp308?.value?.name || 'control'

  return (
    <>
      <TopContainer view={props.view}>
        {isCard && (
          <BestBadge
            best={(entity as CompareCredit.FormattedCard).best}
            view={props.view}
          />
        )}
        <div
          className={`${
            isModalOrDetail && 'flex flex-col md:flex-row md:items-start w-full'
          }`}
        >
          {isModalOrDetail && (
            <DetailCardArt
              _rev={entity._rev}
              card={entity}
              cardArt={entity.cardArt}
              categoryId={categoryId}
              externalId={externalId}
              customCodeSnippets={
                props.view !== 'modal' ? entity.customCodeSnippets : undefined
              }
              issuer={entity.issuer.slug.current}
              key={entity.slug}
              linkParams={
                (entity as CompareCredit.FormattedCard)?.applyNowLinkParameters
              }
              name={entity.name}
              orderId={orderId}
              ratesAndFees={entity.ratesAndFees}
              slug={entity.slug}
            />
          )}
          <div
            className={`md:flex md:flex-wrap lg:mb-2 ${
              isModalOrDetail && 'md:w-8/12 lg:pl-8'
            }`}
          >
            <div
              className={`c-product-panel__info ${
                props.view === 'list' ? 'md:w-8/12' : 'md:w-full'
              } ${
                props.view === 'details' &&
                (entity as CompareCredit.FormattedCard).best
                  ? 'md:pr-8'
                  : 'md:pr-4'
              } text-center md:text-left`}
            >
              <Title
                externalId={externalId}
                isCollapsed={props.isCollapsed}
                entity={entity}
                linkParams={
                  (entity as CompareCredit.FormattedCard)
                    ?.applyNowLinkParameters
                }
                name={entity.name}
                orderId={orderId}
                position={position}
                setCollapsed={props.setCollapsed}
                slug={entity.slug}
                view={props.view}
              />

              {(!isLoadingExp310 &&
                isCard &&
                variationExp310 == 'v1-cc-number-rating') ||
              (!isLoadingExp308 &&
                isCard &&
                variationExp308 == 'v1-cc-number-rating') ? (
                <div
                  className={` ${
                    props.view == 'details' ? 'mb-1 lg:mb-2' : 'mb-0.5'
                  } `}
                >
                  <StarRatingEditor
                    column={true}
                    marginBottom="mb-1"
                    productSlug={entity.slug}
                    starsEditor={entity.expertReviewRating}
                    userAvgRating={entity.visitorReviewsAverageRating}
                    visitorReviewsTotalCount={entity.visitorReviewsTotalCount}
                  />
                </div>
              ) : (
                <>
                  <StarRating
                    marginBottom="mb-1"
                    productSlug={entity.slug}
                    stars={entity.expertReviewRating}
                    userAvgRating={entity.visitorReviewsAverageRating}
                    visitorReviewsTotalCount={entity.visitorReviewsTotalCount}
                  />
                </>
              )}
            </div>

            <CurrentOffer card={entity} view={props.view} />
            {props.view === 'list' ? (
              <ListCTAs
                orderId={orderId}
                externalId={externalId}
                slug={entity.slug}
                phoneNumber={
                  (entity as CompareCredit.FormattedCard)?.phoneNumber
                }
                isMobile={props.isMobile}
                clientLocation={clientLocation}
                entity={entity as CompareCredit.FormattedCard}
                inCart={props.inCart}
                updateCart={updateCart}
                position={position}
              />
            ) : (
              <CTAs
                externalId={externalId}
                orderId={orderId}
                slug={entity.slug}
                entity={entity}
                phoneNumber={isCard ? entity.phoneNumber : undefined}
                isMobile={props.isMobile}
                inCart={props.inCart}
                updateCart={updateCart}
                comparisonDisabled={props.comparisonDisabled}
              />
            )}
          </div>

          {props.view === 'list' && (
            <div className="c-highlight / relative / -mx-6 xs:-mx-8 md:-mx-2 lg:-mx-3 / border-t border-b border-gray-300 md:border-0">
              <Stats entity={entity} view="list" category={props.category} />
            </div>
          )}
        </div>

        {isModalOrDetail && (
          <div
            className={cn(
              'c-highlight / relative / md:mt-4 / -mx-6 xs:-mx-8 md:mx-0 / border-t border-b border-gray-300 md:border-t-2 md:border-b-2',
              {
                'md:pt-4 md:pb-1 md:mb-6':
                  (!isMobileOnly && isModal) || isDetail,
                'md:mb-2 md:border-none': !isDetail && isMobileOnly,
              },
            )}
          >
            <Stats
              entity={props.entity}
              view="details"
              category={props.category}
            />
          </div>
        )}
      </TopContainer>
    </>
  )
}
