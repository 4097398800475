import React from 'react'
import Link from 'next/link'
import Image from 'next/image'
import { getPopularArticles, nextImageHelper } from '../../utils'
import { CompareCredit } from '../../../types/compare-credit'

export function PopularArticles(props: {
  articles: CompareCredit.PopularArticle[]
}) {
  const popularArticles = getPopularArticles(props.articles)

  return (
    <div className="w-full-vw / py-16 lg:bg-primary-light-bg">
      <div className="container-fluid px-4">
        <h3 className="mb-3 / text-center font-serif font-bold text-2xl text-primary tracking-wide">
          Popular Articles
        </h3>
        <ul className="flex flex-col sm:flex-row sm:flex-wrap sm:justify-items-stretch / items-start justify-between xs:items-stretch">
          {popularArticles.map((article) => {
            const href = `/credit-cards/tips/${article.slug}`
            return (
              <Tile
                key={article.slug}
                headline={article.headline}
                href={href}
                thumbnail={article.thumbnail.url}
              />
            )
          })}
        </ul>
      </div>
    </div>
  )
}

const Tile = (props: { headline: string; href: string; thumbnail: string }) => {
  return (
    <>
      <li className="c-article / w-full sm:w-5/12 lg:w-23pct / max-w-sm mx-auto / mb-8 / bg-white shadow rounded">
        <Link href={props.href}>
          <span className="relative / h-48 w-full / rounded-t">
            <Image
              src={props.thumbnail}
              className="rounded-t"
              height={192}
              width={258}
              alt={props.headline}
              role="presentation"
              aria-hidden={true}
              style={nextImageHelper.responsive}
              sizes="100vw"
            />
          </span>
          <div className="px-4 py-4">
            <h3 className="c-article__title / font-serif font-bold text-2xl sm:text-xl tracking-wide leading-tight text-center">
              {props.headline}
            </h3>
          </div>
        </Link>
      </li>
      <style jsx>
        {`
          .c-article {
            transition: all 0.15s ease-in-out;
          }
          .c-article:hover {
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15);
          }
          .c-article__title {
            color: #052f5e;
          }
          .c-article:hover .c-article__title {
            color: #0047ab;
          }
        `}
      </style>
    </>
  )
}
