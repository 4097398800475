import React from 'react'
import Image from 'next/image'
import { PortableText } from '@portabletext/react'
import Link from 'next/link'

import { clickRatesAndFees, fireHoldUpModal } from '../../clients/segment'
import { nextImageHelper, supify } from '../../utils'
import { CompareCredit } from '../../../types'
import {
  blockSerializers,
  CardArt,
  LinkToRedirect,
  StarRating,
  UserContext,
  SecureSite,
  ModalLeaveBehindContext,
} from '../'
import { useStatsigClient } from '@statsig/react-bindings'

const handleApplyClick = () => {
  const { logEvent } = useStatsigClient()

  logEvent('modal_leave_behind_apply_now_clicked')
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function ModalLeaveBehindCard({
  modalLeaveBehindCardProduct,
  modalLeaveBehindCardTitle,
  modalLeaveBehindCardContent,
  modalLeaveBehindCardDeclineText,
  categoryId,
  externalId,
  orderId,
  cardOrderIds,
  referencedCards,
  onClose,
  open,
}: {
  modalLeaveBehindCardProduct:
    | CompareCredit.FormattedCard
    | CompareCredit.NonPaidCard
    | CompareCredit.ProductGeneric
  modalLeaveBehindCardTitle?: string
  modalLeaveBehindCardContent?: {
    children: any | null
  }[]
  modalLeaveBehindCardDeclineText?: string
  categoryId: string
  externalId: string
  orderId: string | null
  cardOrderIds: null | Record<string, string>
  referencedCards: null | Record<string, CompareCredit.Entities>
  onClose: () => void
  open?: boolean
}) {
  const { modalLeaveBehindId } = React.useContext(ModalLeaveBehindContext)
  const { logEvent } = useStatsigClient()

  const handleCloseModal = React.useCallback(() => {
    onClose()
    fireHoldUpModal({
      modal_id: modalLeaveBehindId || '',
      type: 'closed',
      meta: {
        redirect_order_id: orderId || '',
      },
    })
    logEvent('modal_leave_behind_closed')
  }, [onClose, modalLeaveBehindId, orderId])

  const { clientLocation } = React.useContext(UserContext)

  function AttributeRenderer<K extends keyof CompareCredit.Entity>(attrProps: {
    value: { attribute: K }
  }) {
    return (
      <span>
        {
          modalLeaveBehindCardProduct[
            attrProps.value.attribute
          ] as React.ReactNode
        }
      </span>
    )
  }

  return (
    <div
      className={open ? 'block' : 'hidden'}
      aria-hidden={open ? 'false' : 'true'}
    >
      <div
        className={`c-modal-lb / fixed / flex items-center justify-center /
      w-full h-full top-0 left-0 / px-4 / overflow-scroll ${
        modalLeaveBehindCardProduct &&
        modalLeaveBehindCardProduct.slug == 'cap-one-shopping'
          ? 'c-modal-lb--cap-one-shopping'
          : ''
      }`}
      >
        <div className="c-modal-lb__container / relative z-2 top-0 / max-w-lg sm:max-w-2xl w-full / mx-auto mb-6 mt-6 / overflow-visible">
          <div
            className="c-modal-lb__content / relative / flex-wrap flex flex-col sm:flex-row items-center justify-center sm:items-start /
            w-full / mx-auto / px-4 pb-6 pt-4 / xs:pt-3 / sm:pt-4 sm:pb-10 / leading-normal / bg-white rounded shadow-lg"
            tabIndex={-1}
            role="dialog"
          >
            <button
              className="c-modal-lb-ext__btn-close / group / absolute z-3 -top-[32px] -right-[16px] xs:-right-[24px] opacity-100 / w-12 h-12 / mt-2 mr-2 / p-1.5 /
              cursor-pointer / opacity-100 / bg-[rgba(33,163,252,.4)] / rounded-full / transition-all--25 / hover:opacity-100 hover:bg-[rgba(33,163,252,.8)]"
              aria-label="close modal"
              type="button"
              onClick={handleCloseModal}
            >
              <span className="block w-full / p-1 / bg-[rgba(255,255,255,.9)] rounded-full group-hover:bg-[rgba(255,255,255,1)]">
                <Image
                  className="w-8"
                  height={16}
                  width={16}
                  alt="close"
                  role="presentation"
                  aria-hidden="true"
                  src="/static/icons/icon-close-primary-mid.svg"
                  style={nextImageHelper.responsive}
                  sizes="100vw"
                />
              </span>
            </button>

            <div className="c-modal-lb__card-container c-modal-lb__col-2 / w-1/3 / pr-2 pt-3 / hidden sm:flex sm:flex-col">
              {modalLeaveBehindCardProduct &&
              modalLeaveBehindCardProduct.slug == 'cap-one-shopping' ? (
                <div className="c-modal-lb__cap-one-container / w-full text-center">
                  <CapOneShopping
                    categoryId={categoryId}
                    card={modalLeaveBehindCardProduct}
                    clientLocation={clientLocation}
                    orderId={orderId}
                  />
                </div>
              ) : (
                modalLeaveBehindCardProduct && (
                  <CardTile
                    categoryId={categoryId}
                    card={modalLeaveBehindCardProduct}
                    clientLocation={clientLocation}
                    orderId={orderId}
                  />
                )
              )}
            </div>

            {modalLeaveBehindCardProduct &&
            modalLeaveBehindCardProduct.slug == 'cap-one-shopping' ? (
              <div className="c-modal-lb__col-2 / flex flex-col / sm:pl-4 sm:pr-2 / sm:w-2/3 / leading-[1.3] text-center">
                <p className="c-modal-lb__title / mb-2.5 md:mb-1.5 / text-primary text-3xl xs:text-4xl md:text-[46px] font-bold">
                  Hold Up!
                </p>
                <p className="c-modal-lb__content-1 / mb-2.5 / font-bold text-lg md:text-[23px] text-primary">
                  Do you actually not want automatic savings at checkout?
                </p>
                <p className="c-modal-lb__content-2 / mb-2 / font-bold text-sm text-[#C5C5C5] md:text-lg">
                  We only notify when there are likely savings.
                </p>
                <p className="c-modal-lb__content-3 / mb-5 / text-primary text-base md:text-[19px]">
                  Join millions of customers who found{' '}
                  <b className="block / pt-0.5 / font-bold text-[17px] md:text-xl">
                    Over $800 million in savings last&nbsp;year
                  </b>
                </p>

                <div className="c-modal-lb__card-container / w-full / mb-1 / sm:hidden">
                  <CapOneShopping
                    categoryId={categoryId}
                    card={modalLeaveBehindCardProduct}
                    clientLocation={clientLocation}
                    orderId={orderId}
                  />
                </div>

                <LinkToRedirect
                  externalId={categoryId}
                  orderId={orderId}
                  slug={modalLeaveBehindCardProduct.slug}
                >
                  <button
                    className="c-btn c-btn--primary / w-full max-w-none / xs:py-2.5 / text-lg xs:text-xl sm:text-lg md:text-xl text-center leading-snug"
                    onClick={handleApplyClick}
                    type="button"
                  >
                    Yes, get coupons
                  </button>
                </LinkToRedirect>

                <button
                  className="c-modal-lb__btn-decline / mt-4 font-semibold text-slate-500 text-base leading-snug text-center / hover:text-primary-bright hover:underline transition-all"
                  type="button"
                  aria-label="close modal"
                  onClick={handleCloseModal}
                >
                  No, don&apos;t save
                </button>
              </div>
            ) : (
              <div className="c-modal-lb__col-2 / flex flex-col / sm:pl-4 sm:pr-2 / sm:w-2/3">
                <p className="c-modal-lb__title / text-primary text-3xl sm:text-4xl font-serif font-bold text-center sm:text-left">
                  {modalLeaveBehindCardTitle}
                </p>

                <div className="c-modal-lb__content / mb-1 / text-center sm:text-left text-slate-700">
                  <PortableText
                    value={modalLeaveBehindCardContent as any}
                    components={{
                      ...blockSerializers({
                        cardOrderIds,
                        categoryId,
                        externalId,
                        referencedCards,
                      }),
                      types: {
                        cardAttributeTips: AttributeRenderer,
                        advertorialAttribute: AttributeRenderer,
                      },
                    }}
                  />
                </div>

                <div className="c-modal-lb__card-container / w-full / mb-2 / sm:hidden">
                  {modalLeaveBehindCardProduct && (
                    <CardTile
                      categoryId={categoryId}
                      card={modalLeaveBehindCardProduct}
                      clientLocation={clientLocation}
                      orderId={orderId}
                    />
                  )}
                </div>
                {modalLeaveBehindCardProduct && (
                  <>
                    <div className="c-modal-lb__cta-container / mb-0.5">
                      {modalLeaveBehindCardProduct._type === 'card' ? (
                        <LinkToRedirect
                          externalId={categoryId}
                          linkParams={
                            modalLeaveBehindCardProduct.applyNowLinkParameters
                          }
                          orderId={orderId}
                          slug={modalLeaveBehindCardProduct.slug}
                        >
                          <button
                            className="c-btn c-btn--primary / w-full max-w-none / xs:py-2.5 / text-lg xs:text-xl sm:text-lg text-center leading-snug"
                            onClick={handleApplyClick}
                            type="button"
                          >
                            Apply Now{' '}
                            <span className="c-btn__icon / inline-block shrink-0 ml-1">
                              <img
                                alt="lock"
                                src="/static/icons/icon-lock-round-w.svg"
                                className="w-5"
                                aria-hidden={true}
                              />
                            </span>
                          </button>
                        </LinkToRedirect>
                      ) : (
                        <Link
                          href={
                            modalLeaveBehindCardProduct._type === 'nonPaidCard'
                              ? modalLeaveBehindCardProduct.moreInfoLink
                              : modalLeaveBehindCardProduct.link
                          }
                          target="_blank"
                          rel="nofollow"
                        >
                          <button
                            className="c-btn c-btn--primary / w-full max-w-none / xs:py-2.5 / text-lg xs:text-xl sm:text-lg text-center leading-snug"
                            onClick={handleApplyClick}
                            type="button"
                          >
                            Learn More{' '}
                          </button>
                        </Link>
                      )}
                    </div>
                    {modalLeaveBehindCardProduct._type === 'card' &&
                      modalLeaveBehindCardProduct.issuer.slug.current && (
                        <p className="c-card-sticky__issuer / text-fs11 text-gray-600 text-center leading-snug">
                          <SecureSite
                            issuerName={modalLeaveBehindCardProduct.issuer.name}
                            issuerSecureSite={
                              modalLeaveBehindCardProduct.issuerSecureSite
                            }
                          />
                        </p>
                      )}

                    {modalLeaveBehindCardProduct._type === 'card' &&
                      modalLeaveBehindCardProduct.ratesAndFees && (
                        <div className="c-modal-lb__rates-fees / w-full / text-center">
                          {modalLeaveBehindCardProduct.ratesAndFees !==
                            'N/A' && (
                            <a
                              className="inline-block text-primary-bright text-fs12 leading-snug / hover:text-primary-mid hover:underline"
                              href={modalLeaveBehindCardProduct.ratesAndFees}
                              onClick={() => {
                                clickRatesAndFees({
                                  applyNowLink:
                                    modalLeaveBehindCardProduct.applyNowLink,
                                  component: 'Modal Leave Behind Tile',
                                  name: modalLeaveBehindCardProduct.name,
                                  url: window.location.href,
                                })
                              }}
                              rel="noopener noreferrer"
                              target="_blank"
                            >
                              Rates &amp; Fees
                            </a>
                          )}
                          {modalLeaveBehindCardProduct.issuer.slug.current ===
                            'american-express' && (
                            <p className="c-modal-lb__terms / text-fs10 text-gray-600 / text-center / w-full leading-snug">
                              Terms Apply
                            </p>
                          )}
                        </div>
                      )}
                  </>
                )}

                <button
                  className="c-modal-lb__btn-decline / mt-4 font-semibold text-slate-500 text-lg leading-snug text-center / hover:text-primary-bright hover:underline transition-all"
                  type="button"
                  aria-label="close modal"
                  onClick={handleCloseModal}
                >
                  {modalLeaveBehindCardDeclineText
                    ? modalLeaveBehindCardDeclineText
                    : 'No Thanks'}
                </button>
              </div>
            )}
          </div>
        </div>

        <button
          className={`c-modal-lb__bg / fixed top-0 left-0 z-1 / w-full h-full`}
          type="button"
          aria-label="close modal"
          onClick={handleCloseModal}
        />
      </div>
      <style jsx>{`
        .c-modal-lb {
          z-index: 81;
          background-color: rgba(2, 22, 45, 0.8);
        }
        .c-modal-lb__content {
          background-color: #fff;
        }
        .c-modal__bg {
          background-color: rgba(2, 22, 45, 0.8);
        }
      `}</style>
    </div>
  )
}

function CapOneShopping(props: {
  clientLocation: { country?: string; state?: string; status: string }
  orderId: string | null
  card:
    | CompareCredit.FormattedCard
    | CompareCredit.NonPaidCard
    | CompareCredit.ProductGeneric
  categoryId: string
}) {
  const { card, categoryId, orderId } = props

  return (
    <div className="c-capone-shopping">
      <div
        className={`c-card-art / mb-1.5 sm:mb-3 / text-center / flex items-center justify-center / w-48 sm:w-4/5 / mx-auto`}
      >
        <div className={`relative / w-full`}>
          {card && card._type !== 'card' && (
            <Link
              href={
                card._type === 'nonPaidCard' ? card.moreInfoLink : card.link
              }
              target="_blank"
              rel="nofollow"
            >
              <button
                className="c-card-art_image / relative flex w-full rounded-sm overflow-hidden"
                type="button"
                onClick={handleApplyClick}
              >
                <span className="inline-block w-full / sm:hidden">
                  <Image
                    height={41}
                    width={207}
                    alt="CapitalOne Shopping logo"
                    src="/static/logos/capitalone-shopping-horizontal.svg"
                    style={nextImageHelper.responsive}
                    sizes="100vw"
                  />
                </span>
                <CardArt
                  _rev={card._rev}
                  cardArt={
                    card._type === 'nonPaidCard' ? card.cardArt : card.image
                  }
                  imgClasses="relative z-0 w-32 sm:w-full h-full object-cover top-0 hidden sm:block"
                  issuer={
                    card._type === 'nonPaidCard'
                      ? card.issuer.slug.current
                      : card.brand.slug.current
                  }
                  name={card.name}
                  orderId={orderId}
                  slug={card.slug}
                  customCodeSnippets={
                    card._type === 'nonPaidCard'
                      ? card.customCodeSnippets
                      : undefined
                  }
                  categoryId={categoryId}
                  externalId={categoryId}
                />
              </button>
            </Link>
          )}
        </div>
      </div>
      <p className="c-capone-shopping__title / mb-3 leading-tight">
        <Link
          href={card._type === 'nonPaidCard' ? card.moreInfoLink : card.link}
          target="_blank"
          rel="nofollow"
        >
          <span className="font-bold text-sm text-primary / hover:text-primary-bright">
            {card.name} Browser Extension
          </span>
        </Link>
      </p>
      <div
        className="c-capone-shopping__product-rating / flex justify-center mb-0.5"
        role="presentation"
      >
        <ul className="c-product-rating__stars / flex">
          <li className={`c-product-rating__item flex`}>
            <span className="c-product-rating__star full / inline-block w-6 h-6 / bg-cover bg-transparent bg-no-repeat"></span>
          </li>
          <li className={`c-product-rating__item flex`}>
            <span className="c-product-rating__star full / inline-block w-6 h-6 / bg-cover bg-transparent bg-no-repeat"></span>
          </li>
          <li className={`c-product-rating__item flex`}>
            <span className="c-product-rating__star full / inline-block w-6 h-6 / bg-cover bg-transparent bg-no-repeat"></span>
          </li>
          <li className={`c-product-rating__item flex`}>
            <span className="c-product-rating__star full / inline-block w-6 h-6 / bg-cover bg-transparent bg-no-repeat"></span>
          </li>
          <li className={`c-product-rating__item flex`}>
            <span className="c-product-rating__star half / inline-block w-6 h-6 / bg-cover bg-transparent bg-no-repeat"></span>
          </li>
        </ul>
      </div>
      <p className="c-capone-shopping__rating-total / mb-3 / text-fs13 text-gray-600">
        (8,000,000+ users)
      </p>
    </div>
  )
}

function CardTile(props: {
  clientLocation: { country?: string; state?: string; status: string }
  orderId: string | null
  card:
    | CompareCredit.FormattedCard
    | CompareCredit.NonPaidCard
    | CompareCredit.ProductGeneric
  categoryId: string
}) {
  const { card, categoryId, orderId } = props

  return (
    <div className="c-modal-lb__tile / flex flex-col justify-center items-center / w-full">
      <div
        className={`c-card-art / mb-1.5 sm:mb-2 / text-center / flex items-center justify-center / w-32 sm:w-4/5 / mx-auto`}
      >
        <div className={`relative w-full`}>
          {card && card._type === 'card' ? (
            <LinkToRedirect
              linkParams={card.applyNowLinkParameters}
              orderId={orderId}
              slug={card.slug}
              externalId={categoryId}
            >
              <button
                className="c-card-art_image / relative flex / w-full rounded-sm overflow-hidden"
                type="button"
                onClick={handleApplyClick}
              >
                <span className="c-card-art__content / absolute / flex flex-wrap content-center items-center / z-10 left-0 / w-full h-full leading-snug text-white">
                  <span className="c-card-art__icon / w-full">
                    <span className="inline-block w-4">
                      <Image
                        height={32}
                        width={32}
                        alt="lock icon"
                        src="/static/icons/icon-lock-round-w.svg"
                        style={nextImageHelper.responsive}
                        sizes="100vw"
                      />
                    </span>
                  </span>
                  <span className="w-full font-bold text-sm">Apply Now</span>
                </span>
                <CardArt
                  _rev={card._rev}
                  cardArt={card.cardArt}
                  imgClasses="relative z-0 w-32 sm:w-full h-full object-cover top-0"
                  issuer={card.issuer.slug.current}
                  name={card.name}
                  orderId={orderId}
                  slug={card.slug}
                  customCodeSnippets={card.customCodeSnippets}
                  categoryId={categoryId}
                  externalId={categoryId}
                />
              </button>
            </LinkToRedirect>
          ) : (
            <Link
              href={
                card._type === 'nonPaidCard' ? card.moreInfoLink : card.link
              }
              target="_blank"
              rel="nofollow"
            >
              <button
                className="c-card-art_image / relative flex rounded-sm overflow-hidden"
                type="button"
                onClick={handleApplyClick}
              >
                {card._type === 'nonPaidCard' && (
                  <span className="c-card-art__content / absolute / flex flex-wrap content-center items-center / z-10 left-0 / w-full h-full leading-snug text-white">
                    <span className="w-full font-bold text-sm">Learn More</span>
                  </span>
                )}
                <CardArt
                  _rev={card._rev}
                  cardArt={
                    card._type === 'nonPaidCard' ? card.cardArt : card.image
                  }
                  imgClasses="relative z-0 w-32 sm:w-full h-full object-cover top-0"
                  issuer={
                    card._type === 'nonPaidCard'
                      ? card.issuer.slug.current
                      : card.brand.slug.current
                  }
                  name={card.name}
                  orderId={orderId}
                  slug={card.slug}
                  customCodeSnippets={
                    card._type === 'nonPaidCard'
                      ? card.customCodeSnippets
                      : undefined
                  }
                  categoryId={categoryId}
                  externalId={categoryId}
                />
              </button>
            </Link>
          )}
        </div>
      </div>

      {card && card.slug && (
        <>
          <p className="c-modal-lb__name / w-64 xs:w-full / mb-0.5 leading-tight">
            {card._type === 'card' ? (
              <LinkToRedirect
                externalId={categoryId}
                linkParams={card.applyNowLinkParameters}
                orderId={orderId}
                query={undefined}
                slug={card.slug}
              >
                <span
                  className="inline-block / w-full / text-fs13 font-bold text-center text-primary-mid / hover:text-primary-bright cursor-pointer / transition-all"
                  dangerouslySetInnerHTML={{
                    __html: supify(card.name),
                  }}
                />
              </LinkToRedirect>
            ) : (
              <Link
                href={
                  card._type === 'nonPaidCard' ? card.moreInfoLink : card.link
                }
                target="_blank"
                rel="nofollow"
              >
                <span
                  className="inline-block / w-full / text-fs13 font-bold text-center text-primary-mid / hover:text-primary-bright cursor-pointer / transition-all"
                  dangerouslySetInnerHTML={{
                    __html: supify(card.name),
                  }}
                />
              </Link>
            )}
          </p>
          <div className="c-modal-lb__rating">
            {card._type === 'card' ||
              (card._type === 'nonPaidCard' && (
                <StarRating
                  column={true}
                  marginBottom="mb-0.5"
                  productSlug={card.slug}
                  stars={card.expertReviewRating}
                  userAvgRating={card.visitorReviewsAverageRating}
                  visitorReviewsTotalCount={card.visitorReviewsTotalCount}
                />
              ))}
          </div>
        </>
      )}
    </div>
  )
}
