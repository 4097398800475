import React from 'react'
import { CompareCredit } from '../../../types'
import { useExperiment } from 'utils/use-experiment-wrapper'

import { ByTag as Filter } from 'components/filters/by-tag'
import { CardList, NoOffers } from 'components/'
import { LoadingSkeleton } from 'components/card-list/loading-skeleton'
import ErrorBoundary from 'components/error-boundary'
import { useFetchCardsSlug } from 'utils/'
import { pinEntities } from 'utils/pin-entities'
import mapEntityPositions from 'utils/mapEntityPositions'
import { TagCategories, bestTags } from './categoryTags'

interface pinnedProductsByCategory {
  [selectedTag: string]: CompareCredit.PinnedProductFormatted
}

export function ByCategory(props: {
  category: string
  cards: CompareCredit.BestPageCards
  categoryDescription: any[]
  categoryListBanner?: CompareCredit.CategoryListBanner
  referencedCards: CompareCredit.Entities[]
  pinnedProducts: pinnedProductsByCategory
}) {
  const { cards: categories } = props

  return (
    <div className="w-full">
      <div id="filter-best-category">
        <div>
          <Filter
            categoryDescription={props.categoryDescription}
            tags={Object.values(tags)}
            referencedCards={props.referencedCards}
            render={(selectedTag) => {
              const [cardSlugs, setCardSlugs] = React.useState<string[]>([])
              const sortParams = {
                categoryId: tags[selectedTag].code,
              }
              const { cards, isFetching, error } = useFetchCardsSlug(
                cardSlugs,
                sortParams,
                (entities) => {
                  const pinnedPositions = mapEntityPositions(cardSlugs)

                  const { experiment, isLoading } = useExperiment(
                    'pinning_sort_position',
                  )

                  return !isLoading && experiment.get('pinned', true)
                    ? pinEntities(
                        entities,
                        props.pinnedProducts[selectedTag],
                        pinnedPositions,
                      )
                    : entities
                },
              )
              React.useEffect(() => {
                const cardSlugs = categories[selectedTag]?.cards
                setCardSlugs(cardSlugs)
                return () => {
                  setCardSlugs([])
                }
              }, [selectedTag])

              const loadingSkeletonNumber =
                cards && cards.length > 0 ? cards.length : 3

              return (
                <>
                  {isFetching ? (
                    <LoadingSkeleton n={loadingSkeletonNumber} />
                  ) : (
                    <ErrorBoundary key={`best:${selectedTag}`}>
                      <CardList
                        slugs={cardSlugs}
                        entities={cards}
                        category={`best:${selectedTag}`}
                        categoryId={tags[selectedTag].code}
                        externalId={tags[selectedTag].code} // Need to follow up to make sure this is correct
                        categoryListBanner={props.categoryListBanner}
                      />
                    </ErrorBoundary>
                  )}
                  {error && <NoOffers />}
                </>
              )
            }}
            desktopMax={7}
          />
        </div>
      </div>
    </div>
  )
}

const tags: TagCategories = bestTags
