import React from 'react'
import Link from 'next/link'
import Image from 'next/image'
import imageUrlBuilder from '@sanity/image-url'
import { createClient } from '@sanity/client'
import { nextImageHelper } from '../../utils'

export const CategoryTile = (props: {
  href: string
  icon: any
  label: string
  type: string
}) => {
  const { href, icon, label, type } = props

  const builder = imageUrlBuilder(
    createClient({
      projectId: process.env.SANITY_PROJECT_ID || '',
      dataset: process.env.SANITY_DATA_SET || '',
      apiVersion: '1',
      useCdn: false, // `false` if you want to ensure fresh data for every query
    }),
  )

  const urlFor = (source: any) => {
    return builder.image(source) as any
  }

  return (
    <div className="flex-grow / w-full xxs:w-1/2 xs:w-1/3 lg:w-1/6 / text-center / hover:bg-primary-light-bg hover:text-primary-mid / tile-border border-r border-b border-gray-200 transition-all">
      <Link
        className="flex flex-col items-center justify-center / w-28 h-28 / mx-auto / transition-all"
        href={
          type === 'business'
            ? `/credit-cards/business/${href === 'business' ? '' : `${href}`}`
            : `/credit-cards/best/${href === 'best' ? '' : `${href}`}`
        }
      >
        <div>
          <Image
            alt={`${label} icon`}
            aria-hidden={true}
            src={urlFor(icon).url()}
            height={48}
            width={48}
            style={nextImageHelper.intrinsic}
          />
        </div>
        {label}
      </Link>
      <style jsx>
        {`
          @media (max-width: 479px) {
            .tile-border:nth-child(odd) {
              border-right: 1px solid
                rgba(237, 242, 247, var(--tw-border-opacity));
            }
          }

          @media (min-width: 480px) and (max-width: 1023px) {
            .tile-border:nth-child(3n) {
              border-right: 1px solid
                rgba(237, 242, 247, var(--tw-border-opacity));
            }
          }

          @media (min-width: 1024px) {
            .tile-border:last-child {
              border-right: none;
            }
          }
        `}
      </style>
    </div>
  )
}

export const DefaultTile = (props: {
  href: string
  icon: string
  label: string
}) => {
  const { href, icon, label } = props
  return (
    <div className="flex-grow / w-full xxs:w-1/2 / text-center / hover:bg-primary-light-bg hover:text-primary-mid / tile-border border-r border-b border-gray-200 transition-all">
      <Link
        href={href}
        className="flex flex-col items-center justify-center / w-28 h-28 / mx-auto / transition-all"
      >
        <div>
          <Image
            alt={`${label} icon`}
            src={`/static/icons/categories/${icon}.svg`}
            height={48}
            width={48}
            style={nextImageHelper.intrinsic}
          />
        </div>
        {label}
      </Link>
    </div>
  )
}
