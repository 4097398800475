import React from 'react'

export function DropdownMenu(props: {
  dropdownActive: boolean
  selectedCat: { id: string; label: string }
  setDropdownActive: React.Dispatch<React.SetStateAction<boolean>>
  setSelectedCat: React.Dispatch<
    React.SetStateAction<{ id: string; label: string }>
  >
}) {
  const { dropdownActive, selectedCat, setDropdownActive, setSelectedCat } =
    props

  const handleCatSelection = (category: { id: string; label: string }) => {
    setDropdownActive(false)
    setSelectedCat(category)
  }
  return (
    <div
      className={`c-dropdown / absolute left-0 z-1 / ${
        dropdownActive ? 'block' : 'hidden'
      }`}
    >
      <ul
        className={`c-dropdown__menu / flex flex-col overflow-hidden / w-full / bg-white shadow-xl rounded rounded-t-none / font-sans text-sm sm:text-base font-normal / transition-all`}
      >
        {[
          {
            id: 'balance-transfer',
            label: 'Balance Transfer',
          },
          {
            id: 'low-interest',
            label: 'Low Interest',
          },
          {
            id: 'rewards',
            label: 'Rewards',
          },
          {
            id: 'cash-back',
            label: 'Cash Back',
          },
          {
            id: 'no-annual-fee',
            label: 'No Annual Fee',
          },
          {
            id: 'travel',
            label: 'Travel',
          },
        ].map((category) => (
          <li key={category.id}>
            <button
              className={`flex / w-full / px-3 sm:px-6 py-2.5 / hover:bg-primary-light-bg / ${
                selectedCat.id === category.id && 'bg-primary-light-bg'
              }`}
              onClick={() => handleCatSelection(category)}
            >
              <img
                className="w-5 mr-2 sm:mr-3"
                alt=""
                src={`/static/icons/categories/${category.id}.svg`}
              />
              {category.label}
            </button>
          </li>
        ))}
      </ul>
      <style jsx>
        {`
          .c-dropdown__menu {
            top: 100%;
            max-height: 30rem;
            min-width: 10.33rem;
          }
          @media (min-width: 480px) {
            .c-dropdown__menu {
              min-width: 12rem;
            }
          }
          @media (min-width: 640px) {
            .c-dropdown__menu {
              min-width: 14.5rem;
            }
          }
        `}
      </style>
    </div>
  )
}
