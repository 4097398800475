import {
  PortableText,
  PortableTextListComponent,
  PortableTextListItemComponent,
} from '@portabletext/react'

import { CompareCredit } from '../../../types/compare-credit'
import React from 'react'
import propOr from 'lodash/fp/propOr'
import { supify } from '../../utils'

export function ProsAndCons(props: {
  card: CompareCredit.FormattedCard | CompareCredit.NonPaidCard
  view?: 'list' | 'details'
}) {
  const { card } = props

  const list = ({ children }: { children: any }) => (
    <ul className="c-list-disc c-list-disc--blue / pl-5 py-1 text-sm">
      {children}
    </ul>
  )

  const listItem = ({ children }: { children: any }) => (
    <li className="c-list-disc__item / mb-2">{children}</li>
  )

  function AttributeRenderer<K extends keyof CompareCredit.Entity>(attrProps: {
    value: { attribute: K }
  }) {
    return React.createElement('span', {
      dangerouslySetInnerHTML: {
        __html: supify(
          propOr('', attrProps.value.attribute, props.card)?.toString(),
        ),
      },
    })
  }

  return (
    <div
      className={`lg:flex ${
        props.view === 'details' && 'md:flex md:flex-col md:w-full'
      }`}
    >
      <div
        className={`w-full ${
          props.view !== 'details' ? 'lg:w-1/2' : 'pb-4'
        } lg:pr-4 / mb-4 lg:mb-0`}
      >
        <p className="text-sm uppercase text-secondary font-bold flex">
          <img
            className="inline-block mr-2 w-5"
            alt=""
            src="/static/icons/icon-thumbs-up-green.svg"
          />{' '}
          Pros
        </p>
        {card.expertReviewPros && (
          <PortableText
            value={card.expertReviewPros as any}
            components={{
              list: list as PortableTextListComponent,
              listItem: listItem as PortableTextListItemComponent,
              types: {
                cardAttribute: AttributeRenderer,
              },
            }}
          />
        )}
      </div>

      <div
        className={`w-full ${props.view !== 'details' && 'lg:w-1/2 lg:pl-4'} `}
      >
        <p className="text-sm uppercase text-primary font-bold flex">
          <img
            className="inline-block mr-2 w-5"
            alt=""
            src="/static/icons/icon-thumbs-down-blue.svg"
          />{' '}
          Cons
        </p>
        {card.expertReviewCons && (
          <PortableText
            value={card.expertReviewCons as any}
            components={{
              list: list as PortableTextListComponent,
              listItem: listItem as PortableTextListItemComponent,
              types: {
                cardAttribute: AttributeRenderer,
              },
            }}
          />
        )}
      </div>
    </div>
  )
}
