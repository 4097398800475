import smoothScroll from 'smoothscroll-polyfill'

export function autoScroll(targetEl: HTMLElement, offset?: number): void {
  const yOffset = offset || 0
  const y = targetEl.getBoundingClientRect().top + window.pageYOffset + yOffset
  smoothScroll.polyfill()
  window.scrollTo({
    top: y,
    behavior: 'smooth',
  })
}
