import React from 'react'
import { InstagramEmbed } from 'react-social-media-embed'

export const InstagramPost = (props: { value: { url: string } }) => {
  const { value } = props
  return (
    <div className="lg:overflow-scroll mt-4">
      <div className="c-video-social / flex lg:flex-row flex-col justify-center max-h-">
        <div className="c-video-social__item">
          <InstagramEmbed url={value.url} />
        </div>
      </div>
    </div>
  )
}
