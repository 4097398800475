import React from 'react'

export function Close() {
  return (
    <svg
      width="20px"
      height="19px"
      viewBox="0 0 20 19"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>close</title>
      <desc>Created with Sketch.</desc>
      <g
        id="_components"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
      >
        <g
          id="close"
          transform="translate(1.000000, 0.000000)"
          stroke="#052F5E"
          strokeWidth={2}
        >
          <line
            x1="-2.47826087"
            y1="9.5"
            x2="20.4782609"
            y2="9.5"
            id="Line-5"
            transform="translate(9.000000, 9.500000) rotate(-315.000000) translate(-9.000000, -9.500000) "
          />
          <line
            x1="0.883643903"
            y1="17.6163561"
            x2="17.1163561"
            y2="1.3836439"
            id="Line-5-Copy-2"
          />
        </g>
      </g>
    </svg>
  )
}
