import React from 'react'

export const JumpLinkSection = (props: any) => {
  return (
    <div
      id={props.value.sectionId}
      className={`c-jump-link-section h-0`}
      role="presentation"
    >
      &nbsp;
    </div>
  )
}
