import React from 'react'
import Image from 'next/image'
import { createClient } from '@sanity/client'
import { useNextSanityImage } from 'next-sanity-image'
import { nextImageHelper } from '../../utils'

const client: any = createClient({
  dataset: process.env.SANITY_DATA_SET,
  projectId: process.env.SANITY_PROJECT_ID,
  apiVersion: '1',
  useCdn: true,
  useProjectHostname: false,
})
export const PhotoRenderer = (block: any) => {
  // @ts-ignore
  const { height, src, width } = useNextSanityImage(client, block.value.image)
  return (
    <Image
      alt={block.value.image.altText}
      src={src}
      height={height}
      width={width}
      loading="lazy"
      style={nextImageHelper.intrinsic}
      placeholder="blur"
      blurDataURL={block.value.image.metadata.lqip}
      className="c-image-renderer"
    />
  )
}
