import React from 'react'

export function CardTileLoadingSkeleton() {
  return (
    <div className="w-full">
      <div className="c-card-tile / relative / p-4 md:pt-0 lg:px-6 xl:px-8 xl:pb-6 / rounded shadow-lg bg-gradient-blue-green-270">
        <div className="relative z-2 / flex flex-row flex-wrap items-start / md:flex-col">
          <div
            className={`c-card-art / text-center / flex items-center justify-center / w-1/4 md:w-3/4 lg:w-5/6 / mx-auto mb-1.5 md:-mt-4`}
          >
            <div className={`relative / w-full / rounded-sm / overflow-hidden`}>
              <div className="relative / z-0 / w-full h-12 xs:h-16 sm:h-20 md:h-24 xl:h-28 / bg-gray-300 rounded-sm animate-pulse"></div>
            </div>
          </div>
          <div className="flex flex-col md:justify-center md:items-center / w-3/4 md:w-full / pl-4 md:pl-0">
            <p className="w-10/12 md:w-3/4 lg:w-5/6 h-6 / mb-1.5 / bg-gray-300 rounded-sm animate-pulse">
              &nbsp;
            </p>

            <p className="w-28 h-6 / mb-1.5 / bg-gray-300 rounded-sm animate-pulse">
              &nbsp;
            </p>

            <p className="w-full h-8 md:h-9 / bg-gray-300 rounded-sm animate-pulse">
              &nbsp;
            </p>
          </div>
        </div>
        <div className="absolute left-0 top-0 / w-full h-40">
          <div className="w-full h-12 bg-white">&nbsp;</div>
          <img
            src="/static/images/bg-bot-mask-white-sm-2.png"
            className="c-bg-wave__bg-mask / w-full / rotate-180"
            alt="background mask"
            aria-hidden={true}
          />
        </div>
      </div>
    </div>
  )
}
