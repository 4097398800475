import React from 'react'
import pathOr from 'lodash/fp/pathOr'

import { ArrowDown, ArrowUp } from '../icons'

export function Select(props: {
  children: React.ReactElement[]
  value: string | number
  onChange?: (arg1: string | number) => void
}) {
  const [dropdownActive, setDropdownActive] = React.useState(false)

  const updatedChildren = React.Children.map(props.children, (child) => {
    return React.cloneElement(child, {
      selected: props.value === child.props.value,
      onClick: () => props.onChange && props.onChange(child.props.value),
    })
  })

  const selectedChild = React.Children.toArray(props.children).find((child) => {
    return (child as React.ReactElement).props.value === props.value
  })

  const handleClickOnMenu = () => setDropdownActive(!dropdownActive)
  /***********************************************************************
   * Clickaway listener for closing dropdown menu
   */
  const mobileRef = React.createRef<HTMLDivElement>()

  const useClickAwayListener = (ref: React.RefObject<HTMLDivElement>) => {
    const handleClickAway = (
      event: React.MouseEvent<HTMLElement, MouseEvent>,
    ) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setDropdownActive(false)
      }
    }
    React.useEffect(() => {
      // @ts-ignore
      document.addEventListener('mousedown', handleClickAway)
      return () => {
        // @ts-ignore
        document.removeEventListener('mousedown', handleClickAway)
      }
    })
  }
  useClickAwayListener(mobileRef)

  return (
    <div className="max-w-md mx-auto" ref={mobileRef}>
      <div className="block / w-full">
        <div className="relative w-full">
          <button
            className={`${
              dropdownActive
                ? 'bg-white shadow-lg border-primary-light'
                : 'bg-white shadow'
            } relative / w-full / focus:outline-none py-2 px-6 / rounded-full border border-transparent /
            text-center text text-primary font-semibold / hover:shadow-lg hover:border-primary-light / transition-all
            `}
            onClick={handleClickOnMenu}
          >
            <div className="">
              <div className="">
                <span>{pathOr('', ['props', 'children'], selectedChild)}</span>
              </div>
              <span className={`c-dropdown__arrow absolute`}>
                <ArrowDown
                  className={`${dropdownActive ? 'hidden' : 'block'}`}
                  color="#a0aec0"
                />
                <ArrowUp
                  className={`${dropdownActive ? 'block -mt-1' : 'hidden'}`}
                  color="#21A3FC"
                />
              </span>
            </div>
          </button>
          {/* dropdown menu */}
          <div
            className={`c-dropdown__menu / absolute / z-30 ${
              dropdownActive ? 'block' : 'is-closed'
            }`}
          >
            <ul className="c-dropdown__list / flex flex-col shadow-md xs:flex-row xs:flex-wrap / rounded-lg bg-white px-2 py-2 / xs:px-1 xs:py-3">
              {updatedChildren}
            </ul>
          </div>
        </div>
      </div>
      <style jsx>{`
        .c-dropdown__arrow {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 1.25rem;
        }
        .c-dropdown__menu {
          top: 100%;
          width: 100%;
          max-height: 60rem;
          opacity: 1;
          overflow: visible;
          transition: all 0.25s ease-in-out;
        }
        .c-dropdown__menu.is-closed {
          max-height: 0;
          opacity: 0;
          overflow: hidden;
        }
      `}</style>
    </div>
  )
}
