import React from 'react'
import Link from 'next/link'
import {
  PortableTextBlockComponent,
  PortableTextListComponent,
  PortableText,
} from '@portabletext/react'

import { CompareCredit } from '../../../../types/compare-credit'
import { supify } from '../../../utils'
import { CardArt } from '../../'

export function CardTile(props: {
  card: CompareCredit.FormattedCard
  title: string
  description: any
  categoryId: string
  externalId: string
  cardOrderIds?: null | Record<string, string>
}) {
  const { card, title, description, categoryId, externalId } = props

  function AttributeRenderer<K extends keyof CompareCredit.Entity>(attrProps: {
    value: { attribute: K }
  }) {
    return (
      <span>{props.card[attrProps.value.attribute] as React.ReactNode}</span>
    )
  }
  const Normal = ({ children }: { children: any }) => <span>{children}</span>

  return (
    <div className="c-tile__container / flex items-stretch / w-full">
      <Link
        href={`/credit-cards/${card.issuer.slug.current}/${card.slug}`}
        className="c-tile / flex flex-col justify-between items-stretch / w-full / p-3 md:p-0 / bg-white rounded shadow transition-all / hover:bg-primary-light-bg-2 hover:shadow-lg"
      >
        <div className="c-tile__card-art-wrap / md:flex md:flex-row md:items-stretch">
          <div className="c-tile__art-wrap / flex items-stretch / shrink-0 / w-full md:w-36 lg:w-48 h-28 md:h-auto / mb-2 md:mb-0 / rounded overflow-hidden transition-all / md:rounded-br-none md:rounded-tr-none">
            <div className="flex items-center justify-center / w-full">
              <span className="c-tile__art transition-all / rounded-sm overflow-hidden">
                <CardArt
                  _rev={card._rev}
                  cardArt={card.cardArt}
                  customCodeSnippets={card.customCodeSnippets}
                  issuer={card.issuer.slug.current}
                  name={card.name}
                  slug={card.slug}
                  categoryId={categoryId}
                  externalId={externalId}
                />
              </span>
            </div>
          </div>
          <div className="c-tile__content / md:flex md:flex-col / md:w-full / md:px-8 md:py-4">
            <h3
              className="c-tile__title / mb-2 md:mb-1 / text-primary leading-tight text-lg md:text-xl font-semibold / transition-all"
              dangerouslySetInnerHTML={{
                __html: supify(`${title}`),
              }}
            />
            <div className="c-tile__description / text-sm lg:text-base text-slate-600">
              <PortableText
                value={description}
                components={{
                  block: { normal: Normal as PortableTextBlockComponent },
                  list: {
                    bullet: ((props: { children: React.ReactElement }) => (
                      <ul className="c-tile-list / mb-1 pl-5 / list-disc">
                        {props.children}
                      </ul>
                    )) as PortableTextListComponent,
                  },
                  types: {
                    advertorialAttribute: AttributeRenderer,
                  },
                }}
              />
              <span className="c-tile__learn-more / pt-1 / font-bold text-primary-bright / transition-all / flex">
                Learn More
              </span>
            </div>
          </div>
        </div>
      </Link>
      <style jsx>{`
        .c-tile-list li {
          margin-bottom: 0.25rem;
        }
        .c-tile__art {
          width: 80%;
        }
        .c-tile:hover .c-tile__art {
          width: 85%;
        }
        .c-tile:hover .c-tile__learn-more {
          color: #0047ab;
        }

        .c-tile__art-wrap {
          background: linear-gradient(
            to bottom,
            rgba(240, 250, 255, 1) 0%,
            rgba(209, 239, 255, 1) 100%
          );
        }
        .c-tile:hover .c-tile__art-wrap {
          background: linear-gradient(
            to bottom,
            rgba(225, 239, 226, 1) 0%,
            rgba(209, 239, 255, 1) 100%
          );
        }
        @media (min-width: 1024px) {
          .c-tile__art {
            width: 75%;
          }
          .c-tile:hover .c-tile__art {
            width: 80%;
          }
        }
      `}</style>
    </div>
  )
}
