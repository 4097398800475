import React from 'react'
import * as Sentry from '@sentry/nextjs'

import { checkErrorInstance } from '../utils/check-error-instance'

export default class ErrorBoundary extends React.Component<React.PropsWithChildren> {
  constructor(props: any) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error: Error) {
    const err = checkErrorInstance(error)

    Sentry.captureException(err)
  }

  render() {
    // @ts-ignore
    if (this.state.hasError) {
      return null
    }

    return this.props.children
  }
}
