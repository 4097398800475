import React from 'react'
import Image from 'next/image'
import { CompareCredit } from '../../../types/compare-credit'
import { CreditDisclosure } from '../credit-disclosure'
import { nextImageHelper } from '../../utils'
import cn from 'utils/classnames'

export function CardRecommendedCredit(props: {
  card: CompareCredit.FormattedCard | CompareCredit.NonPaidCard
  align?: string | null
  disclosureStyling?: string
  style?: string | null
  iconHide?: boolean
  maxW?: string | null
}) {
  const { card, align, disclosureStyling, style, iconHide, maxW } = props

  return (
    <div
      className={cn(
        'c-rec-credit / w-full / py-1 / text-fs13 text-center / rounded-sm',
        style,
        maxW,
        {
          'bg-primary-light-bg': !style,
          'sm:max-w-xs': !maxW,
        },
      )}
    >
      {card.creditNeeded && (
        <span
          className={`c-rec-credit__text / flex items-center / -ml-1 / leading-tight font-bold text-primary tracking-wide ${
            align === 'left' ? 'justify-start' : 'justify-center'
          }`}
        >
          {card.creditNeeded.toLowerCase().startsWith('excellent') && (
            <span
              className={`c-rec-credit__icon / w-7 mr-2 ${
                iconHide ? 'hidden md:inline-block' : 'inline-block'
              }`}
            >
              {card.creditNeeded.toLowerCase().includes('good') ? (
                <Image
                  alt="good credit"
                  height={12}
                  width={12}
                  src={`/static/icons/credit-quality/good.svg`}
                  aria-hidden={true}
                  style={nextImageHelper.responsive}
                  sizes="100vw"
                />
              ) : (
                <Image
                  alt="excellent credit"
                  height={12}
                  width={12}
                  src={`/static/icons/credit-quality/excellent.svg`}
                  aria-hidden={true}
                  style={nextImageHelper.responsive}
                  sizes="100vw"
                />
              )}
            </span>
          )}

          {card.creditNeeded.toLowerCase().startsWith('good') && (
            <span className="c-rec-credit__icon / inline-block w-7 mr-2">
              <Image
                alt="good credit"
                height={12}
                width={12}
                src={`/static/icons/credit-quality/good.svg`}
                aria-hidden={true}
                style={nextImageHelper.responsive}
                sizes="100vw"
              />
            </span>
          )}

          {card.creditNeeded.toLowerCase().startsWith('average') ||
          card.creditNeeded.toLowerCase().startsWith('fair') ? (
            <span className="c-rec-credit__icon / inline-block w-7 mr-2">
              <Image
                alt="average credit"
                height={12}
                width={12}
                src={`/static/icons/credit-quality/fair.svg`}
                aria-hidden={true}
                style={nextImageHelper.responsive}
                sizes="100vw"
              />
            </span>
          ) : null}

          {card.creditNeeded.toLowerCase().startsWith('bad') ||
          card.creditNeeded.toLowerCase().startsWith('poor') ? (
            <span className="c-rec-credit__icon / inline-block w-7 mr-2">
              <Image
                alt="bad credit"
                height={12}
                width={12}
                src={`/static/icons/credit-quality/poor.svg`}
                aria-hidden={true}
                style={nextImageHelper.responsive}
                sizes="100vw"
              />
            </span>
          ) : null}

          {card.creditNeeded.toLowerCase().startsWith('limited') && (
            <span className="c-rec-credit__icon / inline-block w-7 mr-2">
              <Image
                alt="limited credit"
                height={12}
                width={12}
                src={`/static/icons/credit-quality/limited.svg`}
                aria-hidden={true}
                style={nextImageHelper.responsive}
                sizes="100vw"
              />
            </span>
          )}

          {card.creditNeeded}
        </span>
      )}

      <span
        className={`c-rec-credit__range / relative z-2 / flex items-center / -mt-1 / text-xs leading-tight ${
          align === 'left' ? 'justify-start' : 'justify-center'
        }`}
      >
        <span>
          {card.creditRange &&
            `Recommended Credit (${card.creditRange.min}-${card.creditRange.max})`}
        </span>
        {card.creditRange && (
          <CreditDisclosure
            theme={`t-card-tile ${
              disclosureStyling ? `${disclosureStyling}` : ''
            }`}
            id={card.slug}
          />
        )}
      </span>
    </div>
  )
}
