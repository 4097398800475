import Analytics from 'analytics-node'
import Cookies from 'js-cookie'
import assign from 'lodash/fp/assign'
import * as Sentry from '@sentry/nextjs'

import { Segment } from '../../types'
import { CompareCredit } from '../../types/compare-credit'
import getUserAgentInfo from '../utils/getUserAgentInfo'
import { omitLocationStatus } from '../utils/omitLocationStatus'
import { EstimatedRprEvent } from '../utils/rpr/rpr-service-helpers-types'
import { hashString } from './_helpers'
import { sendConversion } from './gtag'

export function allocateTraffic(
  data: Omit<Segment.EventData['Traffic Allocated'], 'nonInteraction'>,
) {
  track('Traffic Allocated', {
    ...data,
    nonInteraction: 1,
  })
}

export function captureUser(
  email: string | null,
  acquisitionSource: Segment.AcquisitionSource,
) {
  identify(email, { acquisitionSource })
}

export function captureUserTraits(
  email: string | null,
  traits: Record<string, Segment.AcceptedTraitType>,
) {
  identify(email, traits)
}

export function addProductToCart(
  data: Omit<Segment.EventData['Product Added'], 'version'>,
) {
  track('Product Added', {
    ...data,
    version: '1.0',
  })
}

export function clickProduct(
  data: Omit<Segment.EventData['Product Clicked'], 'version'>,
) {
  track('Product Clicked', {
    ...data,
    version: '1.0',
  })
}

export function clickPromotion(
  data: Omit<Segment.EventData['Promotion Clicked'], 'version'>,
) {
  track('Promotion Clicked', {
    ...data,
    version: '1.0',
  })
}

export function clickCreditScoreFilter(
  data: Omit<Segment.CreditScore, 'not sure'>,
) {
  track('Credit Filter Interaction', {
    category: 'credit-card',
    creditScore: data,
    action: 'clicked',
    label: data,
    version: '1.0',
  })
}

export function businessSegmentationFilterInteraction(
  data: Segment.BusinessFilterData,
) {
  track('Business Segmentation Interaction', {
    category: 'business-filter',
    creditScore: data.creditScore,
    action: 'clicked',
    legalEntity: data.legalEntity,
    version: '1.0',
    step: data.step,
  })
}

export function clickRatesAndFees(
  data: Omit<Segment.EventData['Rates and Fees'], 'version'>,
) {
  track('Rates and Fees', {
    ...data,
    version: '1.0',
  })
}

export function viewPromotion(
  data: Omit<
    Segment.EventData['Promotion Viewed'],
    'nonInteraction' | 'version'
  >,
) {
  track('Promotion Viewed', {
    ...data,
    nonInteraction: 1,
    version: '1.0',
  })
}

export function removeProductFromCart(
  data: Omit<Segment.EventData['Product Removed'], 'version'>,
) {
  track('Product Removed', {
    ...data,
    version: '1.0',
  })
}

export function reviewProduct(
  data: Omit<Segment.EventData['Product Reviewed'], 'version'>,
) {
  track('Product Reviewed', {
    ...data,
    version: '1.0',
  })
}
const trackedOrderIds: Record<string, boolean> = {}

export function makeImpression(
  data: Omit<
    Segment.EventData['Impression Made'],
    'nonInteraction' | 'version'
  >,
) {
  const preparedData = omitLocationStatus(data) as any
  if (data.order_id && !trackedOrderIds[data.order_id]) {
    fireImpression(preparedData)
    trackedOrderIds[data.order_id] = true
  } else if (!data.order_id) {
    fireImpression(preparedData)
  }
}

export function fireImpression(
  data: Omit<
    Segment.EventData['Impression Made'],
    'nonInteraction' | 'version'
  >,
) {
  track('Impression Made', {
    ...data,
    nonInteraction: 1,
    version: '2.1',
  })
}

export function observeExperimentResult(data: {
  experimentId: string
  variation: string
  value: number
}) {
  const { experimentId, variation, value } = data
  track('Experiment Result Observed', {
    experiment_id: experimentId,
    experiment_name: experimentId,
    nonInteraction: 1,
    value,
    variation_id: variation,
    variation_name: variation,
    version: '1.0',
  })
}

export function viewExperiment(
  data: Omit<
    Segment.EventData['Experiment Viewed'],
    'nonInteraction' | 'version'
  >,
) {
  track('Experiment Viewed', {
    ...data,
    nonInteraction: 1,
    version: '1.0',
  })
}

export function viewProduct(
  data: Omit<Segment.EventData['Product Viewed'], 'nonInteraction' | 'version'>,
) {
  track('Product Viewed', {
    ...data,
    nonInteraction: 1,
    version: '2.0',
  })
}

export function viewProductList(data: {
  entities: Array<CompareCredit.Entities>
  category: string
  arrangementId?: string
}) {
  if (data.arrangementId === 'primary' || data.arrangementId === null) {
    // Send to Sentry
    Sentry.captureMessage('arrangementId is primary or null', {
      contexts: {
        context: {
          arrangementId: data.arrangementId,
          file: '/clients/segment.ts | viewProductList',
          path: window.location.pathname,
        },
      },
    })
  }

  const cardsToProducts = (
    entities: CompareCredit.FormattedCard,
    idx: number,
  ) => {
    const commonProps = {
      type: entities._type,
      name: entities.name,
      position: idx + 1,
    }

    return entities._type === 'card'
      ? {
          ...commonProps,
          brand: entities.issuer.slug.current,
          id: entities.slug,
          product_id: entities.slug,
          sku: entities.slug,
        }
      : {
          ...commonProps,
          id: entities.slug.current,
          product_id: entities.slug.current,
          sku: entities.slug.current,
        }
  }

  const products = data.entities.map((entity, idx) =>
    cardsToProducts(entity as CompareCredit.FormattedCard, idx),
  )

  const { arrangementId } = data

  track('Product List Viewed', {
    category: data.category,
    nonInteraction: 1,
    products,
    version: '1.1',
    ...(arrangementId && { arrangementId }),
  })
}

export function clickCategoryTag(list_id: string, category: string) {
  track('Product List Filtered', {
    list_id,
    category,
  })
}
/**
 * Send a Checkout Started event to Segment.
 *
 * Optionally, and because **Segment** does not allow fine-grain control over
 * how to map **Conversion Actions** we can also specify that we want the
 * conversion to be sent directly to Google by specifying the conversion label.
 *
 * @param data
 * @param [phone=false] Indicate whether or not this indicates a click-to-call
 * event or not.
 * @param [conversion=] Provide the conversion label in the form of
 * `CONVERSION_ID/CONVERSION_LABEL` if we want to send this as a conversion
 * action to Google. The `CONVERSION_ID` and `CONVERSION_LABEL` can be found on
 * our Google Ads dashboard under Tools & Settings > Measurements > Conversions.
 * More info can be found here: https://support.google.com/google-ads/answer/6331304
 */
export function startCheckout(
  data: Omit<
    Segment.EventData['Checkout Started'],
    'label' | 'success' | 'test' | 'type' | 'version'
  >,
  phone = false,
  conversion?: string,
) {
  const fb_click_id = Cookies.get('_fbc') || null
  const ga_client_id = Cookies.get('_ga') || null

  return new Promise<void>((resolve) => {
    const type = phone ? 'phone-out' : 'click-out'
    trackAsync('Checkout Started', {
      ...data,
      fb_click_id,
      ga_client_id,
      label: type,
      success: true,
      test: false,
      type,
      value: 1.0,
      version: '3.2',
    }).then(() => {
      if (conversion) {
        sendConversion(conversion, data.revenue)
      }
      resolve()
    })
  })
}

export function modalExtensionClicked() {
  track('Modal Extension Download Now Clicked', { version: '1.0' })
}

export function modalExtensionClosed() {
  track('Modal Extension Closed', { version: '1.0' })
}

export function modalExtensionWatched() {
  track('Modal Extension Video Watched', {
    version: '1.0',
  })
}

export function modalExtensionOpened() {
  track('Modal Extension Opened', {
    version: '1.0',
  })
}

export function exitModalApplyNowClicked(
  data: Omit<Segment.EventData['Exit Modal Apply Now Clicked'], 'version'>,
) {
  track('Exit Modal Apply Now Clicked', {
    ...data,
    version: '1.0',
  })
}

export function exitModalClosed(
  data: Omit<Segment.EventData['Exit Modal Closed'], 'version'>,
) {
  track('Exit Modal Closed', {
    ...data,
    version: '1.0',
  })
}

export function exitModalShown(
  data: Omit<Segment.EventData['Exit Modal Shown'], 'version'>,
) {
  track('Exit Modal Shown', {
    ...data,
    version: '1.0',
  })
}

export function trackFormInput(
  segmentData: Segment.EventData['freedom-dividend-input'],
) {
  track('freedom-dividend-input', {
    ...segmentData,
  })
}

export function submitContestEntry(
  segmentData: Omit<Segment.EventData['Contest Entry Submitted'], 'version'>,
) {
  track('Contest Entry Submitted', {
    ...segmentData,
    version: '1.1',
  })
}

export function updatePersonalInformation(
  email: string,
  personalInformation: {
    'do-not-sell'?: boolean
    'first-name'?: string
    'last-name'?: string
    name?: string
    email?: string
  },
) {
  captureUserTraits(email, personalInformation)
}

export function requestPersonalInformation() {
  track('Personal Info Requested', { version: '1.0' })
}

export function trackUserInteraction(
  segmentData: Omit<Segment.EventData['User Interaction Received'], 'version'>,
) {
  track('User Interaction Received', { ...segmentData, version: '1.0' })
}

export function tipVideoWatched(slug: string, videoId: string) {
  track('Tip Video Watched', {
    slug,
    videoId,
    version: '1.0',
  })
}
// TODO: once we've converged on an acceptable UserModel, we'll want to type our
// traits here as something like `Partial<Segment.UserTraits>` so that we ensure
// we don't introduce new traits without first documenting them.
export async function identify(
  email: string | null,
  traits?: Record<string, Segment.AcceptedTraitType>,
) {
  const id = email ? await hashString(email) : null
  try {
    const identify = ((window as any).analytics as Segment.GlobalObj).identify
    if (id) {
      identify(id, traits)
    } else {
      traits && identify(traits)
    }
  } catch (err) {
    Sentry.captureException(err, {
      tags: {
        client: 'segment',
      },
      contexts: {
        context: {
          operation: 'identify',
          id,
        },
      },
    })
  }
}

function trackAsync<T extends keyof Segment.EventData>(
  eventName: T,
  data: Segment.EventData[T],
): Promise<void> {
  return new Promise((resolve) => {
    const redirectTimeout = setTimeout(() => {
      Sentry.captureException(
        new Error('Manual secure page redirect triggered'),
      )
      resolve()
    }, 5000)
    try {
      const segmentData = assign(data, {
        userAgent: getUserAgentInfo(),
      })
      ;((window as any).analytics as Segment.GlobalObj).track(
        eventName,
        segmentData,
        {},
        () => {
          clearTimeout(redirectTimeout)
          resolve()
        },
      )
    } catch (err) {
      Sentry.captureException(err, {
        tags: {
          client: 'segment',
        },
        contexts: {
          context: {
            operation: 'track',
            eventName,
          },
        },
      })
      resolve()
    }
  })
}

function track<T extends keyof Segment.EventData>(
  eventName: T,
  data: Segment.EventData[T],
): void {
  try {
    const userAgent = getUserAgentInfo()
    const impactAgentRexeg = /compatible;impact.com agent/

    if (impactAgentRexeg.test(navigator.userAgent)) {
      return
    }

    const segmentData = assign(data, {
      userAgent,
    })
    ;((window as any).analytics as Segment.GlobalObj).track(
      eventName,
      segmentData,
    )
  } catch (err) {
    Sentry.captureException(err, {
      tags: {
        client: 'segment',
      },
      contexts: {
        context: {
          operation: 'track',
          eventName,
        },
      },
    })
  }
}

export function getUserTraits(userId: string) {
  return fetch(`/api/get-user-traits/?userId=${userId}`)
    .then((res) => {
      if (!res.ok) throw new Error(res.statusText)
      return res.json()
    })
    .catch((err) => Sentry.captureException(err))
}

let memoizedClient: Analytics
export function getClient(client?: Analytics) {
  // don't call actual Segment client for e2e tests
  const segmentWriteKey = process.env.CI
    ? 'test-write-key'
    : process.env.SEGMENT_WRITE_KEY || ''
  if (client) return client
  if (!memoizedClient) {
    memoizedClient = new Analytics(segmentWriteKey)
  }

  return memoizedClient
}

async function flush(client: Analytics): Promise<void> {
  return new Promise((resolve, reject) => {
    client.flush((error: unknown) => {
      if (error) reject(error)
      resolve()
    })
  })
}

export async function estimatedRprCalculated(
  data: EstimatedRprEvent,
  segmentClient?: Analytics,
) {
  const client = getClient(segmentClient)
  try {
    client.track(transformRprBatchRecordToSegment(data))
  } catch (err) {
    Sentry.captureException(err, {
      tags: {
        client: 'segment',
      },
      contexts: {
        context: {
          operation: 'track',
          eventName: 'eRPR Calculated',
        },
      },
    })
  }
  await flush(client)
}

export function transformRprBatchRecordToSegment(
  data: EstimatedRprEvent,
): Segment.EventData['eRPR Calculated'] {
  const { ip, ua, ...rest } = data
  return {
    anonymousId: data.anonymous_id,
    event: 'eRPR Calculated',
    context: {
      ip,
      userAgent: ua,
    },
    properties: {
      ...rest,
    },
  }
}

export function fireHoldUpModal(
  data: Omit<Segment.EventData['Leave Behind Modal Interaction'], 'version'>,
) {
  track('Leave Behind Modal Interaction', {
    ...data,
    version: '1.0',
  })
}

export function fireNetworkRequest(
  data: Omit<Segment.EventData['NetworkRequest'], 'version'>,
) {
  track('NetworkRequest', {
    ...data,
    version: '1.0',
  })
}

export function expandProductCardClicked(
  data: Omit<
    Segment.EventData['Entity Panel Product Details Tab Clicked'],
    'version'
  >,
) {
  track('Entity Panel Product Details Tab Clicked', {
    ...data,
    version: '1.0',
  })
}

export function advertiserDisclosureClicked() {
  track('Advertiser Disclosure Toggle Clicked', {
    name: 'Advertiser Disclosure Toggle Clicked',
    version: '1.0',
  })
}

export function bannerCategoryBtnCompareClicked(
  data: Omit<
    Segment.EventData['Banner Category List Button Compare Clicked'],
    'version'
  >,
) {
  track('Banner Category List Button Compare Clicked', {
    ...data,
    version: '1.0',
  })
}

export function bannerCategoryBtnPdpClicked(
  data: Omit<
    Segment.EventData['Banner Category List Button PDP Clicked'],
    'version'
  >,
) {
  track('Banner Category List Button PDP Clicked', {
    ...data,
    version: '1.0',
  })
}

export function bannerCategoryLinkPdpClicked(
  data: Omit<
    Segment.EventData['Banner Category List Link PDP Clicked'],
    'version'
  >,
) {
  track('Banner Category List Link PDP Clicked', {
    ...data,
    version: '1.0',
  })
}
