import React from 'react'

export const EditorialNote = () => {
  return (
    <div className="c-editorial-note / w-full / text-gray-700 leading-relaxed">
      <div className="py-3 mb-4 xs:mb-6 / border-t border-b / leading-snug">
        <div className="mb-0 text-xs text-gray-600 italic">
          <b className="font-bold">Editorial Note:</b> Our editorial team&apos;s
          content is not provided or commissioned by any financial institution
          or partner. The opinions, reviews or recommendations expressed in any
          article mention are solely those of our editorial team. This page
          contains products from our advertising&nbsp;partners.
        </div>
      </div>
    </div>
  )
}
