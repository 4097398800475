import * as React from 'react'
import range from 'lodash/range'

import cn from 'utils/classnames'

interface Props {
  /**
   * Number of loading skeleton's to render.
   */
  n: number
  theme?: string
}

export function LoadingSkeleton(props: Props) {
  return (
    <>
      {range(props.n).map((idx) => (
        <div
          key={idx}
          className={cn(
            'flex flex-col items-center justify-center / w-full max-w-sm / mx-auto mt-6 md:mr-5 lg:mr-0 / pb-10 /  rounded',
            {
              'px-5 shadow-xl bg-white': props.theme != 'no-bg',
            },
          )}
        >
          {/* card image */}
          <div className="c-content-placeholder-bg transition-all w-56 h-32 -mt-6 mb-2 rounded-sm bg-gray-200"></div>
          {/* rating and cta */}
          <div className="c-content-placeholder-bg w-32 h-4 my-1 rounded-sm bg-gray-200"></div>
          <div className="c-content-placeholder-bg w-56 h-10 mb-10 my-1 rounded-sm bg-gray-200"></div>
          {/* card attributes */}
          <div className="c-content-placeholder-bg w-56 h-4 mb-2 rounded-sm bg-gray-200"></div>
          <div className="c-content-placeholder-bg w-56 h-4 mb-2 rounded-sm bg-gray-200"></div>
          <div className="c-content-placeholder-bg w-56 h-4 mb-2 rounded-sm bg-gray-200"></div>
        </div>
      ))}
    </>
  )
}

export function SlimLoadingSkeleton() {
  return (
    <div className="c-card-skeleton-slim shrink-0 w-full sm:max-w-xs">
      <div className="p-4 / rounded shadow-lg">
        <div className="flex flex-row flex-wrap items-start">
          <div className="c-content-placeholder-bg / w-1/4 h-12 xs:h-16 sm:h-12 / mx-auto mb-2 / bg-gray-100 rounded-sm"></div>
          <div className="flex flex-col / w-3/4 / pl-4">
            <p className="c-content-placeholder-bg / w-10/12 md:w-3/4 h-6 / mb-1.5 / bg-gray-100 rounded-sm"></p>
            <p className="c-content-placeholder-bg / w-28 h-4 / mb-1.5 / bg-gray-100 rounded-sm"></p>
          </div>
        </div>
        <p className="c-content-placeholder-bg / w-full h-8 / bg-gray-100 rounded-sm"></p>
      </div>
      <style jsx>{`
        @media (min-width: 640px) {
          .c-card-skeleton-slim {
            min-width: 320px;
          }
        }
      `}</style>
    </div>
  )
}

export function SlimLoadingSkeletonWFull({ n }: Props) {
  return (
    <>
      {range(n).map((idx) => (
        <div
          key={idx}
          className="c-card-skeleton-slim relative z-1 shrink-0 w-full my-1"
        >
          <div className="pt-2 pb-3 border-b border-slate-200">
            <div className="flex flex-row items-start">
              <div className="c-content-placeholder-bg / shrink-0 / w-20 xxs:w-24 md:w-20 h-14 xs:h-16 sm:h-12 / mx-auto mb-2 / bg-gray-100 rounded-sm"></div>
              <div className="flex flex-col sm:flex-row sm:items-center / w-full / pl-4">
                <div className="sm:w-full">
                  <div className="c-content-placeholder-bg / w-10/12 md:w-3/4 h-6 / mb-1.5 / bg-gray-100 rounded-sm"></div>
                  <div className="c-content-placeholder-bg / w-28 h-4 / mb-1.5 / bg-gray-100 rounded-sm"></div>
                </div>
                <div className="c-content-placeholder-bg / shrink-0 / w-24 h-4 / bg-gray-100 rounded-sm / mr-4 / hidden md:block"></div>

                <div className="c-content-placeholder-bg / shrink-0 / w-36 h-8 / bg-gray-100 rounded-sm"></div>
              </div>
            </div>
          </div>
          <style jsx>{`
            @media (min-width: 640px) {
              .c-card-skeleton-slim {
                min-width: 320px;
              }
            }
          `}</style>
        </div>
      ))}
    </>
  )
}

export function SkeletonCardTileFullW({ n }: Props) {
  return (
    <>
      {range(n).map((idx) => (
        <div
          key={idx}
          className={`flex flex-col items-center justify-center md:flex-row md:items-start / w-full / mx-auto mt-6 mb-2 md:mr-5 lg:mr-0 / px-5 pt-3 pb-4 md:py-5 / border-2 border-gray-200 bg-white rounded`}
        >
          <div className="flex flex-col items-center / w-full md:w-1/3 / md:px-4">
            <div className="c-content-placeholder-bg / w-3/4 md:w-1/2 h-6 / mb-2 rounded-sm bg-gray-200 / md:hidden"></div>
            {/* card image */}
            <div className="c-content-placeholder-bg transition-all / w-36 h-24 md:w-full md:h-28 / mb-1.5 rounded-sm bg-gray-200"></div>
            <div className="c-content-placeholder-bg w-28 h-5 mb-1.5 rounded-sm bg-gray-200 / hidden md:block"></div>
            <div className="c-content-placeholder-bg w-full h-10 / rounded-sm bg-gray-200 / hidden md:block"></div>
          </div>
          <div className="flex flex-col items-center md:items-start / w-full md:w-2/3">
            {/* rating and cta */}
            <div className="c-content-placeholder-bg w-28 h-5 mb-1.5 rounded-sm bg-gray-200 / md:hidden"></div>
            <div className="c-content-placeholder-bg / w-full md:w-3/4 h-10 md:h-8 mb-3 md:mb-1.5 rounded-sm bg-gray-200"></div>
            {/* card attributes */}
            <div className="c-content-placeholder-bg w-48 h-8 mb-3 md:mb-1.5 rounded-sm bg-gray-200"></div>
            <div className="c-content-placeholder-bg w-full h-20 md:h-16 mb-2 rounded-sm bg-gray-200"></div>
            <div className="c-content-placeholder-bg w-full md:w-48 h-8 md:h-10 rounded-sm bg-gray-200"></div>
          </div>
        </div>
      ))}
    </>
  )
}
