import React from 'react'
import { Tile } from './tile'

export function TopOffers() {
  const topOffers = [
    {
      href: '/credit-cards/best',
      icon: 'top-cc',
      label: 'Top Credit Cards',
      height: 64,
      width: 64,
    },
    {
      href: '/credit-cards/best/balance-transfer',
      icon: 'balance-transfer',
      label: 'Balance Transfer',
      height: 64,
      width: 64,
    },
    {
      href: '/credit-cards/best/low-interest',
      icon: 'low-interest',
      label: 'Low Interest',
      height: 64,
      width: 64,
    },
    {
      href: '/credit-cards/best/rewards',
      icon: 'rewards',
      label: 'Rewards',
      height: 64,
      width: 64,
    },
    {
      href: '/credit-cards/best/cash-back',
      icon: 'cash-back',
      label: 'Cash Back',
      height: 64,
      width: 64,
    },
  ]

  return (
    <div className="w-full flex justify-center pb-16">
      <div className="flex flex-col items-center w-full">
        {/* <div className="flex flex-col items-center mb-6 sm:mb-8">
          <h2 className="font-bold font-serif leading-snug mb-3 text-center text-fs32 lg:text-fs40 text-primary w-full">
            Top Credit Cards by Category
          </h2>
          <p className="max-w-md lg:max-w-lg xl:max-w-lg lg:text-lg text-card-gray text-center">
            Explore our most popular card categories to find credit cards with
            the benefits you&apos;re looking&nbsp;for.
          </p>
        </div> */}
        <div className="flex flex-wrap justify-center / md:items-stretch md:justify-around lg:justify-center / w-full">
          {topOffers.map((offer) => (
            <div
              className="relative / w-full max-w-sm md:w-32 lg:w-40 / mb-3 md:mb-0 lg:mx-4"
              key={offer.icon}
            >
              <Tile
                href={offer.href}
                icon={`/static/icons/categories/${offer.icon}.svg`}
                label={offer.label}
                width={offer.width}
                height={offer.height}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
