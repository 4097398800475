import compose from 'lodash/fp/compose'
import first from 'lodash/fp/first'
import replace from 'lodash/fp/replace'
import * as Sentry from '@sentry/nextjs'

export function injectImpressionTag(impressionTagStr: string): void {
  try {
    const img = compose(parseImgStr, replaceTimestamp)(impressionTagStr)

    if (img) {
      if (process.env.DEPLOYMENT_TARGET === 'production') {
        img.classList.add('impression-tag')
        document.body.appendChild(img)
      } else {
        console.log('In prod, we would inject:')
        console.log(img)
        const testImg = img.cloneNode(true) as HTMLImageElement
        testImg.classList.add('impression-tag')
        testImg.src = `http://www.example.com?ord=${getOrdNum(img.src)}`
        document.body.appendChild(testImg)
      }
    }
  } catch (e) {
    Sentry.captureException(e)
  }
}

function parseImgStr(imgStr: string): HTMLImageElement | undefined {
  const doc = new DOMParser().parseFromString(imgStr, 'text/html')
  return first(doc.getElementsByTagName('img'))
}

function replaceTimestamp(imgStr: string): string {
  const a = Math.floor(Math.random() * 10000000000000)
  return replace('[timestamp]', a.toString(), imgStr)
}

// this is for making testing easier
function getOrdNum(imgStr: string) {
  // eslint-disable-next-line no-useless-escape
  return imgStr.split('ord=').pop()?.split(/\?|\;/g)[0]
}
