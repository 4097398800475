import React from 'react'
import Image from 'next/image'
import Link from 'next/link'

import { getDate, nextImageHelper } from '../../../utils'
import { CompareCredit } from '../../../../types/compare-credit'
import { LabelsMap } from '../LABELS_MAP'
import range from 'lodash/range'

interface ITipsByCategory {
  [category: string]: CompareCredit.HubTipsList[]
}

export const LatestArticles = (props: {
  tips: CompareCredit.HubTipsList[]
  latestArticles: { value: string; label: string }[]
}) => {
  const { tips, latestArticles } = props
  const tipsSortedByLatestDate = tips.sort(
    //@ts-ignore
    (a, b) => new Date(b._createdAt) - new Date(a._createdAt),
  )
  const NUMER_TIPS_TO_SHOW = 8
  const tipsAllTopics = tipsSortedByLatestDate
    .filter(
      (tip: CompareCredit.HubTipsList) =>
        tip.image !== undefined && !tip.excludeFromHub,
    )
    .slice(0, NUMER_TIPS_TO_SHOW)

  const tipsByCategory: ITipsByCategory = LabelsMap.reduce(
    (acc, { value }) => {
      if (!latestArticles.map(({ value }) => value).includes(value)) return acc
      const tips = tipsSortedByLatestDate.filter(
        (tip: CompareCredit.HubTipsList) =>
          tip.labels && tip.labels.find((label) => label.value === value),
      )

      const tipsByLabel = tips
        .filter(
          (tip: CompareCredit.HubTipsList) =>
            tip.image !== undefined && !tip.excludeFromHub,
        )
        .slice(0, NUMER_TIPS_TO_SHOW)

      return {
        ...acc,
        [value]: tipsByLabel,
      }
    },
    {
      'all-topics': tipsAllTopics,
    },
  )

  const [tipsCategory, setTipsCategory] = React.useState<string>('all-topics')

  const handleCategoryClick = React.useCallback(
    (category: string) => {
      setTipsCategory(category)
      setShowFilterMobile(false)
    },
    [setTipsCategory],
  )

  const [showFilterMobile, setShowFilterMobile] = React.useState(false)

  const handleFilterMobile = React.useCallback(() => {
    setShowFilterMobile(!showFilterMobile)
  }, [setShowFilterMobile, showFilterMobile])

  return (
    <section className="mb-8">
      <h3 className="flex flex-row items-center / mb-2 / text-primary text-2xl sm:text-3xl font-bold font-serif">
        <span className="shrink-0 mr-4">Latest Articles</span>
        <hr className="w-full border border-slate-200" />
      </h3>
      <div>
        <div className="flex flex-row items-center justify-between / mb-4">
          <div className="relative z-10 / inline-block lg:hidden">
            <button
              onClick={() => handleFilterMobile()}
              className="flex items-center / px-4 py-1 text-primary font-semibold text-sm / rounded-full shadow"
            >
              {LabelsMap.find((label) => label.value === tipsCategory)?.label ||
                'All Topics'}{' '}
              <span className="inline-block w-3 ml-1.5 / opacity-50">
                <Image
                  src="/static/icons/icon-chevron-d-black.svg"
                  height={32}
                  width={32}
                  alt="chevron"
                  role="presentation"
                  aria-hidden={true}
                  style={nextImageHelper.responsive}
                  sizes="100vw"
                />
              </span>
            </button>
            <div
              className={`absolute top-full left-0 / w-48 / bg-white shadow-lg ${
                showFilterMobile ? '' : 'hidden'
              }`}
            >
              <ul className="flex flex-col flex-wrap justify-start / p-2 / text-sm text-slate-600">
                <li className="c-article-latest__item">
                  <button
                    onClick={() => handleCategoryClick('all-topics')}
                    className={`w-full / px-4 py-2 / text-primary text-left ${
                      tipsCategory === 'all-topics' ? 'font-semibold' : ''
                    } / rounded-full / hover:bg-primary-light-bg`}
                  >
                    All Topics
                  </button>
                </li>
                {latestArticles.map(({ value, label }) => (
                  <li key={value} className="c-article-latest__item">
                    <button
                      onClick={() => handleCategoryClick(value)}
                      className={`w-full / px-4 py-2 / text-primary text-left ${
                        tipsCategory === value ? 'font-semibold' : ''
                      } / rounded-full / hover:bg-primary-light-bg`}
                    >
                      {label}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <ul className="flex flex-row flex-wrap justify-start / uppercase text-fs13 text-slate-600 / hidden lg:flex">
            <li className="c-article-latest__item">
              <button
                onClick={() => handleCategoryClick('all-topics')}
                className={`${
                  tipsCategory === 'all-topics'
                    ? 'text-primary font-bold'
                    : 'hover:text-primary-bright'
                }`}
              >
                All Topics
              </button>
            </li>
            {latestArticles.map(({ value, label }) => (
              <li key={value} className="c-article-latest__item">
                <button
                  onClick={() => handleCategoryClick(value)}
                  className={`${
                    tipsCategory === value
                      ? 'text-primary font-bold'
                      : 'hover:text-primary-bright'
                  }`}
                >
                  {label}
                </button>
              </li>
            ))}
          </ul>
        </div>
        <div>
          <ul className="md:flex md:flex-row md:flex-wrap / md:-mx-2 xl:-mx-4">
            {tipsCategory && tipsByCategory[tipsCategory].length > 0 ? (
              tipsByCategory[tipsCategory].map((tip) => {
                const dateObj = getDate(String(tip._updatedAt))
                const dateString = `${dateObj.toLocaleString('en-US', {
                  month: 'short',
                })}. ${dateObj.getDate()}, ${dateObj.getFullYear()}`

                const [filteredContentPreview] = tip.contentPreview
                  .reduce((acc, { columns }) => {
                    return columns.map(({ blocks }: { blocks: any }) => {
                      return blocks.map((block: any) => {
                        return block.content
                      })
                    })
                  }, [])
                  .map((x: any) => x[0])
                  .filter((x: any) => x.length > 0)

                const content =
                  filteredContentPreview &&
                  filteredContentPreview
                    .map(({ children }: any) => {
                      return children[0].text
                    })
                    .find((text: string) => text && text !== '')

                return (
                  <li
                    key={tip._id}
                    className="md:w-1/3 lg:w-1/4 mb-4 md:mb-6 md:px-2 xl:px-4"
                  >
                    <div className="c-article-link / flex flex-row md:flex-col">
                      <span className="c-article-link__image-wrap block / w-1/4 md:w-full md:h-36 / mb-2 / rounded overflow-hidden">
                        <Link href={`/credit-cards/tips/${tip.slug.current}`}>
                          <img
                            alt="Featured tip"
                            className="c-article-link__img / w-full / rounded transform transition object-cover"
                            placeholder="blur"
                            src={tip.image.url}
                          />
                        </Link>
                      </span>
                      <span className="w-3/4 md:w-full / pl-5 md:pl-0">
                        <Link href={`/credit-cards/tips/${tip.slug.current}`}>
                          <h3 className="c-article-link__title / mb-1 / text-primary font-bold leading-tight / transition">
                            {tip.headline}
                          </h3>
                        </Link>
                        {content ? (
                          <p className="mb-2 text-slate-700 text-fs13 / hidden sm:block">
                            {content.trim().split(/\s+/).length <= 20
                              ? `${content} ...`
                              : `${content
                                  .trim()
                                  .split(/\s+/)
                                  .slice(0, 12)
                                  .join(' ')} ...`}
                          </p>
                        ) : (
                          <p className="mb-2 text-slate-700 text-fs13 / hidden sm:block">
                            ...
                          </p>
                        )}
                        <span className="c-author__byline / flex flex-row md:items-center / w-full / mx-auto">
                          <p className="flex flex-row flex-wrap items-center / w-full / text-fs13 text-slate-600 / sm:mb-1">
                            {tip.author[0] ? (
                              tip.author[0].slug &&
                              tip.author[0].authorBioToggle ? (
                                <Link
                                  href={`/author/${tip.author[0].slug}`}
                                  className="inline-block / mr-1 / hover:text-primary-bright"
                                >
                                  {tip.author[0].name}
                                </Link>
                              ) : (
                                <span className="inline-block / mr-1">
                                  {tip.author[0].name}
                                </span>
                              )
                            ) : (
                              <></>
                            )}{' '}
                            | {dateString}
                          </p>
                        </span>
                      </span>
                    </div>
                  </li>
                )
              })
            ) : (
              <>
                <li className="md:w-1/3 lg:w-1/4 mb-4 md:mb-6 md:px-2 xl:px-4">
                  <AritcleSkeleton n={1} />
                </li>
                <li className="md:w-1/3 lg:w-1/4 mb-4 md:mb-6 md:px-2 xl:px-4">
                  <AritcleSkeleton n={1} />
                </li>
                <li className="md:w-1/3 lg:w-1/4 mb-4 md:mb-6 md:px-2 xl:px-4">
                  <AritcleSkeleton n={1} />
                </li>
                <li className="md:w-1/3 lg:w-1/4 mb-4 md:mb-6 md:px-2 xl:px-4 / hidden lg:block">
                  <AritcleSkeleton n={1} />
                </li>
              </>
            )}
          </ul>
        </div>
      </div>
      <style jsx>
        {`
          .c-article-link:hover .c-article-link__img {
            transform: scale(1.05);
          }
          .c-article-link:hover .c-article-link__title {
            color: #21a3fc;
          }
          .c-pop-guides__item {
            margin-right: 0.5rem;
            margin-bottom: 0.25rem;
          }
          .c-pop-guides__item:last-child {
            margin-right: 0;
          }
          @media (min-width: 1024px) {
            .c-article-latest__item {
              margin-right: 1.5rem;
            }
          }
        `}
      </style>
    </section>
  )
}

function AritcleSkeleton({
  n,
}: {
  /**
   * Number of loading skeleton's to render.
   */
  n: number
  theme?: string
}) {
  return (
    <>
      {range(n).map((idx) => (
        <div key={idx} className="shrink-0 w-full py-0.5 md:pb-1.5">
          <div className="flex flex-row items-start md:flex-col">
            <div className="c-content-placeholder-bg / shrink-0 / w-1/4 md:w-full h-14 xs:h-16 sm:h-20 md:h-32 / mx-auto mb-2 / bg-gray-100 rounded-sm"></div>
            <div className="flex flex-col md:justify-start md:items-start / w-3/4 md:w-full / pl-5 md:pl-0">
              <div className="c-content-placeholder-bg / w-full h-8 / mb-2 / bg-gray-100 rounded-sm"></div>
              <div className="c-content-placeholder-bg / w-5/6 h-5 md:h-8 / mb-2 / bg-gray-100 rounded-sm / hidden sm:block"></div>
              <div className="c-content-placeholder-bg / w-28 h-5 / bg-gray-100 rounded-sm"></div>
            </div>
          </div>
        </div>
      ))}
    </>
  )
}
