import React from 'react'
import { CompareCredit } from '../../../types/compare-credit'

export const CardReviewLinkedData = (props: {
  card: CompareCredit.FormattedCard
}) => {
  const { card } = props

  const data = {
    '@context': 'http://schema.org',
    '@type': 'Review',
    author: {
      '@type': 'Person',
      name: card.author?.name,
    },
    description: card.seoDescription,
    headline: card.seoTitle,
    inLanguage: 'en',
    itemReviewed: {
      '@type': 'Product',
      name: card.name,
      brand: card.issuer.name,
      review: {
        '@type': 'Review',
        author: {
          '@type': 'Person',
          name: card.author?.name,
        },
      },
    },
    publisher: { '@id': 'https://www.comparecredit.com/#organization' },
    url: `https://www.comparecredit.com/credit-cards/${card.issuer.slug.current}/${card.slug}`,
    reviewRating: {
      '@type': 'Rating',
      worstRating: 0,
      bestRating: 5,
      ratingValue: card.expertReviewRating,
    },
  }
  return card.expertReviewRating ? (
    <script
      key={`jsonLd-${card._id}`}
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(data) }}
    />
  ) : null
}
