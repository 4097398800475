import React from 'react'
import range from 'lodash/range'

export const SkeletonPreview = (props: { n: number }) => {
  return (
    <div>
      {range(props.n).map((idx: number) => (
        <div key={idx} className="c-content-placeholder-bg / w-full h-6 / mb-3">
          &nbsp;
        </div>
      ))}
    </div>
  )
}
