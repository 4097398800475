import * as React from 'react'
import { ls } from './'
import { CompareCredit } from '../../types/compare-credit'

/**
 * Custom hook that both sets userContext as well as gives access.
 * It also persists userContext to localStorage and uses this to
 * provide an initial value so that userContext will persist between pages.
 *
 * Usage:
 *
 * const [userContext, updateUserContext] = useUserContext()
 */
export function useUserContext(): [
  CompareCredit.UserContext,
  (newUserContext: CompareCredit.UserContext) => void,
] {
  const [state, setState] = React.useState<CompareCredit.UserContext>(
    getInitialValue(),
  )

  React.useEffect(() => ls.setItem('userContext', state), [state])

  return [
    state,
    (newContext: Partial<CompareCredit.UserContext>) =>
      setState({ ...state, ...newContext }),
  ]
}

function getInitialValue(): CompareCredit.UserContext {
  return {
    ...getUserContextFromLocalStorage(),
  }
}

function getUserContextFromLocalStorage(): CompareCredit.UserContext {
  if (typeof window === 'undefined') return {}
  return ls.getItem('userContext') || {}
}
