import * as React from 'react'
import Image from 'next/image'
import { CompareCredit } from '../../../../types/compare-credit'
import { AddToCompareBtn, LinkToRedirect } from 'components/'
import { CardArt } from 'components/card-art'
import { nextImageHelper } from 'utils/'
import cn from 'utils/classnames'
import { clickRatesAndFees } from '../../../clients/segment'
import Link from 'next/link'

export const LeftContainer = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className={cn('md:w-28pct lg:w-3/12 / md:mb-3 / md:pr-6 lg:pr-0')}>
      {children}
    </div>
  )
}

const PanelImage = ({
  cardArt,
  categoryId,
  externalId,
  customCodeSnippets,
  isCard,
  issuer,
  linkParams,
  moreInfoLink,
  name,
  orderId,
  position,
  arrangementId,
  slug,
  _rev,
  sortable,
  sort,
}: {
  cardArt: CompareCredit.FormattedCard['cardArt']
  customCodeSnippets: CompareCredit.FormattedCard['customCodeSnippets']
  customBadge: string | undefined
  categoryId: string
  externalId: string
  featured: boolean | undefined
  isCard: boolean
  issuer: CompareCredit.FormattedCard['issuer']
  linkParams?: CompareCredit.FormattedCard['applyNowLinkParameters']
  moreInfoLink?: string
  name: string
  orderId: string | null
  position?: number
  slug: CompareCredit.FormattedCard['slug'] | CompareCredit.NonPaidCard['slug']
  arrangementId?: string
  _rev: string | null
  sortable?: boolean
  sort?: string
}) => {
  return (
    <div className={`flex justify-center / text-center / mb-2 md:mb-4 lg:mb-6`}>
      <div
        className={`c-card-art relative items-center / w-4/5 max-w-13rem md:max-w-none md:w-full / mx-auto / rounded-sm shadow`}
      >
        <div className="c-card-art__container / relative z-1 / max-w-xs / rounded-sm shadow overflow-hidden">
          {isCard ? (
            <LinkToRedirect
              externalId={externalId}
              linkParams={linkParams}
              orderId={orderId}
              position={position}
              slug={slug}
            >
              <div
                data-cy="apply-now"
                className="relative block rounded-sm md:rounded overflow-hidden"
              >
                <span className="c-card-art__content / absolute / flex flex-wrap content-center items-center / z-10 left-0 / w-full h-full text-white text">
                  <span className="c-card-art__icon / w-full mb-0">
                    <div className={`inline-block w-8 mb-1`}>
                      <Image
                        alt="lock"
                        src="/static/icons/icon-lock-round-w.svg"
                        height={32}
                        width={32}
                        style={nextImageHelper.responsive}
                        sizes="100vw"
                      />
                    </div>
                  </span>
                  <span className={`w-full font-semibold`}>Apply Now</span>
                </span>
                <CardArt
                  categoryId={categoryId}
                  externalId={externalId} // we are only sending this because the categoryid may not exist for a while
                  cardArt={cardArt}
                  imgClasses="relative z-0 w-full h-full object-cover top-0  bg-dark"
                  customCodeSnippets={customCodeSnippets}
                  issuer={issuer.slug.current}
                  name={name}
                  orderId={orderId}
                  slug={slug}
                  position={position}
                  arrangementId={arrangementId}
                  _rev={_rev}
                  sortable={sortable}
                  sort={sort}
                />
              </div>
            </LinkToRedirect>
          ) : (
            <Link href={`${moreInfoLink}`}>
              <div
                data-cy="apply-now"
                className="relative block rounded-sm md:rounded overflow-hidden"
              >
                <span className="c-card-art__content / absolute / flex flex-wrap content-center items-center / z-10 left-0 / w-full h-full text-white text">
                  <span className={`w-full font-semibold`}>More Info</span>
                </span>
                <CardArt
                  categoryId={categoryId}
                  externalId={externalId} // we are only sending this because the categoryid may not exist for a while
                  cardArt={cardArt}
                  imgClasses="relative z-0 w-full h-full object-cover top-0  bg-dark"
                  customCodeSnippets={customCodeSnippets}
                  issuer={issuer.slug.current}
                  name={name}
                  orderId={orderId}
                  slug={slug}
                  position={position}
                  arrangementId={arrangementId}
                  _rev={_rev}
                  sortable={sortable}
                  sort={sort}
                />
              </div>
            </Link>
          )}
        </div>
      </div>
    </div>
  )
}

export function LeftSection(props: {
  categoryId: string
  externalId: string
  entity: CompareCredit.FormattedCard | CompareCredit.NonPaidCard
  updateCart: (arg0: 'add' | 'remove') => void
  featured: boolean | undefined
  inCart: boolean
  show: boolean
  comparisonDisabled?: boolean
  orderId: string | null
  position?: number
  arrangementId?: string
  view?: string
  sortable?: boolean
  isCollapsed: boolean
  setCollapsed: (collapsed: boolean) => void
}) {
  const {
    entity,
    updateCart,
    featured,
    inCart,
    categoryId,
    externalId,
    orderId,
    position,
    arrangementId,
    sortable,
  } = props
  const {
    cardArt,
    customBadge,
    customCodeSnippets,
    issuer,
    ratesAndFees,
    slug,
    _rev,
    _type,
  } = entity

  const isCard = _type === 'card'

  return props.show ? (
    <LeftContainer>
      <div>
        <PanelImage
          cardArt={cardArt}
          categoryId={categoryId}
          externalId={externalId}
          customBadge={customBadge}
          orderId={orderId}
          name={entity.name}
          customCodeSnippets={customCodeSnippets}
          featured={featured}
          issuer={issuer}
          linkParams={isCard ? entity.applyNowLinkParameters : undefined}
          moreInfoLink={!isCard ? entity.moreInfoLink : undefined}
          slug={slug}
          position={position}
          arrangementId={arrangementId}
          _rev={_rev}
          sortable={sortable}
          sort={isCard ? entity?.context?.sort : undefined}
          isCard={isCard}
        />
      </div>

      <div className="hidden md:block / mx-auto max-w-13rem">
        {!props.comparisonDisabled && isCard ? (
          <p className={`relative / mx-auto lg:max-w-13rem mb-2 `}>
            <AddToCompareBtn inCart={inCart} updateCart={updateCart} />
          </p>
        ) : (
          ''
        )}

        {ratesAndFees && ratesAndFees !== 'N/A' && (
          <p className="text-center">
            <a
              className="text-primary-bright text-xs"
              onClick={() => {
                entity._type === 'card' &&
                  clickRatesAndFees({
                    applyNowLink: entity.applyNowLink,
                    component: 'Card List Left',
                    name: entity.name,
                    url: window.location.href,
                  })
              }}
              href={ratesAndFees}
              rel="noopener noreferrer"
              target="_blank"
            >
              Rates &amp; Fees
            </a>
          </p>
        )}
        {issuer.slug.current === 'american-express' && (
          <p className="text-fs10 text-gray-600 / text-center / mb-3 md:mb-1 / w-full leading-4">
            Terms Apply
          </p>
        )}
      </div>
    </LeftContainer>
  ) : null
}
