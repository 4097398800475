import {
  SORT_DATA,
  SORT_DATA_ITEMS,
  SORT_DATA_VERSIONS,
} from '../types/experimentation-types'
import { getCurrentSort } from './getCurrentSort'
import { getSortData } from './getSortData'
import { SortParams } from '../types'
/**
 * builds out the fetch data for a user which is used to call sort service
 * @param sortData
 * @param slugs
 * @param SORT_MAP
 * @param SORT_DATA_MAP
 * @param sortConfig
 */
export const buildSortFetchData = (
  sortData: SortParams,
  slugs: string[],
  SORT_MAP: Record<string, SORT_DATA>,
  SORT_DATA_MAP: Record<SORT_DATA_VERSIONS, SORT_DATA_ITEMS[]>,
  sortConfig: SORT_DATA | null,
) => {
  const sort = getCurrentSort(SORT_MAP, SORT_DATA_MAP, sortConfig)
  const sortFetchData = getSortData(
    {
      categoryId: sortData.categoryId,
      page_path: sortData.page_path,
      position: sortData.position || null,
      first_position: false,
      second_position: false,
      slugs,
      campaign_name: sortData?.campaign_name || null,
      ad_group_id: sortData?.ad_group_id || null,
      campaign_source: sortData?.campaign_source || null,
      campaign_medium: sortData?.campaign_medium || null,
      user_agent_os_name: sortData?.user_agent_os_name || null,
      user_agent_browser_name: sortData?.user_agent_browser_name || null,
      location_state: sortData?.location_state || null,
      location_city: sortData?.location_city || null,
      location_country: sortData?.location_country || null,
      position_bias: 1.75,
      decomposition_weights: sortData?.decomposition_weights || null,
      proximity_to_target_adjustment:
        sortData?.proximity_to_target_adjustment || null,
      brand: sortData?.brand || null,
    },
    sort,
  )
  return sortFetchData
}
