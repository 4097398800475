import React from 'react'
/* eslint-disable react/display-name */
import Cookies from 'js-cookie'
import isEmpty from 'lodash/fp/isEmpty'
import propOr from 'lodash/fp/propOr'
import Image from 'next/image'
import Link from 'next/link'
import { isMobileOnly } from 'react-device-detect'
import { useInView } from 'react-intersection-observer'
import { v4 as uuid } from 'uuid'
import {
  PortableText,
  PortableTextBlockComponent,
  PortableTextListComponent,
  PortableTextListItemComponent,
} from '@portabletext/react'

import { CompareCredit } from '../../../types/compare-credit'
import { getExperiment, nextImageHelper, supify } from '../../utils'
import { clickRatesAndFees, makeImpression } from '../../clients/segment'
import {
  CallToApply,
  CardArt,
  CardRecommendedCredit,
  LinkToRedirect,
  StarRating,
  UserContext,
} from '../'
import { SecureSite } from '../secure-site'
import useNetworkState from '../../utils/use-network-status'

export const CardComparison =
  (data: {
    cardOrderIds: null | Record<string, string>
    referencedCards: null | Record<string, CompareCredit.Entities>
  }) =>
  (props: {
    value: {
      cardTips: CompareCredit.Tip[]
      tag: CompareCredit.CategoryTag
      categoryTag: CompareCredit.CategoryTag
      title: string
      subtitle: string
      pinnedCardTips?: CompareCredit.PinnedProduct[]
      cardOrderIds: null | Record<string, string>
    }
  }) => {
    const { tag, categoryTag } = props.value

    const externalId = tag ? tag.id : '00'
    const categoryId = categoryTag ? categoryTag.id : '00'

    const { cardOrderIds, referencedCards } = data
    const { clientLocation, gaSessionInfo } = React.useContext(UserContext)

    // 1. CARDS
    const cardSlugs = props.value.cardTips.map((cardTip) => cardTip.card.slug)
    const cards = isEmpty(referencedCards)
      ? []
      : (cardSlugs
          .map((slug) => propOr(null, slug, referencedCards))
          .filter((x) => x) as any[])

    // 2. RIBBON TEXT AND COLOR
    const cardRibbonText = props.value.cardTips.map(
      (cardTip) => cardTip.ribbonText,
    )
    const cardRibbonColor = props.value.cardTips.map(
      (cardTip) => cardTip.ribbonColor,
    )

    // 3. COLLAPSE SECTIONS
    const [prosConsIsCollapsed, setProsConsIsCollapsed] = React.useState(false)
    const [signUpOffersIsCollapsed, setSignUpOffersIsCollapsed] =
      React.useState(false)
    const [ongoingInfoIsCollapsed, setOngoingInfoIsCollapsed] =
      React.useState(false)
    const [cardFeesIsCollapsed, setCardFeesIsCollapsed] = React.useState(false)
    const [addlInfoIsCollapsed, setAddlInfoIsCollapsed] = React.useState(false)
    const handleCollapse = (
      state: boolean,
      setter: React.Dispatch<React.SetStateAction<boolean>>,
    ) => setter(!state)

    const networkInfo = useNetworkState()

    // 4. CARD
    const AttributeRenderer =
      (card: any) => (attrProps: { value: { attribute: string } }) => {
        return React.createElement('span', {}, card[attrProps.value.attribute])
      }
    const ListItem = (props: { children: React.ReactElement }) => (
      <li className="c-list-disc__item / mb-2">{props.children}</li>
    )

    const Card = ({ card, orderId }: any) => {
      const { ref, inView } = useInView({
        triggerOnce: true,
      })

      const ListCard = ({ children }: { children: any }) => (
        <ul className="c-list-disc c-list-disc--blue / pl-2.5 py-1 / text-sm">
          <ListItem>
            <>
              Select{' '}
              <LinkToRedirect
                externalId={externalId}
                linkParams={card.applyNowLinkParameters}
                orderId={orderId}
                slug={card.slug}
              >
                <div className="text-primary-bright font-semibold / hover:text-primary-mid hover:underline">
                  &quot;APPLY NOW&quot;
                </div>
              </LinkToRedirect>{' '}
              to apply online
              {card.phoneNumber && <CallToApply entity={card} numOnly />}
            </>
          </ListItem>
          {children}
          <ListItem>
            <>
              <Link
                href={`/credit-cards/${card.issuer.slug.current}/${card.slug}`}
                className="text-primary-bright font-semibold hover:text-primary-mid"
              >
                More Details
              </Link>
            </>
          </ListItem>
        </ul>
      )

      const ListItemCard = ({ children }: { children: any }) => (
        <li className="c-list-disc__item / mb-2">{children}</li>
      )

      React.useEffect(() => {
        // TODO: do we even want to send an impression event here? We already send one for the
        // card art further up the page. Will need a larger refactor though to achieve.

        const experiment = getExperiment()
        const ga_client_id = Cookies.get('_ga') || null

        if (inView && orderId)
          makeImpression({
            ga_client_id,
            ga_session_id: gaSessionInfo.ga_session_id,
            ga_session_number: gaSessionInfo.ga_session_number,
            arrangementId: null,
            experiment,
            latency: null,
            location: clientLocation,
            params: null,
            userModel: null,
            category: 'credit-card',
            order_id: orderId,
            brand: card.issuer.slug.current,
            id: card.slug,
            name: card.name,
            network: networkInfo,
            product_id: card.slug,
            sku: card.slug,
            categoryId: categoryId,
            _rev: card._rev,
          })
      }, [inView])

      return (
        <div
          key={card.slug}
          className={`text-sm text-gray-700 text-left ${
            addlInfoIsCollapsed && 'hidden'
          }`}
          ref={ref}
        >
          <PortableText
            value={card.marketingBullets}
            components={{
              list: ListCard as PortableTextListComponent,
              listItem: ListItemCard as PortableTextListItemComponent,
              types: {
                cardAttribute: AttributeRenderer(card),
              },
            }}
          />
        </div>
      )
    }

    // 5. EXPAND TABLE
    const [comparisonExpand, setComparisonExpand] = React.useState(false)
    const handleComparisonExpand = () => setComparisonExpand(!comparisonExpand)

    // 6. ORDER IDS
    const [orderIdsBySlug, setOrderIdsBySlug] = React.useState<{
      [key: string]: string
    }>({})
    React.useEffect(() => {
      if (props.value.cardTips.length <= 0) return
      const tempOrdersIds = cardSlugs.reduce(
        (acc, currentCardSlug) => ({
          ...acc,
          [currentCardSlug]: uuid(),
        }),
        {},
      )
      setOrderIdsBySlug(tempOrdersIds)
    }, [])

    // 7. NAV SCROLL
    const scrollPos = React.useRef<HTMLDivElement>(null)
    const [leftPos, setLeftPos] = React.useState<number | undefined>()
    const compareRowMainHandleScroll = () => {
      if (scrollPos && props.value.cardTips.length > 2) {
        const position = scrollPos?.current?.getBoundingClientRect().left
        setLeftPos(position)
      }
    }

    // NAV SCROLL TRIGGER
    const [displayNav, setDisplayNav] = React.useState<boolean | null>(false)
    const [displayNavPosition, setDisplayNavPosition] = React.useState(0)
    const ref = React.useRef<HTMLDivElement>(null)

    if (typeof window !== 'undefined') {
      React.useEffect(() => {
        const handleScroll = () => {
          const position = window.pageYOffset

          const elementTrigger = document.getElementById('scrollTrigger')

          if (elementTrigger) {
            const refPosition = elementTrigger.offsetTop + 500
            setDisplayNavPosition(refPosition)
          }

          if (position > displayNavPosition && comparisonExpand) {
            setDisplayNav(true)
          } else {
            setDisplayNav(false)
          }
        }
        window.addEventListener('scroll', handleScroll)
        return () => {
          window.removeEventListener('scroll', handleScroll)
        }
      }, [window.pageYOffset])
    }

    // 8. CARD LIST
    const list = (props: { children: React.ReactElement }) => (
      <ul className="c-list-disc c-list-disc--blue / pl-5 py-1 text-sm">
        {props.children}
      </ul>
    )

    return (
      <div className="-mx-4 xl:mx-0">
        <div className="c-compare__main-container / mb-6">
          <div className="flex flex-col justify-center / px-4 xs:px-0">
            <h3
              className={`c-compare__title / text-center font-serif text-2xl md:text-3xl font-bold text-primary leading-tight ${
                props.value.subtitle ? 'mb-0.5' : 'mb-3'
              }`}
            >
              {props.value.title}
            </h3>
            {props.value.subtitle && (
              <p className="c-compare__sub-title / mt-2 mb-3 / leading-snug text-center text-base md:text-lg">
                {props.value.subtitle}
              </p>
            )}
            <p className="w-full sm:order-1 font-bold text-sm text-gray-700 text-center / sm:hidden">
              Viewing {cards.length} card
              {cards.length > 1 ? 's' : ''}
              {cards.length > 2 ? (
                <span className="inline-block / ml-1.5 / font-normal text-xs text-gray-600 tracking-wider uppercase">
                  (Scroll right to view)
                </span>
              ) : (
                ''
              )}
            </p>
          </div>

          <section
            className={`c-compare / relative z-1 / ${
              cards.length === 1
                ? 't-compare-1-col'
                : cards.length === 2
                ? 't-compare-2-col'
                : cards.length === 3
                ? 't-compare-3-col'
                : 't-compare-4-col'
            } `}
          >
            {cards && (
              <div className={`c-compare__container`}>
                <div
                  id="compare-inner"
                  className={`c-compare__inner / leading-relaxed ${
                    comparisonExpand
                      ? 'c-compare__inner--open'
                      : 'c-compare__inner--collapse'
                  }`}
                  onScroll={compareRowMainHandleScroll}
                >
                  {/* HEADER (card art, card name, apply now button) */}
                  <div
                    id="compare-row-main"
                    className="c-compare__row z-20 / flex / w-full / mb-4 sm:mb-6 / border-t-3 border-primary-mid shadow-lg"
                    ref={scrollPos}
                  >
                    {cards &&
                      cards.length > 0 &&
                      cards.map((card: any, i: number) => (
                        <CompareCardTile
                          key={i}
                          card={card}
                          categoryId={categoryId}
                          externalId={externalId}
                          ribbonText={cardRibbonText[i]}
                          ribbonColor={cardRibbonColor[i]}
                        />
                      ))}
                  </div>

                  {/* HIGHLIGHTS / SIGN UP OFFERS */}
                  <div className="c-compare__row / mb-4">
                    <div className="absolute left-0 / w-full">
                      <button
                        className={`c-compare__collapse-btn / h-10 / pl-8`}
                        onClick={() =>
                          handleCollapse(
                            signUpOffersIsCollapsed,
                            setSignUpOffersIsCollapsed,
                          )
                        }
                        aria-expanded={
                          signUpOffersIsCollapsed ? 'false' : 'true'
                        }
                        aria-controls="section-highlights"
                      >
                        Highlights
                        <span className="absolute left-0 mt-1 ml-2">
                          <span className={`inline-block w-4 h-4`}>
                            {signUpOffersIsCollapsed ? (
                              <Image
                                alt="expand"
                                src="/static/icons/icon-plus-primary.svg"
                                height={16}
                                width={16}
                                aria-hidden={true}
                                style={nextImageHelper.responsive}
                                sizes="100vw"
                              />
                            ) : (
                              <Image
                                alt="hide"
                                src="/static/icons/icon-minus-primary.svg"
                                height={16}
                                width={16}
                                aria-hidden={true}
                                style={nextImageHelper.responsive}
                                sizes="100vw"
                              />
                            )}
                          </span>
                        </span>
                      </button>
                    </div>

                    <div
                      id="section-highlights"
                      className={`c-compare__cell-wrap / pt-12 ${
                        signUpOffersIsCollapsed ? 'hidden' : ''
                      }`}
                    >
                      {cards.map((card) => {
                        const defaultListItems = [
                          { label: 'Regular APR', value: card.aprPurchase },
                          { label: 'Intro APR', value: card.aprIntroPurchase },
                          {
                            label: 'Intro BT APR',
                            value: card.aprIntroBalanceTransfer,
                          },
                        ]

                        const overrideItem = card.featureOverrides.filter(
                          (item: any) => item.categoryOverride === 'best',
                        )

                        const featuresToRender =
                          overrideItem.length > 0
                            ? overrideItem[0].features
                            : propOr([], 'defaultFeatures', card).length > 0
                            ? card.defaultFeatures
                            : defaultListItems

                        return (
                          <CardHighlights
                            key={(card as CompareCredit.FormattedCard).slug}
                            card={card as CompareCredit.FormattedCard}
                            listItems={featuresToRender}
                          />
                        )
                      })}
                    </div>
                  </div>

                  {/* MAIN BENEFITS / ADDITIONAL DETAILS */}
                  <div
                    className={`c-compare__row / mb-4 ${
                      comparisonExpand ? '' : 'hidden'
                    }`}
                    ref={ref}
                    id="scrollTrigger"
                  >
                    <div className="absolute left-0 / w-full">
                      <button
                        className={`c-compare__collapse-btn / h-10 / pl-8`}
                        onClick={() =>
                          handleCollapse(
                            addlInfoIsCollapsed,
                            setAddlInfoIsCollapsed,
                          )
                        }
                        aria-expanded={addlInfoIsCollapsed ? 'false' : 'true'}
                        aria-controls="section-main-benefits"
                      >
                        Main Benefits
                        <span className="absolute left-0 mt-1 ml-2">
                          <span className={`inline-block w-4 h-4`}>
                            {addlInfoIsCollapsed ? (
                              <Image
                                alt="expand"
                                src="/static/icons/icon-plus-primary.svg"
                                height={16}
                                width={16}
                                aria-hidden={true}
                                style={nextImageHelper.responsive}
                                sizes="100vw"
                              />
                            ) : (
                              <Image
                                alt="hide"
                                src="/static/icons/icon-minus-primary.svg"
                                height={16}
                                width={16}
                                aria-hidden={true}
                                style={nextImageHelper.responsive}
                                sizes="100vw"
                              />
                            )}
                          </span>
                        </span>
                      </button>
                    </div>

                    <div
                      id="section-main-benefits"
                      className={`c-compare__cell-wrap / pt-12 ${
                        addlInfoIsCollapsed ? 'hidden' : ''
                      }`}
                    >
                      {cards.map((card, i) => (
                        <div
                          key={card.slug}
                          className={`c-compare__cell / text-left`}
                          id="section-additional-details"
                        >
                          <Card key={i} card={card} orderId={cardOrderIds} />
                        </div>
                      ))}
                    </div>
                  </div>

                  {/* PROS and CONS */}
                  <div
                    className={`c-compare__row / mb-4 ${
                      comparisonExpand ? '' : 'hidden'
                    }`}
                  >
                    <div className="absolute left-0 / w-full">
                      <button
                        className={`c-compare__collapse-btn / h-10 / pl-8`}
                        onClick={() =>
                          handleCollapse(
                            prosConsIsCollapsed,
                            setProsConsIsCollapsed,
                          )
                        }
                        aria-expanded={prosConsIsCollapsed ? 'false' : 'true'}
                        aria-controls="section-pro-cons"
                      >
                        Pros &amp; Cons
                        <span className="absolute left-0 mt-1 ml-2">
                          <span className={`inline-block w-4 h-4`}>
                            {prosConsIsCollapsed ? (
                              <Image
                                alt="expand"
                                src="/static/icons/icon-plus-primary.svg"
                                height={16}
                                width={16}
                                aria-hidden={true}
                                style={nextImageHelper.responsive}
                                sizes="100vw"
                              />
                            ) : (
                              <Image
                                alt="hide"
                                src="/static/icons/icon-minus-primary.svg"
                                height={16}
                                width={16}
                                aria-hidden={true}
                                style={nextImageHelper.responsive}
                                sizes="100vw"
                              />
                            )}
                          </span>
                        </span>
                      </button>
                    </div>
                    <div
                      id="section-pro-cons"
                      className={`c-compare__cell-wrap / pt-12 ${
                        prosConsIsCollapsed ? 'hidden' : ''
                      }`}
                    >
                      {cards.map((card) => (
                        <div
                          key={card.slug}
                          className={`c-compare__cell / text-left`}
                        >
                          <p className="text-sm uppercase text-secondary font-bold flex">
                            <span className="inline-block mr-2 w-5">
                              <Image
                                alt="icon thumbs up green"
                                src="/static/icons/icon-thumbs-up-green.svg"
                                height={22}
                                width={20}
                                aria-hidden={true}
                                style={nextImageHelper.responsive}
                                sizes="100vw"
                              />
                            </span>
                            Pros
                          </p>
                          <div className="mb-3 / pl-2.5 py-1 / text-sm">
                            <PortableText
                              value={card.expertReviewPros as any}
                              components={{
                                list: list as PortableTextListComponent,
                                listItem:
                                  ListItem as PortableTextListItemComponent,
                                types: {
                                  cardAttribute: AttributeRenderer(card),
                                },
                              }}
                            />
                          </div>
                          <p className="text-sm uppercase text-primary font-bold flex">
                            <span className="inline-block mr-2 w-5">
                              <Image
                                alt="icon thumbs down blue"
                                src="/static/icons/icon-thumbs-down-blue.svg"
                                height={22}
                                width={20}
                                aria-hidden={true}
                                style={nextImageHelper.responsive}
                                sizes="100vw"
                              />
                            </span>
                            Cons
                          </p>
                          <div className="pl-2.5 py-1 / text-sm">
                            <PortableText
                              value={card.expertReviewCons as any}
                              components={{
                                list: list as PortableTextListComponent,
                                listItem:
                                  ListItem as PortableTextListItemComponent,
                                types: {
                                  cardAttribute: AttributeRenderer(card),
                                },
                              }}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                  {/* ONGOING INFORMATION */}
                  <div
                    className={`c-compare__row / mb-4 ${
                      comparisonExpand ? '' : 'hidden'
                    }`}
                  >
                    <div className="absolute left-0 / w-full">
                      <button
                        className={`c-compare__collapse-btn / h-10 / pl-8`}
                        onClick={() =>
                          handleCollapse(
                            ongoingInfoIsCollapsed,
                            setOngoingInfoIsCollapsed,
                          )
                        }
                        aria-expanded={
                          ongoingInfoIsCollapsed ? 'false' : 'true'
                        }
                        aria-controls="section-ongoing-information"
                      >
                        Ongoing Rates
                        <span className="absolute left-0 mt-1 ml-2">
                          <span className={`inline-block w-4 h-4`}>
                            {ongoingInfoIsCollapsed ? (
                              <Image
                                alt="expand"
                                src="/static/icons/icon-plus-primary.svg"
                                height={16}
                                width={16}
                                aria-hidden={true}
                                style={nextImageHelper.responsive}
                                sizes="100vw"
                              />
                            ) : (
                              <Image
                                alt="hide"
                                src="/static/icons/icon-minus-primary.svg"
                                height={16}
                                width={16}
                                aria-hidden={true}
                                style={nextImageHelper.responsive}
                                sizes="100vw"
                              />
                            )}
                          </span>
                        </span>
                      </button>
                    </div>

                    <div
                      id="section-ongoing-information"
                      className={`c-compare__cell-wrap / pt-12 ${
                        ongoingInfoIsCollapsed ? 'hidden' : ''
                      }`}
                    >
                      {cards.map((card) => (
                        <div
                          key={card.slug}
                          className={`c-compare__cell / text-left`}
                        >
                          <div className="py-1.5">
                            <p className="font-bold text-primary">
                              Rewards Rate
                            </p>
                            <p>{card.rewardsRate || 'N/A'}</p>
                          </div>
                          <div className="py-1.5">
                            <p className="font-bold text-primary">
                              APR for purchases
                            </p>
                            <p>{card.aprPurchase || 'N/A'}</p>
                          </div>
                          <div className="py-1.5">
                            <p className="font-bold text-primary">
                              APR for balance transfer
                            </p>
                            <p>{card.aprBalanceTransfer || 'N/A'}</p>
                          </div>
                          <div className="py-1.5">
                            <p className="font-bold text-primary">
                              APR for cash advance
                            </p>
                            <p>{card.aprCashAdvance || 'N/A'}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                  {/* CARD FEES */}
                  <div
                    className={`c-compare__row / mb-4 ${
                      comparisonExpand ? '' : 'hidden'
                    }`}
                  >
                    <div className="absolute left-0 / w-full">
                      <button
                        className={`c-compare__collapse-btn / h-10 / pl-8`}
                        onClick={() =>
                          handleCollapse(
                            cardFeesIsCollapsed,
                            setCardFeesIsCollapsed,
                          )
                        }
                        aria-expanded={cardFeesIsCollapsed ? 'false' : 'true'}
                        aria-controls="section-card-fees"
                      >
                        Card Fees
                        <span className="absolute left-0 mt-1 ml-2">
                          <span className={`inline-block w-4 h-4`}>
                            {cardFeesIsCollapsed ? (
                              <Image
                                alt="expand"
                                src="/static/icons/icon-plus-primary.svg"
                                height={16}
                                width={16}
                                aria-hidden={true}
                                style={nextImageHelper.responsive}
                                sizes="100vw"
                              />
                            ) : (
                              <Image
                                alt="hide"
                                src="/static/icons/icon-minus-primary.svg"
                                height={16}
                                width={16}
                                aria-hidden={true}
                                style={nextImageHelper.responsive}
                                sizes="100vw"
                              />
                            )}
                          </span>
                        </span>
                      </button>
                    </div>

                    <div
                      id="section-card-fees"
                      className={`c-compare__cell-wrap / pt-12 ${
                        cardFeesIsCollapsed && 'hidden'
                      }`}
                    >
                      {cards.map((card) => (
                        <div
                          key={card.slug}
                          className={`c-compare__cell / text-left`}
                        >
                          <div className="py-1.5">
                            <p className="font-bold text-primary">Annual Fee</p>
                            <p>{card.feeAnnual || 'N/A'}</p>
                          </div>
                          <div className="py-1.5">
                            <p className="font-bold text-primary">
                              Foreign Transaction Fee
                            </p>
                            <p>{card.feeForeignTransaction || 'N/A'}</p>
                          </div>
                          <div className="py-1.5">
                            <p className="font-bold text-primary">
                              Balance Transfer Fee
                            </p>
                            <p>{card.feeBalanceTransfer || 'N/A'}</p>
                          </div>
                          <div className="py-1.5">
                            <p className="font-bold text-primary">
                              Cash Advance Fee
                            </p>
                            <p>{card.feeCashAdvance || 'N/A'}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  {comparisonExpand ? null : (
                    <div className="absolute bottom-0 left-0 / w-full h-24 / mb-8 / bg-gradient-to-t from-white">
                      &nbsp;
                    </div>
                  )}
                </div>

                <div className="relative z-2 / px-4 xs:px-12 lg:px-0">
                  <button
                    onClick={handleComparisonExpand}
                    className="flex justify-center items-center flex-row / w-full / py-1 / text-center text-primary-bright text-sm font-semibold / border border-primary-bright rounded-sm / hover:text-primary-mid hover:border-primary-mid / transition-all"
                    aria-expanded={comparisonExpand ? 'true' : 'false'}
                    aria-controls="compare-inner"
                  >
                    {comparisonExpand ? 'Collapse' : 'Expand'} Comparisons{' '}
                    <img
                      className={`w-4 ml-2`}
                      alt="expand icon"
                      src={`/static/icons/${
                        comparisonExpand
                          ? 'icon-minus-primary-bright'
                          : 'icon-plus-primary-bright'
                      }.svg`}
                      aria-hidden={true}
                    />
                  </button>
                </div>
              </div>
            )}
          </section>

          <CardNavMain
            cards={cards}
            leftPos={leftPos}
            categoryId={categoryId}
            externalId={externalId}
            orderIds={orderIdsBySlug}
            displayNav={displayNav}
            ribbonText={cardRibbonText}
            ribbonColor={cardRibbonColor}
          />
        </div>
        <style jsx>{`
          .c-compare__inner--collapse {
            max-height: 40rem;
            overflow-x: auto;
            overflow-y: hidden;
          }
        `}</style>
      </div>
    )
  }

const CompareCardTile = (props: {
  card: CompareCredit.FormattedCard | CompareCredit.NonPaidCard
  categoryId: string
  externalId: string
  ribbonText?: string | null
  ribbonColor?: string | null
}) => {
  const { card, categoryId, externalId, ribbonText, ribbonColor } = props
  const [orderId, setOrderId] = React.useState<string | null>(null)
  const { clientLocation } = React.useContext(UserContext)

  React.useEffect(() => {
    const id = uuid()
    setOrderId(id)
  }, [])

  return (
    <div
      className={`c-compare__cell c-compare__cell--card-tile / relative / px-3 pb-1 pt-4 sm:pb-3 / bg-white ${
        ribbonColor === 'gold' ? 'bg-primary-light-bg-2' : 'bg-white'
      }`}
    >
      <div
        className={`relative / flex flex-col items-center justify-between / w-full h-full`}
      >
        <div className="flex flex-col items-center justify-center / w-full">
          {ribbonText ? (
            <div className="mb-1.5 text-center">
              <p
                className={`c-ribbon c-ribbon--slimmer /
                relative inline-block /
                w-auto px-2 py-1 /
                font-bold text-fs11 tracking-wide uppercase /
                ${
                  ribbonColor === 'gold'
                    ? 'bg-tetriary text-primary'
                    : 'c-ribbon--primary-bright bg-primary-bright text-white'
                }`}
              >
                {ribbonText}
              </p>
            </div>
          ) : (
            <div className="mb-1.5">&nbsp;</div>
          )}

          <div className={`w-full`}>
            <div className="c-card-art / text-center flex items-center justify-center / mb-4 lg:mb-5">
              <div className="c-card-art__container / relative / w-full xs:w-10/12 md:w-3/5 lg:w-1/2 max-w-xs / rounded shadow">
                {card._type === 'card' ? (
                  <LinkToRedirect
                    externalId={externalId}
                    linkParams={card.applyNowLinkParameters}
                    orderId={orderId}
                    slug={card.slug}
                  >
                    <div className="relative flex w-full rounded overflow-hidden">
                      <span className="c-card-art__content / absolute / flex flex-wrap content-center items-center / z-10 left-0 / w-full h-full text-white text-fs13 xs:text-sm md:text-base">
                        <span className="c-card-art__icon / w-full mb-0">
                          <span className="inline-block w-6 md:w-7 lg:w-10">
                            <Image
                              height={32}
                              width={32}
                              alt="icon lock"
                              src="/static/icons/icon-lock-round-w.svg"
                              style={nextImageHelper.responsive}
                              sizes="100vw"
                            />
                          </span>
                        </span>
                        <span className="w-full font-bold">Apply Now</span>
                      </span>
                      <CardArt
                        _rev={card._rev}
                        cardArt={card.cardArt}
                        imgClasses="relative z-0 w-full h-full object-cover top-0"
                        issuer={card.issuer.slug.current}
                        name={card.name}
                        orderId={orderId}
                        slug={card.slug}
                        categoryId={categoryId}
                        externalId={externalId}
                      />
                    </div>
                  </LinkToRedirect>
                ) : (
                  <Link href={card.moreInfoLink}>
                    <div className="relative flex w-full rounded overflow-hidden">
                      <span className="c-card-art__content / absolute / flex flex-wrap content-center items-center / z-10 left-0 / w-full h-full text-white text-fs13 xs:text-sm md:text-base">
                        <span className="w-full font-bold">Learn More</span>
                      </span>
                      <CardArt
                        _rev={card._rev}
                        cardArt={card.cardArt}
                        imgClasses="relative z-0 w-full h-full object-cover top-0"
                        issuer={card.issuer.slug.current}
                        name={card.name}
                        orderId={orderId}
                        slug={card.slug}
                        categoryId={categoryId}
                        externalId={externalId}
                      />
                    </div>
                  </Link>
                )}
              </div>
            </div>
            <h2 className="c-compare__card-title / max-w-xs / mx-auto / font-sans font-bold text-center leading-tight / mb-1.5">
              {card._type === 'card' ? (
                <LinkToRedirect
                  externalId={externalId}
                  linkParams={card.applyNowLinkParameters}
                  orderId={orderId}
                  slug={card.slug}
                >
                  <div
                    className="text-primary-mid hover:text-primary-bright / js-card-title"
                    dangerouslySetInnerHTML={{ __html: supify(card.name) }}
                  />
                </LinkToRedirect>
              ) : (
                <Link href={card.moreInfoLink}>
                  <div
                    className="text-primary-mid hover:text-primary-bright / js-card-title"
                    dangerouslySetInnerHTML={{ __html: supify(card.name) }}
                  />
                </Link>
              )}
            </h2>
          </div>
          <div className="flex justify-center lg:mb-2 w-full">
            <StarRating
              column
              marginBottom="mb-0"
              productSlug={card.slug}
              stars={card.expertReviewRating}
              userAvgRating={card.visitorReviewsAverageRating}
              visitorReviewsTotalCount={card.visitorReviewsTotalCount}
            />
          </div>
        </div>

        <div className="flex flex-col items-center justify-center / w-full">
          <div className="w-full">
            <div
              className={
                card._type === 'card' &&
                card.phoneNumber &&
                isMobileOnly &&
                clientLocation.country === 'US'
                  ? 'mb-2'
                  : 'mb-3'
              }
            >
              {card._type === 'card' ? (
                <LinkToRedirect
                  externalId={externalId}
                  linkParams={card.applyNowLinkParameters}
                  orderId={orderId}
                  slug={card.slug}
                >
                  <div className="c-btn c-btn--primary / w-full max-w-xs sm:w-3/4 md:w-3/5 lg:w-1/2 / md:text-lg / rounded-sm">
                    Apply Now
                    <span className="c-btn__icon / ml-2 hidden xs:inline-block">
                      <img
                        className="w-4"
                        alt=""
                        src="/static/icons/icon-lock-round-w.svg"
                      />
                    </span>
                  </div>
                </LinkToRedirect>
              ) : (
                <Link href={card.moreInfoLink}>
                  <div className="c-btn c-btn--secondary / w-full max-w-xs sm:w-3/4 md:w-3/5 lg:w-1/2 / md:text-lg / rounded-sm">
                    Learn More
                  </div>
                </Link>
              )}
            </div>
          </div>
          <div className={`w-full`}>
            <div className="w-full max-w-xs / mb-2 mx-auto">
              <CardRecommendedCredit card={card} iconHide={true} />
            </div>
          </div>
          {card.ratesAndFees && card.ratesAndFees && (
            <div className="w-full / text-center h-6">
              {card._type === 'card' &&
                card.ratesAndFees &&
                card.ratesAndFees !== 'N/A' && (
                  <a
                    className="text-primary-bright text-xs"
                    href={card.ratesAndFees}
                    onClick={() => {
                      clickRatesAndFees({
                        applyNowLink: card.applyNowLink,
                        component: 'Card Comparison Tile',
                        name: card.name,
                        url: window.location.href,
                      })
                    }}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Rates &amp; Fees
                  </a>
                )}
              {card.issuer.slug.current === 'american-express' && (
                <p className="text-fs10 text-gray-600 / text-center / mb-3 md:mb-1 / w-full leading-4">
                  Terms Apply
                </p>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

function CardHighlights(props: {
  card: CompareCredit.FormattedCard
  listItems: any[]
}) {
  const Normal = ({ children }: { children: any }) => <span>{children}</span>

  function AttributeRenderer<K extends keyof CompareCredit.Entity>(attrProps: {
    value: { attribute: K }
  }) {
    return React.createElement(
      'span',
      {},
      props.card[attrProps.value.attribute] as React.ReactNode,
    )
  }
  return (
    <div className={`c-compare__cell / text-left`}>
      {props.listItems.map((item, i) => {
        return (
          <div className="py-1.5" key={i}>
            <p className="font-bold text-primary">{item.Title}</p>
            <p>
              <PortableText
                value={item.Description}
                components={{
                  block: { normal: Normal as PortableTextBlockComponent },
                  types: {
                    cardAttribute: AttributeRenderer,
                  },
                }}
              />
            </p>
          </div>
        )
      })}
    </div>
  )
}

function CardNavMain(props: {
  cards: CompareCredit.FormattedCard[]
  categoryId: string
  externalId: string
  leftPos: number | undefined
  orderIds: { [key: string]: string }
  displayNav: boolean | null
  ribbonText?: string[]
  ribbonColor?: string[]
}) {
  const { cards, orderIds, displayNav, ribbonText, ribbonColor } = props
  const [hasScrolledNav, setHasScrolledNav] = React.useState(false)
  const handleScrollNav = () => setHasScrolledNav(window.pageYOffset > 500)

  React.useEffect(() => {
    handleScrollNav()
    window.addEventListener('scroll', handleScrollNav)
    return () => {
      window.removeEventListener('scroll', handleScrollNav)
    }
  }, [])

  return (
    <div
      className={`c-card-nav / fixed left-0 top-0 z-80 / w-full / leading-snug / bg-white shadow / transition ease-in-out duration-500
    ${displayNav ? `is-shown` : ''}
    ${
      cards.length === 1
        ? 't-card-nav-1-col'
        : cards.length === 2
        ? 't-card-nav-2-col'
        : cards.length === 3
        ? 't-card-nav-3-col'
        : 't-card-nav-4-col'
    }
    `}
      aria-hidden={hasScrolledNav ? false : true}
    >
      <div className="c-card-nav__container / max-w-6xl / mx-auto / mx-auto">
        <div className="c-card-nav__inner / flex items-center justify-center / w-full">
          <div className="c-card-nav__row / w-full">
            <div
              className="c-card-nav__wrap relative sm:static / w-full"
              style={{
                left: `${props.leftPos}px`,
              }}
            >
              {ribbonText &&
                ribbonColor &&
                cards.map((card: any, i: number) => (
                  <CardNavTile
                    key={card.slug}
                    card={card}
                    categoryId={props.categoryId}
                    externalId={props.externalId}
                    orderId={orderIds[card.slug]}
                    ribbonText={ribbonText[i]}
                    ribbonColor={ribbonColor[i]}
                  />
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function CardNavTile(props: {
  card: CompareCredit.FormattedCard
  categoryId: string
  externalId: string
  orderId: string | null
  hideRemove?: boolean
  ribbonText?: string | null
  ribbonColor?: string | null
}) {
  const { card, externalId, orderId, ribbonText, ribbonColor } = props

  return (
    <div
      className={`c-card-nav__cell / relative / flex items-start justify-center / w-full ${
        ribbonColor === 'gold' ? 'bg-primary-light-bg-2' : 'bg-white'
      }`}
    >
      <div className="flex flex-col / w-full">
        {ribbonText ? (
          <div className="mb-0.5 text-center">
            <p
              className={`c-ribbon c-ribbon--slimmer /
            relative inline-block /
            w-auto px-2 py-0.5 /
            font-bold text-fs10 tracking-wide uppercase /
            ${
              ribbonColor === 'gold'
                ? 'bg-tetriary text-primary'
                : 'c-ribbon--primary-bright bg-primary-bright text-white'
            }
            `}
            >
              {ribbonText}
            </p>
          </div>
        ) : (
          <div className="mb-1.5">&nbsp;</div>
        )}

        <div className="c-card-art / flex-shrink-0 / w-16 sm:w-20 / mx-auto mb-1.5 / text-center flex items-center justify-center">
          <div className="relative / w-full / rounded-sm shadow">
            <LinkToRedirect
              externalId={externalId}
              linkParams={card.applyNowLinkParameters}
              orderId={orderId}
              slug={card.slug}
            >
              <div className="relative flex / w-full / overflow-hidden">
                <span className="c-card-art__content / absolute / flex flex-wrap content-center items-center / z-10 left-0 / w-full h-full text-white text-fs11 leading-snug">
                  <span className="c-card-art__icon / w-full">
                    <span className="inline-block w-4">
                      <Image
                        height={32}
                        width={32}
                        alt="icon lock"
                        src="/static/icons/icon-lock-round-w.svg"
                        style={nextImageHelper.responsive}
                        sizes="100vw"
                      />
                    </span>
                  </span>
                  <span className="w-full font-semibold">Apply Now</span>
                </span>
                <CardArt
                  _rev={card._rev}
                  cardArt={card.cardArt}
                  customCodeSnippets={card.customCodeSnippets}
                  imgClasses="relative z-0 w-full h-full object-cover top-0"
                  issuer={card.issuer.slug.current}
                  name={card.name}
                  orderId={orderId}
                  slug={card.slug}
                  categoryId="00"
                  externalId="00"
                />
              </div>
            </LinkToRedirect>
          </div>
        </div>
        <div className="w-full mb-1">
          <LinkToRedirect
            externalId={externalId}
            linkParams={card.applyNowLinkParameters}
            orderId={orderId}
            slug={card.slug}
          >
            <div className="c-card-nav__cta / flex items-center justify-center / mx-auto px-1 xs:py-0.5 / rounded-sm / border border-secondary font-semibold text-white text-fs12 / bg-green-cta / hover:bg-green-cta-light">
              Apply Now
              <span className="c-btn__icon / ml-1 hidden xs:inline-block">
                <img
                  className="w-3"
                  alt=""
                  src="/static/icons/icon-lock-round-w.svg"
                />
              </span>
            </div>
          </LinkToRedirect>
        </div>
        <p className="text-fs10 text-gray-600 / text-center leading-tight">
          <SecureSite
            issuerName={card.issuer.name}
            issuerSecureSite={card.issuerSecureSite}
          />
        </p>
        {card.ratesAndFees && card.ratesAndFees && (
          <div className="w-full / -mt-1 / text-center">
            {card.ratesAndFees && card.ratesAndFees !== 'N/A' && (
              <a
                className="inline-block text-primary-bright text-fs10 leading-snug"
                href={card.ratesAndFees}
                onClick={() => {
                  clickRatesAndFees({
                    applyNowLink: card.applyNowLink,
                    component: 'Card Nav Comparison Tile',
                    name: card.name,
                    url: window.location.href,
                  })
                }}
                rel="noopener noreferrer"
                target="_blank"
              >
                Rates &amp; Fees
              </a>
            )}
            {card.issuer.slug.current === 'american-express' && (
              <p className="text-fs10 text-gray-600 / text-center / w-full leading-snug">
                Terms Apply
              </p>
            )}
          </div>
        )}
      </div>
      <style jsx>{`
        .c-ribbon {
          height: 14px;
        }
        .c-ribbon--slimmer:before {
          left: -6px;
          border-width: 7px 0 7px 7px;
        }
        .c-ribbon--slimmer:after {
          right: -6px;
          border-width: 7px 7px 7px 0;
        }
      `}</style>
    </div>
  )
}
