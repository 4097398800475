import React from 'react'
import Image from 'next/image'
import Link from 'next/link'
import prop from 'lodash/fp/prop'

import { CardArt, LinkToRedirect, UserContext } from '../'
import { CompareCredit } from '../../../types/compare-credit'
import { nextImageHelper } from '../../utils'
import { SecureSite } from '../secure-site'
import { clickRatesAndFees } from '../../clients/segment'

/* eslint-disable react/display-name */
export const BannerCardContent =
  (data: {
    cardOrderIds: null | Record<string, string>
    referencedCards: null | Record<string, CompareCredit.Entities>
    externalId: string
    categoryId: string
  }) =>
  (props: {
    value: {
      card: {
        _id: string
        slug: string
      }
      title: string
      content: string
      tag: CompareCredit.CategoryTag
      categoryTag: CompareCredit.CategoryTag
    }
  }) => {
    const { card, title, content } = props.value
    const { clientLocation } = React.useContext(UserContext)
    const [query, setQuery] = React.useState('')
    const {
      cardOrderIds,
      referencedCards,
      externalId: tagId,
      categoryId: categoryTagId,
    } = data

    const productTip = prop(card.slug, referencedCards)
    const orderId = prop(card.slug, cardOrderIds) as any

    const externalId = tagId ?? '80'
    const categoryId = categoryTagId ?? '80'

    React.useEffect(() => {
      setQuery(window.location.search)
    }, [card.slug])

    return (
      <div className="c-banner-card__container / mb-6 mt-16">
        {productTip && productTip._type === 'card' ? (
          <div
            className={`c-banner-card / flex flex-col md:flex-row items-center / p-4 pb-5 xs:pb-6 md:px-10 md:pt-4 md:pb-5 / rounded`}
          >
            <div className="c-banner-card__content md:order-2 / w-10/12 xs:w-64 md:w-1/3 / mb-1 -mt-16 md:-mt-12">
              <ProductCard
                clientLocation={clientLocation}
                orderId={orderId}
                card={productTip}
                categoryId={categoryId}
                externalId={externalId}
                query={query}
              />
            </div>
            <div className="c-banner-card__content md:order-1 / sm:w-2/3 sm:max-w-none max-w-sm / md:pr-12 / text-center md:text-left text-white leading-snug">
              <p className="c-banner-card__title / mb-2 font-serif leading-tight text-xl xxs:text-2xl">
                {title}
              </p>
              <p className="c-banner-card__description / text-base xxs:text-lg text-primary-light font-semibold leading-normal">
                {content}
              </p>
            </div>
          </div>
        ) : null}

        <style jsx>{`
          .c-banner-card {
            background: linear-gradient(227.71deg, #0047ab 2.09%, #052f5e 91%);
          }
        `}</style>
      </div>
    )
  }

function ProductCard(props: {
  clientLocation: { country?: string; state?: string; status: string }
  orderId: string | null
  card: CompareCredit.FormattedCard | CompareCredit.NonPaidCard
  categoryId: string
  externalId: string
  query?: string
}) {
  const { card, orderId, categoryId, externalId, query } = props

  return (
    <div className="mb-2">
      <div className="c-card-art / text-center / flex items-center justify-center / mx-auto mb-3 / leading-normal">
        <div className="relative / w-full">
          {card._type === 'card' ? (
            <LinkToRedirect
              externalId={externalId}
              linkParams={card.applyNowLinkParameters}
              orderId={orderId}
              query={query}
              slug={card.slug}
            >
              <div className="relative flex rounded-sm shadow-lg overflow-hidden">
                <span className="c-card-art__content / absolute / flex flex-wrap content-center items-center / z-10 left-0 / w-full h-full text-white leading-snug">
                  <span className="c-card-art__icon / w-full">
                    <div className="inline-block w-6">
                      <Image
                        height={32}
                        width={32}
                        alt="lock icon"
                        src="/static/icons/icon-lock-round-w.svg"
                        aria-hidden={true}
                        style={nextImageHelper.responsive}
                        sizes="100vw"
                      />
                    </div>
                  </span>
                  <span className="w-full font-semibold">Apply Now</span>
                </span>

                <CardArt
                  _rev={card._rev}
                  cardArt={card.cardArt}
                  imgClasses="relative / z-0 / w-full h-full object-cover top-0"
                  customCodeSnippets={card.customCodeSnippets}
                  issuer={card.issuer.slug.current}
                  name={card.name}
                  orderId={orderId}
                  slug={card.slug}
                  type={card._type}
                  categoryId={categoryId}
                  externalId={externalId}
                />
              </div>
            </LinkToRedirect>
          ) : (
            <Link href={card.moreInfoLink}>
              <div className="relative flex rounded-sm shadow-lg overflow-hidden">
                <span className="c-card-art__content / absolute / flex flex-wrap content-center items-center / z-10 left-0 / w-full h-full text-white leading-snug">
                  <span className="w-full font-semibold">Learn More</span>
                </span>
                <CardArt
                  _rev={card._rev}
                  cardArt={card.cardArt}
                  imgClasses="relative / z-0 / w-full h-full object-cover top-0"
                  customCodeSnippets={card.customCodeSnippets}
                  issuer={card.issuer.slug.current}
                  name={card.name}
                  orderId={orderId}
                  slug={card.slug}
                  categoryId={categoryId}
                  externalId={externalId}
                />
              </div>
            </Link>
          )}
        </div>
      </div>
      {card._type === 'card' ? (
        <LinkToRedirect
          externalId={externalId}
          linkParams={card.applyNowLinkParameters}
          orderId={orderId}
          query={undefined}
          slug={card.slug}
        >
          <div className="c-btn c-btn--primary max-w-none text-lg mb-2 py-1.5 xxs:py-2 leading-snug shadow-lg">
            Apply Now
            <span className="c-btn__icon / ml-2">
              <div className="w-4">
                <Image
                  width={16}
                  height={16}
                  alt="lock icon"
                  src="/static/icons/icon-lock-round-w.svg"
                  aria-hidden={true}
                  style={nextImageHelper.responsive}
                  sizes="100vw"
                />
              </div>
            </span>
          </div>
        </LinkToRedirect>
      ) : (
        <Link href={card.moreInfoLink}>
          <div className="c-btn c-btn--primary max-w-none text-lg mb-2 py-1.5 xxs:py-2 leading-snug shadow-lg">
            Learn More
          </div>
        </Link>
      )}

      {card._type === 'card' && (
        <p className="text-fs13 text-white / text-center leading-tight / mb-1 / opacity-70">
          <SecureSite
            issuerName={card.issuer.name}
            issuerSecureSite={card.issuerSecureSite}
          />
        </p>
      )}

      {card._type === 'card' && card.ratesAndFees && (
        <div className="w-full / text-center text-shadow leading-tight">
          {card.ratesAndFees && card.ratesAndFees !== 'N/A' && (
            <p className="mb-0.5">
              <a
                className="inline-block text-primary-light text-fs12 leading-snug / hover:text-primary-light-bg hover:underline"
                href={card.ratesAndFees}
                onClick={() => {
                  clickRatesAndFees({
                    applyNowLink: card.applyNowLink,
                    component: 'Banner Card Content',
                    name: card.name,
                    url: window.location.href,
                  })
                }}
                rel="noopener noreferrer"
                target="_blank"
              >
                Rates &amp; Fees
              </a>
            </p>
          )}
          {card.issuer.slug.current === 'american-express' && (
            <p className="text-fs12 text-white / text-center / w-full leading-snug opacity-50">
              Terms Apply
            </p>
          )}
        </div>
      )}
    </div>
  )
}
