import React from 'react'
import isEmpty from 'lodash/fp/isEmpty'
import propOr from 'lodash/fp/propOr'
import { PortableText, PortableTextBlockComponent } from '@portabletext/react'
import { useExperiment } from 'utils/use-experiment-wrapper'

import { CompareCredit } from '../../../../types/compare-credit'
import { formatPinnedProducts } from 'ssg/utils/format-pinned-products'
import mapEntityPositions from 'utils/mapEntityPositions'
import { pinEntities } from 'utils/pin-entities'
import { SkeletonPreview } from './skeleton'

export const CardSummaryBlock = (props: {
  value: {
    cardTips: CompareCredit.Tip[]
    tag: CompareCredit.CategoryTag
    title?: string
    pinnedCardTips?: CompareCredit.PinnedProduct[]
    sort: string
    referencedCards: null | Record<string, CompareCredit.Entities>
  }
}) => {
  const { title, referencedCards, sort } = props.value

  // 1. CARDS
  const cardSlugs = props.value.cardTips.map(
    (cardSummaryBlock) => cardSummaryBlock.card.slug,
  )
  const cardsLength = props.value.cardTips.length
  const [cards, setCards] = React.useState<any[]>([])
  const [cardsToSort, setCardsToSort] = React.useState<any[]>([])

  React.useEffect(() => {
    setCardsToSort(
      isEmpty(referencedCards)
        ? []
        : cardSlugs
            .map((slug) => propOr(null, slug, referencedCards))
            .filter((x) => x),
    )
  }, [sort, JSON.stringify(referencedCards), JSON.stringify(cardSlugs)])

  const pinnedCardTips = props.value.pinnedCardTips
    ? props.value.pinnedCardTips.map((pinnedCard) => {
        return {
          card: {
            _id: pinnedCard.card._id,
            slug: pinnedCard.card.slug as string,
          },
          frequencyPercent: pinnedCard.frequencyPercent,
        }
      })
    : []

  const pinnedProduct = formatPinnedProducts(pinnedCardTips)

  const { experiment, isLoading } = useExperiment('pinning_sort_position')

  React.useEffect(() => {
    if (cardsToSort && cardsToSort.length) {
      const sortedCardTips = cardsToSort.sort((a: any, b: any) => {
        return a.sortPosition - b.sortPosition
      })
      if (sort === 'random-sort') {
        setCards(
          sortedCardTips.map((cardTip: any) => {
            const cardSummaryBlock = props.value.cardTips.find(
              ({ card }) => card.slug === cardTip.slug,
            )
            return {
              ...cardSummaryBlock,
              card: cardTip,
            }
          }),
        )
        return
      }
      const originalPositions = mapEntityPositions(cardSlugs)
      const cardTips =
        !isLoading && experiment.get('pinned', true)
          ? pinEntities(sortedCardTips, pinnedProduct, originalPositions)
          : sortedCardTips

      setCards(
        cardTips.map((cardTip: any) => {
          const cardSummaryBlock = props.value.cardTips.find(
            ({ card }) => card.slug === cardTip.slug,
          )
          return {
            ...cardSummaryBlock,
            card: cardTip,
          }
        }),
      )
    }
  }, [
    JSON.stringify(cardsToSort),
    isLoading,
    experiment.get('pinned', true),
    sort,
  ])

  return (
    <div className="c-summary-cards-block / mt-4 mb-6 / pt-8 md:pt-10 / text-base leading-normal / border-t-2 border-slate-200">
      <p className="c-summary-cards-block__title / mb-3 / text-base text-primary-mid font-bold">
        {title}
      </p>
      {isEmpty(cards) && <SkeletonPreview n={cardsLength} />}
      {cards &&
        cards.map(({ card, description, _key }) => {
          return (
            <CardSummaryProduct
              key={_key}
              card={card}
              description={description}
              referencedCards={referencedCards}
            />
          )
        })}
    </div>
  )
}

const CardSummaryProduct = (props: {
  card: any
  description: any
  referencedCards: any
}) => {
  const Normal = ({ children }: { children: any }) => <p>{children}</p>

  function AttributeRenderer<K extends keyof CompareCredit.Entity>(attrProps: {
    value: { attribute: K }
  }) {
    const card = props.referencedCards && props.referencedCards[props.card.slug]
    return card ? (
      <span>{card[attrProps.value.attribute] as React.ReactNode}</span>
    ) : (
      <></>
    )
  }

  return (
    <div className="c-summary-cards-block__content c-copy / mb-3">
      <PortableText
        value={props.description}
        components={{
          block: { normal: Normal as PortableTextBlockComponent },
          types: {
            advertorialAttribute: AttributeRenderer,
          },
        }}
      />
    </div>
  )
}
