import React from 'react'
import isEmpty from 'lodash/fp/isEmpty'
import Image from 'next/image'
import Link from 'next/link'
import { createClient } from '@sanity/client'
import imageUrlBuilder from '@sanity/image-url'

import { CompareCredit } from '../../../types/compare-credit'
import { nextImageHelper } from '../../utils'

export const TipRecommendedArticles = (props: {
  value: {
    recommendedArticles: CompareCredit.CardTip['recommendedArticles']
    recommendedArticlesStyle: CompareCredit.CardTip['recommendedArticlesPlacement']
  }
}) => {
  const { recommendedArticles, recommendedArticlesStyle } = props.value

  const builder = imageUrlBuilder(
    createClient({
      projectId: process.env.SANITY_PROJECT_ID || '',
      dataset: process.env.SANITY_DATA_SET || '',
      apiVersion: '1',
      useCdn: false, // `false` if you want to ensure fresh data for every query
    }),
  )

  function urlFor(source: any) {
    return builder.image(source) as any
  }

  return (
    <div>
      {!isEmpty(recommendedArticles) &&
        recommendedArticlesStyle !== 'bottom' && (
          <aside className="w-full / mb-6">
            <div className="c-articles / mb-4">
              <h5 className="mb-4 pb-2 / font-bold text-fs13 xxs:text-sm sm:text-lg lg:text-sm text-primary text-center tracking-wide / border-b">
                Recommended Articles
              </h5>
              <ul className="flex flex-col sm:flex-row sm:justify-center lg:flex-col">
                {recommendedArticles.map((article) => {
                  return (
                    <li
                      key={article.thumbnail.asset._ref}
                      className="w-full / sm:px-2 md:pl-0 lg:pr-0 / mb-4 / sm:text-center md:text-left leading-tight"
                    >
                      <div className="w-full">
                        <Link
                          href={`${
                            article.slug
                              ? `/credit-cards/tips/${article.slug}`
                              : article.url
                          }`}
                          className="c-article-link / flex sm:flex-col md:flex-row items-center / w-full"
                        >
                          <span className="block / shrink-0 w-20 h-20 sm:w-24 sm:h-24 lg:w-20 lg:h-20 / mr-5 sm:mr-0 md:mr-4 sm:mb-4 md:mb-0 / bg-gray-200 rounded overflow-hidden">
                            <div className="c-article-link__img / w-full / object-cover / transform / transition">
                              <Image
                                alt={article.headline || article.title}
                                aria-hidden={true}
                                src={urlFor(article.thumbnail)
                                  .width(240)
                                  .height(240)
                                  .url()}
                                width={96}
                                height={96}
                                blurDataURL={urlFor(article.thumbnail)
                                  .width(240)
                                  .height(240)
                                  .url()}
                                style={nextImageHelper.responsive}
                                sizes="100vw"
                              />
                            </div>
                          </span>

                          <span className="c-article-link__title / text-sm text-primary font-bold / hover:text-primary-bright / transition-all / cursor-pointer">
                            {article.headline || article.title}
                          </span>
                        </Link>
                      </div>
                    </li>
                  )
                })}
              </ul>
            </div>
          </aside>
        )}
      {!isEmpty(recommendedArticles) &&
        recommendedArticlesStyle === 'bottom' && (
          <aside className="w-full mb-6 mx-auto">
            <div className="c-articles / mb-8">
              <h5 className="mb-4 pb-2 / font-bold text-fs13 xxs:text-sm sm:text-lg lg:text-sm text-primary text-center tracking-wide / border-b">
                Recommended Articles
              </h5>
              <ul className="flex flex-col sm:flex-row sm:justify-center / leading-snug">
                {recommendedArticles.map((article) => {
                  return (
                    <li
                      key={article.thumbnail.asset._ref}
                      className="sm:w-28pct md:w-30pct lg:w-28pct / mb-5 px-3 / sm:text-center"
                    >
                      <Link
                        href={`${
                          article.slug
                            ? `/credit-cards/tips/${article.slug}`
                            : article.url
                        }`}
                        className="flex sm:flex-col items-center / text-sm text-gray-700 / hover:text-primary-bright"
                      >
                        <div className="shrink-0 w-20 sm:w-full / sm:mb-3 / bg-gray-200 rounded-sm overflow-hidden shadow">
                          <Image
                            alt={article.headline || article.title}
                            aria-hidden={true}
                            src={urlFor(article.thumbnail)
                              .width(240)
                              .height(145)
                              .url()}
                            width={290}
                            height={175}
                            blurDataURL={urlFor(article.thumbnail)
                              .width(240)
                              .height(145)
                              .url()}
                            style={nextImageHelper.responsive}
                            sizes="100vw"
                          />
                        </div>
                        <span className="pl-4 sm:pl-0">
                          {article.headline || article.title}
                        </span>
                      </Link>
                    </li>
                  )
                })}
              </ul>
            </div>
          </aside>
        )}
    </div>
  )
}
