import React from 'react'
import Link from 'next/link'
import Image from 'next/image'
import { nextImageHelper } from '../../utils'

export function Tile(props: {
  button: string
  href: string
  icon: string
  label: string
  description: string
  height: number
  width: number
}) {
  return (
    <div className="relative / flex flex-col items-center justify-center md:justify-start / w-full max-w-xs md:max-w-sm lg:max-w-full / pt-4 pb-20 px-4 md:px-6 lg:pb-24 xl:px-8 / bg-white rounded-lg shadow-md / hover:shadow-lg / transition-all">
      <div className="flex justify-center / w-full / mb-1 text-center cursor-pointer">
        <Link href={props.href || '#'}>
          <div
            className={`w-20 ${props.icon === 'icon-personal-loan' ? '' : ''}`}
          >
            <Image
              src={`/static/icons/${props.icon}.svg`}
              alt={props.label}
              height={props.height}
              width={props.width}
              style={nextImageHelper.responsive}
              sizes="100vw"
            />
          </div>
        </Link>
      </div>
      <h3 className="mb-1 md:mb-2 / font-bold font-serif leading-tight text-2xl md:text-xl xl:text-2xl text-center cursor-pointer">
        <Link href={props.href || '#'}>
          <span className="text-primary hover:text-primary-bright">
            {props.label}
          </span>
        </Link>
      </h3>
      <p className="w-full / text-card-gray text-center text-sm">
        {props.description}
      </p>
      <div className="absolute bottom-0 left-0 w-full / px-4 cursor-pointer">
        <Link href={props.href || '#'}>
          <span className="c-btn c-btn--ghost w-48 md:w-40 lg:w-48 / mb-6 lg:mb-8 / hover:bg-primary-light-bg hover:shadow / transition-all">
            {props.button}
          </span>
        </Link>
      </div>
    </div>
  )
}
