import React from 'react'
import Link from 'next/link'
import { PortableText } from '@portabletext/react'
import { createClient } from '@sanity/client'
import Image from 'next/image'
import { useNextSanityImage } from 'next-sanity-image'
import { nextImageHelper } from '../../utils'

const client: any = createClient({
  useProjectHostname: false,
  projectId: process.env.SANITY_PROJECT_ID,
  dataset: process.env.SANITY_DATA_SET,
  apiVersion: '1',
  useCdn: true,
})

interface Article {
  headline: string
  _type: 'tipV2'
  slug: { current: string }
  route: { slug: string }
  image: { url: string; metadata: { lqip: string } }
  contentPreview?: any
}

export const ArticlesFeatured = ({
  featuredGuide,
  trendingPosts,
  featuredImageBanner,
  hideMoreArticlesLink,
}: {
  featuredGuide: Article
  trendingPosts: Article[]
  featuredImageBanner?: string
  hideMoreArticlesLink?: boolean
}) => {
  return (
    <div>
      <h2 className="mb-4 / font-serif font-bold text-4xl lg:text-fs40 text-center text-primary leading-tight">
        Financial Guides
      </h2>
      <hr className=" mb-6 w-24 mx-auto / border-t-4 border-b-2 border-tetriary" />
      <div className="lg:flex lg:flex-row / max-w-xl lg:max-w-none / mx-auto">
        <FeaturedArticle
          featuredGuide={featuredGuide}
          featuredImageBanner={featuredImageBanner}
        />
        <TrendingPosts
          trendingPosts={trendingPosts}
          hideMoreArticlesLink={hideMoreArticlesLink}
        />
      </div>
    </div>
  )
}

function TrendingPosts({
  trendingPosts,
  hideMoreArticlesLink,
}: {
  trendingPosts: Article[]
  hideMoreArticlesLink?: boolean
}) {
  return (
    <div className="lg:w-5/12 / lg:pl-12">
      <p className="mb-2 / uppercase font-bold text-primary-mid tracking-wider">
        Trending Posts
      </p>
      <div className="lg:-mt-px">
        {trendingPosts.map((post: any) => {
          const { headline, _type, slug, route, image } = post
          const path = _type === 'tipV2' && 'credit-cards/tips'
          const navigation = route?.slug
            ? `${route.slug}`
            : `/${path}/${slug.current}`

          const { height, src, width }: any = useNextSanityImage(client, image)
          const href = navigation
          return (
            <div key={href} className="flex flex-col items-stretch">
              <article className="c-article-trending / flex flex-row items-stretch justify-center / pb-1 lg:pb-1.5 mb-3 lg:mb-3.5 / border-b border-gray-300">
                <Link
                  href={href}
                  className="c-article-link / relative / flex items-center / transition"
                >
                  <span className="flex items-stretch / w-1/4 max-h-14 xs:max-h-16 sm:max-h-20 lg:max-h-16 / mb-2 / rounded-sm overflow-hidden">
                    <Image
                      alt={headline}
                      className="c-article-link__img / object-cover / transform / transition / rounded-sm"
                      height={height}
                      placeholder="blur"
                      blurDataURL={image.metadata.lqip}
                      src={src}
                      width={width}
                      style={nextImageHelper.intrinsic}
                    />
                  </span>
                  <div className="w-3/4 / py-1 px-5">
                    <h3 className="c-article-link__title / mb-2 / text-primary text-sm xs:text-base lg:text-sm font-bold leading-tight transition">
                      {headline}
                    </h3>
                  </div>
                </Link>
              </article>
            </div>
          )
        })}
      </div>

      {!hideMoreArticlesLink && (
        <div className="pt-4">
          <Link
            href={`/credit-cards`}
            className="text-sm text-primary-bright / cursor-pointer / hover:text-primary-mid"
          >
            Read More Articles{' '}
            <div className="inline-block w-3 h-3 ml-1">
              <Image
                src="/static/icons/icon-chevron-r-primary-bright.svg"
                height={12}
                width={12}
                alt="Icon Chevron Right"
                style={nextImageHelper.responsive}
                sizes="100vw"
              />
            </div>
          </Link>
        </div>
      )}

      <style jsx>{`
        .c-article-link:hover .c-article-link__img {
          transform: scale(1.05);
        }
        .c-article-link:hover .c-article-link__title {
          color: #21a3fc;
        }
        .c-article-trending {
          min-height: 4.5rem;
        }
      `}</style>
    </div>
  )
}

function FeaturedArticle({
  featuredGuide,
  featuredImageBanner,
}: {
  featuredGuide: Article
  featuredImageBanner?: string
}) {
  const { headline, _type, image, slug, contentPreview } = featuredGuide
  const path = _type === 'tipV2' && 'credit-cards/tips'

  const { height, src, width } = useNextSanityImage(client, image)

  const href = `/${path}/${slug.current}`
  return (
    <div className="lg:w-7/12 / mb-10 lg:mb-0 / lg:pr-6">
      <p className="mb-2 / uppercase font-bold text-primary-mid text-center lg:text-left tracking-wider">
        Featured Guide
      </p>

      <article>
        <Link
          href={href}
          className="c-article-link / relative / inline-block w-full / transition"
        >
          {featuredImageBanner && (
            <span className="c-ribbon c-ribbon--slim c-ribbon--rt / absolute top-0 left-0 z-10 / inline-block / py-1.5 px-2 mt-4 / font-bold text-primary leading-snug uppercase text-fs13 / bg-tetriary">
              {featuredImageBanner}
            </span>
          )}
          <span className="c-article-link__image-wrap block / mb-2 / rounded overflow-hidden">
            <Image
              alt="Featured tip image"
              className="c-article-link__img / w-full / object-cover / rounded transform transition"
              height={height}
              placeholder="blur"
              blurDataURL={image.metadata.lqip}
              src={src}
              width={width}
              style={nextImageHelper.intrinsic}
            />
          </span>

          <div>
            <h3 className="c-article-link__title / mb-1 / text-primary text-xl xs:text-2xl font-bold leading-tight / transition">
              {headline}
            </h3>
            {contentPreview && (
              <span className="mb-2 text-gray-700 text-sm">
                <PortableText value={contentPreview} />
              </span>
            )}
          </div>
        </Link>
      </article>
      <style jsx>{`
        .c-article-link:hover .c-article-link__img {
          transform: scale(1.05);
        }
        .c-article-link:hover .c-article-link__title {
          color: #21a3fc;
        }
        .c-article-link__image-wrap {
          max-height: 21rem;
        }
      `}</style>
    </div>
  )
}
