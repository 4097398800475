import isObject from 'lodash/isObject'
import isString from 'lodash/isString'

export function getOrderId({
  orderId,
  slug,
  orderIdsBySlug,
}: {
  orderId?: string | null
  slug: string
  orderIdsBySlug?: { [key: string]: string }
}) {
  if (isString(orderId) && !!orderId) return orderId
  if (isObject(orderIdsBySlug) && isString(slug))
    return orderIdsBySlug[slug] || null
  return null
}
