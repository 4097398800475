import React from 'react'

export const VideoWrapper = ({
  url,
  title,
  videoId,
  onPlayerStateChange,
}: {
  url: string
  title: string
  videoId: string
  onPlayerStateChange: (event: any) => void
}) => {
  const playerRef = React.useRef(null)
  const playerRefs = React.useRef({})
  const [isMounted, setIsMounted] = React.useState<boolean>()

  const src = url.includes('?')
    ? `${url}&enablejsapi=1`
    : `${url}?enablejsapi=1`

  const onPlayerReady = (event: any) => {
    const playerId = event.target.id
    // @ts-ignore
    playerRefs.current[playerId] = event.target
  }

  React.useEffect(() => {
    const loadYouTubeAPI = () => {
      // @ts-ignore
      if (!window.YT) {
        const tag = document.createElement('script')
        tag.src = 'https://www.youtube.com/iframe_api'
        const firstScriptTag = document.getElementsByTagName('script')[0]
        firstScriptTag.parentNode &&
          firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)
      }
    }

    loadYouTubeAPI()

    window.onYouTubeIframeAPIReady = () => {
      // @ts-ignore
      playerRef.current = new YT.Player(videoId, {
        // width: '100%',
        // height: '315',
        // videoId,
        events: {
          onReady: onPlayerReady,
          onStateChange: onPlayerStateChange,
        },
      })
    }

    // return () => {
    //   if (playerRef.current) {
    //     playerRef.current.destroy()
    //   }
    // }
  }, [videoId, onPlayerStateChange, onPlayerReady, isMounted])

  React.useEffect(() => {
    setIsMounted(true)
  }, [])

  return (
    <div className="c-video-wrapper u-shadow / mb-3 / border border-slate-400 rounded overflow-hidden">
      <iframe
        id={videoId}
        width="100%"
        height="315"
        src={src}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        className="c-video-wrapper__video"
        title={title}
      ></iframe>
    </div>
  )
}
