import React from 'react'

export function PageHeader(props: {
  mWidth: string
  titleFs: string
  title: string
  description?: string
}) {
  return (
    <div className={`mx-auto / mb-8 / ${props.mWidth}`}>
      <h1
        className={`relative / mb-3 / lg:text-5xl font-serif font-bold leading-tight text-primary text-center / ${props.titleFs}`}
      >
        {props.title}
      </h1>
      <hr className="border-4 w-24 border-tetriary / mb-3 mx-auto / hidden md:block" />
      {props.description && (
        <p className="text-center text-gray-700 text-lg sm:text-xl">
          {props.description}
        </p>
      )}
    </div>
  )
}

PageHeader.defaultProps = {
  mWidth: 'max-w-4xl',
  titleFs: 'text-4xl',
}
