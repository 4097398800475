import isNil from 'lodash/fp/isNil'
import * as Sentry from '@sentry/nextjs'

import { CompareCredit } from '../../types'

export const checkIfEmptyCardsList = (cards: any[]) => {
  if (isNil(cards) || cards.length === 0) {
    Sentry.captureException(new Error('Empty card list shown'), {
      extra: {
        cards,
      },
    })
  }
}

export const checkIfCardExists = (card: CompareCredit.Entities) => {
  if (isNil(card)) {
    Sentry.captureException(new Error('Empty card is shown'), {
      extra: {
        card,
      },
    })
  }
}
