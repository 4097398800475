import React from 'react'
import { CompareCredit } from '../../../../types/compare-credit'
import { CardRecommendedCredit } from '../../card-recommended-credit'
import { ApplyNowLinkV2 } from '../applyNowLinkV2'
import { AttributeRendererWithSlugV2 } from '../attributeRendererV2'
import CardMoreDetails from './card-more-details'
import DisplayName from './display-name'
import FeaturedRibbon from './featured-ribbon'
import ProductArt from './product-art'
import ProductCTAs from './product-ctas'
import ProductHighlights from './product-highlights'

interface MobileViewContainerProps {
  layoutSlim: boolean
  featuredRibbon: boolean
  featuredRibbonText: string
  productTip: any
  categoryId: string
  externalId: string
  orderId: string | null
  query: string
  displayName: boolean
  hideRatingCount: boolean
  recommendedCredit: boolean
  clientLocation: {
    city: string
    country: string
    state: string
    status: string
  }
  referencedCards: null | Record<string, CompareCredit.Entities>
  cardOrderIds: null | Record<string, string>
  singleCardTip: {
    card: {
      _id: string
      slug: string
    }
    content: {
      children: any
    }[]
  }
  setModalOpen: (value: React.SetStateAction<boolean>) => void
  modalOpen: boolean
  hideMoreDetails: boolean
}

export default function MobileViewContainer(props: MobileViewContainerProps) {
  const {
    layoutSlim,
    featuredRibbon,
    featuredRibbonText,
    productTip,
    categoryId,
    externalId,
    orderId,
    query,
    displayName,
    hideRatingCount,
    recommendedCredit,
    clientLocation,
    referencedCards,
    cardOrderIds,
    singleCardTip,
    setModalOpen,
    modalOpen,
    hideMoreDetails,
  } = props

  return (
    <div
      className={`c-card-tile__container / relative / z-30 / mb-4 w-full / sm:mb-4 sm:inline-block sm:w-auto / lg:hidden ${
        layoutSlim ? 'sm:max-w-xs' : 'sm:max-w-xs'
      }`}
    >
      {featuredRibbon && featuredRibbonText && (
        <FeaturedRibbon
          layoutSlim={layoutSlim}
          featuredRibbonText={featuredRibbonText}
        />
      )}
      <div
        className={`c-card-tile ${
          layoutSlim
            ? 'c-card-tile--slim / flex flex-row flex-wrap / px-4 xs:px-5 sm:px-4 pt-3 xs:pt-4 xs:pb-1 sm:pt-3 sm:pb-0'
            : 'pb-3 xxs:px-4 max-w-sm'
        } ${
          hideRatingCount ? 'c-hero--hide-review-count' : ''
        } / relative m-auto / lg:mt-8 / bg-white shadow-lg rounded-lg`}
      >
        <div
          className={`c-card-tile__card-art ${
            layoutSlim ? 'w-1/4 xxs:w-28pct sm:mb-1' : ''
          }`}
        >
          <ProductArt
            categoryId={categoryId}
            externalId={externalId}
            orderId={orderId}
            productTip={productTip}
            query={query}
            width={layoutSlim ? 'w-full' : 'w-56'}
            layoutSlim={layoutSlim ? true : false}
          />
        </div>
        <DisplayName
          layoutSlim={layoutSlim}
          displayName={displayName}
          externalId={externalId}
          productTip={productTip}
          orderId={orderId}
          query={query}
        />
        {recommendedCredit ? (
          <div className="w-full mb-2.5 px-4">
            <CardRecommendedCredit card={productTip} />
          </div>
        ) : null}

        <div className={`w-full ${layoutSlim ? '' : 'px-4'}`}>
          <ProductCTAs
            categoryId={categoryId}
            externalId={externalId}
            clientCountry={clientLocation.country}
            containerClasses={layoutSlim ? 'w-full' : 'max-w-xs'}
            productTip={productTip}
            query={query}
            orderId={orderId}
            layoutSlim={layoutSlim ? true : false}
          />
        </div>

        <div className="c-card-tile__product-highlights px-2 xxs:px-0">
          <ProductHighlights
            attributeRenderer={AttributeRendererWithSlugV2({
              referencedCards,
            })}
            applyNowLinkRenderer={ApplyNowLinkV2({
              externalId,
              referencedCards,
              categoryId,
              cardOrderIds,
            })}
            content={singleCardTip.content}
            cardOrderIds={cardOrderIds}
            categoryId={categoryId}
          />
        </div>
        {productTip._type === 'card' && !hideMoreDetails && (
          <CardMoreDetails handleModal={setModalOpen} modalOpen={modalOpen} />
        )}
      </div>
    </div>
  )
}
