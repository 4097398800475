export function isStorageAccessible(): boolean {
  let isAccessible: boolean = false
  try {
    const storage: Storage = window.sessionStorage
    const testItem: string = '__storage_test__'
    storage.setItem(testItem, testItem)
    storage.removeItem(testItem)
    isAccessible = true
  } catch (e) {
    if (e instanceof DOMException) {
      const domException = e as DOMException & { code: number }
      isAccessible =
        domException.code === 22 ||
        domException.code === 1014 ||
        domException.name === 'QuotaExceededError' ||
        domException.name === 'NS_ERROR_DOM_QUOTA_REACHED'
      isAccessible = isAccessible && window.sessionStorage.length !== 0
    }
  }
  return isAccessible
}
