import { useExperiment as useExperimentStatsig } from '@statsig/react-bindings'
import { useEffect, useState } from 'react'

export function useExperiment(experimentName: string) {
  const [isLoading, setIsLoading] = useState(true)
  const experiment = useExperimentStatsig(experimentName)

  useEffect(() => {
    if (experiment) {
      setIsLoading(false)
    }
  }, [experiment])

  const clarity = (window as any).clarity
  if (clarity && !isLoading) {
    clarity('set', experimentName, experiment?.value?.name || 'control')
  }

  return { experiment, isLoading }
}
