import * as Sentry from '@sentry/nextjs'
import { encode } from './'
import { ALLOC } from './sort/constants'
import Cookies from 'js-cookie'

export default async function getEntitiesSlug(slugs: string[]) {
  try {
    const experimentCookie = Cookies.get(ALLOC)
    const slugMap: Record<string, string> =
      (experimentCookie && JSON.parse(experimentCookie).experiment?.swapMap) ||
      {}
    const encoded = encode({ slugs, slugMap })

    const response = await fetch(`/api/entities/${encoded}/`)
    if (!response.ok) {
      throw new Error(response.statusText)
    }
    const res = await response.json()
    const entities = res.reduce((acc: any[], val: { _id: any }) => {
      if (val._id) {
        acc.push(val)
      } else {
        Sentry.captureException(new Error('Error fetching entity'), {
          contexts: {
            data: {
              val: JSON.stringify(val),
            },
          },
        })
      }
      return acc
    }, [])
    //  if no successful entities at this point we can assume that there were some errors
    if (entities.length === 0) {
      return { cards: [], error: true }
    } else {
      return { cards: entities, error: false }
    }
  } catch (err) {
    if (err instanceof Error) {
      Sentry.captureException(
        new Error(`getEntitiesSlug: Error fetching entity: ${err.message}`, {
          cause: err,
        }),
      )
    }
    return { cards: [], error: true }
  }
}
