import UAParser from 'ua-parser-js'
import { useDeviceSelectors } from 'react-device-detect'

export default function getUserAgentInfo() {
  const ua = new UAParser().getResult()

  const userAgentResult = formatUserAgentInfo(ua)
  return userAgentResult
}

function formatUserAgentInfo(ua: UAParser.IResult) {
  const uaString: string = ua.ua

  const userAgent = {
    browser: {
      name: ua?.browser?.name || null,
      version: ua?.browser?.version || null,
    },
    os: {
      name: ua?.os?.name || null,
      version: ua?.os?.version || null,
    },
    device: {
      type: getDeviceInformation(uaString),
    },
  }
  return userAgent
}

function getDeviceInformation(ua: string | undefined) {
  if (!ua || ua === undefined) {
    return null
  }

  const [selectors] = useDeviceSelectors(ua)

  const device = selectors.isMobile
    ? 'mobile'
    : selectors.isTablet
    ? 'tablet'
    : selectors.isDesktop
    ? 'desktop'
    : null

  return device
}
