import React from 'react'
import MaskedInput, { Mask } from 'react-text-mask'

export function TextInput(props: {
  id: string
  name: string
  value: string
  onChangeInput: (event: React.ChangeEvent<HTMLInputElement>) => void
  onFocus?: () => void
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void
  error: boolean
  errormsg?: string
  placeholder?: string
  className?: string
  type?: string
  maxLength?: number
  mask?: Mask
  pattern?: string
  inputMode?:
    | 'text'
    | 'none'
    | 'tel'
    | 'url'
    | 'email'
    | 'numeric'
    | 'decimal'
    | 'search'
  autoComplete?: string
  autoCorrect?: string
  spellCheck?: boolean
  formNoValidate?: boolean
}) {
  const {
    value,
    onChangeInput,
    onFocus,
    onKeyDown,
    name,
    id,
    error,
    errormsg,
    placeholder,
    className,
    type,
    maxLength,
    mask,
    pattern,
    inputMode,
    autoComplete,
    autoCorrect,
    spellCheck,
    formNoValidate,
  } = props

  if (mask) {
    return (
      <>
        <MaskedInput
          id={id}
          className={className}
          name={name}
          type={type}
          onChange={onChangeInput}
          onFocus={onFocus}
          onKeyDown={onKeyDown}
          value={value}
          mask={mask}
          showMask
          guide={false}
          pattern={pattern}
          inputMode={inputMode}
          autoComplete={autoComplete}
          autoCorrect={autoCorrect}
          spellCheck={spellCheck}
          formNoValidate={formNoValidate}
        />
        {error && (
          <div
            className={`"w-full / mx-auto mt-2 mb-4 px-2 / text-sm ${
              maxLength ? 'text-left' : 'text-center'
            } text-red-700 / rounded / u-hidden"`}
          >
            {errormsg}
          </div>
        )}
      </>
    )
  }
  return (
    <>
      <input
        id={id}
        type={type}
        value={value}
        name={name}
        className={className}
        onChange={onChangeInput}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        maxLength={maxLength}
        pattern={pattern}
        inputMode={inputMode}
        autoComplete={autoComplete}
        autoCorrect={autoCorrect}
        spellCheck={spellCheck}
        formNoValidate={formNoValidate}
      />
      {error && (
        <div
          className={`"w-full / mx-auto mt-2 mb-4 px-2 / text-sm ${
            maxLength ? 'text-left' : 'text-center'
          } text-red-700 / rounded / u-hidden"`}
        >
          {errormsg}
        </div>
      )}
    </>
  )
}
