import difference from 'lodash/fp/difference'
import isEmpty from 'lodash/fp/isEmpty'

import { getParamsAsKeyValuePairs, appendToQueryString } from './'
import { RPR_SERVICE_PARAMS } from './rpr/constants'

export const getRprQuery = (search?: string | undefined | null) => {
  if (!search) return
  const searchParams = getParamsAsKeyValuePairs(search)

  if (!searchParams) return

  // get search params that are not in RPR_SERVICE_PARAMS
  const excludedParams = difference(
    Object.keys(searchParams),
    RPR_SERVICE_PARAMS,
  )

  const searchParamsToSend = getParamsAsKeyValuePairs(search, excludedParams)

  if (searchParamsToSend && !isEmpty(searchParamsToSend)) {
    return appendToQueryString('', searchParamsToSend)
  } else {
    return
  }
}
