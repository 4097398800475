import React from 'react'

import { CategoryTile, DefaultTile } from './category-tile'

export const PopularCategories = (props: { categoryArry?: any }) => {
  const { categoryArry } = props
  return (
    <div className="flex-grow / mb-12">
      <h5 className="mb-2 pb-2 / font-bold text-fs13 xxs:text-sm sm:text-lg lg:text-sm text-primary text-center">
        Popular Credit Card Categories
      </h5>
      <div className="flex flex-wrap / rounded-lg shadow-lg / overflow-hidden text-fs13">
        {categoryArry
          ? categoryArry.map((category: any) => {
              return (
                <CategoryTile
                  href={category.slug}
                  icon={category.thumbnail}
                  key={category.slug}
                  label={category.name}
                  type={category.type}
                />
              )
            })
          : DEFAULT_ARRAY.map((category: any) => {
              return (
                <DefaultTile
                  href={`/credit-cards/${category.slug}`}
                  icon={category.icon}
                  key={category.slug}
                  label={category.label}
                />
              )
            })}
      </div>
    </div>
  )
}

const DEFAULT_ARRAY = [
  { label: 'Top Offers', icon: 'top-cc', slug: 'best' },
  {
    label: 'Balance Transfer',
    icon: 'balance-transfer',
    slug: 'best/balance-transfer',
  },
  { label: 'Low Interest', icon: 'low-interest', slug: 'best/low-interest' },
  { label: 'Rewards', icon: 'rewards', slug: 'best/rewards' },
  { label: 'Cash Back', icon: 'cash-back', slug: 'best/cash-back' },
  { label: 'Travel', icon: 'travel', slug: 'best/travel' },
]
