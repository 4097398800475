import * as React from 'react'
import range from 'lodash/range'

interface Props {
  /**
   * Number of loading skeleton's to render.
   */
  n: number
}

export function LoadingSkeleton({ n }: Props) {
  return (
    <>
      {range(n).map((idx) => (
        <div key={idx} className=" flex justify-center mt-6 flex-col mb-10">
          <div className="w-full py-10 px-5 rounded-sm w-full mb-8">
            <div className="h-9 p-7 mb-2 rounded-sm bg-gray-300 animate-pulse"></div>
          </div>

          {/* card */}
          <div className="w-full shadow-xl py-10 px-5 rounded-sm mt-2">
            <div className="mb-7 p-7 w-full">
              {/* title */}
              <div className="h-9 p-7 mb-7 rounded-sm bg-gray-300 animate-pulse"></div>
              {/* seo description */}
              <div className="h-4 my-1 rounded-sm bg-gray-300 animate-pulse"></div>
              <div className="h-4 my-1 rounded-sm bg-gray-300 animate-pulse"></div>
            </div>
            <div className="md:flex mx-9 p-7 rounded-sm">
              {/* card image */}
              <div className="h-40 md:w-1/3 bg-gray-300 rounded-sm animate-pulse"></div>
              <div className="h-40 md:w-2/3 p-3 md:ml-5">
                {/* title */}
                <div className="h-6 my-2 rounded-sm bg-gray-300 animate-pulse"></div>
                {/* content */}
                <div className="h-4 my-1 rounded-sm bg-gray-300 animate-pulse"></div>
                <div className="h-4 my-1 rounded-sm bg-gray-300 animate-pulse"></div>
                <div className="h-4 my-1 rounded-sm bg-gray-300 animate-pulse"></div>
                <div className="h-4 my-1 rounded-sm bg-gray-300 animate-pulse"></div>
              </div>
            </div>
            {/* card attributes */}
            <div className="md:flex mt-7 py-5 rounded-sm">
              <div className="p-3 h-32 md:w-1/3">
                {/* title */}
                <div className="h-6 mb-4 rounded-sm bg-gray-300 animate-pulse"></div>
                {/* content */}
                <div className="h-4 mb-2 rounded-sm bg-gray-300 animate-pulse"></div>
                <div className="h-4 mb-2 rounded-sm bg-gray-300 animate-pulse"></div>
              </div>
              <div className="p-3 h-32 md:w-1/3">
                {/* title */}
                <div className="h-6 mb-4 rounded-sm bg-gray-300 animate-pulse"></div>
                {/* content */}
                <div className="h-4 mb-2 rounded-sm bg-gray-300 animate-pulse"></div>
                <div className="h-4 mb-2 rounded-sm bg-gray-300 animate-pulse"></div>
              </div>
              <div className="p-3 h-32 md:w-1/3">
                {/* title */}
                <div className="h-6 mb-4 rounded-sm bg-gray-300 animate-pulse"></div>
                {/* content */}
                <div className="h-4 mb-2 rounded-sm bg-gray-300 animate-pulse"></div>
                <div className="h-4 mb-2 rounded-sm bg-gray-300 animate-pulse"></div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  )
}
