import omit from 'lodash/omit'

/**
 * Append an array of keys and values to a URL as query parameters.
 *
 * @param {string} url URL that may or may not already contain query params
 * @param {Array<{[key: string]: string}>} params URL parameters in key values
 *
 * @example
 * // returns "https://example.com?param1=value1&param2=value2"
 * appendToQueryString("https://example.com", [
 *   param1: 'value1',
 *   param2: 'value2',
 * ])
 */
export function appendToQueryString(
  url: string,
  params: {
    [key: string]: string | undefined | boolean
  },
): string {
  const reducer = (
    url: string,
    k: string,
    v: string | undefined | boolean,
  ): any => {
    if (url.indexOf('?') > -1) {
      if (v) {
        return `${url}&${k}=${v}`
      }
      return `${url}&${k}`
    } else if (v) {
      return `${url}?${k}=${v}`
    }
    return `${url}?${k}`
  }
  return Object.keys(params).reduce((acc, k) => reducer(acc, k, params[k]), url)
}

/**
 * Get a value for a given query param if that value exists in the URL.
 *
 * @example
 * // returns 'baz'
 * getValueForParam(url: 'https://example.com?foo=baz', 'foo')
 * // returns undefined
 * getValueForParam(url: 'https://example.com?foo=baz', 'fizz')
 * // returns undefined
 * getValueForParam(url: 'https://example.com', 'foo')
 */
export function getValueForParam(url: string, key: string): string | undefined {
  if (url.indexOf('?') < 0) return undefined
  const extractTerms = (
    acc: { [key: string]: string },
    current: string,
  ): { [key: string]: string } => {
    const [k, v] = current.split('=')
    acc[k] = v
    return acc
  }
  const kvPairs = url.split('?')[1].split('&')
  const values = kvPairs.reduce(extractTerms, {})
  return values[key]
}

/**
 * Get URL query parameters as key/value pairs excluding params passed to function.
 *
 * @param {string} url URL that may or may not already contain query params
 * @param {string []} excluded array of query params to be excluded
 *
 * @example
 * // returns {param1: value1}
 * getParams("https://example.com?param1=value1&param2=value2", ['param2'])
 */

export function getParamsAsKeyValuePairs(
  url: string,
  excluded?: string[],
):
  | {
      [key: string]: string | undefined
    }
  | undefined {
  if (url.indexOf('?') < 0) return undefined
  const kvPairs = url.split('?')[1].split('&')
  const allParams = kvPairs.reduce(
    (acc: { [key: string]: string }, i: string) => {
      const [key, value] = i.split('=')
      return { ...acc, [key]: value }
    },
    {},
  )
  return excluded ? omit(allParams, excluded) : allParams
}
