import React from 'react'

interface Props {
  color?: string
  className?: string
}

export function ArrowDown(props: Props) {
  return (
    <svg
      width="11px"
      height="6px"
      viewBox="0 0 11 6"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <g
        id="8.0_Compliance__Discover"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          id="Discover_Cards-List"
          transform="translate(-888.000000, -31.000000)"
          stroke={props.color || 'black'}
          strokeWidth="1.5"
        >
          <g id="nav/desktop/main">
            <g id="menu-items">
              <g transform="translate(794.000000, 23.000000)">
                <g id="credit-cards">
                  <polyline
                    id="Shape"
                    transform="translate(99.154578, 8.837730) rotate(-315.000000) translate(-99.154578, -8.837730) "
                    points="102.092308 5.9 102.092308 11.7754602 96.2168476 11.7754602"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
