import {
  SORT_DATA,
  SORT_DATA_ITEMS,
  SORT_DATA_VERSIONS,
} from '../types/experimentation-types'
import * as Sentry from '@sentry/nextjs'

/**
 * Checks cookies and returns the current sort data
 * @param SORT_MAP
 * @param SORT_DATA_MAP
 */
export function getCurrentSort(
  SORT_MAP: Record<string, SORT_DATA>,
  SORT_DATA_MAP: Record<SORT_DATA_VERSIONS, SORT_DATA_ITEMS[]>,
  sortConfig: SORT_DATA | null,
) {
  const sort: Record<string, string> | SORT_DATA =
    sortConfig || SORT_MAP['default']

  let data = SORT_DATA_MAP[sort.data as SORT_DATA_VERSIONS]

  let [id, version] = sort.sort

  // putting an extra validation check here incase
  // the exp cookie is somehow malformed
  if (!id || !version || !data) {
    const defaultSort = SORT_MAP['default']
    id = defaultSort.sort[0]
    version = defaultSort.sort[1]
    data = SORT_DATA_MAP[defaultSort.data as SORT_DATA_VERSIONS]
    Sentry.captureException(new Error('experiment sort cookie malformed'), {
      extra: {
        sort: JSON.stringify(sort),
      },
    })
  }

  return { id, version, data }
}
