import React from 'react'
import Image from 'next/image'
import { ClickawayListener } from '../'
import { nextImageHelper } from '../../utils'

export function CreditDisclosure(props: {
  theme?: string
  adjustPopup?: string
  id?: string
  content?: string | null
}) {
  const { content } = props

  const [creditDisclosureOpen, setCreditDisclosureOpen] = React.useState(false)
  const handleCreditDisclosure = () =>
    setCreditDisclosureOpen(!creditDisclosureOpen)

  return (
    <ClickawayListener setState={setCreditDisclosureOpen}>
      <span
        className={`c-credit-disclosure / relative / inline-block ml-0.5 ${props.theme} ${props.adjustPopup}`}
      >
        <button
          className="c-credit-disclosure__btn / inline-block"
          type="button"
          aria-label="credit score disclosure"
          aria-haspopup="true"
          aria-expanded={creditDisclosureOpen ? true : false}
          onClick={handleCreditDisclosure}
          aria-controls={`credit-score-disclosure${props.id ? '-' : ''}${
            props.id
          }`}
        >
          <div className="c-credit-disclosure__btn-img-wrap / w-5 h-5">
            <Image
              alt="more information"
              role="presentation"
              src="/static/icons/icon-info-circle-primary-bright.svg"
              height={20}
              width={20}
              aria-hidden={true}
              style={nextImageHelper.intrinsic}
            />
          </div>
        </button>
        <span
          id={`credit-score-disclosure${props.id ? '-' : ''}${props.id}`}
          className={`c-credit-disclosure__content / absolute top-full / block / w-64 px-6 py-4 / bg-white shadow-lg rounded
        ${creditDisclosureOpen ? 'block' : 'hidden'}`}
        >
          <span className="c-arrow-css c-arrow-css--north"></span>
          <span className="c-arrow-css--west / hidden"></span>
          <span className="c-arrow-css c-arrow-css--south / hidden"></span>
          <p className="font-sans font-normal text-sm text-gray-700 text-left leading-normal">
            {content ? (
              <span>{content}</span>
            ) : (
              <span>
                Credit score ranges may vary depending on the credit&nbsp;bureau
                and scoring model. Applicant approval odds are not solely based
                on credit score. These ranges were determined by the
                CompareCredit Editorial Team and not in conjunction with our
                issuer partners.
              </span>
            )}
          </p>
        </span>
        <style jsx>{`
          .c-credit-disclosure__content {
            transform: translateX(-46.5%);
          }
          .c-credit-disclosure .c-arrow-css:before,
          .c-credit-disclosure .c-arrow-css:after {
            right: 0;
            left: 0;
            margin: 0 auto;
          }
          .c-credit-disclosure .c-arrow-css--south:before,
          .c-credit-disclosure .c-arrow-css--south:after {
            right: 0;
            left: 0;
            margin: 0 auto;
          }
          @media (min-width: 480px) {
            .c-credit-disclosure__content {
              transform: translateX(-80%);
              width: 20rem;
            }
            .c-credit-disclosure .c-arrow-css:before,
            .c-credit-disclosure .c-arrow-css:after {
              right: 3rem;
              left: inherit;
            }
            .c-credit-disclosure .c-arrow-css:before {
              border-width: 8px;
              right: 2.9rem;
            }
          }

          /*========== T-CARD-TILE ==========*/

          // Align the popup on the right side
          .t-align-rt .c-credit-disclosure__content {
            right: 0;
            transform: translateX(5%);
          }
          .t-align-rt.c-credit-disclosure .c-arrow-css:before,
          .t-align-rt.c-credit-disclosure .c-arrow-css:after {
            left: inherit;
          }
          .t-align-rt.c-credit-disclosure .c-arrow-css:before {
            right: 6%;
          }
          .t-align-rt.c-credit-disclosure .c-arrow-css:after {
            right: 6.2%;
          }

          // Align the popup on at top
          .t-align-top .c-credit-disclosure__content {
            top: inherit;
            bottom: 130%;
          }
          .t-align-top .c-arrow-css--north {
            display: none;
          }
          .t-align-top .c-arrow-css--south {
            display: block;
          }

          // used on advertorial tip pages */
          .t-card-tile .c-credit-disclosure__btn-img-wrap {
            width: 1rem;
            height: 1rem;
          }
          .t-card-tile .c-credit-disclosure__content {
            left: -4rem;
          }
          .t-card-tile .c-arrow-css:before,
          .t-card-tile .c-arrow-css:after {
            left: inherit;
            right: 3.6rem;
          }
          @media (min-width: 480px) {
            .t-card-tile .c-credit-disclosure__content {
              left: -15rem;
              transform: translateX(0);
              width: 20rem;
            }
            .t-card-tile .c-arrow-css:before,
            .t-card-tile .c-arrow-css:after {
              left: inherit;
              right: 4rem;
            }
          }

          // Slim
          .t-slim .c-credit-disclosure__content {
            width: 14rem;
          }
          .t-slim.t-compare-v .c-credit-disclosure__content {
            left: -9rem;
          }

          @media (min-width: 1024px) {
            .lg-center .c-credit-disclosure__content {
              transform: translateX(50%);
            }
            .lg-center.c-credit-disclosure .c-arrow-css:before {
              right: 50%;
            }
            .lg-center.c-credit-disclosure .c-arrow-css:after {
              right: 49.5%;
            }
          }
        `}</style>
      </span>
    </ClickawayListener>
  )
}

CreditDisclosure.defaultProps = {
  id: '',
}
