import React from 'react'
import { AdvertiserDisclaimer } from '../'

export function ComparisonHeader(props: {
  comparisonProps: {
    defaultHeadline: string
    defaultSubtitle: string
    emptyHeadline: string
    emptySubtitle: string
  }
  numCards: number
}) {
  const { comparisonProps, numCards } = props

  return (
    <header className="pt-6 pb-4 sm:pb-6 xl:pb-8">
      <div className="w-full-vw / mb-4 sm:mb-6 / px-4 sm:px-8 / flex justify-end">
        <AdvertiserDisclaimer />
      </div>
      <div className="flex flex-col md:flex-row items-center md:items-end justify-center / px-4 xs:px-0">
        <div className="w-full">
          <h1
            className={`c-compare__h1 / mb-2 / font-serif text-3xl sm:text-4xl text-primary font-bold leading-tight text-center`}
          >
            {numCards === 0
              ? comparisonProps.emptyHeadline
              : comparisonProps.defaultHeadline}
          </h1>
          <p
            className={`text-sm sm:text-base lg:text-lg text-gray-700 leading-snug text-center`}
          >
            {numCards === 0
              ? comparisonProps.emptySubtitle
              : comparisonProps.defaultSubtitle}
          </p>
        </div>
      </div>
      <style jsx>{`
        @media (min-width: 1152px) {
          .c-compare__h1 {
            font-size: 40px;
          }
        }
      `}</style>
    </header>
  )
}
