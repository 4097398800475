import React from 'react'
import { CardTile } from './card-tile'

import { CompareCredit } from '../../../types/compare-credit'

export function RelatedCards(props: {
  categoryId: string
  externalId: string
  relatedCards: CompareCredit.Entities[]
}) {
  const { categoryId, relatedCards, externalId } = props
  return (
    <section className="lg:mx-0 mb-0 lg:mb-19 -mx-4 xs:-mx-8 / md-w-full / pt-10 pb-6 lg:pb-4 sm:px-4 lg:px-4 xl:px-8 / bg-primary-light-bg / lg:rounded-lg">
      <p className="text-2xl text-primary font-bold font-serif text-center / mb-10 md:mb-8">
        Customers Also Viewed
      </p>
      <div className="c-card-container flex flex-wrap justify-center sm:justify-around / mx-auto max-w-3xl lg:max-w-none / px-4 lg:px-0">
        {relatedCards.map((card) => {
          return card._type === 'card' ? (
            <CardTile
              key={card.slug}
              card={card}
              categoryId={categoryId}
              externalId={externalId}
            />
          ) : null
        })}
      </div>
    </section>
  )
}
