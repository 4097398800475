import React from 'react'
import Image from 'next/image'
import { CompareCredit } from '../../../types/compare-credit'
import { nextImageHelper } from '../../utils'

export const AuthorHeader = (props: {
  value: {
    author: CompareCredit.Author | any
    headline?: string
    subtitle?: string
    showSocialIcons?: boolean
  }
}) => {
  const { author, headline, subtitle, showSocialIcons } = props.value

  return (
    <div className="c-author-header / relative z-1 / w-full-vw / -mt-9 mb-6 / pb-8 pt-12 / bg-gradient-blue-green-270">
      <div className="container-fluid / flex flex-col items-center justify-center / text-center">
        <div className="c-author-header__pic / inline-block / w-24 h-24 / mb-1 / rounded-full overflow-hidden / bg-no-repeat bg-cover"></div>
        <p className="c-author-header__headline / mb-1 / font-serif text-2xl xxs:text-3xl font-bold text-primary">
          {headline}
        </p>
        <p className="c-author-header__subtitle / mb-3 / text-slate-600 leading-snug">
          {subtitle}
        </p>
        {showSocialIcons && (
          <ul className="c-author-header__list / flex items-center justify-center">
            {author[0].instagramUrl && (
              <li className="c-author-header__list-item / w-6 h-6 / mx-1.5 / rounded-sm opacity-75 transition-all / hover:opacity-100">
                <a
                  href={author[0].instagramUrl}
                  target="_blank"
                  rel="noreferrer"
                  aria-label="Instagram"
                >
                  <Image
                    src="/static/icons/social-instagram-black.svg"
                    height={24}
                    width={24}
                    alt="Instagram"
                    style={nextImageHelper.responsive}
                    sizes="100vw"
                  />
                </a>
              </li>
            )}

            {author[0].tiktokUrl && (
              <li className="c-author-header__list-item / w-6 h-6 / mx-1.5 / rounded-sm opacity-75 transition-all / hover:opacity-100">
                <a
                  href={author[0].tiktokUrl}
                  target="_blank"
                  rel="noreferrer"
                  aria-label="Tiktok"
                >
                  <Image
                    src="/static/icons/social-tiktok-black.svg"
                    height={24}
                    width={24}
                    alt="tiktok"
                    style={nextImageHelper.responsive}
                    sizes="100vw"
                  />
                </a>
              </li>
            )}

            {author[0].youtubeUrl && (
              <li className="c-author-header__list-item / w-6 h-6 / mx-1.5 / rounded-sm opacity-75 transition-all / hover:opacity-100">
                <a
                  href={author[0].youtubeUrl}
                  target="_blank"
                  rel="noreferrer"
                  aria-label="Youtube"
                >
                  <Image
                    src="/static/icons/social-youtube-black.svg"
                    height={24}
                    width={24}
                    alt="Youtube"
                    style={nextImageHelper.responsive}
                    sizes="100vw"
                  />
                </a>
              </li>
            )}

            {author[0].twitchUrl && (
              <li className="c-author-header__list-item / w-6 h-6 / mx-1.5 / rounded-sm opacity-75 transition-all / hover:opacity-100">
                <a
                  href={author[0].twitchUrl}
                  target="_blank"
                  rel="noreferrer"
                  aria-label="Twitch"
                >
                  <Image
                    src="/static/icons/social-twitch-black.svg"
                    height={24}
                    width={24}
                    alt="Twitch"
                    style={nextImageHelper.responsive}
                    sizes="100vw"
                  />
                </a>
              </li>
            )}
          </ul>
        )}
      </div>
      <div className="c-author__bg / absolute / bg-no-repeat / w-full overflow-hidden">
        <img
          src="/static/images/bg-bot-mask-white-sm.png"
          className="c-author__bg-mask / md:hidden"
          alt="background mask"
          role="presentation"
          aria-hidden={true}
        />
        <img
          src="/static/images/bg-bot-mask-white-lg.png"
          className="c-author__bg-mask hidden md:block"
          alt="background mask"
          role="presentation"
          aria-hidden={true}
        />
      </div>
      <style jsx>{`
        .c-author-header__pic {
          background-image: url(${author[0].image.url});
          background-position: center center;
        }
        /*===== HERO BOTTOM =====*/
        .c-author__bg {
          bottom: -1px;
          z-index: 1;
        }
        .c-author__bg-mask {
          width: 100%;
          margin-bottom: -1px;
          height: 2rem;
        }
      `}</style>
    </div>
  )
}
