import React from 'react'

export function BackArrow() {
  return (
    <svg
      width="22px"
      height="16px"
      viewBox="0 0 22 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>back-arrow</title>
      <desc>Created with Sketch.</desc>
      <g
        id="_components"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
      >
        <g
          id="back-arrow"
          transform="translate(11.000000, 8.000000) rotate(-180.000000) translate(-11.000000, -8.000000) translate(1.000000, 1.000000)"
          stroke="#052F5E"
          strokeWidth={2}
        >
          <polyline
            id="Path-2"
            transform="translate(16.000000, 7.333333) rotate(-90.000000) translate(-16.000000, -7.333333) "
            points="9.33333333 4 16 10.6666667 22.6666667 4"
          />
          <line
            x1={18}
            y1="7.33333333"
            x2="0.666666667"
            y2="7.33333333"
            id="Line-5"
          />
        </g>
      </g>
    </svg>
  )
}
