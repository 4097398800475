import React from 'react'
import Image from 'next/image'
import { useNextSanityImage } from 'next-sanity-image'
import { PortableText } from '@portabletext/react'
import { createClient } from '@sanity/client'

import { CompareCredit } from '../../../types/compare-credit'

interface Props {
  description: CompareCredit.EditorialBlock[]
  headline: CompareCredit.EditorialBlock[]
  image: { url: string; metadata: { lqip: string } }
}

const client: any = createClient({
  dataset: process.env.SANITY_DATA_SET,
  projectId: process.env.SANITY_PROJECT_ID,
  apiVersion: '1',
  useCdn: true,
  useProjectHostname: false,
})

export const HubHero = (props: Props) => {
  const { description, headline, image } = props

  const { src } = useNextSanityImage(client, image)

  return (
    <div className="c-header / relative / w-full-vw / pb-56 lg:pb-40">
      <header className="container-fluid / relative z-10 / pt-8">
        <h1 className="c-header__title / w-full max-w-md lg:max-w-lg / mb-4 / font-serif font-bold text-3xl xs:text-4xl text-primary leading-tight">
          <PortableText
            value={headline as any}
            components={{
              block: ({ children }: { children?: any }) => children,
            }}
          />
        </h1>
        <div className="w-full max-w-md lg:max-w-lg / mb-6 sm:mb-10 / text-sm lg:text-base text-gray-700">
          <PortableText
            value={description as any}
            components={{
              block: ({ children }: { children?: any }) => children,
            }}
          />
        </div>
        <div className="relative top-12 right-16 lg:right-24 / hidden md:block / w-56 lg:w-72">
          <Image
            alt="hero image"
            priority
            placeholder="blur"
            blurDataURL={image.metadata.lqip}
            src={src}
            fill
            sizes="100vw"
          />
        </div>
      </header>
      <div className="c-hero__bot / absolute bottom-0 z-2 / w-full / bg-no-repeat overflow-hidden">
        <div className="h-16 w-full / -mb-px">
          <div className="md:hidden">
            <Image
              alt="background mask"
              src="/static/images/bg-bot-mask-white-sm.png"
              fill
              sizes="100vw"
            />
          </div>
          <div className="hidden md:block">
            <Image
              alt="background mask"
              src="/static/images/bg-bot-mask-white-lg.png"
              fill
              sizes="100vw"
            />
          </div>
        </div>
      </div>
      <style jsx>
        {`
          .c-header {
            background: linear-gradient(92.43deg, #f5fafc 0.53%, #f2fff3 99.9%);
          }
          @media (min-width: 1024px) {
            .c-header__title {
              font-size: 2.5rem;
            }
          }
          @media (min-width: 1152px) {
            .c-header__title {
              font-size: 2.75rem;
            }
          }
        `}
      </style>
    </div>
  )
}
