import React from 'react'
import { StatsigContext, useStatsigClient } from '@statsig/react-bindings'

import { SORT_MAP } from '../../utils/sort/sort-map'

export interface ISortSelectContext {
  sort?: string
  setSort: React.Dispatch<React.SetStateAction<string | undefined>>
  weights: Record<string, number> | null
  adjustment: boolean | null
}

export const SortSelectContext = React.createContext<ISortSelectContext>({
  sort: undefined,
  weights: null,
  setSort: () => null,
  adjustment: null,
})

export const SortSelectContextProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { client } = React.useContext(StatsigContext)
  const { getLayer, getExperiment } = useStatsigClient()

  const [sort, setSort] = React.useState<string>()

  const [weights, setWeights] =
    React.useState<ISortSelectContext['weights']>(null)

  const [adjustment, setAdjustment] = React.useState(false)

  React.useEffect(() => {
    if (client.loadingStatus !== 'Ready') return
    const layer = getLayer('sort')
    const sort = layer.get('sort', 'default')
    const weights = layer.get('weights', null) as ISortSelectContext['weights']
    const adjustment = getExperiment('exp_target_adjustment').get(
      'adjustment',
      false,
    )

    const defaultName = Object.keys(SORT_MAP)
      .map((key: string) =>
        SORT_MAP[key] === SORT_MAP['default'] ? key : null,
      )
      .filter((x) => x && x !== sort)[0] as string

    setSort(sort === 'default' ? defaultName : sort)
    setWeights(weights)
    setAdjustment(adjustment)
  }, [client.loadingStatus])

  return (
    <SortSelectContext.Provider
      value={{
        sort,
        setSort,
        weights,
        adjustment,
      }}
    >
      {children}
    </SortSelectContext.Provider>
  )
}
