import React from 'react'
import Link from 'next/link'
import propOr from 'lodash/fp/propOr'
import { CompareCredit } from '../../../types'
import { CardArt, StarRating, UserContext } from '../'
import { supify, useFetchCardsSlug } from '../../utils'
import { v4 as uuid } from 'uuid'
import { useRouter } from 'next/router'
import { isMobileOnly } from 'react-device-detect'
import { PortableText, PortableTextBlockComponent } from '@portabletext/react'
import {
  bannerCategoryBtnCompareClicked,
  bannerCategoryBtnPdpClicked,
  bannerCategoryLinkPdpClicked,
} from 'clients/segment'

const Normal = ({ children }: { children: any }) => {
  return <div className="mb-1.5">{children}</div>
}

const contentDescriptionAttributeRenderer = (attrProps: {
  value: {
    attribute: string
    card: CompareCredit.FormattedCard
  }
}) => {
  return (
    attrProps.value.card &&
    React.createElement('span', {
      dangerouslySetInnerHTML: {
        __html: supify(
          propOr('', attrProps.value.attribute, attrProps.value.card),
        ),
      },
    })
  )
}

export const BannerCategoryListCard = (props: {
  bgColor?: string
  categoryId: string
  externalId: string
  categoryListBanner: CompareCredit.CategoryListBanner
}) => {
  const { bgColor, categoryId, externalId, categoryListBanner } = props
  const [orderIds, setOrderIds] = React.useState<(string | null)[]>([null])

  React.useEffect(() => {
    const ids = [uuid(), uuid()]
    setOrderIds(ids)
  }, [])

  const bannerCards = categoryListBanner?.bannerCards
  const [bannerCardsLength, setBannerCardsLength] = React.useState('string')

  React.useEffect(() => {
    if (Object.keys(categoryListBanner.bannerCards).length == 1) {
      setBannerCardsLength('t-one-card')
    } else if (Object.keys(categoryListBanner.bannerCards).length == 2) {
      setBannerCardsLength('t-two-cards')
    } else {
      setBannerCardsLength('t-three-cards')
    }
  }, [bannerCards])

  return (
    <div
      className={`c-banner-list / text-center rounded-lg ${
        categoryListBanner.bannerBgColor
          ? 'c-banner-list--bg-' + categoryListBanner.bannerBgColor
          : ''
      }`}
    >
      <div
        className={`c-banner-list__inner / relative / md:flex md:flex-row md:items-center / py-4 pb-5 sm:py-6 md:py-4 / rounded border border-slate-200
        ${
          bannerCardsLength == 't-three-cards'
            ? 'px-2 sm:px-4 md:pl-6 md:pr-2'
            : 'px-6'
        }
      `}
      >
        <div
          className={`c-banner-list__content-wrap / md:text-left / relative z-2 w-full ${
            bannerCardsLength == 't-three-cards'
              ? 'md:pr-4 lg:pr-6'
              : 'md:pr-8 lg:pr-12'
          }`}
        >
          <div className="c-banner-list__content / max-w-md md:max-w-none / mb-3 md:mb-4 mx-auto">
            {categoryListBanner.titleLabel && (
              <p className="c-banner-list__label / mb-1 lg:mb-1.5 / font-bold text-primary-mid text-sm">
                {categoryListBanner?.titleLabel}
              </p>
            )}

            <div
              className={`c-banner-list__title / text-primary leading-tight text-primary text-xl ${
                bannerCardsLength == 't-three-cards' ? '' : 'md:text-2xl'
              } `}
            >
              <PortableText
                value={categoryListBanner.titleContent as any}
                components={{
                  block: {
                    normal: Normal as PortableTextBlockComponent,
                  },
                  types: {
                    advertorialAttribute: contentDescriptionAttributeRenderer,
                  },
                }}
              />
            </div>
            {categoryListBanner.contentDescription && (
              <div className="c-banner-list__description / mb-1 lg:mb-1.5 / leading-snug text-slate-600">
                <PortableText
                  value={categoryListBanner.contentDescription as any}
                  components={{
                    block: {
                      normal: Normal as PortableTextBlockComponent,
                    },
                    types: {
                      advertorialAttribute: contentDescriptionAttributeRenderer,
                    },
                  }}
                />
              </div>
            )}
          </div>

          {bannerCardsLength == 't-one-card' && (
            <div className="hidden md:block md:w-11/12 lg:w-10/12">
              {bannerCards.map((bannerCard) => {
                return (
                  <div key={bannerCard._id}>
                    <BannerCta
                      bgColor={bgColor}
                      card={bannerCard as CompareCredit.FormattedCard}
                    />
                  </div>
                )
              })}
            </div>
          )}
          {bannerCardsLength !== 't-one-card' && (
            <div
              className={`hidden md:block w-11/12 ${
                bannerCardsLength == 't-three-cards' ? 'md:w-full' : ''
              }`}
            >
              <BannerCta
                bgColor={bgColor}
                cards={bannerCards as unknown as CompareCredit.FormattedCard[]}
              />
            </div>
          )}
        </div>
        <div
          className={`c-banner-list__card-wrap / relative z-2 shrink-0 / mx-auto ${
            bannerCardsLength == 't-one-card'
              ? 'md:w-1/3'
              : bannerCardsLength == 't-two-cards'
              ? 'md:w-1/2'
              : bannerCardsLength == 't-three-cards'
              ? 'md:w-7/12'
              : ''
          }
          `}
        >
          <ul
            className={`c-banner-list__list ${
              bannerCardsLength !== 't-one-card'
                ? 'flex flex-row items-stretch justify-stretch / mb-3 md:mb-0 / leading-tight text-center'
                : ''
            }`}
          >
            {bannerCards.map((bannerCard) => {
              return (
                <li
                  key={bannerCard._id}
                  className={`c-banner-list__item / flex flex-col items-stretch grow ${
                    bannerCardsLength == 't-two-cards'
                      ? 'w-1/2 pr-4 / border-r border-slate-200 / last:pr-0 last:pl-4 last:border-r-0'
                      : bannerCardsLength == 't-three-cards'
                      ? 'w-1/3 px-1.5 / border-r border-slate-200 / last:border-r-0'
                      : ''
                  }`}
                >
                  <BannerCardTile
                    bgColor={categoryListBanner.bannerBgColor}
                    card={bannerCard as CompareCredit.FormattedCard}
                    orderId={orderIds[0]}
                    categoryId={categoryId}
                    externalId={externalId}
                    theme={bannerCardsLength}
                  />
                </li>
              )
            })}
          </ul>

          {bannerCardsLength !== 't-one-card' && (
            <div
              className={`md:hidden ${
                bannerCardsLength == 't-three-cards' ? 'px-2' : ''
              }`}
            >
              <BannerCta
                bgColor={bgColor}
                cards={bannerCards as unknown as CompareCredit.FormattedCard[]}
              />
            </div>
          )}
        </div>

        <div className="c-banner-list__bg / absolute bottom-0 left-0 z-1 / w-full h-full / overflow-hidden">
          {categoryListBanner.bannerBgColor == 'gold-light' ? (
            <svg
              className="c-banner-list__svg"
              xmlns="http://www.w3.org/2000/svg"
              width="769"
              height="350"
              viewBox="0 0 769 116"
              fill="none"
            >
              <path
                d="M-8 87C413 87 526 0 779 0V124H-149V87H-8Z"
                fill="url(#paint0_linear_917_20223)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_917_20223"
                  x1="321"
                  y1="-32"
                  x2="315"
                  y2="124"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#DC7313" stopOpacity="0.2" />
                  <stop offset="1" stopColor="#E99D58" stopOpacity="0.05" />
                </linearGradient>
              </defs>
            </svg>
          ) : (
            <svg
              className="c-banner-list__svg"
              xmlns="http://www.w3.org/2000/svg"
              width="100%"
              height="119"
              viewBox="0 0 769 119"
              fill="none"
              aria-hidden={true}
            >
              <path
                d="M-8 89.3693C413 89.3693 526 0.478027 779 0.478027V127.174H-149V89.3693H-8Z"
                fill="url(#paint0_linear_854_223)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_854_223"
                  x1="321"
                  y1="-32.2176"
                  x2="314.737"
                  y2="127.163"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#138ADC" stopOpacity="0.35" />
                  <stop offset="1" stopColor="#8FD0FD" stopOpacity="0" />
                </linearGradient>
              </defs>
            </svg>
          )}
        </div>
      </div>

      <style jsx>{`
        .c-banner-list {
          background: linear-gradient(275deg, #effff0 0.59%, #f3fbff 99.05%);
        }
        .c-banner-list__card {
          max-width: 20rem;
        }
        .c-banner-list__svg {
          position: absolute;
          bottom: 0;
          width: 100%;
          height: 420px;
          opacity: 0.4;
        }
        @media (min-width: 768px) {
          .c-banner-list__svg {
            height: 200px;
          }
        }

        /* ========== Theme ========== */
        /*=== BLUE-DARK ===*/
        .c-banner-list--bg-blue-dark {
          background: linear-gradient(270deg, #1f7cff 10.43%, #052f5e 99.98%);
        }
        .c-banner-list--bg-blue-dark .c-banner-list__label {
          color: #8fd0fd;
        }
        .c-banner-list--bg-blue-dark .c-banner-list__title {
          color: #fff;
        }
        .c-banner-list--bg-blue-dark .c-banner-list__description {
          color: #fff;
          opacity: 0.9;
        }
        .c-banner-list--bg-blue-dark .c-banner-list__item {
          border-color: rgba(255, 255, 255, 0.2);
        }

        /*=== GOLD-LIGHT ===*/
        .c-banner-list--bg-gold-light {
          background: radial-gradient(
            910.37% 128.31% at 17.69% 29.89%,
            #fff8e7 0%,
            #ffefc4 100%
          );
        }
        .c-banner-list--bg-blue-dark .c-banner-list__svg {
          opacity: 0.9;
        }
        .c-banner-list--bg-gold-light .c-banner-list__svg {
          opacity: 0.7;
        }
      `}</style>
    </div>
  )
}

function BannerCardTile(props: {
  bgColor?: string
  orderId: string | null
  card: CompareCredit.FormattedCard
  categoryId: string
  externalId: string
  theme?: string
}) {
  const { bgColor, card, categoryId, externalId, orderId, theme } = props
  const cardIssuerSlug =
    typeof card.issuer.slug === 'string'
      ? card.issuer.slug
      : card.issuer.slug.current

  // 1. Handle Click for HREF
  const href = card ? `/credit-cards/${cardIssuerSlug}/${card.slug}` : null

  const router = useRouter()
  const handleClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    href: string,
  ) => {
    e.preventDefault()
    bannerCategoryLinkPdpClicked({
      action: 'clicked',
    })
    if (isMobileOnly) {
      router.push(href)
    } else {
      window.open(href, '_ blank')
    }
  }

  // 2. Convert to card
  const [cardTile, setCardTile] =
    React.useState<CompareCredit.FormattedCard | null>(null)

  const { cards } = useFetchCardsSlug([card.slug])
  React.useEffect(() => {
    async function getCardToNavUpsell() {
      const cardToTile = cards[0] as CompareCredit.FormattedCard
      if (cardToTile) {
        setCardTile(cardToTile)
      }
    }
    getCardToNavUpsell()
  }, [cards])

  return (
    <div
      className={`c-banner-list-card / flex flex-col items-stretch justify-between / w-full h-full ${
        theme == 't-three-cards' ? 't-three-cards' : ''
      } ${bgColor == 'blue-dark' ? 'c-product-rating__total--white' : ''}`}
    >
      {href && cardTile && (
        <>
          <div>
            <div className="c-card-art / w-full max-w-13rem lg:max-w-10rem / mb-2 mx-auto / bg-gray-400 rounded-sm">
              <Link
                href={href}
                className="text-primary-bright / font-semibold / transition-all / hover:text-primary-mid"
                onClick={(e) => {
                  handleClick(e, href)
                }}
              >
                <button
                  type="button"
                  className="c-card-art_image / relative flex rounded-sm w-full overflow-hidden"
                >
                  {' '}
                  <span className="c-card-art__content / absolute / flex flex-wrap content-center items-center / z-10 left-0 / w-full h-full text-white">
                    <span
                      className={`w-full font-semibold text-sm ${
                        theme == 't-three-cards'
                          ? 'text-fs12 sm:text-sm md:text-fs12 lg:text-sm'
                          : theme == 't-two-cards'
                          ? 'text-sm'
                          : 'text-lg'
                      }`}
                    >
                      Learn More
                    </span>
                  </span>
                  <CardArt
                    _rev={cardTile._rev}
                    categoryId={categoryId}
                    externalId={externalId}
                    cardArt={cardTile.cardArt}
                    imgClasses="relative z-0 w-full object-cover top-0 bg-dark"
                    customCodeSnippets={cardTile.customCodeSnippets}
                    issuer={cardTile.issuer.slug.current}
                    name={cardTile.name}
                    orderId={orderId}
                    slug={cardTile.slug}
                  />
                </button>
              </Link>
            </div>

            <div className="mb-0.5 text-sm">
              <Link
                href={href}
                className={`c-card__name inline-block / max-w-xs mx-auto / font-semibold / transition-all /
                ${
                  theme == 't-three-cards'
                    ? 'text-fs12 sm:text-sm md:text-fs12'
                    : ''
                }
                ${
                  bgColor == 'blue-dark'
                    ? 'text-primary-light hover:text-primary-light-bg-2'
                    : bgColor == 'gold-light'
                    ? 'text-primary-mid hover:text-primary-bright'
                    : 'text-primary-bright hover:text-primary-mid'
                }
              `}
                onClick={(e) => {
                  handleClick(e, href)
                }}
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html: supify(cardTile.name),
                  }}
                />
              </Link>
            </div>
          </div>

          <div className="w-full mb-1 ">
            <div
              className={`text-sm ${
                theme == 't-three-cards'
                  ? '-mt-0.5 scale-75 -ml-1 xs:scale-90 xs:-ml-0.5'
                  : theme == 't-two-cards'
                  ? '-mt-0.5'
                  : ''
              }`}
            >
              <StarRating
                column
                marginBottom="mb-0"
                productSlug={cardTile.slug}
                stars={cardTile.expertReviewRating}
                userAvgRating={cardTile.visitorReviewsAverageRating}
                visitorReviewsTotalCount={cardTile.visitorReviewsTotalCount}
              />
            </div>
          </div>

          {/* TESTING WITH JUST ONE CARD INPUTED */}
          {theme === 't-one-card' ? (
            <div className="w-full max-w-20rem mx-auto / md:hidden">
              <BannerCta card={cardTile} />
            </div>
          ) : (
            <></>
          )}
        </>
      )}
      <style jsx>{`
        @media (min-width: 480px) {
          .c-card-art {
            width: 70%;
            margin-left: auto;
            margin-right: auto;
          }
          .t-two-cards .c-card-art,
          .t-three-cards .c-card-art {
            width: 90%;
            max-width: 10rem;
          }
        }
        @media (min-width: 640px) {
          .t-three-cards .c-card-art {
            width: 80%;
          }
        }
        @media (min-width: 1024px) {
          .c-card-art {
            width: 80%;
          }
        }
      `}</style>
    </div>
  )
}

function BannerCta(props: {
  bgColor?: string
  card?: CompareCredit.FormattedCard
  cards?: CompareCredit.FormattedCard[]
}) {
  const { updateComparisonCartCategoryBanner } = React.useContext(UserContext)
  const { bgColor, card, cards } = props
  const ctaCopy = card ? 'Learn More' : 'Compare Now'
  const cardIssuerSlug = card
    ? typeof card.issuer.slug === 'string'
      ? card.issuer.slug
      : card.issuer.slug.current
    : null

  const href =
    card && cardIssuerSlug
      ? `/credit-cards/${cardIssuerSlug}/${card.slug}`
      : `/credit-cards/comparison/?cc_category_banner`

  const router = useRouter()
  const handleClick = React.useCallback(
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, href: string) => {
      e.preventDefault()
      if (!card && cards && cards.length > 0) {
        updateComparisonCartCategoryBanner(
          'add',
          cards as CompareCredit.FormattedCard[],
        )
      }

      if (cards && cards?.length > 1) {
        bannerCategoryBtnCompareClicked({
          action: 'clicked',
        })
      } else {
        bannerCategoryBtnPdpClicked({
          action: 'clicked',
        })
      }

      if (isMobileOnly) {
        router.push(href)
      } else {
        window.open(href, '_ blank')
      }
    },
    [
      updateComparisonCartCategoryBanner,
      card,
      JSON.stringify(cards),
      isMobileOnly,
    ],
  )

  return (
    <>
      {href && (
        <Link
          href={href}
          className={`w-full`}
          onClick={(e) => handleClick(e, href)}
        >
          <button
            type="button"
            className={`c-btn-banner-list-card w-full / px-4 py-2 / bg-primary-bright rounded-sm transition-all shadow-lg / text-white font-semibold text-lg
    ${
      bgColor == 'bg-blue'
        ? ' hover:bg-primary-bright-light'
        : 'hover:bg-primary-mid'
    }
    `}
          >
            {ctaCopy}
          </button>
        </Link>
      )}
    </>
  )
}
