import * as Sentry from '@sentry/nextjs'
import { debugLogger } from '../utils'

declare const gtag: any

const NEXT_PUBLIC_GOOGLE_ANALYTICS = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS

/**
 * Send a conversion action into Google.
 *
 * Because **Segment** doesn't allow us fine-grain control over what conversion
 * eevents to send where from browser-side integrations, this allows us to fire
 * off a specific conversion action.
 *
 * See more info here: https://support.google.com/google-ads/answer/6331304
 *
 * @param conversion The CONVERSION_ID/CONVERSION_LABEL as specified
 * by Google. You can get this value by viewing the details of the Conversion
 * Action in Google Ads.
 * @param value The value of the conversion.
 */
export function sendConversion(conversion: string, value = 1.0): Promise<void> {
  return new Promise((resolve) => {
    if (process.env.DEPLOYMENT_TARGET !== 'production') {
      debugLogger(
        `gtag('event', 'conversion', { send_to: ${conversion}, value: ${value} })`,
      )
      return resolve()
    }

    const timeout = setTimeout(() => {
      const err = new Error('gtag timed out before event_callback')
      Sentry.captureException(err)
      resolve()
    }, 2000)

    try {
      gtag('event', 'conversion', {
        send_to: conversion,
        value,
        currency: 'USD',
        event_callback: () => {
          clearTimeout(timeout)
          resolve()
        },
      })
    } catch (err) {
      Sentry.captureException(err)
      clearTimeout(timeout)
      resolve()
    }
  })
}

export const getSessionInfo = async () => {
  const sessionIdPromise = new Promise((resolve) => {
    gtag('get', NEXT_PUBLIC_GOOGLE_ANALYTICS, 'session_id', resolve)
  })
  const sessionNumPromise = new Promise((resolve) => {
    gtag('get', NEXT_PUBLIC_GOOGLE_ANALYTICS, 'session_number', resolve)
  })

  return Promise.all([sessionIdPromise, sessionNumPromise])
    .then(function (session_data) {
      return {
        ga_session_id: session_data[0] as string,
        ga_session_number: session_data[1] as string,
      }
    })
    .catch((err) => {
      Sentry.captureException(err)
      return {
        ga_session_id: null,
        ga_session_number: null,
      }
    })
}
