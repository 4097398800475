/**
 * A list of card mappings per traffic group.
 * @example
 * {
 *   default: {}, // this should always be present
 *   'exp-1--control': {
 *     'card-one': 'card-one',
 *     'card-two': 'card-two',
 *     'card-three': 'card-three',
 *   },
 *   'exp-1--exp': {
 *     'card-one': 'card-one-alt',
 *     'card-two': 'card-two-alt',
 *     'card-three': 'card-three-alt',
 *   },
 * }
 */
export interface CardSwapMap extends Record<string, unknown> {
  default: Record<string, never>
  [groupName: string]: {
    [expVariation: string]: { [controlCardSlug: string]: string }
  }
}

export const SWAP_MAP: CardSwapMap = Object.freeze({
  default: {},
})
