/* eslint-disable react/display-name */
import React from 'react'
import range from 'lodash/range'
import cn from 'utils/classnames'

export function CardComparisonTableVerticalSkeleton(props: { n: number }) {
  const { n } = props
  return (
    <div
      className={`c-compare-v-skeleton / w-full / shadow-lg rounded-sm ${
        n > 2 ? 'overflow-scroll' : ''
      }`}
    >
      <div
        className={`c-compare-v-skeleton__inner / flex flex-row items-stretch justify-start / w-full / ${
          n > 2 ? 'c-compare-v-skeleton__inner-multiple' : ''
        }`}
      >
        {range(n).map((idx: number) => (
          <div
            key={idx}
            className={cn(
              'c-compare-v-skeleton__tile / flex items-stretch / shrink-0',
              {
                'w-full': n === 1,
                'w-1/2': n === 2,
                'w-1/3': n > 2,
              },
            )}
          >
            <div
              className={`c-compare-v-skeleton__tile-content / flex flex-col items-center justify-between items-stretch / w-full / py-6`}
            >
              <div className="w-full">
                <div className="c-compare-v-skeleton__header /  / mb-4 mx-auto / px-6 border-b-2 border-slate-200">
                  <div
                    className={`c-content-placeholder-bg / flex items-stretch / shrink-0 / w-full max-w-xs / mb-2 mx-auto / rounded overflow-hidden transition-all ${
                      n > 2 ? 'h-24 md:h-28' : 'h-40'
                    }`}
                  ></div>
                  <div className="c-content-placeholder-bg / w-2/3 h-10 max-w-xs / mb-2 mx-auto"></div>
                  <div className="c-content-placeholder-bg / w-full max-w-xs h-10 / mb-4 mx-auto / rounded-sm"></div>
                </div>
                <div className="mb-4 px-6">
                  <div className="c-content-placeholder-bg / w-2/5 h-6 max-w-xs / mb-1.5 mx-auto"></div>
                  <div className="c-content-placeholder-bg / w-full h-16 max-w-xs / mb-4 mx-auto"></div>
                </div>
                <div className="mb-4 px-6">
                  <div className="c-content-placeholder-bg / w-2/5 h-6 max-w-xs / mb-1.5 mx-auto"></div>
                  <div className="c-content-placeholder-bg / w-full h-16 max-w-xs / mb-4 mx-auto"></div>
                </div>
                <div className="px-6">
                  <div className="c-content-placeholder-bg / w-2/5 h-6 max-w-xs / mb-1.5 mx-auto"></div>
                  <div className="c-content-placeholder-bg / w-full h-16 max-w-xs / mx-auto"></div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <style jsx>{`
        /*===== STYLING =====*/
        .c-compare-v-skeleton__tile {
          border-right: 1px solid #e5e5e5;
        }
        .c-compare-v-skeleton__tile:last-child {
          border-right: 0;
        }
        .c-compare-v-skeleton__inner-multiple {
          width: 100%;
          clear: left;
          display: table;
          table-layout: fixed;
        }
        .c-compare-v-skeleton__inner-multiple .c-compare-v-skeleton__tile {
          display: table-cell;
          width: 200px;
        }
        @media (min-width: 768px) {
          .c-compare-v-skeleton__inner-multiple .c-compare-v-skeleton__tile {
            display: table-cell;
            width: 33.333333%;
          }
        }

        /*===== BG =====*/
        .c-content-placeholder-bg {
          position: relative;
          overflow: hidden;
        }
        .c-content-placeholder-bg:before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          display: block;
          width: 70%;
          height: 100%;
          will-change: transform;
          background: #e5e5e5;
          background: -moz-linear-gradient(
            left,
            rgba(26, 32, 44, 0) 0%,
            rgba(26, 32, 44, 1) 50%,
            rgba(255, 255, 255, 0.3) 85%,
            rgba(255, 255, 255, 0) 100%
          );
          background: -webkit-linear-gradient(
            left,
            rgba(26, 32, 44, 0) 0%,
            rgba(26, 32, 44, 1) 50%,
            rgba(255, 255, 255, 0.3) 85%,
            rgba(255, 255, 255, 0) 100%
          );
          background: linear-gradient(
            to right,
            rgba(26, 32, 44, 0) 0%,
            rgba(26, 32, 44, 1) 50%,
            rgba(255, 255, 255, 0.3) 85%,
            rgba(255, 255, 255, 0) 100%
          );
          filter: unquote(
            "progid:DXImageTransform.Microsoft.gradient( startColorstr='#001a202c', endColorstr='#00ffffff',GradientType=1 )"
          );
          opacity: 0.04;
          animation: content-placeholder-animation 1.35s linear infinite;
        }
        @keyframes content-placeholder-animation {
          0% {
            transform: translateX(-80%);
          }
          100% {
            transform: translateX(200%);
          }
        }
      `}</style>
    </div>
  )
}
