import React from 'react'
import Image from 'next/image'
import { ClickawayListener } from '../'
import { nextImageHelper } from '../../utils'

export function PopOverProductSubType(props: {
  disclosureTextAlign?: 'left' | 'right'
  productSubTypeDisclaimer?: string
}) {
  const { productSubTypeDisclaimer } = props
  const [popOverOpen, setPopOverOpen] = React.useState(false)
  const handleAdDiscl = () => setPopOverOpen(!popOverOpen)

  return (
    <ClickawayListener setState={setPopOverOpen}>
      <span className={`inline-block / text-fs13 relative`}>
        <button
          className={`c-popup / text-primary-bright hover:text-primary-mid / opacity-40 transition-all / hover:opacity-70
          ${props.disclosureTextAlign === 'left' ? 'text-left' : 'text-right'}
          `}
          onClick={handleAdDiscl}
        >
          <span className="inline-block / w-4 / mt-1">
            <Image
              alt="Lock Icon"
              src="/static/icons/icon-info-circle-fill-black.svg"
              width={24}
              height={24}
              style={nextImageHelper.responsive}
              sizes="100vw"
            />
          </span>
        </button>
        <span
          className={`c-popup__container / ${
            popOverOpen ? 'block' : 'hidden'
          } absolute cursor-default max-w-md md:max-w-xl / w-full / font-normal text-left text-gray-700 / z-40`}
        >
          <span className="c-popup__content c-popup__arrow / relative inline-block / w-full / rounded-lg px-4 py-3 xs:px-6 xs:py-4 bg-white shadow-lg / z-20">
            {productSubTypeDisclaimer}
          </span>
        </span>
      </span>
      <style jsx>{`
        .c-popup__container {
          transform: translateX(-60%);
        }
        .c-popup__arrow:before {
          right: 85px;
        }
        .c-popup__arrow:after {
          right: 86px;
        }
        @media (min-width: 480px) {
          .c-popup__arrow:before {
            right: 136px;
          }
          .c-popup__arrow:after {
            right: 137px;
          }
        }
        @media (min-width: 768px) {
          .c-popup__container {
            min-width: 34rem;
          }
          .c-popup__arrow:before {
            right: 33px;
          }
          .c-popup__arrow:after {
            right: 34px;
          }
          .c-popup__container {
            transform: translateX(6%);
            right: 0;
          }
        }
      `}</style>
    </ClickawayListener>
  )
}
