import React from 'react'
import {
  PortableText,
  PortableTextListComponent,
  PortableTextListItemComponent,
  PortableTextMarkComponent,
} from '@portabletext/react'

import { blockSerializers } from './index'
import { link } from '../'

import { CompareCredit } from '../../../types/compare-credit'

/* eslint-disable react/display-name */
const List = ({ children }: { children: any }) => (
  <ul className="c-list-disc / mb-2 pl-5">{children}</ul>
)

const ListItem = ({ children }: { children: any }) => {
  return (
    <li className={`c-list-disc__item`}>
      {children}{' '}
      <style jsx>
        {`
          .c-list-disc__item {
            margin-bottom: 0.25em;
          }
          .c-list-disc__item:last-child {
            margin-bottom: 0;
          }
        `}
      </style>
    </li>
  )
}

export const ContentHighlight =
  (data: {
    cardOrderIds: null | Record<string, string>
    referencedCards: null | Record<string, CompareCredit.Entities>
    categoryId: string
    externalId: string
  }) =>
  (props: {
    value: {
      title: string
      contentClass?: string
      border: boolean
      content: any
    }
  }) => {
    const { title, contentClass, border, content } = props.value
    const { cardOrderIds, referencedCards, categoryId, externalId } = data

    return (
      <div
        className={`c-content-highight / mb-6 / p-4 xxs:px-6 xxs:py-5 / bg-primary-light-bg rounded ${
          contentClass && contentClass
        } `}
      >
        <h3 className="c-content-highight__title / mb-2 text-primary text-2xl xs:text-3xl font-serif font-bold leading-snug">
          {title}
        </h3>
        {border ? (
          <hr className="c-content-highight__border / w-24 xs:w-28 / border-3 border-tetriary / my-3" />
        ) : null}
        <div className="c-content-highight__content">
          <PortableText
            value={content}
            components={{
              ...blockSerializers({
                cardOrderIds,
                categoryId,
                externalId,
                referencedCards,
              }),
              list: List as PortableTextListComponent,
              listItem: ListItem as PortableTextListItemComponent,
              marks: {
                link: link as PortableTextMarkComponent,
              },
            }}
          />
        </div>
      </div>
    )
  }
